var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['sl-table-input-wrapper', ( _obj = {
    'sl-table-input-wrapper--disabled': _vm.disabled,
    'sl-table-input-wrapper--editable-bg': _vm.editableBg,
    'sl-table-input-wrapper--number': !_vm.isText,
    'sl-table-input-wrapper--invalid': _vm.isInvalid,
    'sl-table-input-wrapper--read-only': _vm.readOnly
  }, _obj[("color--" + _vm.foreground)] = _vm.foreground, _obj[("background--" + _vm.background)] = _vm.background, _obj )]},[(_vm.isInputVisible)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],ref:"inputRef",staticClass:"sl-table-input",attrs:{"type":"text","maxLength":_vm.maxLength,"disabled":_vm.disabled || _vm.readOnly},domProps:{"value":(_vm.vModel)},on:{"focus":_vm.onInputFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.$refs.inputRef.blur()},"blur":function($event){$event.stopPropagation();return _vm.onSubmit($event)},"input":function($event){if($event.target.composing){ return; }_vm.vModel=$event.target.value}}}):_c('div',{class:['sl-table-input-text', {
      'sl-table-input-text--labeled': _vm.prependLabel
    }],attrs:{"tabindex":"-1"},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.onInputFocus($event)}}},[(_vm.prependLabel)?_c('span',{staticClass:"sl-table-input-text__label"},[_vm._v(" "+_vm._s(_vm.prependLabel)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.inputTextValue)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }