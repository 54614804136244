var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.rootComponent,{tag:"component",staticClass:"sl-tab body-3-md",class:{
    'sl-tab--active': _vm.active,
    'sl-tab--disabled': _vm.disabled,
    'sl-tab--drag-disabled': _vm.dragDisabled,
    'sl-tab--horizontal': _vm.horizontal
  },attrs:{"to":_vm.to}},[(_vm.$scopedSlots.prepend)?_c('div',{staticClass:"sl-tab__prepend"},[_vm._t("prepend")],2):_vm._e(),_c('span',{class:['sl-tab__text', {
      'sl-required': _vm.required
    }]},[_vm._t("default")],2),(_vm.$scopedSlots.append)?_c('div',{staticClass:"sl-tab__append"},[_vm._t("append")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }