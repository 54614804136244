import { checkboxStates } from '@/config/shared/slTable.config';
import { toArray } from '@/helpers/utils/toArray';

export default function(data, rowMap = null, metadataAdapter = null) {
  if (!data) {
    return {};
  }

  let baseMetadata = {};

  if (data.metadata) {
    const { triState, selectedRowsCount, edited, ...metaInfo } = data.metadata;

    baseMetadata = {
      checkbox: triState || checkboxStates.UNCHECKED,
      selectedRowsCount,
      edited,
      ...metaInfo
    };
  }

  return {
    headers: toArray(data.header),
    rows: rowMap
      ? toArray(data.body).map(rowMap)
      : toArray(data.body),
    currentPage: data.currentPageNumber,
    totalRowsCount: data.totalRowsCount,
    metadata: {
      ...(metadataAdapter ? metadataAdapter(data.metadata) : baseMetadata)
    }
  };
}
