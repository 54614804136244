import axios from '@/plugins/axios';

export default {
  getBackups(params) {
    return axios.get('/backups', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  previewBackup(params) {
    return axios.post('/backups/preview', null, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  restoreBackup(params) {
    return axios.post('/backups/restore', null, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  downloadBackup(params) {
    return axios.get('/backups/download', {
      params,
      responseType: 'blob',
      headers: {
        'X-pid-independent': true,
        'Accept': 'application/octet-stream'
      }
    });
  }
};
