import Integrations from '@/views/Integrations/Index.vue';
import { integrationsRouteNames } from '@/config/router/router.config';
import integrationModule from '@/router/middleware/integrations/integrationModule.middleware';
import integrations from '@/router/middleware/integrations/integrations.middleware';
import aggregation from '@/router/middleware/integrations/aggregation.middleware';
export const integrationsRoutes = [
  {
    path: ':projectId/integrations',
    name: integrationsRouteNames.INTEGRATIONS,
    component: Integrations,
    meta: {
      middleware: [integrations]
    }
  },
  {
    path: ':projectId/integration/:integrationId/import/:step',
    name: integrationsRouteNames.IMPORT_INTEGRATION,
    component: () => import('@/views/Integrations/Integration.vue'),
    meta: {
      middleware: [integrationModule]
    }
  },
  {
    path: ':projectId/integration/:integrationId/export/:step',
    name: integrationsRouteNames.EXPORT_INTEGRATION,
    component: () => import('@/views/Integrations/Integration.vue'),
    meta: {
      middleware: [integrationModule]
    }
  },
  {
    path: ':projectId/integrations/aggregation',
    name: integrationsRouteNames.AGGREGATION,
    component: () => import('@/views/Integrations/Aggregation.vue'),
    meta: {
      middleware: [integrations, aggregation]
    }
  }
];
