import { databaseConnectionTypes } from '@/config/integrations/databaseRelated.config';

export default function(settings) {
  const {
    driverType,
    password,
    ...connectionSettings
  } = settings ?? {};

  return {
    driverType: driverType ?? databaseConnectionTypes.ODBC,
    ...connectionSettings,
    password: {
      isSet: password?.isSet ?? false,
      value: ''
    }
  };
}
