<template>
  <SlAccordion
    :mount-open="mountOpen"
    :disabled="disabled"
    :gap="8"
    variant="light"
    class="sl-optional-input"
    inline
  >
    <template #trigger="{ isOpen, toggle }">
      <SlCheckbox
        :value="isOpen"
        :label="label"
        class="sl-accordion__trigger"
        @input="toggle"
        @change="handleCheckboxChange"
      />
    </template>
    <SlValidate
      v-slot="{ invalid }"
      :vid="vid"
      mode="eager"
      :rules="optionalInputRules"
    >
      <SlInput
        v-model="vModel"
        v-bind="$attrs"
        :is-invalid="invalid"
      />
    </SlValidate>
  </SlAccordion>
</template>

<script>
export default {
  name: 'SlOptionalInput',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    vid: {
      type: String,
      required: true
    },
    disabled: Boolean,
    mountOpen: Boolean
  },
  data() {
    return {
      checkboxValue: false
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    optionalInputRules() {
      return {
        required: this.checkboxValue
      };
    }
  },
  mounted() {
    this.checkboxValue = this.mountOpen;
  },
  methods: {
    handleCheckboxChange(value) {
      this.checkboxValue = value;

      if (!value) {
        this.$emit('input', '');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-optional-input';
</style>
