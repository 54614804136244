import Vue from 'vue';
import databaseRelatedApi from '@/api/integrations/databaseRelated';
import QueryExportAdapter from '@/adapters/request/integrations/transform/shared/QueryExport.adapter';
import {
  tabKeys,
  exportTabKeys,
  exportInitialValuesByTab
} from '@/config/integrations/transform.config';

const exportTabsState = () => {
  return exportTabKeys.reduce((acc, tab) => {
    acc[tab] = {
      query: '',
      stubs: [],
      ...(exportInitialValuesByTab[tab] && exportInitialValuesByTab[tab])
    };

    return acc;
  }, {});
};

const types = {
  SET_SETTINGS: 'SET_SETTINGS',
  UPDATE_TAB_SETTINGS: 'UPDATE_TAB_SETTINGS',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  [tabKeys.SETTINGS]: {},
  ...exportTabsState()
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_SETTINGS](state, value) {
    Object.keys(value).forEach(key => {
      if (typeof state[key] !== 'object') {
        state[key] = value[key];

        return;
      }

      if (state[key] !== undefined) {
        Object.keys(value[key]).forEach(subKey => {
          Vue.set(state[key], subKey, value[key][subKey]);
        });
      }
    });
  },
  [types.UPDATE_TAB_SETTINGS](state, { tab, key, value }) {
    Vue.set(state[tab], key, value);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setSettings({ commit }, value) {
    commit(types.SET_SETTINGS, value);
  },
  updateTabSettings({ commit }, payload) {
    commit(types.UPDATE_TAB_SETTINGS, payload);

    this.dispatch('integrations/setIsEdited', true);
  },
  async fetchExportStubs({ dispatch }, tab) {
    try {
      const response = await databaseRelatedApi.getExportStubs({ tab });
      const data = response?.data?.stubs;

      if (!data) {
        return;
      }

      dispatch('updateTabSettings', {
        tab,
        key: 'stubs',
        value: data.map(stub => ({
          label: stub.name,
          value: stub.value
        }))
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'integrations/fetchExportStubs' });
    }
  },
  async testExport({ state, rootState }, tab) {
    try {
      const { id } = rootState.integrations.active_integration;
      const response = await databaseRelatedApi.testExport(
        {
          id,
          tab
        },
        QueryExportAdapter(state, tab)
      );

      return response?.data?.successful;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'integrations/testExport' });

      throw e;
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
