import axios from '@/plugins/axios';

export default {
  checkModules(params, body) {
    return axios.post('/odoo/modules', body, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
