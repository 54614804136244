<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <div class="connector-content-block__row start w-240">
      <SlValidate
        v-slot="{ invalid }"
        vid="nMonthsToDropToReceive"
        mode="eager"
        rules="required|between:0,120"
      >
        <SlInput
          v-model="nMonthsToDropToReceive"
          :label="$t('Web.BaseConnectors.Form.nMonthsToDropToReceive')"
          :description="$t('Web.BaseConnectors.Form.nMonthsToDropToReceiveSubtitle')"
          :is-invalid="invalid"
          step="1"
          type="number"
        />
      </SlValidate>
    </div>
    <SlCheckbox
      v-if="isNew"
      v-model="combineLocs"
      :label="$t('Web.BaseConnectors.Form.combineLocs')"
    />
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';

export default {
  name: 'UnleashedOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.UNLEASHED
    };
  },
  computed: {
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    nMonthsToDropToReceive: {
      get() {
        return +this.connectionData.optional.nMonthsToDropToReceive || 0;
      },
      set(value) {
        this.setOptionalValue({
          key: 'nMonthsToDropToReceive',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    }
  }
};
</script>
