<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div class="connector-content-block__row">
      <SlInput
        v-model="usernameModel"
        :label="$t('Web.DbRelatedConnectors.Form.LabelFishbowlUsername')"
        class="w-320"
      />
    </div>
    <div class="connector-content-block__row">
      <SlPasswordInput
        v-model="passwordModel.value"
        :label="$t('Web.DbRelatedConnectors.Form.LabelFishbowlPassword')"
        class="w-320"
        :is-set="passwordModel.isSet"
      />
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';

export default {
  name: 'Export',
  components: {
    ConnectorContentBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.EXPORT]
    }),
    usernameModel: {
      get() {
        return this.tabData?.username;
      },
      set(value) {
        this.setSettings({ value, key: 'username' });
      }
    },
    passwordModel: {
      get() {
        return this.tabData?.password;
      },
      set(value) {
        this.setSettings({
          value: {
            isSet: this.tabData?.password?.isSet,
            value
          },
          key: 'password'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/settings.scss";
</style>