<template>
  <div
    :id="id"
    ref="slModalWrapper"
    class="sl-modal__wrapper"
    :class="{
      'sl-modal__wrapper--active': modalValue,
      'sl-modal__wrapper--fullscreen': fullscreen || maximized,
      'sl-modal__wrapper--nested': isNested,
      [`sl-modal__wrapper--${position}`]: true
    }"
    tabindex="0"
  >
    <slot
      v-if="modalValue"
      name="modals"
    />
    <transition name="fade">
      <div
        v-if="modalValue"
        class="sl-modal__overlay"
      />
    </transition>
    <transition name="fade-down">
      <div
        v-if="modalValue"
        v-click-outside="vcoConfig"
        class="sl-modal"
        :class="{
          'sl-modal--no-header': noHeader,
          'sl-modal--updating': updating
        }"
        :style="modalStyles"
      >
        <slot
          v-if="$scopedSlots.loader"
          name="loader"
        />
        <SlOverlay
          v-else
          :show="loading"
        />
        <div
          v-if="!noHeader"
          class="sl-modal__header"
        >
          <div class="sl-modal__header-title heading-4-sb grey-90">
            {{ title }}
            <transition name="fade">
              <SlLoader
                v-if="updating"
                size="xxs"
                :text="false"
              />
            </transition>
          </div>
          <div
            v-if="subtitle"
            class="sl-modal__header-subtitle body-1 grey-70"
          >
            {{ subtitle }}
          </div>
          <div class="sl-modal__header-actions">
            <SlButton
              v-if="maximizable"
              v-tooltip.bottom="getMaximizeTooltip"
              variant="tertiary"
              color="grey"
              size="xs"
              icon
              @click="handleMaximize"
            >
              <icon
                :data="getMaximizeIcon"
                class="fill-off size-20"
              />
            </SlButton>
            <SlButton
              v-tooltip.bottom="getTooltip($t('Web.Modals.DiscardAndClose'))"
              variant="tertiary"
              color="grey"
              size="xs"
              icon
              @click="handleClose"
            >
              <icon
                data="@icons/close.svg"
                class="fill-off size-24"
              />
            </SlButton>
          </div>
        </div>

        <div
          v-if="$scopedSlots.alert"
          class="sl-modal__alert"
        >
          <slot name="alert" />
        </div>

        <div
          ref="scrollContainer"
          class="sl-modal__scroll-container"
          :class="{
            'sl-modal__scroll-container--border-top': hasVerticalScrollbar && !scrolledToTop,
            'sl-modal__scroll-container--border-bottom': hasVerticalScrollbar && !scrolledToBottom
          }"
          @scroll.passive="updateScroll"
        >
          <slot />
        </div>

        <slot name="footer" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import { modal } from '@/mixins/modal';
import { scroll } from '@/mixins/scroll';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlModal',
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [modal, scroll],
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 600
    },
    maxWidth: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'center',
      validator: (val) => ['top', 'center'].includes(val)
    },
    closeCallback: {
      type: [Function, null],
      default: null
    },
    persistent: Boolean,
    disabled: Boolean,
    fullscreen: Boolean,
    maximizable: Boolean,
    loading: Boolean,
    noHeader: Boolean,
    updating: Boolean
  },
  data() {
    return {
      eventHandlers: [
        {
          // pass data to current modal (need for reuse shared modals)
          name: 'pass-data',
          handler: ({ id, ...data }) => {
            if (id === this.id) {
              this.$emit('created', data);
            }
          }
        },
        {
          name: `show:${this.id}`,
          handler: () => this.$emit('show')
        },
        {
          name: `shown:${this.id}`,
          handler: () => this.$emit('shown')
        },
        {
          name: `hide:${this.id}`,
          handler: () => this.$emit('hide')
        },
        {
          name: `hidden:${this.id}`,
          handler: () => this.$emit('hidden')
        }
      ],
      isNested: false,
      maximized: false,
      getTooltip
    };
  },
  computed: {
    ...mapGetters({
      modalValueById: 'modals/modalValueById'
    }),
    modalValue() {
      return this.modalValueById(this.id);
    },
    vcoConfig() {
      return {
        handler: this.handleClose,
        middleware: this.vcoMiddleware,
        isActive: !this.persistent && !this.loading,
        events: ['click']
      };
    },
    modalStyles() {
      const width = this.fullscreen || this.maximized ? '100%' : `${this.width}px`;

      return {
        minWidth: width,
        maxWidth: this.maxWidth ? `${this.maxWidth}px` : width
      };
    },
    getMaximizeIcon() {
      return require(`@icons/${this.maximized ? 'fullscreen_close.svg' : 'fullscreen.svg'}`);
    },
    getMaximizeTooltip() {
      const content = this.maximized ? this.$t('Web.Modals.BtnExit') : this.$t('Web.Modals.Fullscreen');

      return this.getTooltip(content);
    }
  },
  watch: {
    modalValue(newVal) {
      if (newVal) {
        this.isNested = !!this.getActiveModalNodeList().length;

        document.body.appendChild(this.$el);

        this.$refs.slModalWrapper.focus();
        document.addEventListener('keydown', this.handleKeydown);
      } else {
        this.removeFromBody();
        this.retainFocus();
        document.removeEventListener('keydown', this.handleKeydown);
      }
    }
  },
  created() {
    this.eventHandlers.forEach(item => {
      this.$root.$on(item.name, item.handler);
    });
  },
  beforeDestroy() {
    this.eventHandlers.forEach(item => {
      this.$root.$off(item.name, item.handler);
    });

    this.hideModal(this.id);

    if (this.modalValue) {
      this.removeFromBody();
    }

    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    vcoMiddleware(e) {
      return this.id === e.target.parentNode.id;
    },
    removeFromBody() {
      if (document.body.contains(this.$el)) {
        document.body.removeChild(this.$el);
      }
    },
    retainFocus() {
      const nodeList = this.getActiveModalNodeList();
      const modal = nodeList.item(nodeList.length - 1);

      modal && modal.focus();
    },
    getActiveModalNodeList() {
      return document.body.querySelectorAll('.sl-modal__wrapper--active');
    },
    checkIsActiveModal() {
      const modals = this.getActiveModalNodeList();

      // emit event only for the last active modal
      return modals[modals.length - 1] === this.$el;
    },
    handleClose() {
      if (this.closeCallback) {
        return this.closeCallback();
      }

      this.hideModal(this.id);
    },
    handleEnter() {
      this.$emit('on-enter');
    },
    handleKeydown(e) {
      if (!this.checkIsActiveModal()) {
        return;
      }

      if (e.keyCode === keyCodes.enter) {
        return this.handleEnter();
      }

      if (e.keyCode === keyCodes.esc) {
        return this.handleClose();
      }
    },
    handleMaximize() {
      this.maximized = !this.maximized;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/modals/sl-modal/index';
</style>
