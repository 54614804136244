import { tabKeys } from '@/config/integrations/transform.config';
import { DEFAULT_START_DATE } from '@/config/connection';
import { formatTableName } from '@/helpers/sql';

export default function(state, { tab, table }) {
  const data = state[tab];

  return {
    sqlQuery: table
      ? `SELECT * FROM ${formatTableName(table)}`
      : data.query,
    tab: table ? null : tab,
    startDate: state[tabKeys.SETTINGS].startDate || DEFAULT_START_DATE
  };
}
