<template>
  <label
    tabindex="0"
    class="sl-radio-button-wrapper"
    :class="{
      'sl-radio-button-wrapper--checked': isSelected,
      'sl-radio-button-wrapper--disabled': disabled,
      'sl-radio-button-wrapper--no-icon': !icon
    }"
  >
    <input
      v-show="false"
      :data-test-id="dataTestId"
      class="sl-radio-button"
      type="radio"
      :name="name"
      :checked="checkedValue"
      :disabled="disabled"
      @change="onChange"
    >
    <span
      v-if="label"
      class="sl-radio-button__label body-1-md grey-80"
    >
      {{ label }}
    </span>
    <span
      v-if="description"
      class="sl-radio-button__label-description body-1 grey-70"
    >
      {{ description }}
    </span>
    <slot />
  </label>
</template>

<script>
export default {
  name: 'SlRadioButton',
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    selected: {
      type: [Boolean, String, Number],
      default: true
    },
    notSelected: {
      type: [Boolean, String, Number],
      default: false
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    icon: {
      type: Boolean,
      default: true
    },
    disabled: Boolean
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    isSelected() {
      return this.vModel === this.selected;
    },
    checkedValue() {
      return this.isSelected ? 'checked' : '';
    }
  },
  methods: {
    onChange(event) {
      this.vModel = event.target.checked ? this.selected : this.notSelected;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-radio-button';
</style>
