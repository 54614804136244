<template>
  <div
    class="sl-table__side-col"
    :class="{
      'sl-table__side-col--scrolled-right': scrolledRight,
      'sl-table__side-col--scrolled-left': scrolledLeft,
      'sl-table__side-col--last': last
    }"
  >
    <slot />
    <i
      v-if="shadow"
      class="sl-table__side-col-shadow"
      :style="{
        height: height ? `${height}px` : '100%'
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'SlTableSideCol',
  props: {
    height: {
      type: Number,
      default: 0
    },
    scrolledRight: Boolean,
    scrolledLeft: Boolean,
    shadow: Boolean,
    last: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/sl-table-side-col.scss";
</style>
