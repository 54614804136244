import lastChangeApi from '@/api/lastChange';
import { fetchUpdatedData } from '@/helpers/lastChange/fetchUpdatedData';

const LAST_CHANGE_INTERVAL = 10 * 1000;

const types = {
  SET_TIMESTAMP: 'SET_TIMESTAMP',
  SET_UPDATE_STATUS: 'SET_UPDATE_STATUS'
};

const state = () => ({
  interval: null,
  timestamp: null,
  canUpdate: true
});

const getters = {};

const mutations = {
  [types.SET_TIMESTAMP](state, value) {
    state.timestamp = Object.assign({}, state.timestamp, value);
  },
  [types.SET_UPDATE_STATUS](state, value) {
    state.canUpdate = value;
  }
};

const actions = {
  initLastChangeInterval({ state, rootState, dispatch }) {
    if (!rootState.manageProjects.projectId) {
      return;
    }

    dispatch('clearUpdateInterval');
    dispatch('checkForLastChange');

    state.interval = setInterval(() => {
      dispatch('checkForLastChange');
    }, LAST_CHANGE_INTERVAL);
  },
  async checkForLastChange({ state, dispatch }) {
    if (!state.canUpdate) {
      return;
    }

    try {
      const response = await lastChangeApi.getLastChange();
      const currentTimestamp = response?.data;

      if (!currentTimestamp) {
        return;
      }

      if (!state.timestamp) {
        return dispatch('setTimestamp', currentTimestamp);
      }

      const requestsList = Object.keys(currentTimestamp).reduce((acc, key) => {
        if (state.timestamp[key] !== currentTimestamp[key]) {
          acc.push(key);
        }

        return acc;
      }, []);

      if (requestsList.length) {
        dispatch('setTimestamp', currentTimestamp);

        fetchUpdatedData(requestsList);
      }
    } catch (e) {
      dispatch('clearUpdateInterval');
      this.dispatch('user/logout', { e, from: 'lastChangeInterval' });
    }
  },
  clearUpdateInterval({ state }) {
    clearInterval(state.interval);
  },
  setUpdateStatus({ commit }, value) {
    commit(types.SET_UPDATE_STATUS, value);
  },
  setTimestamp({ commit }, payload) {
    // todo rm after command result json implementation
    const parsed = Object.keys(payload).reduce((acc, key) => {
      acc[key] = +payload[key];

      return acc;
    }, {});

    commit(types.SET_TIMESTAMP, parsed);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
