export default function(settings) {
  const {
    password,
    ...connectionSettings
  } = settings ?? {};

  return {
    ...connectionSettings,
    password: {
      isSet: password?.isSet ?? false,
      value: ''
    }
  };
}
