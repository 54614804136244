import axios from '@/plugins/axios';

export default {
  createSupplier(body) {
    return axios.post('/suppliers/temporary_records', body);
  },
  deleteSuppliers() {
    return axios.delete('/suppliers/temporary_records');
  },
  getSuppliersTable(params) {
    return axios.get('/suppliers', { params });
  },
  overrideSupplier(params, body) {
    return axios.put('/suppliers/override', body, { params });
  },
  applySupplierOverrides(body) {
    return axios.post('/suppliers', body);
  },
  deleteSupplierOverrides() {
    return axios.delete('/suppliers/overrides');
  },
  getSuppliersList(params) {
    return axios.get('/suppliers/search', { params });
  },
  getSupplierNames(params) {
    return axios.get('/suppliers/names', { params });
  },
  exportToXlsx() {
    return axios.get('/suppliers/xlsx', {
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }
};
