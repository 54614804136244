import { routeNames } from '@/config/router/router.config';
import { integrationStatuses } from '@/config/integrations';

export default function aggregation({ next, store, nextMiddleware }) {
  const importIntegrations = store.getters['integrations/importIntegrations'];
  const isAvailable = importIntegrations.every(integration => integration.status === integrationStatuses.POPULATED);

  if (!isAvailable) {
    return next({
      name: routeNames.INTEGRATIONS,
      params: {
        projectId: store.state.manageProjects.projectId
      }
    });
  }

  return nextMiddleware();
}
