import { groupBy } from '@/config/connection';
import { composeDateKeys } from '@/config/connection/spreadsheet.config';

export default function(data, isUpdate = false) {
  if (!data) {
    return {};
  }

  if (isUpdate) {
    return {
      csvDelimiter: data._csvDelimiter ?? '',
      headerRowsCount: data._HeaderRowsCount ?? 1
    };
  }

  return {
    groupBy: data._groupBy ?? groupBy.MONTH,
    csvDelimiter: data._csvDelimiter ?? '',
    startFrom: data._groupBy !== groupBy.MONTH ? (data._start ?? 0) : 1,
    composeDate: data._composeDate ?? composeDateKeys.NONE,
    headerRowsCount: data._HeaderRowsCount ?? 1,
    uniteLocations: data._stickLocations === '1',
    zeroPriceTransactions: data._zeroPriceTransactions === '1'
  };
}
