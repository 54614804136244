import Vue from 'vue';
import batchesApi from '@/api/item/batchesOnHand';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { getTableConfigParams, getTableConfigStructure, sortingParamsAdapter } from '@/helpers/shared/tableConfig';

const types = {
  SET_EXPIRATIONS: 'SET_EXPIRATIONS',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const state = () => ({
  expirations: null,
  tableConfig: getTableConfigStructure()
});

const getters = {};

const mutations = {
  [types.SET_EXPIRATIONS](state, value) {
    state.expirations = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state.tableConfig, payload);
  }
};

const actions = {
  async fetchBatchesOnHand({ state, commit, rootGetters }) {
    try {
      const response = await batchesApi.getBatchesOnHand({
        nodeId: rootGetters['demand/tree/firstNodeId'],
        ...getTableConfigParams(state.tableConfig)
      });
      const table = response.data;

      if (table) {
        commit(types.SET_EXPIRATIONS, GenericTableAdapter(table));
        commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(table));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchBatchesOnHand' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  exportBatchesOnHandToXlsx({ rootGetters }) {
    return batchesApi.exportBatchesOnHandToXlsx({
      nodeId: rootGetters['demand/tree/firstNodeId']
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
