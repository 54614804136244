<template>
  <div
    class="sl-table__header"
    :class="{
      'sl-table__header--scrolled': scrolled
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlTableHeader',
  props: {
    scrolled: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/sl-table-header.scss";
</style>