import store from '@/store';
import { fetchUpdatedData } from '@/helpers/lastChange/fetchUpdatedData';
import { toArray } from '@/helpers/utils/toArray';
import { ignoredRequests, updateRequestMethods } from '@/config/api/helperRequests.config';

export const updateResponse = async(response, method) => {
  const isUpdateRequest = updateRequestMethods.includes(method);
  const isIgnoredRequest = ignoredRequests.includes(response?.config?.url);

  if (!isUpdateRequest || isIgnoredRequest) {
    return;
  }

  const requests = response?.data?.uiFlags || response?.data?.data?.uiFlags;

  if (requests) {
    return fetchUpdatedData(toArray(requests));
  }
};

export const updateLastChangeStamps = async(response) => {
  const legacyFormatStamps = toArray(response?.data?.timestamped?.uiStamps?.stamp);
  const newFormatStamps = response?.data?.uiStamps ?? {};
  const formattedLegacyFormatStamps = legacyFormatStamps.reduce((acc, { _module, _val }) => {
    if (_val) {
      acc[_module] = _val;
    }

    return acc;
  }, {});
  const stamps = Object.assign({}, formattedLegacyFormatStamps, newFormatStamps);

  if (stamps) {
    return store.dispatch('lastChanges/setTimestamp', stamps);
  }
};
