export const connectionData = () => ({
  company: '',
  consumerKey: '',
  consumerSecretKey: '',
  token: '',
  secretToken: ''
});

export const entities = {
  EMPLOYEE: 'employee',
  SUBSIDIARY: 'subsidiary'
};