import TabsImportAdapter from '@/adapters/request/integrations/transform/shared/TabsImport.adapter';
import TabsExportAdapter from '@/adapters/request/integrations/transform/shared/TabsExport.adapter';
import { tabKeys } from '@/config/integrations/transform.config';
import { integrationExportTypes, integrationModuleTypes } from '@/config/integrations';

const importAdapter = (settings) => {
  return {
    ...settings[tabKeys.SETTINGS],
    tables: TabsImportAdapter(settings)
  };
};

const exportAdapter = (settings, { type, exportType }) => {
  const { legacyExportMode, ...orderColumnNames } = settings[tabKeys.SETTINGS];

  return {
    type,
    exportType,
    legacyExportMode,
    ...(exportType === integrationExportTypes.PLANNED_ORDERS && { orderColumnNames }),
    exportScripts: TabsExportAdapter(settings)
  };
};

export default function(settings, integration) {
  const adapterByModule = {
    [integrationModuleTypes.IMPORT]: importAdapter,
    [integrationModuleTypes.EXPORT]: exportAdapter
  };

  return adapterByModule[integration.module](settings, integration);
}
