export default function(settings) {
  const {
    configuration,
    connection
  } = settings;

  const { startDate } = configuration;
  const { pageLimit } = connection;

  return {
    ...connection,
    startDate,
    pageLimit: pageLimit || 0
  };
}