import { customThrottle } from '@/helpers/shared/listeners';

export const ResizeObserve = {
  inserted(el, binding) {
    const handleResize = customThrottle(entries => {
      const element = entries[0].target;

      binding.value.call(null, element, entries);
    }, 50);

    const observer = new ResizeObserver(handleResize);

    observer.observe(el);
  }
};