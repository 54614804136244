import modalsId from '@/config/shared/modalsId.config';

const initialState = () => {
  return Object.values(modalsId).reduce((store, key) => {
    store[key] = false;

    return store;
  }, {});
};

const types = {
  TOGGLE_MODAL_BY_ID: 'TOGGLE_MODAL_BY_ID',
  RESET_STATE: 'RESET_STATE'
};

const state = () => initialState();

const mutations = {
  [types.TOGGLE_MODAL_BY_ID](state, { id, value }) {
    if (state[id] !== undefined) {
      state[id] = value;
    }
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const getters = {
  modalValueById: state => id => state[id],
  someModalOpen: state => Object.values(state).some(modalValue => modalValue)
};

const actions = {
  toggleModal({ commit }, payload) {
    commit(types.TOGGLE_MODAL_BY_ID, payload);
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
