import { exportTypes } from '@/config/report/inventoryReport/index';
import { routeNames } from '@/config/router/router.config';

export const plannedOrdersRoute = {
  [routeNames.INVENTORY]: routeNames.INVENTORY_PLANNED_ORDERS,
  [routeNames.INTERSTORE]: routeNames.INTERSTORE_PLANNED_ORDERS
};

export const sourceByRoute = {
  [routeNames.INVENTORY_PLANNED_ORDERS]: {
    route: routeNames.INVENTORY,
    locale: 'Main.tabNames.inventory'
  },
  [routeNames.INTERSTORE_PLANNED_ORDERS]: {
    route: routeNames.INTERSTORE,
    locale: 'Main.tabNames.transfers'
  }
};

export const exportOptions = (ctx) => ([
  {
    label: ctx.$t('PlannedOrders.Ui.btExportToExcel'),
    value: exportTypes.XLSX
  },
  {
    label: ctx.$t('PlannedOrders.Ui.btExportToCsv'),
    value: exportTypes.CSV
  }
]);

export const plannedOrdersTypes = {
  ANY: 'any',
  PURCHASE: 'purchase',
  TRANSFER: 'transfer',
  MANUFACTURE: 'manufacture'
};

export const plannedOrdersTabs = [
  plannedOrdersTypes.ANY,
  plannedOrdersTypes.PURCHASE,
  plannedOrdersTypes.TRANSFER,
  plannedOrdersTypes.MANUFACTURE
];

export const orderTypeByTypeKey = {
  [plannedOrdersTypes.ANY]: 'None',
  [plannedOrdersTypes.PURCHASE]: 'Purchase',
  [plannedOrdersTypes.TRANSFER]: 'Transfer',
  [plannedOrdersTypes.MANUFACTURE]: 'Manufacture'
};

export const plannedOrdersConfig = (ctx) => ([
  {
    label: ctx.$t('Main.Lv.ByAll'),
    value: plannedOrdersTypes.ANY
  },
  {
    label: ctx.$t('Main.OrderType.Purchase'),
    value: plannedOrdersTypes.PURCHASE
  },
  {
    label: ctx.$t('Main.OrderType.Transfer'),
    value: plannedOrdersTypes.TRANSFER
  },
  {
    label: ctx.$t('Main.OrderType.Manufacture'),
    value: plannedOrdersTypes.MANUFACTURE
  }
]);

export const exportErrorTypes = {
  ERROR: 'error',
  CONNECTOR_ISSUE: 'ConnectorIssue',
  QB_ISSUE: 'QbIssue',
  INFO_MSG: 'InfoMsg'
};
