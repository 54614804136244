import axios from '@/plugins/axios';

export default {
  getAccounts(params) {
    return axios.get('/qb/accounts', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
