import { connectionTypes } from '@/config/connection';
import dearAdapter from './connectors/dear';
import unleashedAdapter from './connectors/unleashed';
import prontoAdapter from './connectors/pronto';
import cin7Adapter from './connectors/cin7';
import shopifyAdapter from './connectors/shopify';
import customAdapter from './connectors/custom';
import spreadsheetAdapter from './spreadsheet/SpreadsheetSettings.adapter';
import exactAdapter from './connectors/exact';
import sellercloudAdapter from './connectors/sellercloud';
import bc365Adapter from './connectors/bc365';
import finaleAdapter from './connectors/finale';
import spireAdapter from './connectors/spire';
import QBAdapter from './connectors/qb';
import skubanaAdapter from './connectors/skubana';
import dbRelatedAdapter from './dbRelated';

export default function connectionAdapter(data, params) {
  const commonData = commonAdapter(data);

  if (!commonData) {
    return null;
  }

  const adapter = adapterByType[commonData.type];

  return {
    type: commonData.type,
    value: {
      commonData,
      ...adapter(data, params)
    }
  };
}

const adapterByType = {
  [connectionTypes.DEAR]: dearAdapter,
  [connectionTypes.CIN7]: cin7Adapter,
  [connectionTypes.UNLEASHED]: unleashedAdapter,
  [connectionTypes.PRONTO_XI]: prontoAdapter,
  [connectionTypes.SHOPIFY]: shopifyAdapter,
  [connectionTypes.DATABASE]: dbRelatedAdapter,
  [connectionTypes.CUSTOM_API]: customAdapter,
  [connectionTypes.SPREADSHEET_AGGREGATED]: spreadsheetAdapter,
  [connectionTypes.SPREADSHEET_TRANSACTIONAL]: spreadsheetAdapter,
  [connectionTypes.EXACT]: exactAdapter,
  [connectionTypes.SELLERCLOUD]: sellercloudAdapter,
  [connectionTypes.BUSINESS_CENTRAL]: bc365Adapter,
  [connectionTypes.FINALE]: finaleAdapter,
  [connectionTypes.SPIRE]: spireAdapter.connection,
  [connectionTypes.QB_DESKTOP]: QBAdapter.desktop,
  [connectionTypes.QB_ONLINE]: QBAdapter.online,
  [connectionTypes.NETSUITE]: dbRelatedAdapter,
  [connectionTypes.SKUBANA]: skubanaAdapter,
  [connectionTypes.DYNAMICS_NAV]: dbRelatedAdapter,
  [connectionTypes.SAP]: dbRelatedAdapter,
  [connectionTypes.FISHBOWL]: dbRelatedAdapter,
  [connectionTypes.DYNAMICS_GP]: dbRelatedAdapter,
  [connectionTypes.ODOO]: dbRelatedAdapter
};

const commonAdapter = (data) => {
  if (!data) {
    return null;
  }

  const {
    _startDate: startDate,
    _groupBy: groupBy
  } = data.settings?.info ?? {};

  return {
    type: data._type,
    startDate,
    groupBy
  };
};
