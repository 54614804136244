import { keyCodes } from '@/config/utils/statusCodes.config';
import { customDebounce, getCtrl } from '@/helpers/shared/listeners';

// v-undo-redo="{
//    watch: 'vModel', field to be watched & used to apply undo redo changes
//    watchTimeout: 200 watch debounce timeout
// }"

export const UndoRedo = {
  inserted: (el, binding, vnode) => {
    const watchField = binding.value?.watch || 'vModel';
    const watchTimeout = binding.value?.watchTimeout || 200;
    const stack = [vnode.context[watchField]];
    let isOperation = false;
    let index = -1;

    vnode.context.$watch(watchField, customDebounce((val) => {
      // prevent write undo/redo changes
      if (isOperation || stack.at(-1) === val) {
        isOperation = false;

        return;
      }

      // reset changes after undo
      if (index !== -1) {
        stack.splice(index + 1,  Math.abs(index + 1), val);
        index = -1;

        return;
      }

      stack.push(val);
    }, watchTimeout));

    const handleUndoRedo = (e) => {
      const ctrlShiftZ = getCtrl(e) && e.shiftKey && e.keyCode === keyCodes.z;
      const ctrlY = getCtrl(e) && e.keyCode === keyCodes.y;
      const ctrlZ = getCtrl(e) && e.keyCode === keyCodes.z;

      if (ctrlY || ctrlShiftZ) {
        e.preventDefault();

        if (index >= -1) {
          return;
        }

        const next = stack.at(index + 1);

        if (next !== undefined) {
          isOperation = true;
          vnode.context[watchField] = next;
          index += 1;
        }

        return;
      }

      if (ctrlZ) {
        e.preventDefault();

        const prev = stack.at(index - 1);

        if (prev !== undefined) {
          isOperation = true;
          vnode.context[watchField] = prev;
          index -= 1;
        }
      }
    };

    el.addEventListener('keydown', handleUndoRedo);
  }
};