export default function({ integrations, module, exportType }) {
  if (!integrations) {
    return [];
  }

  return integrations?.map((item) => {
    const { connector, state: status, ...params } = item;

    return {
      ...connector,
      ...params,
      ...(exportType && { exportType }),
      status,
      module
    };
  });
}