import { gzipSync } from 'fflate';

export const gzipFile = async(file) => {
  const buffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(buffer);
  const gzipped = gzipSync(uint8Array);

  return new File([gzipped], file.name, {
    type: 'application/gzip'
  });
};