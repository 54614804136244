import CommonSettingsAdapter from '@/adapters/response/connection/dbRelated/shared/CommonSettings.adapter';
import { tabKeys } from '@/config/connection/dbRelated.config';

export default function(sets, isDefault) {
  const {
    _host: host,
    _port: port,
    _companyKey: companyKey
  } = sets.advanceSettings;
  const commonSettings = CommonSettingsAdapter(sets.innerSettings, isDefault);

  return {
    ...commonSettings,
    [tabKeys.EXPORT]: {
      ...commonSettings[tabKeys.EXPORT],
      companyKey,
      port,
      host
    }
  };
}

