import { matchKeys, tabKeys, importTabKeys } from '@/config/integrations/spreadsheet.config';
import TableSlotsAdapter from '@/adapters/request/integrations/transform/shared/TableSlots.adapter';

export default function(state) {
  const tables = importTabKeys.reduce((acc, tab) => {
    const matchedTab = state.match_sheets[tab];
    const matchedFileId = matchedTab[matchKeys.FILE];
    const matchedSheet = matchedTab[matchKeys.SHEET];
    const matchedFile = state.files.find(file => file.id === matchedFileId);
    const isMatched = matchedFile !== null && matchedSheet !== null;

    if (!isMatched) {
      return acc;
    }

    acc[tab] = {
      index: matchedSheet,
      fileName: matchedFile?.name,
      slots: TableSlotsAdapter(state[tab])
    };

    return acc;
  }, {});

  return {
    ...state[tabKeys.SETTINGS],
    tables
  };
}
