export const SEARCH_RESULT_PORTION = 20;
export const ROOT_NODE_ID = 0;

export const treeOrderKeys = {
  ITEM_LOCATION_CHANNEL: 'item_loc_chan',
  ITEM_CHANNEL_LOCATION: 'item_chan_loc',
  LOCATION_ITEM_CHANNEL: 'loc_item_chan',
  LOCATION_CHANNEL_ITEM: 'loc_chan_item',
  CHANNEL_ITEM_LOCATION: 'chan_item_loc',
  CHANNEL_LOCATION_ITEM: 'chan_loc_item'
};

export const treeOrders = (ctx) => {
  if (ctx) {
    return [
      { name: ctx.$t('Main.By.ItemLocation'), order: treeOrderKeys.ITEM_LOCATION_CHANNEL },
      { name: ctx.$t('Main.By.ItemChannel'), order: treeOrderKeys.ITEM_CHANNEL_LOCATION },
      { name: ctx.$t('Main.By.LocationItem'), order: treeOrderKeys.LOCATION_ITEM_CHANNEL },
      { name: ctx.$t('Main.By.LocationChannel'), order: treeOrderKeys.LOCATION_CHANNEL_ITEM },
      { name: ctx.$t('Main.By.ChannelItem'), order: treeOrderKeys.CHANNEL_ITEM_LOCATION },
      { name: ctx.$t('Main.By.ChannelLocation'), order: treeOrderKeys.CHANNEL_LOCATION_ITEM }
    ];
  }

  return [
    treeOrderKeys.ITEM_LOCATION_CHANNEL,
    treeOrderKeys.ITEM_CHANNEL_LOCATION,
    treeOrderKeys.LOCATION_ITEM_CHANNEL,
    treeOrderKeys.LOCATION_CHANNEL_ITEM,
    treeOrderKeys.CHANNEL_ITEM_LOCATION,
    treeOrderKeys.CHANNEL_LOCATION_ITEM
  ];
};

export const noLocationTreeOrders = (ctx) => {
  if (ctx) {
    return [
      { name: ctx.$t('Main.By.Item'), order: treeOrderKeys.ITEM_CHANNEL_LOCATION },
      { name: ctx.$t('Main.By.Channel'), order: treeOrderKeys.CHANNEL_ITEM_LOCATION }
    ];
  }

  return [
    treeOrderKeys.ITEM_CHANNEL_LOCATION,
    treeOrderKeys.CHANNEL_ITEM_LOCATION
  ];
};

export const noChannelTreeOrders = (ctx) => {
  if (ctx) {
    return [
      { name: ctx.$t('Main.By.Item'), order: treeOrderKeys.ITEM_LOCATION_CHANNEL },
      { name: ctx.$t('Main.By.Location'), order: treeOrderKeys.LOCATION_ITEM_CHANNEL }
    ];
  }

  return [
    treeOrderKeys.ITEM_LOCATION_CHANNEL,
    treeOrderKeys.LOCATION_ITEM_CHANNEL
  ];
};

export const treeItemStates = {
  WARN_ABANDONED: 'WarnAbandoned',
  WARNING: 'Warning',
  WARN_MIXED: 'WarnMixed',
  NORMAL: 'Normal',
  CHECKED: 'Checked'
};

export const needAttentionKeys = [
  treeItemStates.WARNING,
  treeItemStates.WARN_MIXED,
  treeItemStates.WARN_ABANDONED
];

export const treeFilterKeys = {
  ORDER: 'order',
  CATEGORIES: 'categories',
  ABC_ANALYSIS: 'wantAbc',
  HIDE_STOCK: 'wantHideDistortions',
  HIDE_INACTIVE: 'wantHideInactive'
};
