import Vue from 'vue';

const types = {
  SET_CONNECTION: 'SET_CONNECTION',
  SET_CONFIGURATION: 'SET_CONFIGURATION',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  connection: {},
  configuration: {}
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_CONNECTION](state, value) {
    state.connection = value;
  },
  [types.SET_CONFIGURATION](state, value) {
    state.configuration = value;
  },
  [types.UPDATE_SETTINGS](state, { field, value }) {
    Object.keys(value).forEach(key => {
      Vue.set(state[field], key, value[key]);
    });
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setConnection({ commit }, payload) {
    if (payload) {
      commit(types.SET_CONNECTION, payload);
    }
  },
  setConfiguration({ commit }, payload) {
    if (payload) {
      commit(types.SET_CONFIGURATION, payload);
    }
  },
  updateConnection({ commit }, value) {
    commit(types.UPDATE_SETTINGS, {
      field: 'connection',
      value
    });

    this.dispatch('integrations/setIsEdited', true);
  },
  updateConfiguration({ commit }, value) {
    commit(types.UPDATE_SETTINGS, {
      field: 'configuration',
      value
    });

    this.dispatch('integrations/setIsEdited', true);
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
