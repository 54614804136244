<template>
  <div
    class="sl-radio-panel-wrapper"
    :class="{
      'sl-radio-panel-wrapper--checked': isSelected,
      'sl-radio-panel-wrapper--disabled': disabled
    }"
    @click="handleClick"
  >
    <SlRadioButton
      :value="value"
      :selected="selected"
      :disabled="disabled"
      v-bind="$attrs"
    >
      <slot />
    </SlRadioButton>
    <div
      v-if="$scopedSlots.footer"
      class="sl-radio-panel__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlRadioPanel',
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false
    },
    selected: {
      type: [Boolean, String, Number],
      default: true
    },
    disabled: Boolean
  },
  computed: {
    isSelected() {
      return this.value === this.selected;
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return;
      }

      if (this.value !== this.selected) {
        this.$emit('input', this.selected);
      }

      this.$nextTick(() => {
        this.$emit('radio-click');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-radio-panel';
</style>
