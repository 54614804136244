export const connectorRouteNames = {
  CREATE_PROJECT: 'create-project',
  CREATE_PROJECT_CONNECTOR: 'create-project.connector',
  EDIT_CONNECTION: 'edit-connection',
  EDIT_CONNECTION_CONNECTOR: 'edit-connection.connector'
};

export const subRouteNames = {
  COLLECTIONS: 'collections',
  INVENTORY_PLANNED_ORDERS: 'inventory/planned-orders',
  INTERSTORE_PLANNED_ORDERS: 'optimization/planned-orders',
  INVENTORY_IN_TRANSITION: 'inventory/in-transition',
  INTERSTORE_IN_TRANSITION: 'optimization/in-transition',
  CONSTRAINED_PLAN: 'constrained-plan',
  REMOVAL_SUBSTITUTION_RULES: 'removal-substitution-rules',
  PROMOTIONS: 'promotions',
  BATCHES_ON_HAND: 'batches-on-hand',
  SETTINGS: 'settings',
  CUSTOM_ROWS: 'custom-rows',
  SUPPLIERS: 'suppliers',
  LOGS_PAGE: 'logs-page',
  BILL_OF_MATERIALS: 'bill-of-materials',
  SEASONALITY_PATTERNS: 'seasonality-patterns'
};

export const integrationsRouteNames = {
  INTEGRATIONS: 'integrations',
  IMPORT_INTEGRATION: 'import-integration',
  EXPORT_INTEGRATION: 'export-integration',
  AGGREGATION: 'aggregation'
};

export const usersManagementRouteNames = {
  USERS: 'users',
  ROLES: 'roles',
  CREATE_ROLE: 'create-role',
  EDIT_ROLE: 'edit-role',
  CATALOGS: 'catalogs',
  CREATE_CATALOG: 'create-catalog',
  EDIT_CATALOG: 'edit-catalog'
};

export const routeNames = {
  PROJECT_LIST: 'projects',
  ARCHIVE: 'archive',
  GET_STARTED: 'get-started',
  NO_ACCESS: 'no-access',
  NO_CONNECTION: 'no-connection',
  EXAMPLES: 'examples',
  // TUTORIALS: 'tutorials',
  DEMAND: 'demand',
  INVENTORY: 'inventory',
  REPORTS: 'reports',
  INTERSTORE: 'optimization',
  DASHBOARD: 'dashboard',
  CHANGES: 'changes',
  BACKUPS: 'version-history',
  ACTIVATION: 'activate-streamline',
  SERVER_DOWN: 'server-down',
  SUBSCRIPTION: 'subscription',
  ...usersManagementRouteNames,
  ...connectorRouteNames,
  ...subRouteNames,
  ...integrationsRouteNames
};

export const systemRouteNames = [
  routeNames.ACTIVATION,
  routeNames.GET_STARTED,
  routeNames.NO_ACCESS,
  routeNames.NO_CONNECTION,
  routeNames.SERVER_DOWN
];

export const noProjectAvailableRouteNames = [
  routeNames.PROJECT_LIST,
  routeNames.ARCHIVE,
  routeNames.EXAMPLES,
  // routeNames.TUTORIALS,
  routeNames.CREATE_PROJECT,
  routeNames.CREATE_PROJECT_CONNECTOR,
  routeNames.BACKUPS,
  routeNames.SUBSCRIPTION
];

export const guardlessRoutes = [
  ...noProjectAvailableRouteNames,
  ...systemRouteNames
];

export const mainRoutes = [
  routeNames.DEMAND,
  routeNames.INVENTORY,
  routeNames.INTERSTORE,
  routeNames.REPORTS,
  routeNames.DASHBOARD,
  routeNames.CHANGES
];

export const planedOrdersRoutes = [
  routeNames.INVENTORY_PLANNED_ORDERS,
  routeNames.INTERSTORE_PLANNED_ORDERS
];
