<template>
  <button
    class="sl-color-picker-option"
    :class="getButtonClass(option)"
    :tabindex="tabIndex"
  >
    <icon
      v-if="selected"
      :class="getIconClass(option)"
      data="@icons/check.svg"
      class="fill-off size-10 sl-color-picker-option__check"
    />
  </button>
</template>

<script>
import { colors } from '@/config/shared/slColorPicker.config';

export default {
  name: 'SlColorPickerOption',
  props: {
    option: {
      type: Object,
      default: null
    },
    selected: Boolean,
    compact: Boolean,
    noActions: Boolean
  },
  data() {
    return {
      defaultOptionConfig: colors.NO_COLOR
    };
  },
  computed: {
    tabIndex() {
      return this.noActions ? -1 : 0;
    }
  },
  methods: {
    getButtonClass(option) {
      const { color, background } = option || this.defaultOptionConfig;

      return [
        {
          'sl-color-picker-option--no-actions': this.noActions,
          'sl-color-picker-option--compact': this.compact
        },
        `border-color-${color} background-color-${background}`
      ];
    },
    getIconClass(option) {
      const { color } = option || this.defaultOptionConfig;

      return `color-${color}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-color-picker/sl-color-picker-option";
</style>
