import { getPasswordPayload } from '@/helpers/integration';

export default function(settings) {
  const { consumerSecret, tokenSecret, ...fields } = settings;

  return {
    ...fields,
    consumerSecret: getPasswordPayload(consumerSecret),
    tokenSecret: getPasswordPayload(tokenSecret)
  };
}