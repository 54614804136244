<template>
  <div
    class="sl-swiper__wrapper"
    :class="{
      'sl-swiper__wrapper--scrolled-left': scrolledLeft,
      'sl-swiper__wrapper--scrolled-right': scrolledRight
    }"
  >
    <swiper-container
      :id="id"
      ref="slSwiper"
      :navigation="false"
      :mousewheel-force-to-axis="true"
      class="sl-swiper"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </swiper-container>

    <SlButton
      v-show="scrolledLeft"
      variant="secondary"
      color="grey"
      size="xs"
      class="sl-swiper-control sl-swiper-control__prev"
      icon
      @click="handleNavigation('prev')"
    >
      <icon
        data="@icons/chevron_left.svg"
        class="fill-off size-16"
      />
    </SlButton>
    <SlButton
      v-show="scrolledRight"
      variant="secondary"
      color="grey"
      size="xs"
      class="sl-swiper-control sl-swiper-control__next"
      icon
      @click="handleNavigation('next')"
    >
      <icon
        data="@icons/chevron_right.svg"
        class="fill-off size-16"
      />
    </SlButton>
  </div>
</template>

<script>
import { customThrottle } from '@/helpers/shared/listeners';

export default {
  name: 'SlSwiperContainer',
  props: {
    id: {
      type: String,
      required: true
    },
    slidesCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      scrolledLeft: false,
      scrolledRight: false
    };
  },
  computed: {
    $swiper() {
      return this.$refs.slSwiper?.swiper;
    }
  },
  watch: {
    slidesCount() {
      this.$nextTick(() => this.$swiper.update());
    }
  },
  mounted() {
    const swiperEl = document.getElementById(this.id);

    swiperEl.addEventListener('progress', customThrottle((event) => {
      const [swiper, progress] = event.detail;
      const hasScroll = swiper.virtualSize > swiper.width;

      this.scrolledLeft = hasScroll ? progress > 0 : false;
      this.scrolledRight = hasScroll ? progress < 1 : false;
    }, 50));

    setTimeout(() => {
      this.$swiper.slideTo(0);
    }, 500);
  },
  methods: {
    handleNavigation(direction) {
      if (!this.$swiper) {
        return;
      }

      if (direction === 'prev') {
        return this.$swiper.slidePrev();
      }

      if (direction === 'next') {
        return this.$swiper.slideNext();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-swiper/sl-swiper-container";
</style>