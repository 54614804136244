export const bc365ConnectionTypes = {
  ON_PREMISES: 'onPremises',
  CLOUD: 'cloud'
};

export const connectionTypeOptions = (ctx) => ([
  {
    label: ctx.$t('Web.BC365.OnPremisesType'),
    value: bc365ConnectionTypes.ON_PREMISES
  },
  {
    label: ctx.$t('Web.BC365.CloudType'),
    value: bc365ConnectionTypes.CLOUD
  }
]);

export const sourceConnectData = () => ({
  type: bc365ConnectionTypes.ON_PREMISES,
  baseUrl: '',
  username: '',
  password: ''
});