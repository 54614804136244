const types = {
  SET_ID: 'SET_ID',
  SET_IS_SINGLE_MODE: 'SET_IS_SINGLE_MODE'
};

const initialState = () => ({
  id: null,
  isSingleMode: false
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_ID](state, value) {
    state.id = value;
  },
  [types.SET_IS_SINGLE_MODE](state, value) {
    state.isSingleMode = value;
  }
};

const actions = {
  setWorkspaceId({ commit }, id) {
    commit(types.SET_ID, id);
  },
  setSingleMode({ commit }, isSingleMode) {
    commit(types.SET_IS_SINGLE_MODE, isSingleMode);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
