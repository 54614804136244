import config from '@/config/global.config';

export default {
  SERVER_URL: 'https://product.streamlineplan.com/2/httpapi',
  CUSTOMER_PIPELINE: '11211692',
  LS_FLAG: 'streamline.amplitude.enabled',
  API_KEY_BY_ENV: {
    [config.APP_MODES.PROD]: 'df99649136ee5a0d7be498bde64d94a7',
    [config.APP_MODES.DEV]: 'b7b92c3b1cadd136bff78dbac9d65424'
  }
};
