import connectionApi from '@/api/connections';
import QBApi from '@/api/connections/qb';
import QBAdapter from '@/adapters/response/connection/connectors/qb';
import vueI18n from '@/plugins/vue-i18n';
import { connectionTypes } from '@/config/connection';
import { qbErrors } from '@/config/error/systemErrors.config';

const types = {
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  accounts: null
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_ACCOUNTS](state, value) {
    state.accounts = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async checkQbConnection({ rootState }, {
    path = '',
    beforeStart,
    failed,
    finished,
    finally: onFinally,
    ignoreResult
  }) {
    try {
      const filepath = path || rootState.connection.connectionData[connectionTypes.QB_DESKTOP].optional.filepath;

      if (!filepath) {
        return failed && failed(vueI18n.tc('Web.QB.filepath'), vueI18n.tc('Web.Modals.Error.TitleConnect'));
      }

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => {
          beforeStart && beforeStart();

          return connectionApi.checkConnect({
            connector: 'qb',
            filepath
          });
        }
      });

      if (!ignoreResult) {
        const { locations, customFieldAsItemInfo } = QBAdapter.desktopConnection(operationData);

        this.dispatch('connection/setConnectorValueByKey', {
          type: connectionTypes.QB_DESKTOP,
          field: 'optional',
          key: 'customFieldAsItemInfo',
          value: customFieldAsItemInfo,
          edit: false
        });

        this.dispatch('connection/setConnectorValueByKey', {
          type: connectionTypes.QB_DESKTOP,
          field: 'optional',
          key: 'locations',
          value: locations,
          edit: false
        });
      }

      finished && finished();
    } catch (e) {
      const message = e?.message;
      const title = message.includes(qbErrors.accessDeniedErrorCode) ? vueI18n.tc('Web.Error.QbAccessDenied') : vueI18n.tc('Web.Modals.Error.TitleConnect');

      this.dispatch('user/logout', { e, from: 'qb/checkConnection' });

      failed && failed(message, title);
    } finally {
      onFinally && onFinally();
    }
  },
  setAccounts({ commit }, accounts) {
    commit(types.SET_ACCOUNTS, accounts);
  },
  async fetchAccounts({ rootState }) {
    return QBApi.getAccounts({
      cacheId: rootState.connection.connectionData[connectionTypes.QB_ONLINE].cacheId
    });
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
