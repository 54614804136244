<template>
  <ConnectorContentBlock>
    <template #title>
      {{ $t('Web.BaseConnectors.Tabs.Subtitle.Advanced') }}
    </template>
    <div
      v-if="$sl_isEditingRoute"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
        :value="combineLocationsValueText"
      />
    </div>
    <div class="connector-content-block__row connector-content-block__row--checkboxes">
      <SlCheckbox
        v-model="cleanUnusedEntriesModel"
        :label="$t('DbImportDialog.Ui.chkCleanup')"
      />
      <SlCheckbox
        v-if="!$sl_isEditingRoute"
        v-model="combineLocationsModel"
        :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
      />
      <SlCheckbox
        v-model="aggregationByDayModel"
        :label="$t('DbInsideImport.Other.OneTranPerDay')"
      />
    </div>
    <div class="connector-content-row w-320">
      <SlSelect
        v-model="subsidiaryModel"
        :select-label="$t('Web.Netsuite.LabelSubsidiary')"
        :options="subsidiaryOptions"
        :loading="isLoading"
        track-by="_id"
        label="_name"
        :internal-search="false"
        :placeholder="$t('Web.Netsuite.NoData', { 1: $t('Web.Netsuite.Subsidiary') })"
        allow-empty
        return-object
        searchable
        clearable
        @search-change="(query) => handleSearch({ entity: entities.SUBSIDIARY, query })"
      />
    </div>
    <div class="connector-content-row w-320">
      <SlSelect
        v-model="employeeModel"
        :select-label="$t('Web.Netsuite.LabelEmployee')"
        :options="employeeOptions"
        :loading="isLoading"
        track-by="_id"
        label="_name"
        :internal-search="false"
        :placeholder="$t('Web.Netsuite.NoData', { 1: $t('Web.Netsuite.Employee') })"
        allow-empty
        return-object
        searchable
        clearable
        @search-change="(query) => handleSearch({ entity: entities.EMPLOYEE, query })"
      />
    </div>
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { entities } from '@/config/connection/netsuite.config';
import { customDebounce } from '@/helpers/shared/listeners';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { connection } from '@/mixins/connection';

export default {
  name: 'Optional',
  components: {
    ConnectorContentBlock
  },
  mixins: [connection],
  data() {
    return {
      entities,
      isLoading: false,
      fileFormats
    };
  },
  inject: [
    'getTooltip',
    'setSettings',
    'historicalTransactionsTemplate'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.OPTIONAL],
      subsidiaryOptions: state => state.netsuite.subsidiary_list || [],
      employeeOptions: state => state.netsuite.employee_list || []
    }),
    combineLocationsValueText() {
      return getLabelFromBoolean(this.combineLocationsModel, this);
    },
    cleanUnusedEntriesModel: {
      get() {
        return this.tabData.cleanUnusedEntries;
      },
      set(value) {
        this.setSettings({ value, key: 'cleanUnusedEntries' });
      }
    },
    combineLocationsModel: {
      get() {
        return this.tabData.combineLocations;
      },
      set(value) {
        this.setSettings({ value, key: 'combineLocations' });
      }
    },
    aggregationByDayModel: {
      get() {
        return this.tabData.aggregationByDay;
      },
      set(value) {
        this.setSettings({ value, key: 'aggregationByDay' });
      }
    },
    subsidiaryModel: {
      get() {
        return this.tabData.subsidiary;
      },
      set(value) {
        this.setSettings({ value, key: 'subsidiary' });
      }
    },
    employeeModel: {
      get() {
        return this.tabData.employee;
      },
      set(value) {
        this.setSettings({ value, key: 'employee' });
      }
    }
  },
  methods: {
    ...mapActions({
      cacheEntity: 'netsuite/cacheEntity',
      getEntities: 'netsuite/getEntities'
    }),
    searchEntities: customDebounce(async function({ entity, query }) {
      try {
        await this.cacheEntity(entity);
        await this.getEntities({ entity, query });
      } finally {
        this.isLoading = false;
      }
    }, 500),
    async handleSearch(payload) {
      if (!payload.query) {
        return;
      }

      this.isLoading = true;

      this.searchEntities(payload);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/settings.scss";

.connector-content-block__row.connector-content-block__row--checkboxes {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 8px;
}

.connector-content-row {
  &.w-320,
  &:first-child {
    margin-bottom: 0;
  }
}
</style>
