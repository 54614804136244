export default function({ id, url, name }) {
  if (!url) {
    return {
      files: []
    };
  }

  return {
    files: [
      {
        id,
        url,
        name
      }
    ]
  };
}
