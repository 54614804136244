import { toArray } from '@/helpers/utils/toArray';
import { connectionTypes, sourceConnectDataFields } from '@/config/connection';
import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(data) {
  const {
    _cacheId: cacheId,
    _startDate: startDate,
    _groupBy: groupBy,
    _nMonthsToDropToReceive,
    _replacePaymentWithShipmentDate
  } = data.settings.info;

  const {
    combineLocs,
    combineChannels,
    locations,
    channels
  } = data.settings.advanceSettings;

  return {
    commonData: {
      type: connectionTypes.SKUBANA,
      startDate,
      groupBy
    },
    optional: {
      locations: toArray(locations?.location),
      channels: toArray(channels?.channel),
      combineLocs: combineLocs === '1',
      combineChannels: combineChannels === '1',
      nMonthsToDropToReceive: _nMonthsToDropToReceive === '1',
      replacePaymentWithShipmentDate: _replacePaymentWithShipmentDate === '1',
      file: FileEditAdapter(data.settings.transactionsFile)
    },
    [sourceConnectDataFields.MAIN]: {
      cacheId
    }
  };
}
