<template>
  <SlModal
    :id="id"
    :width="420"
    position="center"
    no-header
    @on-enter="handleContactSales"
  >
    <div class="modal-content upgrade-plan-modal">
      <icon
        data="@icons/upgrade-plan_double.svg"
        class="fill-off size-48"
      />
      <div class="upgrade-plan-modal__title heading-4-sb grey-90">
        {{ $t('Web.FreeVersion.UpgradeTo') }}
      </div>
      <div class="upgrade-plan-modal__body body-1 grey-70">
        <p>{{ $t('Web.FreeVersion.Unavailable') }}</p>
        <p>{{ $t('Web.FreeVersion.UpgradePlan') }}</p>
        <ul>
          <li
            v-for="benefit in benefitsList"
            :key="benefit"
          >
            <icon
              data="@icons/check.svg"
              class="fill-off size-16 color-primary-80"
            />
            {{ benefit }}
          </li>
        </ul>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          full-width
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          full-width
          @click="handleContactSales"
        >
          {{ 'Contact Sales' }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { SALES_DEMO_URL } from '@/config/shared/resources.config';
import { getBenefitsList } from '@/config/shared/freeVersion.config';
import { openLink } from '@/helpers/shared/webAPI';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'UpgradePlanModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.UPGRADE_PLAN,
      benefitsList: getBenefitsList(this)
    };
  },
  methods: {
    handleContactSales() {
      openLink(SALES_DEMO_URL, true);

      ampli.talkToExpertButtonClicked({ talkToExpertButtonSource: 'upgradeWindow' });
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/upgrade-to-enterprise.scss";
</style>
