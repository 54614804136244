export const formatNumber = (val, fixed = 2) => {
  if (
    val?.includes
    && (['∞', '>', '-', '+', '*'].some(s => val.includes(s)))
  ) {
    return val;
  }

  if (val === 'nan' || isNaN(val) || (!val && val !== 0)) {
    return '';
  }

  let value = val;

  if (!value.split) {
    value = `${value}`;
  }

  if (value.split('.')[0] === `${+value}`) {
    value = `${+value}`;
  } else {
    value = `${+(+value).toFixed(fixed)}`;
  }
  value = Intl.NumberFormat('EN-us').format(value);

  return value;
};

export const unformatNumber = (val) => {
  if (!val) {
    return val;
  }

  val = val.replace(/\xa0/g, '');

  if (['*', '+', '-', '>'].includes(val[0]) || val?.endsWith('>')) {
    return val;
  }

  const thousandSeparator = Intl.NumberFormat('EN-us')
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat('EN-us')
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  val = parseFloat(
    val
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );

  if (isNaN(val)) {
    return '';
  }

  return val;
};
