export const toggleUserInteraction = (shouldBlock) => {
  document.body.classList.toggle('blocked', shouldBlock);
};

export const openLink = (href, isNewTab) => {
  const link = document.createElement('a');

  link.setAttribute('href', href);

  if (isNewTab) {
    link.setAttribute('target', '_blank');
  }

  document.body.append(link);

  link.click();

  link.remove();
};

const dummyFunction = () => true;

export const preventTabClose = (shouldStay) => {
  window.onbeforeunload = shouldStay ? dummyFunction : null;
};
