import axios from '@/plugins/axios';

export default {
  exportInTransitionToXlsx(params) {
    return axios.get('/in_transition/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importInTransitionFromXlsx(params, body) {
    return axios.post('/in_transition/xlsx', body, {
      params,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  },
  getInTransition(params) {
    return axios.get('/in_transition', { params });
  },
  createOrder(params, body) {
    return axios.post('/in_transition/create', body, { params });
  },
  deleteOrder(params) {
    return axios.delete('/in_transition/delete', { params });
  },
  saveInTransition() {
    return axios.put('/in_transition/save');
  },
  updateInTransitionOverrides(params, body) {
    return axios.put('/in_transition/overrides', body, { params });
  },
  discardInTransitionOverrides() {
    return axios.delete('/in_transition/overrides');
  },
  updateVisibleColumns(params) {
    return axios.put('/in_transition/columns', null, {
      params
    });
  }
};