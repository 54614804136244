<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.wantSubcats')"
        :value="wantSubcatsLabel"
      />
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <template v-else>
      <SlCheckbox
        v-model="wantSubcats"
        :label="$t('Web.BaseConnectors.Form.wantSubcats')"
      />
      <SlCheckbox
        v-model="combineLocs"
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
      />
    </template>
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
export default {
  name: 'ShopifyOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.SHOPIFY
    };
  },
  computed: {
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    wantSubcats: {
      get() {
        return Boolean(+this.connectionData.optional.wantSubcats);
      },
      set(value) {
        this.setOptionalValue({
          key: 'wantSubcats',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    },
    wantSubcatsLabel() {
      return getLabelFromBoolean(this.wantSubcats, this);
    }
  }
};
</script>
