<template>
  <div
    class="sl-skeleton-loader"
    :class="{ 'sl-skeleton-loader--no-margin': noMargin }"
  >
    <div
      class="sl-skeleton-loader__bone"
      :class="boneClass"
      :style="boneStyle"
    />
  </div>
</template>

<script>
export default {
  name: 'SlSkeletonLoader',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    borderRadius: {
      type: String,
      default: ''
    },
    noMargin: Boolean
  },
  data() {
    return {
      validTypes: {
        accordion: 'accordion',
        avatar: 'avatar',
        'avatar-s': 'avatar avatar--s',
        'avatar-m': 'avatar avatar--m',
        'avatar-l': 'avatar avatar--l',
        'avatar-xl': 'avatar avatar--xl',
        badge: 'badge',
        breadcrumbs: 'breadcrumbs',
        button: 'button',
        checkbox: 'checkbox',
        divider: 'divider',
        image: 'image',
        input: 'input',
        tab: 'tab',
        textarea: 'textarea',
        text: 'text',
        'text-12': 'text text--12',
        'text-13': 'text text--13',
        'text-14': 'text text--14',
        'text-16': 'text text--16',
        'text-18': 'text text--18',
        'text-20': 'text text--20',
        'text-22': 'text text--22',
        'text-24': 'text text--24'
      }
    };
  },
  computed: {
    boneClass() {
      const type = this.validTypes[this.type];

      return type
        ? type.split(' ').map((item) => `sl-skeleton-loader__${item}`).join(' ')
        : '';
    },
    boneStyle() {
      return {
        width: this.width,
        height: this.height,
        borderRadius: this.borderRadius
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/style/components/ui-kit/sl-skeleton-loader";
</style>