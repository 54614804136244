import { routeNames } from '@/config/router/router.config';

export default function integrations({ to, store, next, nextMiddleware }) {
  const activeStepIndex = store.getters['integrations/activeStepIndex'];
  const activeSteps = store.state.integrations.active_steps;

  if (!activeSteps.length) {
    return next({
      name: routeNames.INTEGRATIONS
    });
  }

  const isActiveStep = activeSteps[activeStepIndex].type === to.params.step;

  if (!isActiveStep) {
    return next({
      name: to.name,
      params: {
        ...to.params,
        step: activeSteps[activeStepIndex].type
      }
    });
  }

  return nextMiddleware();
}
