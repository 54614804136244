<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div class="connector-content-block__row">
      <SlInput
        v-model="hostModel"
        :label="$t('Web.DbRelatedConnectors.Form.LabelGpServiceHost')"
        class="w-320"
      >
        <template #icon>
          <SlInfoButton
            v-tooltip="getTooltip($t('Web.DbRelatedConnectors.Form.TooltipGpServiceHost'))"
          />
        </template>
      </SlInput>
      <SlInput
        v-model="portModel"
        :label="$t('Web.DbRelatedConnectors.Form.LabelGpPort')"
        class="w-150"
      />
    </div>
    <div class="connector-content-block__row">
      <SlInput
        v-model="usernameModel"
        :label="$t('Web.DbRelatedConnectors.Form.LabelWinUsername')"
        class="w-320"
      />
    </div>
    <div class="connector-content-block__row">
      <SlPasswordInput
        v-model="passwordModel.value"
        :label="$t('Web.DbRelatedConnectors.Form.LabelWinPassword')"
        class="w-320"
        :is-set="passwordModel.isSet"
      />
    </div>
    <div class="connector-content-block__row">
      <SlInput
        v-model="companyKeyModel"
        type="number"
        :label="$t('Web.DbRelatedConnectors.Form.LabelCompanyId')"
        class="w-320"
      />
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'Export',
  components: {
    ConnectorContentBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setSettings'
  ],
  data() {
    return {
      getTooltip
    };
  },
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.EXPORT]
    }),
    hostModel: {
      get() {
        return this.tabData?.host;
      },
      set(value) {
        this.setSettings({ value, key: 'host' });
      }
    },
    portModel: {
      get() {
        return this.tabData?.port;
      },
      set(value) {
        this.setSettings({ value, key: 'port' });
      }
    },
    usernameModel: {
      get() {
        return this.tabData?.username;
      },
      set(value) {
        this.setSettings({ value, key: 'username' });
      }
    },
    passwordModel: {
      get() {
        return this.tabData?.password;
      },
      set(value) {
        this.setSettings({
          value: {
            isSet: this.tabData?.password?.isSet,
            value
          },
          key: 'password'
        });
      }
    },
    companyKeyModel: {
      get() {
        return this.tabData?.companyKey;
      },
      set(value) {
        this.setSettings({ value, key: 'companyKey' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block {
  ::v-deep {
    .connector-content-block__main {
      row-gap: 16px;
    }

    .connector-content-block__row {
      display: flex;
      align-items: center;
      column-gap: 16px;
    }
  }
}
</style>