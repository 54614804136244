<template>
  <div
    ref="wrapper"
    class="sl-group-dropdown-transition"
    :style="{ height }"
  >
    <transition
      name="group"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <slot name="group" />
    </transition>
    <transition
      name="category"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <slot name="category" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SlGroupDropdownTransition',
  props: {
    height: {
      type: String,
      default: '200px'
    }
  },
  data() {
    return {
      lastHeight: 0
    };
  },
  async mounted() {
    const contentNode = this.$refs.wrapper.children?.[0];

    if (contentNode) {
      this.$refs.wrapper.style.height = contentNode.scrollHeight + 'px';
    }
  },
  methods: {
    beforeEnter() {
      this.$refs.wrapper.style.height = this.lastHeight + 'px';
    },
    enter(el) {
      this.$refs.wrapper.style.height = el.scrollHeight + 'px';
    },
    beforeLeave(el) {
      this.$refs.wrapper.style.transition = 'height .35s ease-in-out';

      if (el.classList.contains('group')) {
        this.$refs.wrapper.style.height = el.scrollHeight + 'px';
        this.lastHeight = el.scrollHeight;
      }
    },
    leave(el) {
      if (el.classList.contains('group')) {
        this.$refs.wrapper.style.height = el.scrollHeight + 'px';
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/style/components/ui-kit/sl-group-dropdown/sl-group-dropdown-transition';
</style>