import { uuId } from '@/helpers/shared/uuId';
import { toArray } from '@/helpers/utils/toArray';
import { formatNumber } from '@/helpers/format/formatNumber';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

export default function(data) {
  if (!data) {
    return null;
  }

  const tableData = toArray(data.tableData.table.row);
  const dates = toArray(data.tableData.dates.date);
  const rowsInfo = toArray(data.tableData.rowsInfo.row);

  const transposedTable = tableData[0].cell.map((_, colIndex) => {
    const id = uuId();
    const col = tableData.map(row => ({
      ...row.cell[colIndex],
      foregrounds: toArray(row.cell[colIndex].foregrounds?.foreground),
      tooltips: toArray(row.cell[colIndex].tooltips?.tooltip),
      icons: toArray(row.cell[colIndex].icons?.icon, null),
      related: toArray(row.cell[colIndex].related?.cell, null)
    }));

    col.unshift({
      val: {
        __text: dateByLocaleKey(dates[colIndex], localeDateKeys.YMD)
      }
    });

    return {
      id,
      col
    };
  });

  data.tableData = transposedTable;
  data.rowsInfo = rowsInfo;

  data.safetyStock              = formatNumber(+data.safetyStock);
  data.debtReceived.current     = formatNumber(+data.debtReceived.current);
  data.debtReceived.max         = formatNumber(+data.debtReceived.max);
  data.debtReceived.accumulated = formatNumber(+data.debtReceived.accumulated);
  data.minOrder.initial         = formatNumber(+data.minOrder.initial);
  data.minOrder.canceled        = formatNumber(+data.minOrder.canceled);
  data.debtPassed.current       = formatNumber(+data.debtPassed.current);
  data.debtPassed.max           = formatNumber(+data.debtPassed.max);
  data.horizon                  = formatNumber(+data.horizon);

  return data;
}