<template>
  <transition name="fade">
    <div
      v-if="show"
      class="sl-overlay"
      :class="{
        'sl-overlay--full-loader': fullLoader,
        'sl-overlay--filled': filled
      }"
    >
      <slot v-if="$scopedSlots.default" />
      <SlLoader
        v-else
        v-bind="$attrs"
      />
    </div>
  </transition>
</template>

<script>
import SlLoader from '@/components/UIKit/SlLoader';
export default {
  name: 'SlOverlay',
  components: {
    SlLoader
  },
  props: {
    show: Boolean,
    fullLoader: Boolean,
    filled: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-overlay";
</style>
