var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:['sl-checkbox-wrapper', ( _obj = {
    'sl-checkbox-wrapper--checked': _vm.isChecked,
    'sl-checkbox-wrapper--disabled': _vm.disabled,
    'sl-checkbox-wrapper--toggle': _vm.toggle,
    'sl-checkbox-wrapper--inactive': _vm.inactive
  }, _obj[("sl-checkbox-wrapper--" + _vm.size)] = _vm.size, _obj )],attrs:{"tabindex":"0"}},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"sl-checkbox",attrs:{"id":_vm.id,"data-test-id":_vm.dataTestId,"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.checkedValue},on:{"click":_vm.onClick,"change":_vm.onChange}}),_c('icon',{staticClass:"sl-checkbox__check fill-off size-16 color-white-stroke",attrs:{"data":_vm.icon}}),(_vm.$scopedSlots.label || _vm.label)?_c('span',{staticClass:"sl-checkbox__label"},[(_vm.$scopedSlots.label)?_vm._t("label"):(_vm.label)?[_vm._v(" "+_vm._s(_vm.label)+" ")]:_vm._e()],2):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }