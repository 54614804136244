import shopifyAdapter from './shopify';

export default function(data) {
  const { 
    _companyName: companyName,
    _user: user,
    _password: password
  } = data.settings.credentials;
  const {
    _startDate: startDate,
    _groupBy: groupBy,
    _combineLocs: combineLocs,
    _transactionsFromShopify: transactionsFromShopify
  } = data.settings.info;

  const { required: shopifyData } = shopifyAdapter({
    settings: data.settings.shopify
  });

  return {
    required: {
      companyName,
      user,
      password,
      startDate,
      groupBy
    },
    optional: {
      combineLocs,
      transactionsFromShopify,
      ...shopifyData
    }
  };
}
