<template>
  <div class="sl-input-wrapper">
    <div class="sl-input__content">
      <input
        v-model="inputValue"
        v-click-outside="vcoConfig"
        :data-test-id="dataTestId"
        class="sl-input heading-4-md grey-90"
        :class="{
          'sl-input--edited': inputValue,
          'sl-input--invalid': isInvalid
        }"
        :maxlength="maxLength"
        type="text"
        :placeholder="$t('Web.BaseConnectors.placeholderNew')"
        @keypress="onKeyPress"
        @keyup.enter="handleEnter"
        @keyup.esc="handleEsc"
        @focus="handleFocus"
      >
      <div
        v-if="isFocused"
        class="append-element"
      >
        <SlButton
          size="xs"
          variant="tertiary"
          color="grey"
          class="sl-hidden-input__button"
          icon
        >
          <icon
            data="@icons/check.svg"
            class="size-16 fill-off"
            @click="submitValue"
          />
        </SlButton>
        <SlButton
          size="xs"
          variant="tertiary"
          color="grey"
          class="sl-hidden-input__button"
          icon
        >
          <icon
            data="@icons/close.svg"
            class="size-16"
            @click="cancel"
          />
        </SlButton>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import SlButton from '@/components/UIKit/SlButton';
import regExp from '@/helpers/utils/regExp.js';

export default {
  name: 'SlHiddenInput',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    SlButton
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    maxLength: {
      type: String,
      default: '500'
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    isValid: Boolean
  },
  data() {
    return {
      isFocused: false,
      inputValue: this.value,
      blackListSymbols: [
        '/', '?', ':', '&', '\\',
        '*', '"', '<', '>', '|',
        '#', '%', '.', ','
      ]
    };
  },
  computed: {
    vcoConfig() {
      return {
        handler: this.handleClickOutside,
        middleware: this.clickOutsideMiddleware,
        events: ['mousedown'],
        capture: true
      };
    },
    isInvalid() {
      return !this.isValid && !this.inputValue;
    }
  },
  methods: {
    onKeyPress(e) {
      const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
      const charStr = String.fromCharCode(charCode);

      if (this.blackListSymbols.includes(charStr)) {
        e.preventDefault();

        return false;
      }

      return true;
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleEnter(e) {
      this.hideConfirmationButtons();
      this.submitValue();
      e.target.blur();
    },
    handleEsc(e) {
      this.hideConfirmationButtons();
      this.cancel();

      e.target.blur();
    },
    clickOutsideMiddleware(e) {
      const isButton = e.target.closest('.sl-hidden-input__button');

      return this.isFocused && !isButton;
    },
    handleClickOutside() {
      if (this.isFocused) {
        this.submitValue();
        this.hideConfirmationButtons();
      }
    },
    hideConfirmationButtons() {
      this.isFocused = false;
    },
    submitValue() {
      this.inputValue = this.inputValue.replace(regExp.fileName, '').trim();

      this.$emit('save', this.inputValue);
      this.isFocused = false;
    },
    cancel() {
      this.inputValue = this.value;
      this.isFocused = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-hidden-input";
</style>