import axios from '@/plugins/axios';

export default {
  getSpireDatabases(body) {
    return axios.post('/spire/databases', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
