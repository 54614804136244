import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(data) {
  const { _login: login, _password: password } = data.settings.credentials;
  const {
    _wantSubcats: wantSubcats,
    _combineLocs: combineLocs,
    _customerGroupIsChannel: customerGroupIsChannel,
    _areItemsWoQtyDispatchShipped: areItemsWoQtyDispatchShipped,
    _nMonthsToDropToReceive: nMonthsToDropToReceive
  } = data.settings.advanced;

  const {
    _startDate: startDate,
    _groupBy: groupBy
  } = data.settings.info;

  return {
    required: {
      login,
      password,
      groupBy,
      startDate
    },
    optional: {
      wantSubcats,
      combineLocs,
      customerGroupIsChannel,
      areItemsWoQtyDispatchShipped,
      nMonthsToDropToReceive,
      file: FileEditAdapter(data.settings.transactionsFile)
    }
  };
}
