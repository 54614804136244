<template>
  <div class="connector-wrap connector-wrap--simple">
    <SlAlert
      :accent="$t('Web.QB.NoteAdmin')"
      :text="$t('Web.QB.NoteAllow')"
      type="info"
    />
    <Periods
      v-if="!noPeriods"
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';

export default {
  name: 'QBDesktopRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean,
    noPeriods: Boolean,
    connectorType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      type: connectionTypes.QB_DESKTOP
    };
  }
};
</script>
