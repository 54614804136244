<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <SlCheckbox
      v-else
      v-model="combineLocs"
      :label="$t('Web.BaseConnectors.Form.combineLocs')"
    />
    <div class="connector-content-block__row">
      <SlCheckbox
        v-model="allowUntrustedCert"
        :label="$t('Web.BaseConnectors.Form.allowUntrustedCert')"
      >
        <SlInfoButton
          v-tooltip="allowSelfSignedCertTooltip"
        />
      </SlCheckbox>
    </div>
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { getSplitTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'CustomOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.CUSTOM_API
    };
  },
  computed: {
    // todo refactor after connector 2.0 merge
    allowSelfSignedCertTooltip() {
      return getSplitTooltip({
        title: this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipTitle'),
        body: `<ul style="list-style:disc; white-space: normal">
          <li style="margin-left: 20px">${this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipBody1')}</li>
          <li style="margin-left: 20px">${this.$t('Web.BaseConnectors.Form.allowUntrustedCertTooltipBody2')}</li>
        </ul>`
      });
    },
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    },
    allowUntrustedCert: {
      get() {
        return Boolean(+this.connectionData.optional.allowUntrustedCert);
      },
      set(value) {
        this.setOptionalValue({
          key: 'allowUntrustedCert',
          value
        });
      }
    }
  }
};
</script>
