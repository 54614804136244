<template>
  <div
    class="content-block"
    :class="{
      'content-block--footer': $scopedSlots.footer
    }"
  >
    <div
      ref="scrollContainer"
      class="content-block__main"
      :class="{
        'pb-0': noPaddingBottom,
        'overflow-hidden': overflowHidden
      }"
      @scroll.passive="updateScroll"
    >
      <SlErrorBoundary>
        <slot
          v-bind="{
            scrolledToTop,
            scrolledToBottom,
            scrolledToLeft,
            scrolledToRight,
            hasVerticalScrollbar,
            hasHorizontalScrollbar
          }"
        />
      </SlErrorBoundary>
    </div>

    <div
      v-if="$scopedSlots.footer"
      class="content-block__footer"
      :class="{
        'content-block__footer--shadow': hasVerticalScrollbar && !scrolledToBottom
      }"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { scroll } from '@/mixins/scroll';

export default {
  name: 'ContentBlock',
  mixins: [scroll],
  props: {
    noPaddingBottom: Boolean,
    overflowHidden: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/page/content-block.scss";
</style>
