import amazonApi from '@/api/integrations/amazon';
import { toArray } from '@/helpers/utils/toArray';

const types = {
  SET_MARKETPLACES: 'SET_MARKETPLACES'
};

const initialState = () => ({
  marketplaces: []
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_MARKETPLACES](state, value) {
    state.marketplaces = value;
  }
};

const actions = {
  async fetchMarketplaces({ commit }) {
    try {
      const response = await amazonApi.getMarketplaces();
      const marketplaces = response?.data?.marketplaces;

      if (!marketplaces) {
        return;
      }

      commit(types.SET_MARKETPLACES, toArray(marketplaces));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchMarketplaces' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
