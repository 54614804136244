import store from '@/store';

import {
  storeActionByRequest,
  requestsByRouteName,
  requestDependencies
} from '@/config/api/requestsKeys.config';
import { componentNames } from '@/config/app.config';
import { toArray } from '@/helpers/utils/toArray';
import { getRouteName } from '@/helpers/shared/router';

const REJECTED_PROMISE_STATUS = 'rejected';

const fetchRequests = async(setRequests, payload, sync = false) => {
  const uniqRequests = [...new Set(setRequests)];

  if (sync) {
    const results = [];

    for (let i = 0; i < uniqRequests.length; i++) {
      const result = await store.dispatch(`${uniqRequests[i]}`, payload);

      results.push(result);
    }

    return Promise.allSettled(results);
  }

  return Promise.allSettled(
    uniqRequests.map(request => store.dispatch(`${request}`, payload))
  );
};

export const fetchUpdatedData = async(requests, payload) => {
  const independentActions = [];
  let dependentActions = [];
  const routeName = getRouteName();

  const routeRequests = [
    ...requestsByRouteName[componentNames.ROOT],
    ...toArray(requestsByRouteName[routeName])
  ];

  toArray(requests).forEach(request => {
    if (!routeRequests.includes(request)) {
      return;
    }

    const action = storeActionByRequest[request];
    const dependencies = requestDependencies[request] || [];

    dependencies.forEach(dep => {
      const independentAction = storeActionByRequest[dep];

      if (routeRequests.includes(dep) && requests.includes(dep) && !independentActions.includes(independentAction)) {
        independentActions.push(independentAction);
      }
    });

    if (!dependentActions.includes(action) && !independentActions.includes(action)) {
      dependentActions.push(action);
    }
  });

  dependentActions = dependentActions.filter(action => {
    return !independentActions.includes(action);
  });

  const independentRequestsResult = await fetchRequests(independentActions, payload, true);
  const dependentRequestsResult = await fetchRequests(dependentActions, payload);

  const independentFailedRequest = independentRequestsResult.find(result => result.status === REJECTED_PROMISE_STATUS);

  if (independentFailedRequest) {
    throw independentFailedRequest.reason;
  }

  const dependentFailedRequest = dependentRequestsResult.find(result => result.status === REJECTED_PROMISE_STATUS);

  if (dependentFailedRequest) {
    throw dependentFailedRequest.reason;
  }
};
