export const updateRequestMethods = ['post', 'put', 'patch', 'delete'];

export const ignoredRequests = ['/time_machine', '/manage_projects/open_example'];

export const manageProjectRequest = 'manage_projects';

export const pingRequests = [
  '/initialization/status',
  '/manage_projects/ping',
  '/manage_projects/list',
  '/last_change',
  '/operations',
  '/operations/project_data',
  '/operations/data',
  '/project/ram_stats',
  '/planned_orders'
];
