import { accessStateKeys } from '@/config/shared/access.config';
import { roleSettingTypes as t } from '@/config/users/users.config';

export default function({ settings, name, description, id }) {
  const initial = {
    ...(id && { id: +id }),
    name,
    descr: description || '',
    [t.TAB_RESTRICTION]: {
      start: accessStateKeys.EDITABLE,
      alerts: accessStateKeys.NONE
    },
    [t.FUNCTIONAL_RESTRICTION]: {},
    [t.DATA_RESTRICTION]: {}
  };

  return Object.keys(settings).reduce((acc, key) => {
    if (Object.values(t).includes(settings[key].type)) {
      acc[settings[key].type][key] = settings[key].state || settings[key].value;

      return acc;
    }

    return acc;
  }, initial);
}
