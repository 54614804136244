import CommonSettingsAdapter from '@/adapters/request/connection/dbRelated/shared/CommonSettings.adapter';
import TabsAdapter from '@/adapters/request/connection/dbRelated/shared/Tabs.adapter';
import databaseAdapter from './database/ConnectionSettings.adapter';
import netsuiteAdapter from './netsuite/ConnectionSettings.adapter';
import dynamicsNavAdapter from './dynamicsNav/ConnectionSettings.adapter';
import sapAdapter from './sap/ConnectionSettings.adapter';
import fishbowlAdapter from './fishbowl/ConnectionSettings.adapter';
import dynamicsGpAdapter from './dynamicsGp/ConnectionSettings.adapter';
import odooAdapter from './odoo/ConnectionSettings.adapter';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { DEFAULT_START_DATE } from '@/config/connection';
import { connectionTypes } from '@/config/connection';

const commonAdapterByType = {
  [connectionTypes.DYNAMICS_NAV]: dynamicsNavAdapter,
  [connectionTypes.SAP]: sapAdapter,
  [connectionTypes.FISHBOWL]: fishbowlAdapter,
  [connectionTypes.DYNAMICS_GP]: dynamicsGpAdapter,
  [connectionTypes.ODOO]: odooAdapter
};

const uniqueAdapterByType = {
  [connectionTypes.DATABASE]: databaseAdapter,
  [connectionTypes.NETSUITE]: netsuiteAdapter
};

export default function(state, _, shared) {
  const { type } = state.commonData;

  if (uniqueAdapterByType[type]) {
    return uniqueAdapterByType[type](state, shared);
  }

  const { tabInfo, exportSetsQuery } = TabsAdapter(state);
  const { connection, advanceSettings, importExportSettings = {} } = commonAdapterByType[type](state, shared);

  return {
    innerSettings: {
      ...CommonSettingsAdapter(state),
      ...importExportSettings,
      settings: {
        connection,
        _startDate: state.commonData.startDate || DEFAULT_START_DATE,
        queries: {
          tabInfo,
          exportSets: {
            query: exportSetsQuery
          },
          commonSets: {
            groupBy: state[tabKeys.REQUIRED].groupBy,
            start: state[tabKeys.REQUIRED].startFrom,
            orderTypes: {
              purchase: state[tabKeys.ORDERS_RECEIVE].purchase,
              work: state[tabKeys.ORDERS_RECEIVE].manufacture,
              transfer: state[tabKeys.ORDERS_RECEIVE].transfer
            }
          },
          protection: {
            _val: 'none'
          }
        },
        info: {
          _combineLocs: +state[tabKeys.OPTIONAL].combineLocations,
          _cleanUnusedEntries: +state[tabKeys.OPTIONAL].includeZeroOnHand,
          _zeroPriceTransactions: +state[tabKeys.OPTIONAL].zeroPriceTransactions,
          _zeroQtyTransactions: +state[tabKeys.OPTIONAL].zeroQtyTransactions,
          _legacyExportMode: +state[tabKeys.OPTIONAL].legacyExportMode,
          _cacheId: state.cache_id
        }
      }
    },
    advanceSettings
  };
}
