import { dataFields } from '@/config/connection/dbRelated.config';

export default function(state, shared) {
  const {
    dbServer: host,
    database,
    port,
    username: user,
    password,
    odooUrl,
    odooUsername,
    odooPassword
  } = state[dataFields.CONNECTION_DATA];
  const {
    fileId: _fileId,
    fileRemoved: _fileRemoved
  } = shared.optional;

  return {
    connection: {
      host,
      dataset: '',
      credentials: {
        user,
        password
      },
      port
    },
    advanceSettings: {
      transactionsFile: {
        _fileId,
        _fileRemoved
      }
    },
    importExportSettings: {
      importSets: {
        dbServer: '',
        database
      },
      exportSets: {
        user: odooUsername,
        password: odooPassword,
        exportOrdersUrl: odooUrl
      }
    }
  };
}