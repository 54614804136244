import axios from '@/plugins/axios';

export default {
  getBatchesOnHand(params) {
    return axios.get('/item/expirations', {
      params
    });
  },
  exportBatchesOnHandToXlsx(params) {
    return axios.get('/item/expirations/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream'
      }
    });
  }
};