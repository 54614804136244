import axios from '@/plugins/axios';

export default {
  getSettings() {
    return axios.get('/project/settings');
  },
  saveSettings() {
    return axios.post('/project/settings');
  },
  changeSettings(data) {
    return axios.patch('/project/settings', data);
  },
  discardSettings() {
    return axios.delete('/project/settings');
  }
};