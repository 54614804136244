import axios from '@/plugins/axios';

export default {
  pingProject() {
    return axios.get('/manage_projects/ping');
  },
  getProjectList() {
    return axios.get('/manage_projects/list', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getAvailableConnectors() {
    return axios.get('/manage_projects/available_connectors', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  stopProject(body) {
    return axios.put('/manage_projects/stop', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  startProject(body) {
    return axios.post('/manage_projects/start', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  downloadProject(params) {
    return axios.get('/manage_projects/download', {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
        'X-pid-independent': true
      },
      params
    });
  },
  archiveProject(params, body) {
    return axios.post('/manage_projects/archived', body, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  deleteProject(body) {
    return axios.post('/manage_projects/delete', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  renameProject(params, body) {
    return axios.put('/manage_projects/rename', body, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  uploadProject(body) {
    return axios.post('/manage_projects/upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-pid-independent': true
      }
    });
  },
  getExamples() {
    return axios.get('/manage_projects/examples', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  openExample(body) {
    return axios.post('/manage_projects/open_example', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  createProject(body) {
    return axios.post('/manage_projects/create', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
