import { getPasswordPayload } from '@/helpers/integration';

export default function(settings) {
  const {
    password,
    ...connectionSettings
  } = settings;

  return {
    ...connectionSettings,
    password: getPasswordPayload(password)
  };
}
