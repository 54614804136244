import Vue from 'vue';
import projectApi from '@/api/project';

const types = {
  SET_LOGS_LIST: 'SET_LOGS_LIST'
};

const state = () => ({
  logs_list: null
});

const getters = {};

const mutations = {
  [types.SET_LOGS_LIST](state, value) {
    state.logs_list = value;
  }
};

const actions = {
  async fetchLogsList({ commit }) {
    try {
      const response = await projectApi.getLogsList();

      if (!response?.data) {
        return;
      }

      commit(types.SET_LOGS_LIST, response.data);
    } catch (e) {
      Vue.notify({
        type: 'error',
        text: e?.message,
        duration: 15000
      });

      this.dispatch('user/logout', { e, from: 'logs/fetchLogsList' });

      throw e;
    }
  },
  downloadLogs(_, { id }) {
    return projectApi.downloadLogs({
      operationId: id
    });
  },
  async deleteLogs({ dispatch }, { id }) {
    try {
      await projectApi.deleteLogs({
        operationId: id
      });

      dispatch('fetchLogsList');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'logs/deleteLogs' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
