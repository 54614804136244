export const valueFormat = {
  date: 'YYYY-MM-DD',
  time12: 'hh:mm A',
  time24: 'HH:mm'
};

export const errorValue = 'Invalid Date';

export const timeFormats = {
  t_12h: '12h',
  t_24h: '24h'
};

export const timeConfigByFormat = {
  [timeFormats.t_12h]: {
    format: valueFormat.time12,
    mask: '##:## AA',
    placeholder: '--:-- --'
  },
  [timeFormats.t_24h]: {
    format: valueFormat.time24,
    mask: '##:##',
    placeholder: '--:--'
  }
};

export const valueFormatByTime = {
  [timeFormats.t_24h]: valueFormat.time24,
  [timeFormats.t_12h]: valueFormat.time12
};
