<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div class="connector-content-block__row">
      <SlAlert type="warn">
        <div class="sl-alert__accent">
          <i18n path="Web.SapB1.AlertTitle">
            <template v-slot:1>
              <SlLink
                :href="SAP_PROXY_DOWNLOAD_URL"
                target="_blank"
                variant="yellow"
              >
                {{ $t('Web.SapB1.AlertLink') }}
              </SlLink>
            </template>
          </i18n>
        </div>
        <div class="sl-alert__text">
          {{ $t('Web.SapB1.AlertText') }}
        </div>
      </SlAlert>
    </div>
    <div class="connector-content-block__row">
      <SlInput
        v-model="proxyServerNameModel"
        :label="$t('Web.DbRelatedConnectors.Form.LabelSapServerName')"
        class="w-320"
      />
    </div>
    <div class="connector-content-block__row">
      <SlInput
        v-model="usernameModel"
        :label="$t('Web.DbRelatedConnectors.Form.LabelSapUsername')"
        class="w-320"
      />
    </div>
    <div class="connector-content-block__row">
      <SlPasswordInput
        v-model="passwordModel.value"
        :label="$t('Web.DbRelatedConnectors.Form.LabelSapPassword')"
        class="w-320"
        :is-set="passwordModel.isSet"
      />
    </div>
    <div class="connector-content-block__row">
      <SlInput
        v-model="exportOrdersUrlModel"
        :label="$t('DbInsideImport.Names.SapSLDAddress')"
        class="w-320"
      />
    </div>
    <div class="connector-content-block__row">
      <SlSelect
        v-model="bitnessModel"
        :options="bitnessOptions"
        :select-label="$t('DbInsideSourceWidget.Ui.lbBitness')"
        class="w-320"
      />
    </div>
    <div class="connector-content-block__row">
      <SlSelect
        v-model="sqlVersionModel"
        :options="sqlVersionOptions"
        :select-label="$t('DbInsideImport.Ui.lbSqlVersion')"
        class="w-320"
      />
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { bitnessOptions, sqlVersionOptions } from '@/config/connection/sap.config';
import { SAP_PROXY_DOWNLOAD_URL } from '@/config/shared/resources.config';

export default {
  name: 'Export',
  components: {
    ConnectorContentBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      SAP_PROXY_DOWNLOAD_URL,
      bitnessOptions,
      sqlVersionOptions
    };
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.EXPORT]
    }),
    proxyServerNameModel: {
      get() {
        return this.tabData?.proxyServerName;
      },
      set(value) {
        this.setSettings({ value, key: 'proxyServerName' });
      }
    },
    bitnessModel: {
      get() {
        return this.tabData?.bitness;
      },
      set(value) {
        this.setSettings({ value, key: 'bitness' });
      }
    },
    sqlVersionModel: {
      get() {
        return this.tabData?.sqlVersion;
      },
      set(value) {
        this.setSettings({ value, key: 'sqlVersion' });
      }
    },
    exportOrdersUrlModel: {
      get() {
        return this.tabData?.exportOrdersUrl;
      },
      set(value) {
        this.setSettings({ value, key: 'exportOrdersUrl' });
      }
    },
    usernameModel: {
      get() {
        return this.tabData?.username;
      },
      set(value) {
        this.setSettings({ value, key: 'username' });
      }
    },
    passwordModel: {
      get() {
        return this.tabData?.password;
      },
      set(value) {
        this.setSettings({
          value: {
            isSet: this.tabData?.password?.isSet,
            value
          },
          key: 'password'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/settings.scss";
</style>
