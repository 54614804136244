<template>
  <component
    :is="component"
    v-bind="$attrs"
    :to="$attrs.href"
    class="sl-link"
    :class="{
      ...variantClasses,
      ...colorClasses,
      [sizeClass]: true
    }"
    v-on="$listeners"
  >
    <div
      v-if="$scopedSlots.prepend"
      class="prepend-element"
    >
      <slot name="prepend" />
    </div>
    
    <slot />
  </component>
</template>

<script>
export default {
  name: 'SlLink',
  props: {
    variant: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'grey',
      validator: color => ['grey', 'dark-grey', 'primary', 'red', 'yellow', 'green'].includes(color)
    },
    size: {
      type: String,
      default: 'md',
      validator: size => ['xs', 'sm', 'md', 'lg'].includes(size)
    },
    disabled: Boolean
  },
  computed: {
    variantClasses() {
      return {
        [`sl-link__${this.variant}`]: true,
        [`sl-link__${this.variant}--disabled`]: this.disabled
      };
    },
    colorClasses() {
      return {
        [`sl-link__${this.color}`]: true,
        [`sl-link__${this.color}--disabled`]: this.disabled
      };
    },
    sizeClass() {
      return `sl-link__${this.size}`;
    },
    component() {
      return this.$attrs.href ? 'router-link' : 'a';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-link/index';
</style>
