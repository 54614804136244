import Vue from 'vue';
import plannedOrdersApi from '@/api/reports/inventoryReport/plannedOrders';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import SaveOrderFiltersRequestAdapter from '@/adapters/request/orders/SaveOrderFiltersRequest.adapter';
import OrdersMetadataAdapter from '@/adapters/response/plannedOrders/OrdersMetadata.adapter';
import {
  orderTypeByTypeKey,
  plannedOrdersTabs,
  plannedOrdersTypes
} from '@/config/report/inventoryReport/plannedOrders.config';
import { ordersTypesKeyMap } from '@/config/report/inventoryReport';
import { namespaceByRoute } from '@/config/report';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { planedOrdersRoutes } from '@/config/router/router.config';
import { getTableConfigStructure, getTableConfigParams, sortingParamsAdapter } from '@/helpers/shared/tableConfig';
import { getRouteName } from '@/helpers/shared/router';
import { filtersToNum } from '@/helpers/plannedOrders';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_ORDER_SUPPLIERS_TABLE: 'SET_ORDER_SUPPLIERS_TABLE',
  SET_SUPPLIERS_TABLE_ID: 'SET_SUPPLIERS_TABLE_ID',
  SET_ORDERS_REFETCH_INTERVAL: 'SET_ORDERS_REFETCH_INTERVAL',
  SET_IS_CALCULATION_IN_PROGRESS: 'SET_IS_CALCULATION_IN_PROGRESS',
  SET_FILTERS: 'SET_FILTERS',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  SET_AVAILABLE_TABS: 'SET_AVAILABLE_TABS',
  SUBMIT_CELL_VALUE: 'SUBMIT_CELL_VALUE',
  RESET_TABS_IS_LOADED: 'RESET_TABS_IS_LOADED',
  RESET_IS_EDITED: 'RESET_IS_EDITED',
  SELECT_ROW: 'SELECT_ROW',
  RESET_STATE: 'RESET_STATE',
  SET_TAB: 'SET_TAB',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const initialTabsState = () => {
  return plannedOrdersTabs.reduce((acc, tab) => {
    acc[tab] = {
      table: null,
      metadata: null,
      isLoaded: false,
      tableConfig: getTableConfigStructure()
    };

    return acc;
  }, {});
};

const initialState = () => ({
  ...initialTabsState(),
  filters: null,
  orderSuppliersTable: null,
  orderSuppliersTableId: '',
  ordersRefetchInterval: null,
  isCalculationInProgress: false,
  can_create: false,
  available_tabs: [],
  tab: plannedOrdersTypes.ANY
});

const state = initialState();

const getters = {
  ordersByTab: (state) => (tab) => state[tab],
  getCommonParams: (state, _, rootState) => {
    const routeName = getRouteName();
    const type = ordersTypesKeyMap[routeName];

    return {
      filters: filtersToNum(state.filters),
      type,
      filterId: rootState[namespaceByRoute[routeName]].active_filter_id
    };
  },
  isEdited: (state) => {
    return Object.values(plannedOrdersTypes).some(type => {
      return state[type]?.table?.metadata?.edited;
    });
  },
  getParams: (state, getters) => ({
    ...getters.getCommonParams,
    orderType: orderTypeByTypeKey[state.tab],
    ...getTableConfigParams(state[state.tab].tableConfig)
  })
};

const mutations = {
  [types.SET_TABLE](state, value) {
    Object.assign(state[state.tab], {
      table: value,
      isLoaded: true
    });
  },
  [types.SET_ORDER_SUPPLIERS_TABLE](state, value) {
    state.orderSuppliersTable = value;
  },
  [types.SET_SUPPLIERS_TABLE_ID](state, value) {
    state.orderSuppliersTableId = value;
  },
  [types.SET_ORDERS_REFETCH_INTERVAL](state, value) {
    state.ordersRefetchInterval = value;
  },
  [types.SET_IS_CALCULATION_IN_PROGRESS](state, value) {
    state.isCalculationInProgress = value;
  },
  [types.SET_TAB](state, value) {
    state.tab = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state[state.tab].tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state[state.tab].tableConfig, payload);
  },
  [types.SET_FILTERS](state, { filters, canCreate }) {
    state.filters = filters;
    state.can_create = canCreate;
  },
  [types.UPDATE_FILTERS](state, value) {
    state.filters = value;
  },
  [types.SET_AVAILABLE_TABS](state, value) {
    state.available_tabs = value;
  },
  [types.SUBMIT_CELL_VALUE](_, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.SELECT_ROW](_, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, { val: value });
  },
  [types.RESET_TABS_IS_LOADED](state, tabToReset) {
    if (tabToReset) {
      return Vue.set(state[tabToReset], 'isLoaded', false);
    }

    plannedOrdersTabs.forEach(tab => {
      Vue.set(state[tab], 'isLoaded', false);
    });
  },
  [types.RESET_IS_EDITED](state) {
    plannedOrdersTabs.forEach(tab => {
      if (state[tab]?.table?.metadata) {
        Vue.set(state[tab].table.metadata, 'edited', false);
      }
    });
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetIsTabsLoaded({ commit }) {
    plannedOrdersTabs.forEach(tab => {
      commit(types.RESET_TABS_IS_LOADED, tab);
    });
  },
  resetIsEdited({ getters, commit }) {
    if (getters.isEdited) {
      commit(types.RESET_IS_EDITED);
    }
  },
  updateFilters({ commit }, value) {
    commit(types.UPDATE_FILTERS, value);
  },
  updateTableConfig({ state, commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
    commit(types.RESET_TABS_IS_LOADED, state.tab);
  },
  async fetchOrders({ state, commit, dispatch, getters }, manualUpdate) {
    try {
      // call by last change - update all tabs
      if (!manualUpdate) {
        await dispatch('resetIsTabsLoaded');
      }

      const response = await plannedOrdersApi.getPlannedOrders(getters.getParams);

      const data = response?.data;

      if (!data) {
        return;
      }

      if (state.ordersRefetchInterval) {
        clearInterval(state.ordersRefetchInterval);
        commit(types.SET_ORDERS_REFETCH_INTERVAL, null);
        dispatch('setIsCalculationInProgress', false);
      }

      const { table, filters, erpFlags, exportTabFlags } = data;

      commit(types.SET_FILTERS, {
        filters,
        canCreate: erpFlags.toString(2).slice(-1) === '1'
      });
      commit(types.SET_TABLE, GenericTableAdapter(table, null, OrdersMetadataAdapter));
      commit(types.SET_AVAILABLE_TABS, exportTabFlags);
      commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(table));
      commit(types.RESET_TABS_IS_LOADED, state.tab);
    } catch (e) {
      if (e?.code === slErrorCodes.RECALCULATING_PLANNED_ORDERS_IS_IN_PROGRESS) {
        dispatch('setIsCalculationInProgress', true);
        dispatch('setOrdersRefetchInterval');
      } else {
        this.dispatch('user/logout', { e, from: 'fetchOrders' });
      }
    }
  },
  setOrdersRefetchInterval({ state, commit, dispatch }) {
    if (state.ordersRefetchInterval) {
      return;
    }

    const refetchInterval = setInterval(() => {
      if (planedOrdersRoutes.include(getRouteName())) {
        dispatch('fetchOrders');
      }
    }, 1000);

    commit(types.SET_ORDERS_REFETCH_INTERVAL, refetchInterval);
  },
  setIsCalculationInProgress({ commit }, value) {
    commit(types.SET_IS_CALCULATION_IN_PROGRESS, value);
  },
  togglePlannedOrdersChildNodes(_, payload) {
    try {
      plannedOrdersApi.toggleChildNodes(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/togglePlannedOrdersChildNodes' });
    }
  },
  createOrders({ state, getters }) {
    const params = {
      ...getters.getCommonParams,
      orderType: orderTypeByTypeKey[state.tab]
    };

    return plannedOrdersApi.postOrders(params);
  },
  exportToCsv({ getters }, { tab, ...params }) {
    const payload = {
      ...getters.getCommonParams,
      ...params,
      orderType: orderTypeByTypeKey[tab]
    };

    return plannedOrdersApi.exportPlannedOrdersToCsv(payload);
  },
  exportToXlsx({ getters }, { tab, ...params }) {
    const payload = {
      ...getters.getCommonParams,
      ...params,
      orderType: orderTypeByTypeKey[tab]
    };

    return plannedOrdersApi.exportPlannedOrdersToXlsx(payload);
  },
  async saveOrders({ dispatch }, filters) {
    try {
      dispatch('setIsCalculationInProgress', true);

      await this.dispatch('operations/subscribe', {
        subscriber: () => plannedOrdersApi.saveOrderFilters(SaveOrderFiltersRequestAdapter(filters))
      });
    } catch (e) {
      const errorMessage = e?.message;

      if (errorMessage) {
        this.$notify({
          type: 'error',
          text: errorMessage,
          duration: 9000
        });
      }

      this.dispatch('user/logout', { e, from: 'plannedOrders/saveOrders' });
    } finally {
      dispatch('setIsCalculationInProgress', false);
    }
  },
  setSupplierTableId({ commit, dispatch }, id) {
    commit(types.SET_SUPPLIERS_TABLE_ID, id);

    if (!id) {
      return;
    }

    dispatch('fetchOrderSuppliers');
  },
  async fetchOrderSuppliers({ state, getters, commit }) {
    if (!state.orderSuppliersTableId) {
      return;
    }

    try {
      const response = await plannedOrdersApi.getOrderSuppliers({
        type: getters.getCommonParams.type,
        entryId: state.orderSuppliersTableId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_ORDER_SUPPLIERS_TABLE, GenericTableAdapter(response.data));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchOrderSuppliers' });
    }
  },
  async updateVisibleColumns({ getters }, params) {
    try {
      await plannedOrdersApi.updateVisibleColumns({
        ...params,
        type: getters.getCommonParams.type
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/updateVisibleColumns' });
    }
  },
  async updateOverrides({ getters }, { id, supplierId, ...body }) {
    try {
      const { id: filterId, ...params } = getters.getParams;

      await plannedOrdersApi.updateOverrides({
        ...(!id && { filterId, ...params }),
        global: !id,
        id,
        supplierId
      }, body);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/updateOverrides' });
    }
  },
  async saveOverrides({ dispatch, getters }) {
    try {
      await plannedOrdersApi.saveOverrides(getters.getCommonParams);

      dispatch('resetIsEdited');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/saveOverrides' });
    }
  },
  async discardOverrides({ dispatch }) {
    try {
      await plannedOrdersApi.discardOverrides();

      dispatch('resetIsEdited');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/discardOverrides' });
    }
  },
  selectRow({ state, commit }, { rowId, tab, ...payload }) {
    const rows = state[tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    commit(types.SELECT_ROW, {
      cell: rows[rowIndex],
      ...payload
    });
  },
  submitCell({ state, commit }, { tab, rowId, cellKey, value }) {
    const rows = state[tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    if (rowIndex === -1) {
      return;
    }

    const oldCell = rows[rowIndex][cellKey];

    const newValue = {
      ...oldCell,
      val: value
    };

    commit(types.SUBMIT_CELL_VALUE, {
      cell: rows[rowIndex],
      cellKey,
      value: newValue
    });
  },
  submitSuppliersCell({ state, commit }, { rowId, cellKey, value }) {
    const rows = state.orderSuppliersTable.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    if (rowIndex === -1) {
      return;
    }

    const oldCell = rows[rowIndex][cellKey];

    const newValue = {
      ...oldCell,
      val: value
    };

    commit(types.SUBMIT_CELL_VALUE, {
      cell: rows[rowIndex],
      cellKey,
      value: newValue
    });
  },
  async updateSuppliersOverrides(_, { entryId, supplierId, ...body }) {
    try {
      await plannedOrdersApi.putOrderSupplierOverride({
        entryId,
        supplierId
      }, body);
    } catch (e) {
      const message = e?.message;

      if (message) {
        Vue.notify({
          type: 'warn',
          text: message
        });
      }
      this.dispatch('user/logout', { e, from: 'plannedOrders/putOrderSupplierOverride' });
    }
  },
  async saveSuppliersOverrides(_, payload) {
    try {
      await plannedOrdersApi.saveOrderSuppliersOverrides(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/saveOverrides' });
    }
  },
  async discardSuppliersOverrides(_, payload) {
    try {
      await plannedOrdersApi.deleteOrderSuppliersOverrides(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'plannedOrders/discardSuppliersOverrides' });
    }
  },
  clearOrders({ commit }) {
    commit(types.SET_TABLE, null);
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setTab({ commit }, value) {
    commit(types.SET_TAB, value);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
