export const alertTypes = {
  INFO: 'info',
  INFO_COMMON: 'info-common',
  INFO_PASSIVE: 'info-passive',
  INFO_EXTRA: 'info-extra',
  ERROR: 'error',
  WARNING: 'warn',
  SUCCESS: 'success'
};

export const alertIcons = {
  [alertTypes.INFO]: 'info',
  [alertTypes.INFO_EXTRA]: 'info',
  [alertTypes.ERROR]: 'error_circle',
  [alertTypes.WARNING]: 'warning',
  [alertTypes.SUCCESS]: 'success'
};

export const lineAlertIcons = {
  [alertTypes.INFO]: 'info',
  [alertTypes.INFO_COMMON]: 'info',
  [alertTypes.INFO_PASSIVE]: 'info',
  [alertTypes.ERROR]: 'warning',
  [alertTypes.WARNING]: 'warning',
  [alertTypes.SUCCESS]: 'success'
};