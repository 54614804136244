var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connector-query"},[_c('SlValidate',{attrs:{"vid":"query","rules":"required","mode":"eager","message":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlTextArea',{ref:"queryInput",attrs:{"label":_vm.label,"placeholder":_vm.placeholder,"height":_vm.height,"is-invalid":invalid,"highlight":"sql"},model:{value:(_vm.queryModel),callback:function ($$v) {_vm.queryModel=$$v},expression:"queryModel"}})]}}])}),_c('div',{staticClass:"connector-query__slots"},[(_vm.stubs && _vm.stubs.length)?_c('div',{staticClass:"connector-query__stubs",class:{
        'connector-query__stubs--only': !_vm.$slots.default && !_vm.$slots.actions
      }},[_c('div',{directives:[{name:"resize-observe",rawName:"v-resize-observe",value:(_vm.onResizeStubs),expression:"onResizeStubs"}],ref:"stubsWrapper",staticClass:"connector-query__stubs-wrapper",class:{
          'open': _vm.showStubs
        }},[_c('div',{staticClass:"connector-query__stubs-label body-4-md grey-60"},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.LabelInsertParams'))+" ")]),_vm._l((_vm.stubs),function(stub){return _c('SlTag',{key:stub.value,attrs:{"id":stub.value},on:{"select":_vm.handleStubSelect}},[_vm._v(" "+_vm._s(stub.label)+" ")])}),(_vm.showStubsArrow)?_c('SlButton',{staticClass:"connector-query__stubs-arrow",attrs:{"size":"xs","variant":"secondary","color":"grey","icon":""},on:{"click":function($event){_vm.showStubs = !_vm.showStubs}}},[_c('icon',{staticClass:"fill-off size-16",class:{
              'rotate-180': _vm.showStubs
            },attrs:{"data":require("@icons/chevron_down.svg")}})],1):_vm._e()],2)]):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"connector-query__main"},[_vm._t("default")],2):_vm._e(),(_vm.$slots.actions)?_c('div',{staticClass:"connector-query__actions"},[_vm._t("actions")],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }