export const emptySection = () => ({
  widgets: [],
  height: 0
});

export const MAX_SECTION_WIDTH = 100;
export const MAX_WIDGETS_PER_ROW = 4;
export const DEFAULT_SECTION_HEIGHT_PC = 33.33;

export const widgetTypes = {
  PERIODIC: 'Periodic',
  COMPARATIVE: 'Comparative',
  SINGLE_MEANING: 'SingleMeaning',
  TOTAL_VALUE: 'TotalValue'
};

export const controlType = {
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  COMBOBOX: 'comboData',
  CONTROL: 'control',
  NUMBER_INPUT: 'numberInput'
};

export const blockTypes = {
  NAME: 'name',
  WIDGET_TYPE: 'widgetType',
  REPORT_CONFIG: 'reportConfig',
  AGGREGATION: 'aggregation',
  FILTER: 'filter',
  SORTING: 'sorting',
  TIME_VIEW: 'timeView'
};

export const chartTypes = {
  BAR: 'bar',
  STACKED_BAR: 'stackedBar',
  LINE: 'line',
  DONUT: 'doughnut'
};

const colorPalette = [
  '#5990FD',
  '#F2966A',
  '#69D093',
  '#F8E687',
  '#F38888',
  '#7AB4FF',
  '#BEEF9E',
  '#CD95EA',
  '#73C8C8',
  '#ACDC79',
  '#FFC6A4',
  '#76A3FE',
  '#A3CAB7',
  '#FBED8D',
  '#D18FAD',
  '#6AC9DA',
  '#F8DF72',
  '#FEACA7',
  '#9999FF',
  '#F9AAEF'
];

const colorblindPalette = [
  '#264EA6',
  '#2652B3',
  '#2F5DC2',
  '#3062CF',
  '#3066DC',
  '#306AE9',
  '#306EF6',
  '#3B77F8',
  '#4880F8',
  '#5588F8',
  '#6291F8',
  '#6F9AF8',
  '#7CA3F8',
  '#88ACF8',
  '#95B4F8',
  '#A3BDF8',
  '#B0C7F9',
  '#BCD0F9',
  '#C9D8F9'
];

export const datasetColorsMap = (isColorblind) => {
  if (isColorblind) {
    return colorblindPalette;
  }

  return colorPalette;
};

const contrastByColorPalette = {
  '#5990FD': 'white',
  '#F2966A': 'white',
  '#69D093': 'black',
  '#F8E687': 'black',
  '#F38888': 'white',
  '#7AB4FF': 'white',
  '#BEEF9E': 'black',
  '#CD95EA': 'white',
  '#73C8C8': 'black',
  '#ACDC79': 'black',
  '#FFC6A4': 'black',
  '#76A3FE': 'white',
  '#A3CAB7': 'black',
  '#FBED8D': 'black',
  '#D18FAD': 'white',
  '#6AC9DA': 'white',
  '#F8DF72': 'black',
  '#FEACA7': 'black',
  '#9999FF': 'white',
  '#F9AAEF': 'white'
};

const contrastByColorblindPalette = {
  '#264EA6': 'white',
  '#2652B3': 'white',
  '#2F5DC2': 'white',
  '#3062CF': 'white',
  '#3066DC': 'white',
  '#306AE9': 'white',
  '#306EF6': 'white',
  '#3B77F8': 'white',
  '#4880F8': 'white',
  '#5588F8': 'white',
  '#6291F8': 'white',
  '#6F9AF8': 'white',
  '#7CA3F8': 'black',
  '#88ACF8': 'black',
  '#95B4F8': 'black',
  '#A3BDF8': 'black',
  '#B0C7F9': 'black',
  '#BCD0F9': 'black',
  '#C9D8F9': 'black'
};

export const datasetContrastColorsMap = (isColorblind) => {
  if (isColorblind) {
    return contrastByColorblindPalette;
  }

  return contrastByColorPalette;
};
export const requestKeys = {
  FORECAST_VERSION: 'ForecastVersion',
  ARCHIVE_PERIOD: 'ArchivePeriod'
};