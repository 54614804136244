import { integrationModuleTypes } from '@/config/integrations';

const importAdapter = ({ aggregationByDay, ...orderColumnNames }) => {
  return {
    orderColumnNames,
    aggregationByDay
  };
};

const exportAdapter = ({ subsidiary, employee }) => {
  return {
    subsidiary: subsidiary
      ? { id: subsidiary._id, name: subsidiary._name }
      : null,
    employee: employee
      ? { id: employee._id, name: employee._name }
      : null
  };
};

export default function(settings, integration) {
  const adapterByModule = {
    [integrationModuleTypes.IMPORT]: importAdapter,
    [integrationModuleTypes.EXPORT]: exportAdapter
  };

  return adapterByModule[integration.module](settings, integration);
}

