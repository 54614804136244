<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="isCloudConnection"
      class="connector-content-block__row w-320"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="environment"
        mode="eager"
        rules="required"
      >
        <SlSelect
          v-model="environmentModel"
          :select-label="$t('Web.BaseConnectors.Form.Environment')"
          :options="environmentOptions"
          track-by="id"
          label="value"
          :loading="isLoading"
          :is-invalid="invalid"
          :placeholder="$t('Main.Lv.ByNone')"
          @open="handleEnvironmentsSelectOpen"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row w-320">
      <SlValidate
        v-slot="{ invalid }"
        vid="company"
        mode="eager"
        rules="required"
      >
        <SlSelect
          v-model="companyModel"
          :select-label="$t('Web.BaseConnectors.Form.Company')"
          :options="companyOptions"
          track-by="id"
          label="name"
          :loading="isLoading"
          :is-invalid="invalid"
          :placeholder="$t('Main.Lv.ByNone')"
          return-object
          @open="handleCompaniesSelectOpen"
        >
          <template
            v-if="needNoOptionLabel"
            #noOptions
          >
            {{ noCompanyOptionsLabel }}
          </template>
        </SlSelect>
      </SlValidate>
    </div>
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Periods from '@/components/Connections/Common/Periods.vue';
import { connection } from '@/mixins/connection';
import { modal } from '@/mixins/modal';
import { connectionTypes } from '@/config/connection';
import modalsId from '@/config/shared/modalsId.config';
import { operationInfoTypes } from '@/config/api/operations.config';
import { bc365ConnectionTypes } from '@/config/connection/BC365.config';
import { openLink } from '@/helpers/shared/webAPI';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { dealWith } from '@/api/helpers/errorRegistry';

export default {
  name: 'BC365RequiredTab',
  components: {
    Periods
  },
  mixins: [connection, modal],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.BUSINESS_CENTRAL,
      isLoading: false,
      isConnectionError: false,
      isEnvironmentsLoaded: false,
      isCompaniesLoaded: false
    };
  },
  computed: {
    ...mapState({
      environmentOptions: state => state.bc365.environments || [],
      companyOptions: state => state.bc365.companies || []
    }),
    sourceConnectData() {
      return this.sourceConnectDataByType[this.type] || {};
    },
    isCloudConnection() {
      return this.sourceConnectData.type === bc365ConnectionTypes.CLOUD;
    },
    needNoOptionLabel() {
      return !this.isLoading && !this.companyOptions.length;
    },
    noCompanyOptionsLabel() {
      if (!this.isCloudConnection) {
        return this.$t('Web.DbImport.TitleNoData');
      }

      if (!this.environmentModel) {
        return this.$t('Web.BaseConnectors.Form.NoEnvironment');
      }

      if (!this.companyOptions.length) {
        return this.$t('Web.BaseConnectors.Form.NoCompanies');
      }

      return this.$t('Web.DbImport.TitleNoData');
    },
    environmentModel: {
      get() {
        return this.connectionData.required?.environment;
      },
      set(value) {
        this.companyModel = null;
        this.setCompanies(null);
        this.isCompaniesLoaded = false;

        this.setRequiredValue({
          key: 'environment',
          value
        });
      }
    },
    companyModel: {
      get() {
        return this.connectionData.required?.company;
      },
      set(value) {
        this.setRequiredValue({
          key: 'company',
          value
        });
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCompaniesCloud: 'bc365/fetchCompaniesCloud',
      fetchCompaniesOnPremises: 'bc365/fetchCompaniesOnPremises',
      fetchEnvironments: 'bc365/fetchEnvironments',
      setCompanies: 'bc365/setCompanies',
      setEnvironments: 'bc365/setEnvironments',
      subscribe: 'operations/subscribe',
      logout: 'user/logout'
    }),
    handleEnvironmentsSelectOpen() {
      if (this.isNew) {
        return;
      }

      if (!this.isEnvironmentsLoaded) {
        this.updateEnvironments();
      }
    },
    handleCompaniesSelectOpen() {
      if ((this.isNew && this.companyOptions.length) || this.isConnectionError) {
        return;
      }

      if (!this.isCompaniesLoaded) {
        this.isCloudConnection
          ? this.updateCompaniesCloud(this.environmentModel)
          : this.updateCompaniesOnPremises();
      }
    },
    handleBgOperationError(error) {
      this.isConnectionError = true;

      dealWith({
        [slErrorCodes.OAUTH_TIMEOUT_ERROR]: {
          silent: true,
          before: () => {
            this.showModal(modalsId.BC365_CONNECTION_TIMEOUT, {
              source: this.$t('Web.BC365.Title')
            });
          }
        },
        '*': {
          text: (e) => e.message
        }
      })(error);
    },
    async updateCompaniesCloud(id) {
      try {
        if (!id) {
          return;
        }

        this.isLoading = true;

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchCompaniesCloud(id),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          }
        });

        this.isCompaniesLoaded = true;
        this.isConnectionError = false;

        this.setCompanies(operationData.companies);
      } catch (e) {
        this.handleBgOperationError(e);

        this.logout({
          e,
          from: 'bc365/updateCompaniesCloud'
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateCompaniesOnPremises() {
      try {
        this.isLoading = true;

        await this.fetchCompaniesOnPremises({
          login: this.sourceConnectData.username,
          password: this.sourceConnectData.password,
          baseUrl: this.sourceConnectData.baseUrl
        });

        this.isCompaniesLoaded = true;
        this.isConnectionError = false;
      } catch {
        this.isConnectionError = true;

        await this.showModal(modalsId.BC365_CONNECTOR, {
          closeCallback: () => {
            this.isConnectionError = false;
          }
        });

        setTimeout(() => {
          this.showModal(modalsId.CONNECTOR_ERROR, {
            connector: this.$t('Web.BC365.Title')
          }, 1000);
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateEnvironments() {
      try {
        this.isLoading = true;

        const { operationData } = await this.subscribe({
          subscriber: async() => this.fetchEnvironments({ cache: true }),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          }
        });

        const { cacheId, values } = operationData;

        this.isEnvironmentsLoaded = true;
        this.isConnectionError = false;

        this.setEnvironments(values);

        this.setRequiredValue({
          key: 'cacheId',
          value: cacheId
        });
      } catch (e) {
        this.handleBgOperationError(e);

        this.logout({
          e,
          from: 'bc365/updateEnvironments'
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
