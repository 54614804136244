import { dataFields } from '@/config/connection/dbRelated.config';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { getPasswordPayload } from '@/helpers/integration';

export default function(state) {
  const { dbServer, database } = state[dataFields.CONNECTION_DATA];

  const {
    username: user,
    password,
    exportOrdersUrl
  } = state[tabKeys.EXPORT];

  return {
    importSets: {
      dbServer,
      database
    },
    exportSets: {
      user,
      password: getPasswordPayload(password),
      exportOrdersUrl
    }
  };
}