import router from '@/router';
import store from '@/store';

export const systemRedirect = async(config) => {
  await router.push({
    ...config,
    params: {
      ...config.params,
      system: true
    }
  });
};

export const projectRedirect = async(config) => {
  await router.push({
    ...config,
    params: {
      ...config.params,
      projectId: router.currentRoute.params.projectId || store.state.manageProjects.projectId
    }
  });
};
