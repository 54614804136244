import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(data) {
  const { _id: id, _key: key } = data.settings.credentials;
  const {
    _nMonthsToDropToReceive: nMonthsToDropToReceive,
    _importItemTypeAsCat: importItemTypeAsCat,
    _locIsDc: locIsDc,
    _customerAsChannel: customerAsChannel,
    _combineLocs: combineLocs,
    _importItemBrandAsCat: importItemBrandAsCat
  } = data.settings.info;

  return {
    required: {
      id,
      key
    },
    optional: {
      nMonthsToDropToReceive,
      importItemTypeAsCat,
      locIsDc,
      customerAsChannel,
      file: FileEditAdapter(data.settings.transactionsFile),
      combineLocs,
      importItemBrandAsCat
    }
  };
}
