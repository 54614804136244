<template>
  <div
    ref="wrapperRef"
    class="sl-table-datepicker-wrapper"
    :class="{
      'sl-table-datepicker-wrapper--disabled': disabled || !editableBg,
      'sl-table-datepicker-wrapper--editable-bg': editableBg,
      [`color--${foreground}`]: foreground
    }"
    tabindex="1"
    @mousedown.right.prevent
    @focus="onWrapperFocus"
  >
    <SlValidate
      v-if="isInputVisible"
      v-slot="{ invalid }"
      vid="datepicker"
      :message="false"
      rules="sl_date_between|sl_valid_date"
      class="sl-table-datepicker"
    >
      <SlDatePicker
        ref="inputRef"
        v-model="vModel"
        :disabled="disabled"
        :is-invalid="invalid"
        :clearable="!!foreground"
        @submit="onSubmit(invalid)"
      />
    </SlValidate>
    <div
      v-else
      class="sl-table-datepicker-text"
    >
      {{ inputTextValue }}
    </div>
  </div>
</template>

<script>
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

export default {
  name: 'SlTableDatepicker',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    // text will show with focusVisible prop
    textValue: {
      type: [Number, String],
      required: false,
      default: ''
    },
    foreground: {
      type: String,
      required: false,
      default: ''
    },
    focusVisible: {
      type: Boolean,
      default: true
    },
    editableBg: {
      type: Boolean,
      default: true
    },
    vid: {
      type: String,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      isChanged: false,
      isFocused: false
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value.replace('≈', '');
      },
      set(value) {
        this.$emit('input', value);

        this.isChanged = true;
      }
    },
    isInputVisible() {
      return !this.focusVisible || this.isFocused;
    },
    inputTextValue() {
      return this.textValue || dateByLocaleKey(this.value, localeDateKeys.YMD);
    }
  },
  methods: {
    onWrapperFocus() {
      if (this.disabled) {
        return;
      }

      this.isFocused = true;

      this.$nextTick(() => this.$refs.inputRef.openPopup());
    },
    onSubmit(invalid) {
      if (invalid) {
        return;
      }

      if (this.isChanged) {
        this.$emit('submit');
      }

      this.isChanged = false;
      this.isFocused = false;
      this.$refs.wrapperRef.blur();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/inputs/sl-table-datepicker.scss";
</style>