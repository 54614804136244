import shopifyAdapter from './shopify';

export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const _groupBy = groupBy.toLowerCase();

  const { 
    companyName: _companyName,
    user: _user,
    password: _password
  } = data.required;

  const {
    combineLocs: _combineLocs,
    transactionsFromShopify: _transactionsFromShopify
  } = data.optional;

  const shopifyData = shopifyAdapter({
    ...data,
    required: data.optional
  });

  delete shopifyData.oauthToken;
  delete shopifyData.info;

  const commonStructure = {
    credentials: {
      _companyName,
      _user,
      _password
    },
    shopify: shopifyData,
    info: {
      _startDate,
      _groupBy,
      _combineLocs: +_combineLocs,
      _transactionsFromShopify: +_transactionsFromShopify
    }
  };

  return commonStructure;
}
