export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const {
    teamName: _teamName,
    user: _user,
    password: _password
  } = data.required;
  const {
    combineLocs: _combineLocs,
    fileId: _fileId,
    fileRemoved: _fileRemoved
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    credentials: {
      _teamName,
      _user,
      _password
    },
    info: {
      _startDate,
      _groupBy
    },
    advanced: {
      _combineLocs
    },
    transactionsFile: {
      _fileId,
      _fileRemoved
    }
  };
}
