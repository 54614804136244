import TableSlotsAdapter from '@/adapters/request/connection/shared/TableSlots.adapter';
import {
  exportKeysOrder,
  exportTabKeys,
  importKeysOrder,
  importTabKeys
} from '@/config/connection/dbRelated.config';

export default function(state) {
  const tabInfo = importTabKeys
    .reduce((acc, tab) => {
      const matchedSlots = TableSlotsAdapter(state[tab]);

      acc.push({
        query: {
          _type: tab,
          __text: state[tab].query
        },
        matchedCols: {
          _wasCopied: '1',
          _n: matchedSlots.length,
          col: matchedSlots
        }
      });

      return acc;
    }, [])
    .sort((a, b) => importKeysOrder[a.query._type] - importKeysOrder[b.query._type]);
  const exportSetsQuery = exportTabKeys
    .reduce((acc, tab) => {
      acc.push({
        _type: tab,
        __text: state[tab].query
      });

      return acc;
    }, [])
    .sort((a, b) => exportKeysOrder[a._type] - exportKeysOrder[b._type]);

  return {
    tabInfo,
    exportSetsQuery
  };
}
