import axios from '@/plugins/axios';

export default {
  changeUiSettings(body, params) {
    return axios.post('/ui-settings', body, { params });
  },
  getUiSettings(params) {
    return axios.get('/ui-settings', { params });
  }
};
