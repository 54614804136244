const KEY_SEPARATOR = '~';

export function getResizedIndexesFromClasses(columns) {
  return Object.keys(columns).reduce((acc, key) => {
    if (!key.includes(KEY_SEPARATOR)) {
      return acc;
    }

    const [position] = key.split(KEY_SEPARATOR);

    if (position) {
      acc[+position] = columns[key];
    }

    return acc;
  }, []);
}

export function getColumnKey(headerMap, columnKey) {
  const position = headerMap[columnKey].columnPosition;

  return `${position}${KEY_SEPARATOR}${columnKey}`;
}
