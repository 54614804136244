import CommonSettingsAdapter from '@/adapters/response/connection/dbRelated/shared/CommonSettings.adapter';
import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(sets, isDefault) {
  const connection = sets?.settings?.connection;

  const connectionData = {
    database: connection?.dataset || '',
    dbServer: connection?.dbServer || '',
    port: connection?.port || ''
  };

  return {
    ...CommonSettingsAdapter(sets.innerSettings, isDefault, connectionData),
    transactionalFile: FileEditAdapter(sets.advanceSettings.transactionsFile)
  };
}

