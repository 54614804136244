<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row connector-wrap">
      <SlValidate
        v-slot="{ invalid }"
        vid="companyName"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="companyName"
          :label="$t('DbInsideSourceWidget.Ui.lbCompany')"
          :is-invalid="invalid"
        />
      </SlValidate>

      <SlValidate
        v-slot="{ invalid }"
        vid="username"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="user"
          :label="$t('DbInsideSourceWidget.Labels.lbUsername')"
          :is-invalid="invalid"
        />
      </SlValidate>

      <SlValidate
        v-slot="{ invalid }"
        key="password"
        vid="password"
        rules="required"
      >
        <SlPasswordInput
          v-model="password"
          :label="$t('DbImportDialog.Ui.lbPassword')"
          :is-invalid="invalid"
        />
      </SlValidate>

      <Periods
        :type="type"
        :is-new="isNew"
      />
    </div>

    <div class="connector-wrap connector-wrap--simple">
      <div class="heading-4-sb">
        {{ $t('Web.Shopify.ShopifyConnction') }}
      </div>
      <div v-if="isNew">
        <SlCheckbox
          v-model="transactionsFromShopify"
          :label="$t('Web.Shopify.TransactionsFromShopify')"
        />
      </div>
      <div v-else>
        <SlInfoItem
          :label="$t('Web.Shopify.TransactionsFromShopify') "
          :value="transactionsFromShopifyLabel"
          class="connector-content-block__row"
        />
      </div>
      <ShopifyRequiredTab
        v-if="transactionsFromShopify"
        :is-new="isNew"
        :connector-type="type"
        no-periods
      />
    </div>
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import Periods from '@/components/Connections/Common/Periods.vue';
import SlValidate from '@/components/UIKit/SlValidate';
import ShopifyRequiredTab from '@/components/Connections/Shopify/RequiredTab';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';

export default {
  name: 'FinaleRequiredTab',
  components: {
    Periods,
    SlValidate,
    ShopifyRequiredTab
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.FINALE
    };
  },
  computed: {
    companyName: {
      get() {
        return this.connectionData.required.companyName;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: 'required',
          key: 'companyName',
          value
        });
      }
    },
    user: {
      get() {
        return this.connectionData.required.user;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: 'required',
          key: 'user',
          value
        });
      }
    },
    password: {
      get() {
        return this.connectionData.required.password;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: 'required',
          key: 'password',
          value
        });
      }
    },
    transactionsFromShopify: {
      get() {
        return Boolean(+this.connectionData.optional.transactionsFromShopify);
      },
      set(value) {
        this.setOptionalValue({
          key: 'transactionsFromShopify',
          value
        });
      }
    },
    transactionsFromShopifyLabel() {
      return getLabelFromBoolean(this.transactionsFromShopify, this);
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    }
  }
};
</script>
