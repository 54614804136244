import axios from '@/plugins/axios';

export default {
  getEnvironments(params) {
    return axios.get('/bc365/read_environments', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getCompaniesCloud(params) {
    return axios.get('/bc365/cloud/read_companies', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getCompaniesOnPremises(body) {
    return axios.post('/bc365/on_premises/read_companies', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
