export const setNestedValue = (obj, path, value) => {
  const keys = path.split('.');
  const lastKey = keys.pop();

  // Build or update the nested object
  const target = keys.reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = {};
    }

    return acc[key];
  }, obj);

  target[lastKey] = value;

  return obj;
};
