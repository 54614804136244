export default function(data, connectData) {
  const {
    groupBy: _groupBy,
    startDate: _startDate
  } = data.commonData;
  const {
    locations,
    channels,
    nMonthsToDropToReceive,
    replacePaymentWithShipmentDate,
    combineLocs,
    combineChannels,
    fileId: _fileId,
    fileRemoved: _fileRemoved
  } = data.optional;

  return {
    info: {
      _startDate,
      _groupBy,
      _nMonthsToDropToReceive: +nMonthsToDropToReceive,
      _replacePaymentWithShipmentDate: +replacePaymentWithShipmentDate,
      _cacheId: connectData.cacheId
    },
    advanceSettings: {
      combineLocs: +combineLocs,
      combineChannels: +combineChannels,
      locations: {
        _n: locations.length,
        location: locations
      },
      channels: {
        _n: channels.length,
        channel: channels
      }
    },
    transactionsFile: {
      _fileId,
      _fileRemoved
    }
  };
}
