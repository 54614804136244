<template>
  <div class="connector-wrap connector-wrap--simple w-320">
    <div class="connector-content-block__row connector-wrap">
      <SlValidate
        v-slot="{ invalid }"
        vid="teamName"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="teamName"
          :label="$t('Web.Sellercloud.LabelTeamName')"
          :is-invalid="invalid"
        />
      </SlValidate>

      <SlValidate
        v-slot="{ invalid }"
        vid="user"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="user"
          :label="$t('DbInsideSourceWidget.Labels.lbUsername')"
          :is-invalid="invalid"
        />
      </SlValidate>

      <SlValidate
        v-slot="{ invalid }"
        key="password"
        vid="password"
        rules="required"
      >
        <SlPasswordInput
          v-model="password"
          :label="$t('DbImportDialog.Ui.lbPassword')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>

    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import SlValidate from '@/components/UIKit/SlValidate';

export default {
  name: 'SellercloudRequiredTab',
  components: {
    Periods,
    SlValidate
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.SELLERCLOUD
    };
  },
  computed: {
    teamName: {
      get() {
        return this.connectionData.required.teamName;
      },
      set(value) {
        this.setRequiredValue({
          key: 'teamName',
          value
        });
      }
    },
    user: {
      get() {
        return this.connectionData.required.user;
      },
      set(value) {
        this.setRequiredValue({
          key: 'user',
          value
        });
      }
    },
    password: {
      get() {
        return this.connectionData.required.password;
      },
      set(value) {
        this.setRequiredValue({
          key: 'password',
          value
        });
      }
    }
  }
};
</script>
