export const cacheLimits = {
  FIVE_MINUTES: 300000
};

export const needCacheUpdate = (timestamp = 0, limit = cacheLimits.FIVE_MINUTES) => {
  if (!timestamp) {
    return true;
  }

  return (Date.now() - timestamp) > limit;
};