import TabsImportAdapter from '@/adapters/response/integrations/transform/shared/TabsImport.adapter';
import { tabKeys } from '@/config/integrations/transform.config';

export default function({ startDate, tables }) {
  return {
    [tabKeys.SETTINGS]: {
      startDate
    },
    ...TabsImportAdapter(tables)
  };
}
