<template>
  <div class="integration-card-wrapper">
    <div
      class="integration-card"
      :class="{
        'integration-card--aggregation': isAggregation,
        'integration-card--nested': nested,
        'integration-card--disabled': disabled,
        'integration-card--failed': !disabled && isFailed
      }"
      @click="cardClickCallback"
    >
      <div class="integration-card__col integration-card__col--title">
        <div class="integration-card__icon">
          <icon
            :data="getIconByType(integration.type)"
            :class="getCardClasses"
          />
        </div>
        <div class="integration-card__content">
          <div class="integration-card__col">
            <SlLink
              v-if="!isAggregation"
              color="dark-grey"
              class="integration-card__title"
              @click="handleOpenIntegration"
            >
              {{ integration.name }}
            </SlLink>
            <div
              v-else
              class="integration-card__title"
            >
              {{ integration.name }}
            </div>
            <SlBadge
              v-if="isStatusShown(integration)"
              class="integration-card__status"
              :variant="badgeVariantByStatus[integration.status]"
            >
              <template
                v-if="isLoading(integration)"
                #prepend
              >
                <SlLoader
                  size="3xs"
                  :text="false"
                  inline
                />
              </template>
              <template #text>
                {{ $t(`Web.Integrations.Statuses.${integration.status}`) }}
              </template>
            </SlBadge>
          </div>

          <div
            v-if="integration.description"
            class="integration-card__description body-3 grey-70"
          >
            {{ integration.description }}
          </div>
        </div>
      </div>
      <div class="integration-card__col integration-card__col--icon">
        <IntegrationActionsDropdown
          v-if="!isAggregation"
          :integration="integration"
          :actions="integration.actions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IntegrationActionsDropdown from '@/components/Integrations/IntegrationActionsDropdown';
import {
  integrationsConfig,
  integrationStatuses,
  integrationTypes
} from '@/config/integrations';

export default {
  name: 'IntegrationCard',
  components: {
    IntegrationActionsDropdown
  },
  props: {
    integration: {
      type: Object,
      required: true
    },
    nested: Boolean,
    disabled: Boolean,
    isInvalid: Boolean
  },
  data() {
    return {
      badgeVariantByStatus: {
        [integrationStatuses.UNINITIALIZED]: 'default',
        [integrationStatuses.LOADING]: 'primary',
        [integrationStatuses.IMPORTED]: 'primary',
        [integrationStatuses.POPULATED]: 'accent-green',
        [integrationStatuses.FAILED_TO_IMPORT]: 'accent-red',
        [integrationStatuses.VALIDATION_NEEDED]: 'accent-red'
      }
    };
  },
  computed: {
    ...mapGetters({
      isProjectEditing: 'integrations/isProjectEditing'
    }),
    isAggregation() {
      return this.integration.type === integrationTypes.AGGREGATION;
    },
    isCustomApi() {
      return this.integration.type === integrationTypes.CUSTOM_API;
    },
    isFailed() {
      return this.integration.status === integrationStatuses.FAILED_TO_IMPORT
        || this.isInvalid && this.integration.status === integrationStatuses.UNINITIALIZED
        || this.integration.status === integrationStatuses.VALIDATION_NEEDED;
    },
    cardClickCallback() {
      if (this.isAggregation) {
        return this.handleOpenIntegration;
      }

      return () => {};
    },
    getCardClasses() {
      return ['size-40', (this.isAggregation || this.isCustomApi) ? 'fill-off' : ''];
    }
  },
  methods: {
    isLoading({ status }) {
      return [
        integrationStatuses.LOADING,
        integrationStatuses.IMPORTED
      ].includes(status);
    },
    isStatusShown({ status }) {
      if (this.disabled) {
        return false;
      }

      if (this.isProjectEditing) {
        return status !== integrationStatuses.POPULATED;
      }

      return status;
    },
    getIconByType(type) {
      return integrationsConfig[type]?.icon;
    },
    handleOpenIntegration() {
      this.$emit('open', this.integration);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/integrations/integration-card.scss';
</style>
