import { routeNames } from '@/config/router/router.config';

export const planTypes = {
  AGGREGATED: 'aggregated',
  RAW: 'raw'
};

export const sumBy = {
  AS_IS: 'asIs',
  WEEK: 'week',
  QUARTER: 'quarter',
  YEAR: 'year'
};

export const sourceByRoute = {
  [routeNames.CONSTRAINED_PLAN]: {
    route: routeNames.INVENTORY,
    locale: 'Main.tabNames.inventory'
  }
};

export const exportOptions = (ctx) => ([
  {
    label: ctx.$t('Web.ConstrainedPlan.ExportAggr'),
    value: planTypes.AGGREGATED
  },
  {
    label: ctx.$t('Web.ConstrainedPlan.ExportRaw'),
    value: planTypes.RAW
  }
]);

export const planOptions = (ctx) => ([
  {
    label: ctx.$t('Web.ConstrainedPlan.OptRaw'),
    value: planTypes.RAW,
    loading: false
  },
  {
    label: ctx.$t('Web.ConstrainedPlan.OptAggr'),
    value: planTypes.AGGREGATED,
    loading: false
  }
]);
