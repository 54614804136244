<template>
  <div class="sl-info-item">
    <span
      class="sl-info-item--label body-1 grey-70"
      :style="{
        minWidth: labelMinWidth
      }"
    >
      {{ label }}:&nbsp;
    </span>
    <span
      v-if="!$scopedSlots.value"
      class="sl-info-item--value body-2 grey-80"
    >
      {{ value }}
    </span>
    <slot name="value" />
  </div>
</template>

<script>
export default {
  name: 'SlInfoItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    labelMinWidth: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-info-item";
</style>