<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <SlCheckbox
      v-else
      v-model="combineLocs"
      :label="$t('Web.BaseConnectors.Form.combineLocs')"
      class="sl-checkbox"
    />
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
  </div>
</template>
<script>
import { connectionTypes } from '@/config/connection';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';

export default {
  name: 'SellercloudOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.SELLERCLOUD,
      fileFormats
    };
  },
  inject: [
    'historicalTransactionsTemplate'
  ],
  computed: {
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    }
  }
};
</script>