<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div
      v-if="$sl_isEditingRoute"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
        :value="combineLocationsValueText"
      />
    </div>
    <SlCheckbox
      v-if="!$sl_isEditingRoute"
      v-model="combineLocationsModel"
      :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
    />
    <SlCheckbox
      v-model="includeZeroOnHandModel"
      :label="$t('DbImportDialog.Ui.chkCleanup')"
    />
    <SlCheckbox
      v-model="zeroPriceTransactionsModel"
      :label="$t('OrderListDialog.Ui.chkZeroPriceTransactions')"
    />
    <SlCheckbox
      v-model="zeroQtyTransactionsModel"
      :label="$t('DbImportDialog.Ui.chkZeroQtyTransactions')"
    />
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';

export default {
  name: 'Optional',
  components: {
    ConnectorContentBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.OPTIONAL]
    }),
    combineLocationsValueText() {
      return getLabelFromBoolean(this.combineLocationsModel, this);
    },
    includeZeroOnHandModel: {
      get() {
        return this.tabData?.includeZeroOnHand;
      },
      set(value) {
        this.setSettings({ value, key: 'includeZeroOnHand' });
      }
    },
    combineLocationsModel: {
      get() {
        return this.tabData?.combineLocations;
      },
      set(value) {
        this.setSettings({ value, key: 'combineLocations' });
      }
    },
    zeroPriceTransactionsModel: {
      get() {
        return this.tabData.zeroPriceTransactions;
      },
      set(value) {
        this.setSettings({ value, key: 'zeroPriceTransactions' });
      }
    },
    zeroQtyTransactionsModel: {
      get() {
        return this.tabData.zeroQtyTransactions;
      },
      set(value) {
        this.setSettings({ value, key: 'zeroQtyTransactions' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/settings.scss";
</style>