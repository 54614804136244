export const MONTHS_IN_YEAR =  12;
export const DAYS_IN_WEEK =  7;
export const SECONDS_IN_DAY = 24 * 60 * 60;

export const months = (ctx) => {
  return Array.from({ length: MONTHS_IN_YEAR }, (_, index) => ({
    value: index + 1,
    text: ctx.$t(`Common.MonthCap.${index + 1}`)
  }));
};

export const weekDays = (ctx, diff = 0) => {
  return Array.from({ length: DAYS_IN_WEEK }, (_, index) => ({
    value: index + diff,
    label: ctx.$t(`Common.DayOfWeekCap.${index + 1}`)
  }));
};

export const daysToMs = (days) => {
  const daysAmount = days || 0;

  return daysAmount * SECONDS_IN_DAY * 1000;
};
