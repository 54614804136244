import { tabKeys } from '@/config/connection/dbRelated.config';

export const dbConnectionTypes = {
  ODBC: 'odbc',
  ODBC32: 'odbc32',
  BIG_QUERY: 'bqodbc',
  MYSQL: 'mysql'
};

export const connectionTypeOptions = [
  {
    label: 'ODBC',
    value: dbConnectionTypes.ODBC
  },
  {
    label: 'ODBC 32',
    value: dbConnectionTypes.ODBC32
  },
  {
    label: 'BigQuery',
    value: dbConnectionTypes.BIG_QUERY
  },
  {
    label: 'MySQL',
    value: dbConnectionTypes.MYSQL
  }
];

export const connectionData = () => ({
  type: dbConnectionTypes.ODBC,
  sourceName: '',
  host: '',
  port: '',
  database: '',
  username: '',
  password: ''
});

export const dbRequiredTabs = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO
];
