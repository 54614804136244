<template>
  <SlModal
    :id="id"
    :title="$t('Web.UpdateData.UpdateDataWithLogs')"
    @created="onCreated"
  >
    <div class="modal-content">
      <SlControl
        v-model="updateData"
        :options="updateDataOptions"
        :label="$t('Web.UpdateData.UpdateData')"
      />
      <SlControl
        v-model="logLevel"
        :options="logLevelOptions"
        :label="$t('Web.UpdateData.LogLevel')"
      >
        <template #icon>
          <SlInfoButton v-tooltip="logLevelTooltip" />
        </template>
      </SlControl>
      <SlCheckbox
        v-model="storeResult"
        :label="$t('Web.UpdateData.StoreResultModel')"
      />
    </div>

    <template #footer>
      <SlModalFooter between>
        <SlLink
          :href="{
            name: routeNames.LOGS_PAGE,
            params: {
              projectId
            }
          }"
          exact-path
          color="primary"
        >
          <template #prepend>
            <icon
              data="@icons/open_link.svg"
              class="fill-off size-16 color-primary-80"
            />
          </template>
          {{ $t('Web.UpdateData.ViewLogs') }}
        </SlLink>
        <div>
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton @click="handleImport">
            {{ $t('Web.Integrations.Actions.BtnImport') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import { 
  updateData,
  updateDataOptions,
  logLevel,
  logLevelOptions,
  logLevelTooltip
} from '@/config/project/updateData.config';

export default {
  name: 'UpdateWithLogsModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.UPDATE_WITH_LOGS,
      updateData: updateData.QUICK,
      logLevel: logLevel.NORMAL,
      updateDataOptions: updateDataOptions(this),
      logLevelOptions: logLevelOptions(this),
      logLevelTooltip: logLevelTooltip(this),
      storeResult: false,
      routeNames
    };
  },
  inject: ['handleReImport'],
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId
    })
  },
  methods: {
    handleCancel() {
      this.hideModal(this.id);
    },
    handleImport() {
      this.handleReImport(this.updateData, this.logLevel, this.storeResult);
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
  .modal-content {
    display: grid;
    gap: 16px;
  }
</style>