import { routeNames } from '@/config/router/router.config';

export const ROOT_GROUP_ID = '0';
export const DEFAULT_FILTER_ID = -1;

export const columnTypes = {
  ENUM: 'Enum',
  STRING: 'String',
  NUMBER: 'Number',
  DATE: 'Date'
};

export const filterTypes = {
  PRIVATE: 'Private',
  PUBLIC: 'Public'
};

export const logicOperationsTypes = {
  AND: 'And',
  OR: 'Or'
};

export const relations = {
  EQ: 'EQ',
  NE: 'NE',
  LESS: 'LESS',
  LE: 'LE',
  GE: 'GE',
  GREATER: 'GREATER',
  NUL: 'NUL',
  NON_NUL: 'NON_NUL',
  CONTAINS: 'CONTAINS',
  NCONTAINS: 'NCONTAINS'
};

export const noValueRelations = [relations.NUL, relations.NON_NUL];
export const baseRelations = [relations.EQ, relations.NE];

export const iconByRelation = {
  [relations.EQ]: 'equal',
  [relations.NE]: 'not_equal',
  [relations.LESS]: 'less',
  [relations.LE]: 'less_equal',
  [relations.GE]: 'greater_equal',
  [relations.GREATER]: 'greater'
};

const equalOptions = (ctx) => ([
  {
    value: relations.EQ,
    label: ctx.$t(`Web.Filters.Relations.${relations.EQ}`),
    icon: iconByRelation[relations.EQ]
  },
  {
    value: relations.NE,
    label: ctx.$t(`Web.Filters.Relations.${relations.NE}`),
    icon: iconByRelation[relations.NE]
  }
]);

const emptyOptions = (ctx) => ([
  {
    value: relations.NUL,
    label: ctx.$t(`Web.Filters.Relations.${relations.NUL}`)
  },
  {
    value: relations.NON_NUL,
    label: ctx.$t(`Web.Filters.Relations.${relations.NON_NUL}`)
  }
]);

const lessGreaterOptions = (ctx, type) => ([
  {
    value: relations.LESS,
    label: ctx.$t(`Web.Filters.Relations.${type}${relations.LESS}`),
    icon: iconByRelation[relations.LESS]
  },
  {
    value: relations.LE,
    label: ctx.$t(`Web.Filters.Relations.${type}${relations.LE}`),
    icon: iconByRelation[relations.LE]
  },
  {
    value: relations.GREATER,
    label: ctx.$t(`Web.Filters.Relations.${type}${relations.GREATER}`),
    icon: iconByRelation[relations.GREATER]
  },
  {
    value: relations.GE,
    label: ctx.$t(`Web.Filters.Relations.${type}${relations.GE}`),
    icon: iconByRelation[relations.GE]
  }
]);

export const relationOptions = (ctx) => ({
  [columnTypes.STRING]: [
    ...equalOptions(ctx),
    ...emptyOptions(ctx),
    {
      value: relations.CONTAINS,
      label: ctx.$t(`Web.Filters.Relations.${relations.CONTAINS}`)
    },
    {
      value: relations.NCONTAINS,
      label: ctx.$t(`Web.Filters.Relations.${relations.NCONTAINS}`)
    }
  ],
  [columnTypes.ENUM]: [
    ...equalOptions(ctx),
    ...emptyOptions(ctx)
  ],
  [columnTypes.NUMBER]: [
    ...equalOptions(ctx),
    ...lessGreaterOptions(ctx, columnTypes.NUMBER),
    ...emptyOptions(ctx)
  ],
  [columnTypes.DATE]: [
    ...equalOptions(ctx),
    ...lessGreaterOptions(ctx, columnTypes.DATE),
    ...emptyOptions(ctx)
  ]
});

export const logicOperationOptions = (ctx) => ([
  {
    value: logicOperationsTypes.AND,
    label: ctx.$t('SelectFilter.LogicOp.And')
  },
  {
    value: logicOperationsTypes.OR,
    label: ctx.$t('SelectFilter.LogicOp.Or')
  }
]);

export const filterTypesByRouteName = {
  [routeNames.INVENTORY]: 'Inventory',
  [routeNames.INTERSTORE]: 'Intersite',
  [routeNames.REPORTS]: 'Report',
  [routeNames.DASHBOARD]: 'Analytics'
};