import { connectionTypes } from '@/config/connection';
import databaseAdapter from './database/Credentials.adapter';
import netsuiteAdapter from './netsuite/Credentials.adapter';
import dynamicsNavAdapter from './dynamicsNav/Credentials.adapter';
import sapAdapter from './sap/Credentials.adapter';
import fishbowlAdapter from './fishbowl/Credentials.adapter';
import dynamicsGpAdapter from './dynamicsGp/Credentials.adapter';
import odooAdapter from './odoo/Credentials.adapter';

const credentialsAdapterByType = {
  [connectionTypes.DATABASE]: databaseAdapter,
  [connectionTypes.NETSUITE]: netsuiteAdapter,
  [connectionTypes.DYNAMICS_NAV]: dynamicsNavAdapter,
  [connectionTypes.SAP]: sapAdapter,
  [connectionTypes.FISHBOWL]: fishbowlAdapter,
  [connectionTypes.DYNAMICS_GP]: dynamicsGpAdapter,
  [connectionTypes.ODOO]: odooAdapter
};

export default function(type, credentials) {
  return credentialsAdapterByType[type](credentials);
}
