<template>
  <component
    :is="component"
    v-close-popper.all="selectClose"
    :to="href"
    :href="href"
    :target="target"
    tabindex="0"
    class="sl-dropdown-option body-1 grey-80"
    :class="{
      'sl-dropdown-option--disabled': disabled,
      'sl-dropdown-option--selected': selected,
      'sl-dropdown-option--divider': divider,
      'sl-dropdown-option--round': round
    }"
    @click="$emit('select')"
    @keydown.enter="$emit('select')"
  >
    <slot name="prepend" />

    <div class="sl-dropdown-option__label">
      <slot />
      <slot name="append-inner" />
    </div>

    <slot name="append" />
  </component>
</template>

<script>
export default {
  name: 'SlDropdownOption',
  props: {
    href: {
      type: [String, Object],
      default: null
    },
    target: {
      type: String,
      default: ''
    },
    selected: Boolean,
    selectClose: Boolean,
    selectCloseAll: Boolean,
    disabled: Boolean,
    divider: Boolean,
    round: Boolean
  },
  computed: {
    component() {
      if (!this.href) {
        return 'div';
      }

      return this.target ? 'a' : 'router-link';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-dropdown/sl-dropdown-option';
</style>
