import TableSlotsAdapter from '@/adapters/response/connection/shared/TableSlots.adapter';
import { toArray } from '@/helpers/utils/toArray';

const parseImportSettings = (settings) => {
  return toArray(settings).reduce((acc, { query, matchedCols }) => {
    acc[query._type] = {
      query: query.__text || '',
      ...TableSlotsAdapter(matchedCols.col)
    };

    return acc;
  }, {});
};
const parseExportSettings = (settings) => {
  return toArray(settings).reduce((acc, tab) => {
    acc[tab._type] = {
      query: tab.__text || ''
    };

    return acc;
  }, {});
};

export default function({ importData, exportData }) {
  return {
    importTabs: parseImportSettings(importData),
    exportTabs: parseExportSettings(exportData)
  };
}
