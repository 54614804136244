import axios from '@/plugins/axios';

export default {
  getCharts(params) {
    return axios.get('demand/chart', {
      params
    });
  },
  getCurvesVisibility() {
    return axios.get('demand/chart/curve');
  },
  updateCurveVisibility(params) {
    return axios.put('demand/chart/curve', null, { params });
  }
};
