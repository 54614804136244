export const reimportWarningTypes = {
  CYCLIC_SUBSTITUTIONS: 'CyclicSubstitutions',
  QB_ISSUE: 'QbIssue',
  BOM_ISSUE: 'BomIssue',
  RESTRICTED_PROJECT: 'RestrictedProject'
};

export const qbIssueTypes = {
  skippedDocs: 'skippedDocs',
  unsetLocDocs: 'unsetLocDocs'
};

export const bomIssueTypes = {
  bomCycleIssues: 'bomCycleIssues',
  bomNonExistingItems: 'bomNonExistingItems'
};
