import { themesKeys } from '@/config/shared/themes.config';
import clientStorage from '@/api/clientStorage';
import clientStorageKeysConfig from '@/config/api/clientStorageKeys.config';

const types = {
  SET_THEME: 'SET_THEME',
  SET_SIDEBAR: 'SET_SIDEBAR',
  SET_INITIALIZATION: 'SET_INITIALIZATION'
};

const state = () => ({
  theme: clientStorage.getDataByKey(clientStorageKeysConfig.COLORBLIND_MODE)
    ? themesKeys.COLORBLIND
    : themesKeys.LIGHT,
  sidebar: false
});

const getters = {
  isColorblindMode: state => state.theme === themesKeys.COLORBLIND
};

const mutations = {
  [types.SET_THEME](state, value) {
    state.theme = value;
  },
  [types.SET_SIDEBAR](state, value) {
    state.sidebar = value;
  }
};

const actions = {
  setTheme({ commit }, value) {
    commit(types.SET_THEME, value);
  },
  setSidebar({ commit }, value) {
    commit(types.SET_SIDEBAR, value);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
