import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';
import nodeApi from '@/api/demand/node';
import tableApi from '@/api/demand/table';
import { getNoteKey } from '@/helpers/shared/note';

const types = {
  SET_NODE_NOTES: 'SET_NODE_NOTES',
  RESET_NODE_NOTES: 'RESET_NODE_NOTES'
};

const initialState = () => ({
  nodeNotes: {}
});

const state = initialState();

const getters = {
  getNoteByEntity: state => entity => state.nodeNotes[getNoteKey(entity)] || null
};

const mutations = {
  [types.SET_NODE_NOTES](state, { key, value }) {
    Vue.set(state.nodeNotes, key, value);
  },
  [types.RESET_NODE_NOTES](state) {
    state.nodeNotes = {};
  }
};

const actions = {
  async fetchNotice({ commit }, { update =  false, ...payload }) {
    try {
      const key = getNoteKey(payload);

      if (state.nodeNotes[key] && !update) {
        return;
      }

      const response = await nodeApi.getNodeNotice(payload);
      const value = response?.data?.body ? response?.data : null;

      commit(types.SET_NODE_NOTES, { key, value });

      return value;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchNotice' });
    }
  },
  async updateNotice({ dispatch }, { text, metadata }) {
    try {
      const { col, row, index, ...entity } = metadata;
      const body = {
        text,
        col,
        // it's here because of the awesome custom row api architecture without row ids
        row: row ? { name: row, index } : undefined
      };
      const request = row ? tableApi.updateTableNote : nodeApi.updateNodeNotice;
      const response = await request(entity, body);

      if (!response) {
        return;
      }

      Vue.notify({
        type: 'success',
        title: i18n.t(`Web.Notification.${text ? 'SavedSuccessfully' : 'ClearedSuccessfully'}`, { 1: i18n.t('Main.Cols.Note') })
      });

      await dispatch('fetchNotice', { ...entity, update: true });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateNotice' });
    }
  },
  resetNodeNotes({ commit }) {
    commit(types.RESET_NODE_NOTES);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
