<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div
      v-if="$sl_isEditingRoute"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
        :value="combineLocationsValueText"
      />
    </div>
    <SlCheckbox
      v-model="includeZeroOnHandModel"
      :label="$t('DbImportDialog.Ui.chkCleanup')"
    />
    <SlCheckbox
      v-if="!$sl_isEditingRoute"
      v-model="combineLocationsModel"
      :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
    />
    <SlCheckbox
      v-model="zeroPriceTransactionsModel"
      :label="$t('DbImportDialog.Ui.chkZeroPriceTransactions')"
    />
    <SlCheckbox
      v-model="zeroQtyTransactionsModel"
      :label="$t('DbImportDialog.Ui.chkZeroQtyTransactions')"
    />
    <SlCheckbox
      v-if="isODBCdriver"
      v-model="legacyExportModeModel"
      :label="$t('Web.DbImport.LabelLegacyExport')"
    />
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { dataFields, tabKeys } from '@/config/connection/dbRelated.config';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { dbConnectionTypes } from '@/config/connection/database.config';

export default {
  name: 'Optional',
  components: {
    ConnectorContentBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  inject: [
    'setSettings'
  ],
  computed: {
    ...mapState({
      dbDriver: state => state.dbRelated[dataFields.CONNECTION_DATA].type,
      commonData: state => state.dbRelated.commonData,
      tabData: state => state.dbRelated[tabKeys.OPTIONAL]
    }),
    isODBCdriver() {
      return this.dbDriver !== dbConnectionTypes.MYSQL;
    },
    combineLocationsValueText() {
      return getLabelFromBoolean(this.combineLocationsModel, this);
    },
    includeZeroOnHandModel: {
      get() {
        return this.tabData.includeZeroOnHand;
      },
      set(value) {
        this.setSettings({ value, key: 'includeZeroOnHand' });
      }
    },
    combineLocationsModel: {
      get() {
        return this.tabData.combineLocations;
      },
      set(value) {
        this.setSettings({ value, key: 'combineLocations' });
      }
    },
    zeroPriceTransactionsModel: {
      get() {
        return this.tabData.zeroPriceTransactions;
      },
      set(value) {
        this.setSettings({ value, key: 'zeroPriceTransactions' });
      }
    },
    zeroQtyTransactionsModel: {
      get() {
        return this.tabData.zeroQtyTransactions;
      },
      set(value) {
        this.setSettings({ value, key: 'zeroQtyTransactions' });
      }
    },
    legacyExportModeModel: {
      get() {
        return this.tabData.legacyExportMode;
      },
      set(value) {
        this.setSettings({ value, key: 'legacyExportMode' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block {
  ::v-deep {
    .connector-content-block__main {
      row-gap: 16px;
    }
  }
}

.connector-content-row:first-child {
  margin-bottom: 8px;
}
</style>
