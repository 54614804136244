const baseStructure = {
  permanent: [],
  temporary: [],
  archived: []
};

export const projectListResponseAdapter = (data) => {
  if (!data) {
    return { ...baseStructure };
  }

  const { permanent = [], temporary = [], archived = [] } = data;

  return {
    permanent,
    temporary,
    archived,
    timestamp: Date.now()
  };
};