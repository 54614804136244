import axios from '@/plugins/axios';

export default {
  getProjectOperations() {
    return axios.get('/operations/project_data');
  },
  getOperations() {
    return axios.get('/operations', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getOperationData(params) {
    return axios.get('/operations/data', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  cancelOperation(params) {
    return axios.delete('/operations/data', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
