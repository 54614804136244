<template>
  <div class="connector-wrap connector-wrap--simple w-320">
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="host"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="host"
          :label="$t('DbInsideSourceWidget.Ui.lbHost')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="web-resource"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="webResource"
          :label="$t('Web.Pronto.LabelWebResource')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="username"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="user"
          :label="$t('DbImportDialog.Ui.lbUserName')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="password"
        rules="required"
      >
        <SlPasswordInput
          v-model="password"
          :label="$t('DbImportDialog.Ui.lbPassword')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';
import showPasswordIcon from '@/image/svg/show_password.svg';
import hidePasswordIcon from '@/image/svg/hide_password.svg';

export default {
  name: 'ProntoRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.PRONTO_XI,
      showPassword: false
    };
  },
  computed: {
    togglePasswordIcon() {
      return this.showPassword
        ? showPasswordIcon
        : hidePasswordIcon;
    },
    user: {
      get() {
        return this.connectionData.required.user;
      },
      set(value) {
        this.setRequiredValue({
          key: 'user',
          value
        });
      }
    },
    password: {
      get() {
        return this.connectionData.required.password;
      },
      set(value) {
        this.setRequiredValue({
          key: 'password',
          value
        });
      }
    },
    host: {
      get() {
        return this.connectionData.required.host;
      },
      set(value) {
        this.setRequiredValue({
          key: 'host',
          value
        });
      }
    },
    webResource: {
      get() {
        return this.connectionData.required.webResource;
      },
      set(value) {
        this.setRequiredValue({
          key: 'webResource',
          value
        });
      }
    }
  }
};
</script>
