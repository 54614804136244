import { routeNames } from '@/config/router/router.config';

export default function backups({ to, next, nextMiddleware }) {
  if (!to.params.originId) {
    return next({
      name: routeNames.PROJECT_LIST
    });
  }

  return nextMiddleware();
}