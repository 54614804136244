import { routeNames } from '@/config/router/router.config';

export default function integrations({ to, next, nextMiddleware }) {
  if (!to.params.projectId) {
    return next({
      name: routeNames.PROJECT_LIST
    });
  }

  return nextMiddleware();
}
