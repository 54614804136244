import axios from '@/plugins/axios';

export default {
  setUserLimit(params) {
    return axios.put('/subscription/max_users_limit', null, { 
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};