var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subpage"},[(_vm.showAlerts)?_c('GlobalAlerts'):_vm._e(),_c('div',{class:['subpage-wrapper', {
      'subpage-wrapper--left-col': _vm.hasLeftCol,
      'subpage-wrapper--right-col': _vm.hasRightCol,
      'subpage-wrapper--both-cols': _vm.hasBothCols,
      'subpage-wrapper--relative': _vm.relative,
      'subpage-wrapper--breadcrumbs': _vm.$scopedSlots.breadcrumbs,
      'subpage-wrapper--actions': _vm.$scopedSlots.actions,
      'subpage-wrapper--no-padding': _vm.noPadding
    }]},[_vm._t("loader"),(_vm.hasLeftCol)?_c('div',{staticClass:"subpage-wrapper__left-col"},[_vm._t("left-col")],2):_vm._e(),_c('div',{staticClass:"subpage-wrapper__main",class:("subpage-wrapper__main--" + _vm.width)},[(_vm.hasHeader)?_c('div',{staticClass:"subpage-wrapper__main-header"},[(_vm.$scopedSlots.breadcrumbs)?_c('div',{staticClass:"subpage__breadcrumbs"},[_vm._t("breadcrumbs"),(_vm.$scopedSlots.filter)?_c('div',{staticClass:"subpage__filter"},[_vm._t("filter")],2):_vm._e()],2):_vm._e(),(_vm.$scopedSlots.actions)?_c('div',{staticClass:"subpage__actions"},[_vm._t("actions")],2):_vm._e(),(_vm.$scopedSlots.filters)?_c('div',{staticClass:"subpage__filters"},[_vm._t("filters")],2):_vm._e()]):_vm._e(),_vm._t("default")],2),(_vm.hasRightCol)?_c('div',{staticClass:"subpage-wrapper__right-col"},[_vm._t("right-col")],2):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }