<template>
  <swiper-slide
    class="sl-swiper__slide"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </swiper-slide>
</template>

<script>
export default {
  name: 'SlSwiperSlide'
};
</script>