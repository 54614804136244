<template>
  <div class="sl-control-input-wrapper">
    <div
      v-if="hasLabel"
      class="sl-control-input__label"
    >
      <div class="sl-control-input__label-title body-1-md grey-80">
        {{ label }}
        <slot
          v-if="$slots.icon"
          name="icon"
        />
      </div>
      <div class="sl-control-input__label-description body-3 grey-60">
        {{ description }}
      </div>
    </div>
    <slot
      v-else
      name="label"
    />

    <div class="sl-control-input__content">
      <div class="sl-control-input__decrement">
        <SlButton
          variant="secondary"
          color="grey"
          :disabled="decrementDisabled"
          icon
          @click.stop="handleDecrement"
        >
          <icon
            data="@icons/minus.svg"
            class="size-20"
          />
        </SlButton>
      </div>
      <input
        v-model="vModel"
        :data-test-id="dataTestId"
        class="sl-control-input__input body-1 grey-90"
        :class="{
          'sl-control-input__input--invalid': isInvalid
        }"
        type="number"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        v-on="bindListeners"
        @keypress="onKeyPress"
        @blur="handleBlur"
      >
      <div class="sl-control-input__increment">
        <SlButton
          variant="secondary"
          color="grey"
          :disabled="incrementDisabled"
          icon
          @click.stop="handleIncrement"
        >
          <icon
            data="@icons/plus.svg"
            class="size-20"
          />
        </SlButton>
      </div>
    </div>
  </div>
</template>

<script>
import SlButton from '@/components/UIKit/SlButton';

export default {
  name: 'SlControlInput',
  components: {
    SlButton
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    step: {
      type: Number,
      default: 1
    },
    fixed: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    isInvalid: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      allowedDelimeters: ['.', ','],
      extendedAllowedSymbols: ['-']
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    bindListeners() {
      const { input, change, ...restListeners } = this.$listeners;

      return restListeners;
    },
    hasLabel() {
      return !this.$slots.label && (this.label || this.description);
    },
    isFloat() {
      return this.fixed > 0;
    },
    allowedSymbols() {
      return this.min < 0 ? this.allowedDelimeters.concat(this.extendedAllowedSymbols) : this.allowedDelimeters;
    },
    decrementDisabled() {
      return this.disabled || this.vModel === this.min || (+this.vModel - this.step) < this.min ;
    },
    incrementDisabled() {
      return this.disabled || this.vModel === this.max || (+this.vModel + this.step) > this.max;
    }
  },
  methods: {
    onKeyPress(e) {
      const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
      const charStr = String.fromCharCode(charCode);

      if (!this.fixed && this.allowedDelimeters.includes(charStr)) {
        e.preventDefault();

        return false;
      }

      if (this.allowedSymbols.includes(charStr)) {
        return true;
      }

      if (!/\d/.test(charStr)) {
        e.preventDefault();

        return false;
      }
    },
    handleDecrement() {
      const value = this.isFloat
        ? ((parseFloat(`${this.vModel}`) || 0) - parseFloat(`${this.step}`)).toFixed(this.fixed)
        : +this.vModel - this.step;

      this.vModel = value;
      this.$emit('change', value);
    },
    handleIncrement() {
      const value = this.isFloat
        ? ((parseFloat(`${this.vModel}`) || 0) + parseFloat(`${this.step}`)).toFixed(this.fixed)
        : +this.vModel + this.step;

      this.vModel = value;
      this.$emit('change', value);
    },
    handleBlur() {
      this.$emit('change', this.vModel);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-control-input";
</style>