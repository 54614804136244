import store from '@/store';
import { namespaceByRoute } from '@/config/report';
import { sharedReportModule } from '@/store/modules/report/shared';
import cloneObject from '@/helpers/utils/cloneObject';

export const registerReportModules = () => {
  for (const key in namespaceByRoute) {
    const namespace = namespaceByRoute[key];

    if (!store.hasModule(namespace)) {
      // vuex-persistedstate update store from ls before module registration
      const savedState = cloneObject(store.state[namespace]);

      store.registerModule(namespace, sharedReportModule);
      store.state[namespace] = Object.assign({}, store.state[namespace], savedState);
    }
  }
};

const unregisterReportModules = () => {
  for (const key in namespaceByRoute) {
    if (store.hasModule(namespaceByRoute[key])) {
      store.unregisterModule(namespaceByRoute[key]);
    }
  }
};

export const resetReportModules = () => {
  unregisterReportModules();

  setTimeout(registerReportModules);
};
