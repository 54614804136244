import { formatTableName } from '@/helpers/sql';

export default function(state, { tab, table }) {
  const data = state[tab];

  return {
    queryParams: {
      query: table
        ? `SELECT * FROM ${formatTableName(table)}`
        : data.query,
      startDate: state.commonData.startDate,
      tab: table ? '' : tab
    }
  };
}
