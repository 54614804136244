import axios from '@/plugins/axios';

export default {
  fetchDashboards() {
    return axios.get('/analytics/dashboard/list');
  },
  createDashboard(body) {
    return axios.post('/analytics/dashboard', body);
  },
  renameDashboard(params, body) {
    return axios.put('/analytics/dashboard', body, { params });
  },
  deleteDashboard(params) {
    return axios.delete('/analytics/dashboard', { params });
  },
  getDashboardUiSettings(params) {
    return axios.get('/analytics/dashboard/ui_settings', { params });
  },
  updateDashboardUiSettings(params, body) {
    return axios.put('/analytics/dashboard/ui_settings', body, { params });
  },
  createWidgetCache(params) {
    return axios.post('/analytics/dashboard/widget', null, { params });
  },
  getWidgetConfiguration(params) {
    return axios.get('/analytics/dashboard/widget/config', { params });
  },
  updateWidgetConfiguration(params, body) {
    return axios.put('/analytics/dashboard/widget/config', body, { params });
  },
  saveWidgetConfiguration(params) {
    return axios.post('/analytics/dashboard/widget/config', null, { params });
  },
  discardWidgetConfiguration(params) {
    return axios.delete('/analytics/dashboard/widget/config', { params });
  },
  getWidgetsList(params) {
    return axios.get('/analytics/dashboard/widget/list', { params });
  },
  deleteWidget(params) {
    return axios.delete('/analytics/dashboard/widget', { params });
  },
  duplicateWidget(params) {
    return axios.post('/analytics/dashboard/widget/duplicate', null, { params });
  },
  exportWidgetToXlsx(params) {
    return axios.get('/analytics/dashboard/widget/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }
};
