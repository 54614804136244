<template>
  <div
    class="sl-control"
    :class="{
      'sl-control--loading': isLoading
    }"
  >
    <div
      v-if="label || description"
      class="sl-control__label"
    >
      <div
        v-if="label"
        class="sl-control__label-title body-1-md grey-80"
      >
        {{ label }}
        <slot name="icon" />
      </div>
      <div
        v-if="description"
        class="sl-control__label-description body-3 grey-60"
      >
        {{ description }}
      </div>
    </div>
    <div class="sl-control__buttons">
      <span
        v-for="item in options"
        :key="item.value"
        v-tooltip="item.tooltip"
        class="sl-control__option"
      >
        <transition name="fade">
          <SlOverlay
            :show="item.loading"
            size="xxs"
            filled
            :text="false"
            class="sl-control__loader"
          />
        </transition>
        <label
          class="sl-control__button body-1-md grey-80"
          :class="{
            'sl-control--disabled': isDisabled(item),
            'sl-control--checked': isChecked(item)
          }"
        >
          <icon
            v-if="item.icon"
            :class="optionsIconStyleClass"
            :data="item.icon"
          />
          {{ item.label }}
          <input
            v-show="false"
            :checked="checkedValue(item)"
            :name="item.value"
            :type="type"
            :disabled="isDisabled(item)"
            class="sl-control__input"
            @change="(e) => onChange(e, item.value)"
          >
          <SlInfoButton
            v-if="item.info"
            v-tooltip="item.info"
            class="sl-control__info"
          />
        </label>
      </span>
    </div>
  </div>
</template>

<script>
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'SlControl',
  props: {
    value: {
      type: [Number, String, Array],
      default: ''
    },
    type: {
      type: String,
      default: 'radio'
    },
    options: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    optionsIconStyleClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    isLoading() {
      return this.options.some(item => item.loading);
    }
  },
  methods: {
    isDisabled(item) {
      return this.disabled || item.disabled;
    },
    isChecked(item) {
      if (this.type === 'radio') {
        return item.value === this.vModel;
      }

      return toArray(this.vModel).includes(item.value);
    },
    checkedValue(item) {
      return this.isChecked(item) ? 'checked' : '';
    },
    onChange(e, value) {
      if (this.type === 'radio') {
        this.vModel = value;

        return;
      }

      this.vModel = e.target.checked
        ? [...new Set([...toArray(this.vModel), value])]
        : toArray(this.vModel).filter(v => v !== value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-control";
</style>
