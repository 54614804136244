import en from './en';
import es from './es';
import fr from './fr';
import ja from './ja';
import pl from './pl';
import pt from './pt';
import ru from './ru';
import tr from './tr';
import uk from './uk';
import zh from './zh';
import fa from './fa';
import cs from './cs';
import de from './de';

export default {
  en,
  es,
  fr,
  ja,
  pl,
  pt,
  ru,
  tr,
  uk,
  zh,
  fa,
  cs,
  de
};
