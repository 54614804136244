import axios from '@/plugins/axios';

export default {
  getRules(params) {
    return axios.get('/substitutions', { params });
  },
  saveRules(params) {
    return axios.put('/substitutions', null, { params });
  },
  createRule(params, body) {
    return axios.post('/substitutions/temporary_records', body, { params });
  },
  deleteRules(params) {
    return axios.delete('/substitutions/temporary_records', { params });
  },
  updateOverrides(params, body) {
    return axios.put('/substitutions/overrides', body, { params });
  },
  discardOverrides() {
    return axios.delete('/substitutions/overrides');
  },
  getItemClones(params) {
    return axios.get('/substitutions/item_clones', { params });
  },
  replaceItem(body) {
    return axios.put('/substitutions/replace_item', body);
  },
  removeFromImport(params) {
    return axios.delete('/substitutions/ban_from_import', { params });
  },
  exportToXlsx(params) {
    return axios.get('/substitutions/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importFromXlsx(params, body) {
    return axios.post('/substitutions/xlsx', body, {
      params,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  }
};