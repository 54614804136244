import Vue from 'vue';
import router from '@/router';
import sharedReportApi from '@/api/reports/shared';
import filterApi from '@/api/filter/filter';
import sortingApi from '@/api/sorting';
import ReportTableAdapter from '@/adapters/response/ReportTable.adapter';
import { ordersTypesKeyMap, tableTypeKeyMap } from '@/config/report/inventoryReport';
import { namespaceByRoute } from '@/config/report';
import { uiTabByRoute } from '@/config/users/uiSettings.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { uiSettingsKeys } from '@/config/users/uiSettings.config';
import { DEFAULT_FILTER_ID, filterTypesByRouteName } from '@/config/filter';
import { PER_PAGE_MAX } from '@/config/shared/slTable.config';
import { getRouteName } from '@/helpers/shared/router';
import { getColumnKey, getResizedIndexesFromClasses } from '@/helpers/report/shared';
import { getTableConfigParams, getTableConfigStructure } from '@/helpers/shared/tableConfig';
import { projectRedirect } from '@/helpers/router';

const types = {
  SET_TABLE: 'SET_TABLE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  SET_FILTERS_LIST: 'SET_FILTERS_LIST',
  SET_ACTIVE_FILTER_ID: 'SET_ACTIVE_FILTER_ID',
  SET_COLUMNS_VISIBILITY: 'SET_COLUMNS_VISIBILITY',
  SET_COLUMN_SIZES: 'SET_COLUMN_SIZES',
  SET_IS_REFETCHED: 'SET_IS_REFETCHED',
  SET_IS_UPDATING: 'SET_IS_UPDATING'
};

export const sharedReportModule = {
  namespaced: true,
  state() {
    return {
      table: null,
      tableConfig: getTableConfigStructure({
        perPage: PER_PAGE_MAX,
        queryKeys: ['search', 'perPage', 'page']
      }),
      columns_visibility: [],
      column_sizes: [],
      filters_list: [],
      active_filter_id: DEFAULT_FILTER_ID,
      isRefetched: false,
      is_updating: false
    };
  },
  mutations: {
    [types.SET_TABLE](state, value) {
      state.table = value;
    },
    [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
      Vue.set(state.tableConfig, key, value);
    },
    [types.SET_TABLE_CONFIG](state, payload) {
      Object.assign(state.tableConfig, payload);
    },
    [types.SET_FILTERS_LIST](state, value) {
      state.filters_list = value;
    },
    [types.SET_ACTIVE_FILTER_ID](state, value) {
      state.active_filter_id = value;
    },
    [types.SET_COLUMNS_VISIBILITY](state, value) {
      state.columns_visibility = value;
    },
    [types.SET_COLUMN_SIZES](state, value) {
      state.column_sizes = value;
    },
    [types.SET_IS_REFETCHED](state, value) {
      state.isRefetched = value;
    },
    [types.SET_IS_UPDATING](state, value) {
      state.is_updating = value;
    }
  },
  actions: {
    setIsUpdating({ commit }, value) {
      commit(types.SET_IS_UPDATING, value);
    },
    updateTableConfig({ commit }, payload) {
      commit(types.UPDATE_TABLE_CONFIG, payload);
    },
    async fetchPage({ state, commit, dispatch, getters }) {
      try {
        dispatch('fetchSortingParams');

        const params = {
          type: getters.reportType(),
          filterId: getters.activeFilterId,
          ...getTableConfigParams(state.tableConfig)
        };

        const response = await sharedReportApi.getReport(params);
        const data = response?.data;

        if (!data) {
          return;
        }

        commit(types.SET_TABLE, ReportTableAdapter(data));
      } catch (e) {
        if (e?.code === slErrorCodes.INSUFFICIENT_ACCOUNT_PERMISSIONS) {
          if (!state.isRefetched) {
            commit(types.SET_IS_REFETCHED, true);

            await dispatch('fetchPage');
            commit(types.SET_IS_REFETCHED, false);

            return;
          }
        }

        this.dispatch('user/logout', { e, from: 'fetchPage' });
      }
    },
    async fetchFiltersList({ commit }) {
      try {
        const response = await filterApi.getFiltersList({
          type: filterTypesByRouteName[getRouteName()]
        });

        if (!response?.data) {
          return;
        }

        commit(types.SET_FILTERS_LIST, response.data.tabs);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchFiltersList' });
      }
    },
    async updateFiltersOrder({ commit }, list) {
      commit(types.SET_FILTERS_LIST, list);

      try {
        const tab = uiTabByRoute[getRouteName()];

        if (!tab) {
          return;
        }

        await sharedReportApi.postFiltersOrder(
          { order: list.map(i => i.id) },
          { tab }
        );
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'setFilterByKey' });
      }
    },
    async duplicateFilter({ getters, dispatch }, filterId = null) {
      try {
        const response = await sharedReportApi.duplicateFilter({
          filterId: filterId ?? getters.activeFilterId,
          type: filterTypesByRouteName[getRouteName()]
        });
        const id = response.data.payload.id;

        dispatch('setActiveFilterId', id);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'duplicateFilter' });
      }
    },
    async deleteFilter({ state, dispatch }, filterId) {
      await sharedReportApi.deleteFilter({
        filterId,
        type: filterTypesByRouteName[getRouteName()]
      });

      if (state.active_filter_id === filterId) {
        dispatch('setActiveFilterId', DEFAULT_FILTER_ID);
      }
    },
    async filterBy(_, { filterBy, ...body }) {
      try {
        const response = await sharedReportApi.filterBy(
          {
            type: filterTypesByRouteName[getRouteName()],
            filterBy
          },
          body
        );

        if (!response?.data) {
          return;
        }

        this.dispatch('filter/setFilter', response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'filterBy' });
      }
    },
    async addToFilter({ getters }, { filterBy, ...body }) {
      try {
        const response = await sharedReportApi.addToFilter(
          {
            filterId: getters.activeFilterId,
            type: filterTypesByRouteName[getRouteName()],
            filterBy
          },
          body
        );

        if (!response?.data) {
          return;
        }

        this.dispatch('filter/setFilter', response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'addToFilter' });
      }
    },
    setActiveFilterId({ commit, dispatch }, id) {
      commit(types.SET_ACTIVE_FILTER_ID, id);
      commit(types.UPDATE_TABLE_CONFIG, { key: 'page', value: 1 });

      // todo add conditional refetch
      return dispatch('fetchPage');
    },
    async fetchSortingParams({ commit, getters }) {
      try {
        const response = await sortingApi.getSortingParams({
          type: getters.reportType(),
          filterId: getters.activeFilterId,
          table: getters.reportTable()
        });
        const data = response?.data;

        if (!data) {
          return;
        }

        const { sortOrder, columnKey } = data;

        commit(types.UPDATE_TABLE_CONFIG, {
          key: 'sortOrder',
          value: sortOrder
        });

        commit(types.UPDATE_TABLE_CONFIG, {
          key: 'colClass',
          value: columnKey
        });
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchSortingParams' });
      }
    },
    updateSortingParams({ getters }, params) {
      return sortingApi.setSortingParams({
        ...params,
        filterId: getters.activeFilterId
      });
    },
    async fetchColumnsVisibility({ commit, getters }) {
      try {
        const response = await sharedReportApi.getColumnsVisibility({
          type: getters.reportType()
        });

        commit(types.SET_COLUMNS_VISIBILITY, response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchColumnsVisibility' });
      }
    },
    async updateColumnVisibility({ getters }, payload) {
      try {
        await sharedReportApi.postColumnVisibility({
          ...payload,
          type: getters.reportType()
        });
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'updateColumnVisibility' });
      }
    },
    async toggleColumnPin({ getters }, payload) {
      try {
        await sharedReportApi.toggleColumnPin({
          ...payload,
          type: getters.reportType()
        });
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'updateColumnVisibility' });
      }
    },
    updateColumnSizes({ getters }, { columnKey, width }) {
      this.dispatch('user/changeUiSettings', {
        key: uiSettingsKeys.COLUMNS_WIDTH,
        value: {
          clazz: getColumnKey(getters.headerByColumnKeyMap, columnKey),
          width
        }
      });
    },
    setColumnSizes({ commit }, data) {
      commit(types.SET_COLUMN_SIZES, getResizedIndexesFromClasses(data));
    },
    exportTableToDatabase({ getters }) {
      const params = {
        filterId: getters.activeFilterId,
        type: getters.reportType()
      };

      return sharedReportApi.exportTableToDatabase(params);
    },
    openTableLink(_, { search, route }) {
      const namespace = namespaceByRoute[route];

      this.dispatch(`${namespace}/setActiveFilterId`, DEFAULT_FILTER_ID);
      this.dispatch(`${namespace}/updateTableConfig`, { key: 'search', value: search });

      projectRedirect({
        name: route,
        query: {
          ...router.currentRoute.query,
          search
        }
      });
    }
  },
  getters: {
    reportType: () => () => ordersTypesKeyMap[getRouteName()],
    reportTable: () => () => tableTypeKeyMap[getRouteName()],
    filterByIdMap: (state) => {
      return state.filters_list.reduce((acc, item) => {
        acc[item.id] = item;

        return acc;
      }, {});
    },
    activeFilter: (state, getters) => getters.filterByIdMap[state.active_filter_id],
    headerByColumnKeyMap: (state) => {
      const tableHeaders = [
        ...state.table.header.pinned.map((header) => ({ ...header, pinned: true })),
        ...state.table.header.default
      ];

      return tableHeaders.reduce((acc, header) => {
        acc[header.columnKey] = header;

        return acc;
      }, {});
    },
    columnByColumnKeyMap: (state) => {
      const tableColumns = [
        ...state.table.body.pinned.map((column) => ({ ...column, pinned: true })),
        ...state.table.body.default
      ];

      return tableColumns.reduce((acc, column) => {
        acc[column.columnKey] = column;

        return acc;
      }, {});
    },
    activeFilterId: (state) => typeof state.active_filter_id === 'number'
      ? state.active_filter_id
      : DEFAULT_FILTER_ID
  }
};