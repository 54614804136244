import { routeNames } from '@/config/router/router.config';
import { bomTypes } from '@/config/item/bom.config';
import { projectRedirect } from '@/helpers/router';

export default function billOfMaterials({ to, store, nextMiddleware }) {
  const type = to.params.type;
  const { item, loc } = store.state.bom.selected_entry || {};
  const hasEntry = type === bomTypes.ENTIRE || (item ?? loc);

  if (!hasEntry) {
    return projectRedirect({
      name: routeNames.DEMAND
    });
  }

  return nextMiddleware();
}
