var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"slModalWrapper",staticClass:"sl-modal__wrapper",class:( _obj = {
    'sl-modal__wrapper--active': _vm.modalValue,
    'sl-modal__wrapper--fullscreen': _vm.fullscreen || _vm.maximized,
    'sl-modal__wrapper--nested': _vm.isNested
  }, _obj[("sl-modal__wrapper--" + _vm.position)] = true, _obj ),attrs:{"id":_vm.id,"tabindex":"0"}},[(_vm.modalValue)?_vm._t("modals"):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.modalValue)?_c('div',{staticClass:"sl-modal__overlay"}):_vm._e()]),_c('transition',{attrs:{"name":"fade-down"}},[(_vm.modalValue)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.vcoConfig),expression:"vcoConfig"}],staticClass:"sl-modal",class:{
        'sl-modal--no-header': _vm.noHeader,
        'sl-modal--updating': _vm.updating
      },style:(_vm.modalStyles)},[(_vm.$scopedSlots.loader)?_vm._t("loader"):_c('SlOverlay',{attrs:{"show":_vm.loading}}),(!_vm.noHeader)?_c('div',{staticClass:"sl-modal__header"},[_c('div',{staticClass:"sl-modal__header-title heading-4-sb grey-90"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.updating)?_c('SlLoader',{attrs:{"size":"xxs","text":false}}):_vm._e()],1)],1),(_vm.subtitle)?_c('div',{staticClass:"sl-modal__header-subtitle body-1 grey-70"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"sl-modal__header-actions"},[(_vm.maximizable)?_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getMaximizeTooltip),expression:"getMaximizeTooltip",modifiers:{"bottom":true}}],attrs:{"variant":"tertiary","color":"grey","size":"xs","icon":""},on:{"click":_vm.handleMaximize}},[_c('icon',{staticClass:"fill-off size-20",attrs:{"data":_vm.getMaximizeIcon}})],1):_vm._e(),_c('SlButton',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getTooltip(_vm.$t('Web.Modals.DiscardAndClose'))),expression:"getTooltip($t('Web.Modals.DiscardAndClose'))",modifiers:{"bottom":true}}],attrs:{"variant":"tertiary","color":"grey","size":"xs","icon":""},on:{"click":_vm.handleClose}},[_c('icon',{staticClass:"fill-off size-24",attrs:{"data":require("@icons/close.svg")}})],1)],1)]):_vm._e(),(_vm.$scopedSlots.alert)?_c('div',{staticClass:"sl-modal__alert"},[_vm._t("alert")],2):_vm._e(),_c('div',{ref:"scrollContainer",staticClass:"sl-modal__scroll-container",class:{
          'sl-modal__scroll-container--border-top': _vm.hasVerticalScrollbar && !_vm.scrolledToTop,
          'sl-modal__scroll-container--border-bottom': _vm.hasVerticalScrollbar && !_vm.scrolledToBottom
        },on:{"&scroll":function($event){return _vm.updateScroll($event)}}},[_vm._t("default")],2),_vm._t("footer")],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }