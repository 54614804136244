import { editRoutes } from '@/config/connection';

export const routeMixin = {
  computed: {
    $sl_routeName() {
      return this.$route.name;
    },
    $sl_routeMeta() {
      return this.$route.meta;
    },
    $sl_isEditingRoute() {
      return editRoutes.includes(this.$sl_routeName);
    }
  }
};
