export default function(data) {
  if (!data) {
    return null;
  }

  return {
    credentials: {
      database: data.database,
      odooUrl: data.odooUrl || '',
      odooUsername: data.odooUsername || '',
      odooPassword: data.odooPassword || ''
    }
  };
}