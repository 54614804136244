export default function(data) {
  if (!data) {
    return;
  }

  const { class: row, name, index, column, value } = data;
  const isCustomRow = index !== undefined;
  const customRowName = index !== 0 ? name : '';

  return {
    column,
    ...(isCustomRow && { index }),
    row: isCustomRow ? customRowName : row,
    val: isCustomRow ? value ?? '' : value
  };
}