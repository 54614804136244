import { fetchUpdatedData } from '@/helpers/lastChange/fetchUpdatedData.js';
import { mountedRequestsByRouteName } from '@/config/api/requestsKeys.config';
import logger from '@/api/logger';

export const sendRequests = async(routeName, payload = {}) => {
  try {
    await fetchUpdatedData(mountedRequestsByRouteName[routeName], payload);
  } catch (e) {
    logger.formatAndWriteError({
      e,
      from: 'sendRequests'
    });

    throw e;
  }
};
