import { dataFields, tabKeys } from '@/config/connection/dbRelated.config';
import { TEMPLATE_PASSWORD } from '@/config/integrations';

export default function(innerSettings, isDefault, connection = {}) {
  const { exportSets, importSets, settings } = innerSettings;
  const  credentials = settings?.connection?.credentials;

  const connectionData = {
    database: importSets.database,
    dbServer: importSets.dbServer,
    username: credentials?.user || '',
    password: '',
    ...connection
  };

  const isSet = exportSets?.password?._isSet === 'true';

  return {
    ...(!isDefault && { [dataFields.CONNECTION_DATA]: connectionData }),
    [tabKeys.EXPORT]: {
      exportOrdersUrl: exportSets.exportOrdersUrl,
      username: exportSets.user,
      password: {
        isSet,
        value: isSet ? TEMPLATE_PASSWORD : ''
      }
    },
    [tabKeys.OPTIONAL]: {
      includeZeroOnHand: settings.info._cleanUnusedEntries === '1',
      zeroQtyTransactions: settings.info._zeroQtyTransactions === '1',
      zeroPriceTransactions: settings.info._zeroPriceTransactions === '1',
      combineLocations: settings.info._combineLocs === '1'
    }
  };
}
