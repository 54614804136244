<template>
  <div class="sl-node-path__wrapper">
    <div
      v-for="(pathItem, pathIndex) in visiblePath"
      :key="`${pathItem}_${pathIndex}`"
      class="sl-node-path"
    >
      <div
        v-tooltip="getPathItemTooltip(pathIndex)"
        class="sl-node-path__text"
        :class="{
          'sl-node-path__text--highlighted': isTargetHighlighted(pathIndex)
        }"
      >
        {{ getPathItemLabel(pathIndex) }}
      </div>
      <icon
        v-if="pathIndex < visiblePath.length - 1"
        data="@icons/chevron_right.svg"
        class="size-12 fill-off color-grey-60"
      />
    </div>
  </div>
</template>

<script>
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlNodePath',
  props: {
    path: {
      type: Array,
      default: () => []
    },
    highlightTarget: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lastVisiblePathsCount: 3,
      minTooltipLength: 40
    };
  },
  computed: {
    visiblePath() {
      // last shift increases the number of visible paths to 4
      // external one needed to show the "..." collapsed paths
      return this.path.filter((_, pathIndex) => !this.isHiddenPathItem(pathIndex, this.path, 2));
    }
  },
  methods: {
    isHiddenPathItem(index, array, lastShift = 1) {
      // hidden more than first and less than last 3 items
      return index > 0 && index <= array.length - this.lastVisiblePathsCount - lastShift;
    },
    isTargetHighlighted(index) {
      if (!this.highlightTarget) {
        return false;
      }

      return index === this.visiblePath.length - 1;
    },
    getPathItemTooltip(index) {
      if (this.isHiddenPathItem(index, this.visiblePath)) {
        const hiddenItems = this.path
          .filter((_, pathIndex) => this.isHiddenPathItem(pathIndex, this.path))
          .join(' > ');

        return getTooltip(hiddenItems);
      }

      const text = this.path[index];

      return getTooltip({
        content: text,
        disabled: text.length < this.minTooltipLength
      });
    },
    getPathItemLabel(index) {
      return this.isHiddenPathItem(index, this.visiblePath)
        ? '...'
        : this.visiblePath[index];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-node-path";
</style>