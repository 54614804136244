import axios from '@/plugins/axios';

export default {
  getDatabaseTables(params) {
    return axios.post('/integrations/database_related/list', null, { params });
  },
  getExportStubs(params) {
    return axios.get('/integrations/database_related/export/stub', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  exportToCsv(params, body) {
    return axios.post('/integrations/database_related/export', body, { params });
  },
  downloadCsv(params) {
    return axios.get('/integrations/database_related/download', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream',
        'X-pid-independent': true
      }
    });
  },
  testExport(params, body) {
    return axios.post('/integrations/database_related/export/test', body, { params });
  }
};