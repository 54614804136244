import CommonSettingsAdapter from '@/adapters/response/connection/dbRelated/shared/CommonSettings.adapter';
import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(sets, isDefault) {
  const connection = sets?.innerSettings?.settings?.connection;
  const exportSets = sets?.innerSettings?.exportSets;

  const connectionData = {
    dbServer: connection?.host || '',
    port: connection?.port || '',
    odooUrl: exportSets?.exportOrdersUrl || '',
    odooUsername: exportSets?.user || '',
    odooPassword: ''
  };
  const commonSettings = CommonSettingsAdapter(sets?.innerSettings, isDefault, connectionData);

  return {
    ...commonSettings,
    transactionalFile: FileEditAdapter(sets?.advanceSettings?.transactionsFile)
  };
}