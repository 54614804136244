import axios from '@/plugins/axios';

export default {
  getPromotions(params) {
    return axios.get('/promotions', { params });
  },
  exportToXlsx(params) {
    return axios.get('/promotions/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importFromXlsx(body) {
    return axios.post('/promotions/xlsx', body, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  }
};