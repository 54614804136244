import initializationApi from '@/api/initialization';

const types = {
  SET_INITIALIZING: 'SET_INITIALIZING',
  SET_PROJECT_STATUS: 'SET_PROJECT_STATUS',
  SET_TENANT_USERS: 'SET_TENANT_USERS'
};

const state = () => ({
  initializing: false,
  hasProject: false,
  tenantUsers: null
});

const getters = {
  tenantUsers: (state) => state.tenantUsers.filter(user => user.id)
};

const mutations = {
  [types.SET_INITIALIZING](state, value) {
    state.initializing = value;
  },
  [types.SET_PROJECT_STATUS](state, value) {
    state.hasProject = value;
  },
  [types.SET_TENANT_USERS](state, value) {
    state.tenantUsers = value;
  }
};

const actions = {
  setInitializing({ commit }, value) {
    commit(types.SET_INITIALIZING, value);
  },
  async getProjectStatus({ commit }) {
    const response = await initializationApi.getProjectStatus();
    const data = response?.data;

    if (!data) {
      throw new Error('Server is down');
    }

    commit(types.SET_PROJECT_STATUS, data?.hasAvailableProject);

    return response;
  },
  async getTenantUsers({ commit }) {
    try {
      const response = await initializationApi.getTenantUsers();
      const data = response?.data;

      if (data) {
        commit(types.SET_TENANT_USERS, data?.users);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getTenantUsers' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
