import axios from '@/plugins/axios';

export default {
  getNodeTable(params) {
    return axios.get('demand/table', { params });
  },
  updateTableOverride(params, body) {
    return axios.post('demand/table/override', body, { params });
  },
  updateCustomRowOverride(params, body) {
    return axios.post('demand/table/override/custom_row', body, { params });
  },
  applyToFinalForecast(params, body) {
    return axios.post('demand/table/override/apply', body, { params });
  },
  copyFromStatisticalForecast(params, body) {
    return axios.post('demand/table/override/copy_from_statistical', body, { params });
  },
  updateTableNote(params, body) {
    return axios.post('demand/table/note', body, { params });
  },
  getRowsVisibility() {
    return axios.get('demand/table/visibility');
  },
  updateRowVisibility(params) {
    return axios.post('demand/table/visibility', null, { params });
  },
  getCustomRows(params) {
    return axios.get('demand/table/custom_rows', { params });
  },
  updateCustomRows(body) {
    return axios.post('demand/table/custom_rows', body);
  }
};
