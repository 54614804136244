var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connector-wrap connector-wrap--simple"},[_c('div',{staticClass:"connector-content-block__row"},[_c('SlControl',{attrs:{"options":_vm.authTypeOptions,"label":_vm.$t('Web.BaseConnectors.Form.authType')},model:{value:(_vm.authType),callback:function ($$v) {_vm.authType=$$v},expression:"authType"}})],1),_c('div',{staticClass:"connector-content-block__row w-320"},[_c('SlValidate',{attrs:{"vid":"shop-name","mode":"eager","rules":{
        required: true,
        regex: { regex: _vm.regExp.shopifyShopName }
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.shopName'),"is-invalid":invalid},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})]}}])})],1),(_vm.isCustomApp)?_c('div',{staticClass:"connector-content-block__row w-512"},[_c('SlValidate',{attrs:{"vid":"api-key","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.APIkey'),"is-invalid":invalid},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=$$v},expression:"apiKey"}})]}}],null,false,3633203754)})],1):_vm._e(),(_vm.isCustomApp)?_c('div',{staticClass:"connector-content-block__row w-512"},[_c('SlValidate',{attrs:{"vid":"api-access-token","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.APIaccessToken'),"is-invalid":invalid},model:{value:(_vm.apiAccessToken),callback:function ($$v) {_vm.apiAccessToken=$$v},expression:"apiAccessToken"}})]}}],null,false,689687157)})],1):_vm._e(),(!_vm.noPeriods)?_c('Periods',{attrs:{"type":_vm.type,"is-new":_vm.isNew}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }