export const checkNegativeNum = (num) => {
  return num > 0 ? num : 0;
};

export const stringToNumber = (val) => {
  if (typeof val === 'string'){
    return val ? +val : null;
  }

  return val;
};

export const stringToBoolean = (val) => {
  return !!(+val);
};

export const numberToBinary = (val) => {
  if (!val && typeof val !== 'number') {
    return null;
  }

  return (+val).toString(2);
};