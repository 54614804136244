<template>
  <div class="subpage">
    <GlobalAlerts v-if="showAlerts" />

    <div
      :class="['subpage-wrapper', {
        'subpage-wrapper--left-col': hasLeftCol,
        'subpage-wrapper--right-col': hasRightCol,
        'subpage-wrapper--both-cols': hasBothCols,
        'subpage-wrapper--relative': relative,
        'subpage-wrapper--breadcrumbs': $scopedSlots.breadcrumbs,
        'subpage-wrapper--actions': $scopedSlots.actions,
        'subpage-wrapper--no-padding': noPadding
      }]"
    >
      <slot name="loader" />

      <div
        v-if="hasLeftCol"
        class="subpage-wrapper__left-col"
      >
        <slot name="left-col" />
      </div>
      <div
        class="subpage-wrapper__main"
        :class="`subpage-wrapper__main--${width}`"
      >
        <div
          v-if="hasHeader"
          class="subpage-wrapper__main-header"
        >
          <div
            v-if="$scopedSlots.breadcrumbs"
            class="subpage__breadcrumbs"
          >
            <slot name="breadcrumbs" />
            <div
              v-if="$scopedSlots.filter"
              class="subpage__filter"
            >
              <slot name="filter" />
            </div>
          </div>
          <div
            v-if="$scopedSlots.actions"
            class="subpage__actions"
          >
            <slot name="actions" />
          </div>
          <div
            v-if="$scopedSlots.filters"
            class="subpage__filters"
          >
            <slot name="filters" />
          </div>
        </div>

        <slot />
      </div>
      <div
        v-if="hasRightCol"
        class="subpage-wrapper__right-col"
      >
        <slot name="right-col" />
      </div>
    </div>
  </div>
</template>

<script>
import GlobalAlerts from '@/components/Shared/Page/GlobalAlerts.vue';

export default {
  name: 'SubPageWrapper',
  components: {
    GlobalAlerts
  },
  props: {
    width: {
      type: String,
      default: 'xl',
      validator: (s) => ['xl', '2xl', '3xl', 'full'].includes(s)
    },
    showAlerts: {
      type: Boolean,
      default: true
    },
    emptyCols: Boolean,
    relative: Boolean,
    noPadding: Boolean
  },
  computed: {
    hasLeftCol() {
      return this.$scopedSlots['left-col'] || this.emptyCols;
    },
    hasRightCol() {
      return this.$scopedSlots['right-col'] || this.emptyCols;
    },
    hasBothCols() {
      return this.hasLeftCol && this.hasRightCol;
    },
    hasHeader() {
      return this.$scopedSlots.breadcrumbs || this.$scopedSlots.actions || this.$scopedSlots.filters;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/shared/page/sub-page-wrapper.scss';
</style>
