import connectionApi from '@/api/connections';
import { connectionTypes } from '@/config/connection';

const types = {
  SET_LOCATIONS_CHANNELS: 'SET_LOCATIONS_CHANNELS',
  RESET_STATE: 'RESET_STATE'
};
const initialState = () => ({
  locations: null,
  channels: null
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_LOCATIONS_CHANNELS](state, { locations, channels }) {
    state.locations = locations;
    state.channels = channels;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setLocationsChannels({ commit }, { isNew, locations, channels }) {
    commit(types.SET_LOCATIONS_CHANNELS, {
      locations,
      channels
    });

    if (!isNew) {
      return;
    }

    this.dispatch('connection/setConnectorValueByKey', {
      type: connectionTypes.SKUBANA,
      field: 'optional',
      key: 'locations',
      value: locations,
      edit: false
    });

    this.dispatch('connection/setConnectorValueByKey', {
      type: connectionTypes.SKUBANA,
      field: 'optional',
      key: 'channels',
      value: channels,
      edit: false
    });
  },
  fetchLocationsChannels() {
    return connectionApi.checkConnect({ connector: 'skubana' });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
