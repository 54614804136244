export const databaseConnectionTypes = {
  ODBC: 'odbc',
  ODBC32: 'odbc32',
  BIG_QUERY: 'bqodbc',
  MYSQL: 'mysql'
};

export const connectionTypeOptions = [
  {
    label: 'ODBC',
    value: databaseConnectionTypes.ODBC
  },
  {
    label: 'ODBC 32',
    value: databaseConnectionTypes.ODBC32
  },
  {
    label: 'BigQuery',
    value: databaseConnectionTypes.BIG_QUERY
  },
  {
    label: 'MySQL',
    value: databaseConnectionTypes.MYSQL
  }
];
