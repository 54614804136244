import CommonCredentialsAdapter from '@/adapters/request/connection/dbRelated/shared/CommonCredentials.adapter';

export default function(data) {
  if (!data) {
    return null;
  }

  return {
    ...CommonCredentialsAdapter(data),
    port: data.port
  };
}