var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',_vm._b({ref:"provider",staticClass:"sl-validation-provider",attrs:{"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var dirty = ref.dirty;
return [_vm._t("default",null,null,{
      invalid: !!(errors && errors.length),
      dirty: dirty,
      validate: _vm.$refs.provider && _vm.$refs.provider.validate,
      sync: _vm.$refs.provider && _vm.$refs.provider.syncValue,
      reset: _vm.$refs.provider && _vm.$refs.provider.reset
    }),(_vm.message)?_c('transition',{attrs:{"name":"fade"}},[(errors && errors.length)?_c('div',{staticClass:"error-wrapper body-1 accent-red-90"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]):_vm._e()]}}],null,true)},'ValidationProvider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }