import axios from '@/plugins/axios';

export default {
  getEnvironments(params) {
    return axios.get('/integrations/bc365/environments', {
      params
    });
  },
  getCompanies(params) {
    return axios.get('/integrations/bc365/companies', {
      params
    });
  }
};
