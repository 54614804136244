import { toArray } from '@/helpers/utils/toArray';

export default function(data) {
  if (!data) {
    return {
      headers: [],
      rows: [],
      matchedSlots: {},
      slotsIndexes: {}
    };
  }

  const slotsIndexes = {};
  const matchedSlots = {};
  const headers = toArray(data.header).reduce((acc, header, headerIndex) => {
    const headerColMeaning = header.metadata?.colMeaning;

    if (headerColMeaning) {
      matchedSlots[headerIndex] = headerColMeaning;
      slotsIndexes[headerColMeaning] = [headerIndex];
    }

    acc.push({
      key: header.key,
      text: header.name,
      mappingSupported: header.metadata?.mappingSupported
    });

    return acc;
  }, []);

  return {
    headers,
    rows: data.body,
    matchedSlots,
    slotsIndexes
  };
}
