import Vue from 'vue';
import { VueSvgIcon, setOptions } from '@yzfe/vue-svgicon';
import '@yzfe/svgicon/lib/svgicon.css';

setOptions({
  classPrefix: 'sl-svg',
  isStroke: false,
  isOriginalDefault: true,
  defaultHeight: '12px',
  defaultWidth: '12px'
});

Vue.component('icon', VueSvgIcon);