import axios from '@/plugins/axios';

export default {
  getMarketplaces(params) {
    return axios.get('/integrations/amazon/marketplaces', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
