<template>
  <button
    v-bind="$attrs"
    :type="type"
    class="sl-button"
    :class="{
      ...variantClasses,
      [sizeClass]: true,
      'sl-button--icon': icon,
      'sl-button--full-width': fullWidth
    }"
    :style="{ 'min-width': minWidth }"
    :tabindex="tabindex"
    v-on="$listeners"
  >
    <div
      v-if="$scopedSlots.prepend"
      class="prepend-element"
    >
      <slot name="prepend" />
    </div>

    <div
      v-if="$scopedSlots.loader"
      class="sl-button__loader"
    >
      <slot name="loader" />
    </div>

    <transition name="fade">
      <SlOverlay
        :show="loading"
        size="xxs"
        filled
        :text="false"
        class="sl-button__loader"
      />
    </transition>
    <slot />

    <div
      v-if="$scopedSlots.append"
      class="append-element"
    >
      <slot name="append" />
    </div>
  </button>
</template>

<script>
export default {
  name: 'SlButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'secondary', 'tertiary', 'text'].includes(v)
    },
    color: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'accent-red', 'grey'].includes(v)
    },
    size: {
      type: String,
      default: 'lg',
      validator: (v) => ['lg', 'md', 'sm', 'xs', '2xs'].includes(v)
    },
    minWidth: {
      type: String,
      default: 'unset'
    },
    loading: Boolean,
    disabled: Boolean,
    icon: Boolean,
    fullWidth: Boolean,
    active: Boolean
  },
  computed: {
    variantClasses() {
      return {
        [`sl-button__${this.variant}__${this.color}`]: true,
        [`sl-button__${this.variant}__${this.color}--disabled`]: this.localDisabled,
        [`sl-button__${this.variant}--active`]: this.active
      };
    },
    sizeClass() {
      return `sl-button__${this.size}`;
    },
    localDisabled() {
      return this.disabled || this.loading;
    },
    tabindex() {
      return this.localDisabled ? -1 : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-button/index';
</style>
