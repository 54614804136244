import { slErrorCodes } from '@/config/api/slErrorCodes.config';

export const noAccessReasons = {
  NO_ACCESS: 'no-access',
  LICENSE_EXPIRED: 'license-expired',
  LICENSE_INCOMPLETE: 'license-incomplete',
  CANT_SETUP_SERVER: 'no-permissions-to-setup-server',
  ERROR_ON_SETUP: 'error-during-setup',
  WRONG_TENANT: 'error-wrong-company',
  AUTH_FAILED: 'error-auth-failed'
};

export const noAccessReasonByErrorCode = {
  [slErrorCodes.INVALID_METADATA]: noAccessReasons.LICENSE_INCOMPLETE,
  [slErrorCodes.NO_PROJECT_PERMISSION]: noAccessReasons.CANT_SETUP_SERVER,
  [slErrorCodes.NO_TOKEN_PERMISSION]: noAccessReasons.CANT_SETUP_SERVER,
  [slErrorCodes.FE_SET_TOKEN]: noAccessReasons.ERROR_ON_SETUP,
  [slErrorCodes.AUTH_FAILED]: noAccessReasons.AUTH_FAILED,
  [slErrorCodes.DIFFERENT_TENANT]: noAccessReasons.WRONG_TENANT,
  [slErrorCodes.NOT_ALLOWED_BY_LICENSE]: noAccessReasons.LICENSE_EXPIRED,
  [slErrorCodes.NO_PROJECT]: noAccessReasons.NO_ACCESS
};
