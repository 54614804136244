<template>
  <SlInput
    v-model="vModel"
    :type="showPassword ? 'text' : 'password'"
    :required="required"
    v-bind="$attrs"
    @focus="handleFocus"
    @blur="handleBlur"
  >
    <template
      #append
      v-if="isIconVisible"
    >
      <icon
        :data="passwordIcon"
        class="fill-off size-20"
        @click="showPassword = !showPassword"
      />
    </template>
  </SlInput>
</template>

<script>

import { TEMPLATE_PASSWORD } from '@/config/integrations';

export default {
  name: 'SlPasswordInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    isSet: Boolean,
    required: Boolean
  },
  data() {
    return {
      showPassword: false,
      showIcon: false,
      tempValue: TEMPLATE_PASSWORD
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    passwordIcon() {
      return require(`@icons/${this.showPassword ? 'eye' : 'eye_slash'}.svg`);
    },
    isIconVisible() {
      return this.showIcon || !this.isSet;
    }
  },
  mounted() {
    if (this.isSet) {
      this.vModel = this.tempValue;
    }
  },
  methods: {
    handleFocus() {
      if (!this.isSet) {
        return;
      }

      if (this.vModel === this.tempValue) {
        this.showIcon = true;
        this.vModel = '';
      }
    },
    handleBlur() {
      if (!this.isSet) {
        return;
      }

      if (!this.vModel) {
        this.showIcon = false;
        this.vModel = this.tempValue;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.sl-input-wrapper::v-deep .append-element {
  cursor: pointer;
}
</style>
