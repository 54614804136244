<template>
  <div class="connector-wrap connector-wrap--simple">
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';

export default {
  name: 'SkubanaRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.SKUBANA
    };
  }
};
</script>
