export const hasDuplicateNames = (files) => {
  const names = new Set();

  for (const file of files) {
    if (names.has(file.name)) {
      return true;
    }

    names.add(file.name);
  }

  return false;
};