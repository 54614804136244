<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row start w-240">
      <SlValidate
        v-slot="{ invalid }"
        vid="nMonthsToDropToReceive"
        mode="eager"
        rules="required|between:0,120"
      >
        <SlInput
          v-model="nMonthsToDropToReceive"
          :label="$t('Web.BaseConnectors.Form.nMonthsToDropToReceive')"
          :description="$t('Web.BaseConnectors.Form.nMonthsToDropToReceiveSubtitle')"
          :is-invalid="invalid"
          step="1"
          type="number"
        />
      </SlValidate>
    </div>
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
      <SlInfoItem
        :label="$t('Web.CIN7.CustomerGroupIsChannel')"
        :value="customerGroupIsChannelLabel"
        class="sl-checkbox"
      />
    </div>
    <div
      v-else
      class="connector-wrap"
    >
      <SlCheckbox
        v-model="combineLocs"
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        class="sl-checkbox"
      />
      <SlCheckbox
        v-model="customerGroupIsChannel"
        :label="$t('Web.CIN7.CustomerGroupIsChannel')"
        class="sl-checkbox"
      />
    </div>
    <SlCheckbox
      v-model="wantSubcats"
      :label="$t('Web.CIN7.WantSubcats')"
      class="sl-checkbox"
    />
    <SlCheckbox
      v-model="areItemsWoQtyDispatchShipped"
      :label="$t('Web.CIN7.AreItemsWoQtyDispatchShipped')"
      class="sl-checkbox"
    />
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { fileFormats } from '@/config/utils/fileUpload.config';

export default {
  name: 'Cin7OptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.CIN7,
      fileFormats
    };
  },
  inject: [
    'historicalTransactionsTemplate'
  ],
  computed: {
    nMonthsToDropToReceive: {
      get() {
        return +this.connectionData.optional.nMonthsToDropToReceive || 0;
      },
      set(value) {
        this.setOptionalValue({
          key: 'nMonthsToDropToReceive',
          value
        });
      }
    },
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    wantSubcats: {
      get() {
        return Boolean(+this.connectionData.optional.wantSubcats);
      },
      set(value) {
        this.setOptionalValue({
          key: 'wantSubcats',
          value
        });
      }
    },
    customerGroupIsChannel: {
      get() {
        return Boolean(+this.connectionData.optional.customerGroupIsChannel);
      },
      set(value) {
        this.setOptionalValue({
          key: 'customerGroupIsChannel',
          value
        });
      }
    },
    areItemsWoQtyDispatchShipped: {
      get() {
        return Boolean(+this.connectionData.optional.areItemsWoQtyDispatchShipped);
      },
      set(value) {
        this.setOptionalValue({
          key: 'areItemsWoQtyDispatchShipped',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    },
    customerGroupIsChannelLabel() {
      return getLabelFromBoolean(this.customerGroupIsChannel, this);
    }
  },
  beforeMount() {
    if (this.isNew) {
      const isSubcatsSetted = Object.prototype.hasOwnProperty.call(this.connectionData.optional, 'wantSubcats');

      if (isSubcatsSetted) {
        return;
      }

      this.setOptionalValue({
        key: 'wantSubcats',
        value: true
      });
    }
  }
};
</script>
