import tableApi from '@/api/demand/table';
import DemandTableOverrideAdapter from '@/adapters/request/demand/DemandTableOverride.adapter';
import { forecastVersionsMagicIds } from '@/config/demand/table.config';
import { nodeFlags } from '@/config/shared/fgs.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { getResizedIndexesFromDates } from '@/helpers/demand/table';
import { dealWith } from '@/api/helpers/errorRegistry';
import { ampli } from '@/plugins/product/ampli';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_ROWS_VISIBILITY: 'SET_ROWS_VISIBILITY',
  SET_COLUMN_SIZES: 'SET_COLUMN_SIZES'
};

const state = () => ({
  table: null,
  rows_visibility: null,
  column_sizes: null
});

const getters = {
  isNodeEditable: (state) => state.table?.metadata?.nodeFlags & nodeFlags.HAS_EDITABLE_PERMISSION,
  nodeFlagByShift: (state) => (shift) => state.table?.metadata?.nodeFlags & shift,
  // todo unify across the demand modules
  getActionFgs: () => (action, fgs) => {
    if (typeof action === 'bigint') {
      return !!(BigInt(fgs || 0) & action);
    }

    return !!(fgs & action);
  }
};

const mutations = {
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.SET_ROWS_VISIBILITY](state, value) {
    state.rows_visibility = value;
  },
  [types.SET_COLUMN_SIZES](state, value) {
    state.column_sizes = value;
  }
};

const actions = {
  async fetchNodeTable({ commit, rootGetters, dispatch }) {
    try {
      dispatch('fetchRowsVisibility');

      const response = await tableApi.getNodeTable({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });
      const table = response?.data;

      if (!table) {
        return;
      }

      commit( types.SET_TABLE, table);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchNodeTable' });
    } finally {
      this.dispatch('demand/setIsNodeUpdating', false);
    }
  },
  async fetchRowsVisibility({ commit }) {
    try {
      const response = await tableApi.getRowsVisibility();

      if (!response?.data) {
        return;
      }

      commit(types.SET_ROWS_VISIBILITY, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchRowsVisibility' });
    }
  },
  updateRowVisibility(_, { visible, ...payload }) {
    return tableApi.updateRowVisibility(payload);
  },
  async updateTableOverride({ dispatch, rootGetters }, payload) {
    try {
      this.dispatch('demand/setIsNodeUpdating', true);

      const isCustomRow = payload.index !== undefined;
      const request = isCustomRow
        ? tableApi.updateCustomRowOverride
        : tableApi.updateTableOverride;

      await request({
        nodeId: rootGetters['demand/tree/activeNodeId']
      },
      DemandTableOverrideAdapter(payload)
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateTableOverride' });
      this.dispatch('demand/setIsNodeUpdating', false);

      dealWith(
        [
          slErrorCodes.INVALID_NUMBER_LIMIT,
          slErrorCodes.INVALID_NUMBER,
          slErrorCodes.INVALID_INPUT
        ].reduce((acc, code) => {
          acc[code] = {
            text: (e) => e.message
          };

          return acc;
        }, {})
      )(e);

      dispatch('fetchNodeTable');
    } finally {
      ampli.demandTableOverridden({
        demandTableRowName: payload.name
      });
    }
  },
  async applyToFinalForecast({ rootGetters }, body) {
    try {
      this.dispatch('demand/setIsNodeUpdating', true);

      await tableApi.applyToFinalForecast(
        {
          nodeId: rootGetters['demand/tree/activeNodeId']
        },
        body
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'applyToFinalForecast' });

      this.dispatch('demand/setIsNodeUpdating', false);
    }
  },
  applyCellToFinalForecast({ dispatch }, { name: row, column }) {
    return dispatch('applyToFinalForecast', {
      column,
      row
    });
  },
  applyRowToFinalForecast({ dispatch }, { name: row }) {
    return dispatch('applyToFinalForecast', {
      row
    });
  },
  async copyFromStatisticalForecast({ rootGetters }, { column, row, index }) {
    try {
      this.dispatch('demand/setIsNodeUpdating', true);

      await tableApi.copyFromStatisticalForecast(
        {
          nodeId: rootGetters['demand/tree/activeNodeId']
        },
        {
          column,
          row,
          type: index > 0 ? forecastVersionsMagicIds.CUSTOM : forecastVersionsMagicIds.FINAL
        }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyFromStatisticalForecast' });
      this.dispatch('demand/setIsNodeUpdating', false);
    }
  },
  copyCellFromStatisticalForecast({ dispatch }, { name: row, column, index }) {
    return dispatch('copyFromStatisticalForecast', {
      column,
      row,
      index
    });
  },
  copyRowFromStatisticalForecast({ dispatch }, { name: row, index }) {
    return dispatch('copyFromStatisticalForecast', {
      row,
      index
    });
  },
  propagateCell({ dispatch }, payload) {
    return dispatch('updateTableOverride', {
      ...payload,
      value: payload.edit + '>'
    });
  },
  setResizedColumns({ state, commit }, data) {
    commit(types.SET_COLUMN_SIZES, getResizedIndexesFromDates(state.table.body, data));
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
