export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const { id: _id, key: _key } = data.required;
  const {
    nMonthsToDropToReceive: _nMonthsToDropToReceive,
    importItemTypeAsCat: _importItemTypeAsCat,
    locIsDc: _locIsDc,
    customerAsChannel: _customerAsChannel,
    fileId: _fileId,
    fileRemoved: _fileRemoved,
    combineLocs: _combineLocs,
    importItemBrandAsCat: _importItemBrandAsCat
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    credentials: {
      _id,
      _key
    },
    info: {
      _startDate,
      _groupBy,
      _nMonthsToDropToReceive,
      _importItemTypeAsCat: +_importItemTypeAsCat,
      _customerAsChannel: +_customerAsChannel,
      _locIsDc: +_locIsDc,
      _combineLocs: +_combineLocs,
      _importItemBrandAsCat: +_importItemBrandAsCat
    },
    transactionsFile: {
      _fileId,
      _fileRemoved
    }
  };
}
