import { toArray } from '@/helpers/utils/toArray';
import { multipleSlots } from '@/config/connection';

export const setColSlot = (types, { state, commit }, { tab, value, index }) => {
  const removeSlot = (slotKey, colIndex) => {
    commit(types.SET_SLOTS_INDEXES, {
      tab,
      slotKey,
      indexesList: state[tab].slotsIndexes[slotKey]?.filter(i => +i !== +colIndex) || []
    });

    commit(types.SET_MATCHED_SLOT, {
      tab,
      index: colIndex,
      value: ''
    });
  };
  const addSlot = () => {
    commit(types.SET_SLOTS_INDEXES, {
      tab,
      slotKey: value.colMeaning,
      indexesList: [...toArray(state[tab].slotsIndexes[value.colMeaning]), index]
    });

    return commit(types.SET_MATCHED_SLOT, {
      tab,
      index,
      value: value.colMeaning
    });
  };

  if (!value) {
    const slotKey = state[tab].matchedSlots[index];

    // remove col slot
    return removeSlot(slotKey, index);
  }

  // replace old slot
  const existSlotKey = state[tab].matchedSlots[index];

  if (existSlotKey) {
    removeSlot(existSlotKey, index);
  }

  //second condition for connector 2.0 json response
  // add multiple slot
  if (value._isMultiple === 'true' || value.isMultiple) {
    return addSlot();
  }

  // add already taken col slot
  const colSlotValues = Object.entries(state[tab].matchedSlots);
  const taken = colSlotValues.find(pair => pair[1] === value.colMeaning);

  // if taken delete old slot & slots indexes => add new col slot & slot indexes
  if (taken) {
    removeSlot(taken[1], taken[0]);
  }

  // add col slot
  addSlot();
};

export const getColumnSlotOptions = (tabData, colIndex) => {
  let hasMultiple = false;
  // set multiple slot count per column
  const multipleSlotCount = multipleSlots.reduce((acc, slotKey) => {
    const indexes = tabData.slotsIndexes[slotKey];

    // add count if multiple slot already was selected
    if (indexes?.length > 0) {
      const hasIndex = indexes.includes(colIndex);
      let slotCount = '';

      // for other columns set future selecting count
      if (!hasIndex) {
        slotCount = indexes.length + 1;
      }

      // for already selected multiple slot set its own order count
      if (indexes.length > 1 && hasIndex) {
        slotCount = indexes.findIndex(i => i === colIndex) + 1;
      }

      acc[slotKey] = slotCount;

      hasMultiple = true;
    }

    return acc;
  }, {});

  if (!hasMultiple) {
    return tabData.availableSlots;
  }

  // if multiple slot/slots already selected add its counts
  return tabData.availableSlots.map(option => {
    const slotCount = multipleSlotCount[option.colMeaning];

    if (slotCount > 1) {
      return {
        ...option,
        name: `${option.name} ${slotCount}`
      };
    }

    return option;
  });
};
