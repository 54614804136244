<template>
  <div
    class="sl-tag body-3-md grey-80"
    :class="{
      'sl-tag--active': active,
      'sl-tag--clearable': clearable
    }"
    @click.stop="handleSelect"
  >
    <slot />
    <SlButton
      v-if="clearable"
      size="2xs"
      variant="tertiary"
      color="grey"
      icon
      @click.stop="handleClear"
    >
      <icon
        data="@icons/close.svg"
        class="fill-off size-16 color-grey-60"
      />
    </SlButton>
  </div>
</template>

<script>
export default {
  name: 'SlTag',
  props: {
    id: {
      type: [String, Number, null],
      default: null
    },
    clearable: Boolean,
    active: Boolean
  },
  methods: {
    handleSelect() {
      this.$emit('select', this.id);
    },
    handleClear() {
      this.$emit('clear', this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-tag";
</style>