import { render, staticRenderFns } from "./SlSelectionInfo.vue?vue&type=template&id=58fb3594&scoped=true&"
import script from "./SlSelectionInfo.vue?vue&type=script&lang=js&"
export * from "./SlSelectionInfo.vue?vue&type=script&lang=js&"
import style0 from "./SlSelectionInfo.vue?vue&type=style&index=0&id=58fb3594&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58fb3594",
  null
  
)

export default component.exports