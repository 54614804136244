<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.locIsDc')"
        :value="locIsDcLabel"
      />
      <SlInfoItem
        :label="$t('Web.CIN7.ImportItemBrandAsCat')"
        :value="importItemBrandAsCatLabel"
      />
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.importItemTypeAsCat')"
        :value="importItemTypeAsCatLabel"
      />
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <template v-else>
      <SlCheckbox
        v-model="locIsDc"
        :label="$t('Web.BaseConnectors.Form.locIsDc')"
        class="sl-checkbox"
      />
      <SlCheckbox
        v-model="importItemBrandAsCat"
        :label="$t('Web.CIN7.ImportItemBrandAsCat')"
        class="sl-checkbox"
      />
      <SlCheckbox
        v-model="importItemTypeAsCat"
        :label="$t('Web.BaseConnectors.Form.importItemTypeAsCat')"
        class="sl-checkbox"
      />
      <SlCheckbox
        v-model="combineLocs"
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        class="sl-checkbox"
      />
    </template>
    <SlCheckbox
      v-model="customerAsChannel"
      :label="$t('Web.CIN7.CustomerGroupIsChannel')"
      class="sl-checkbox"
    />
    <div class="connector-content-block__row start w-240">
      <SlValidate
        v-slot="{ invalid }"
        vid="nMonthsToDropToReceive"
        mode="eager"
        rules="required|between:0,120"
      >
        <SlInput
          v-model="nMonthsToDropToReceive"
          :label="$t('Web.BaseConnectors.Form.nMonthsToDropToReceive')"
          :description="$t('Web.BaseConnectors.Form.nMonthsToDropToReceiveSubtitle')"
          step="1"
          type="number"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { fileFormats } from '@/config/utils/fileUpload.config';

export default {
  name: 'DearOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.DEAR,
      fileFormats
    };
  },
  inject: [
    'historicalTransactionsTemplate'
  ],
  computed: {
    locIsDc: {
      get() {
        return Boolean(+this.connectionData.optional.locIsDc);
      },
      set(value) {
        this.setOptionalValue({
          key: 'locIsDc',
          value
        });
      }
    },
    importItemTypeAsCat: {
      get() {
        return Boolean(+this.connectionData.optional.importItemTypeAsCat);
      },
      set(value) {
        this.setOptionalValue({
          key: 'importItemTypeAsCat',
          value
        });
      }
    },
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    nMonthsToDropToReceive: {
      get() {
        return +this.connectionData.optional.nMonthsToDropToReceive || 0;
      },
      set(value) {
        this.setOptionalValue({
          key: 'nMonthsToDropToReceive',
          value
        });
      }
    },
    customerAsChannel: {
      get() {
        return Boolean(+this.connectionData.optional.customerAsChannel);
      },
      set(value) {
        this.setOptionalValue({
          key: 'customerAsChannel',
          value
        });
      }
    },
    importItemBrandAsCat: {
      get() {
        return Boolean(+this.connectionData.optional.importItemBrandAsCat);
      },
      set(value) {
        this.setOptionalValue({
          key: 'importItemBrandAsCat',
          value
        });
      }
    },
    locIsDcLabel() {
      return getLabelFromBoolean(this.locIsDc, this);
    },
    importItemTypeAsCatLabel() {
      return getLabelFromBoolean(this.importItemTypeAsCat, this);
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    },
    importItemBrandAsCatLabel() {
      return getLabelFromBoolean(this.importItemBrandAsCat, this);
    }
  }
};
</script>
