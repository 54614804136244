import { tabKeys } from '@/config/connection/dbRelated.config';

export default function(state, tab) {
  const data = state[tab];

  const body = {
    query: data.query,
    list: {
      itemCode: data.itemCode,
      location: data.location || ''
    }
  };
  const forecastDate = data.forecastDate && data.forecastTime
    ? `${data.forecastDate} ${data.forecastTime}`
    : data.forecastDate;

  if (tab === tabKeys.PLANNED_ORDERS) {
    return {
      ...body,
      list: {
        ...body.list,
        reorderPoint: +data.reorderPoint,
        qty: +data.orderQty
      }
    };
  }

  if (tab === tabKeys.MIN_MAX) {
    return {
      ...body,
      list: {
        ...body.list,
        reorderPoint: +data.reorderPoint,
        maxInv: +data.maxInventory
      }
    };
  }

  if (tab === tabKeys.INVENTORY_REPORT) {
    return {
      ...body,
      list: {
        ...body.list,
        onHand: +data.onHand,
        supplyDays: +data.supplyDays,
        qty: +data.receiveQty
      }
    };
  }

  if (tab === tabKeys.INVENTORY_FORECAST) {
    return {
      ...body,
      list: {
        ...body.list,
        forecast: +data.demandForecast,
        purchasePlan: +data.orderingPlan,
        invProjection: +data.inventoryProjection,
        forecastDate,
        startDate: data.forecastDateStart,
        endDate: data.forecastDateEnd
      }
    };
  }

  if (tab === tabKeys.REPORTS) {
    return {
      ...body,
      list: {
        ...body.list,
        channel: data.channel,
        forecast: +data.demandForecast,
        Revenue: +data.projectedRevenue,
        forecastDate,
        startDate: data.forecastDateStart,
        endDate: data.forecastDateEnd
      }
    };
  }
}