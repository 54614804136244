import { emptySection } from '@/config/dashboard/analytics.config';
import { addWidgetToSection } from '@/helpers/dashboard/analytics';

export default function(sections, widgetsMap = null) {
  if (!widgetsMap || !Object.keys(widgetsMap)?.length) {
    return [emptySection()];
  }

  const widgetIds = Object.keys(widgetsMap);
  const uiSettingsWidgetIds = sections.map(({ widgets }) => widgets.map(({ id }) => id)).flat();
  const isValid = widgetIds.length === uiSettingsWidgetIds.length && uiSettingsWidgetIds.every(id => widgetsMap[id]);

  if (isValid) {
    return [
      ...(sections || []),
      emptySection()
    ];
  }

  let sectionIndex = 0;
  // in case of broken ui settings
  const newSections = widgetIds.reduce((acc, id) => {
    if (!acc[sectionIndex]) {
      acc.push(addWidgetToSection(id));
    } else {
      acc.splice(sectionIndex, 1, addWidgetToSection(id, acc[sectionIndex]));

      sectionIndex += 1;
    }

    return acc;
  }, []);

  return [
    ...newSections,
    emptySection()
  ];
}
