export const backupTypes = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  EVENTS: 'Events'
};

export const backupTabsConfig = (ctx) => ([
  {
    label: ctx.$t('Web.Backups.Types.Daily'),
    value: backupTypes.DAILY
  },
  {
    label: ctx.$t('Web.Backups.Types.Monthly'),
    value: backupTypes.MONTHLY
  },
  {
    label: ctx.$t('Web.Backups.Types.Events'),
    value: backupTypes.EVENTS
  }
]);