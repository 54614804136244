import { exportTabKeys } from '@/config/integrations/transform.config';

export default function(state) {
  return exportTabKeys.reduce((acc, tab) => {
    if (state[tab].query) {
      acc[tab] = state[tab].query;
    }

    return acc;
  }, {});
}
