var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-block",class:{
    'content-block--footer': _vm.$scopedSlots.footer
  }},[_c('div',{ref:"scrollContainer",staticClass:"content-block__main",class:{
      'pb-0': _vm.noPaddingBottom,
      'overflow-hidden': _vm.overflowHidden
    },on:{"&scroll":function($event){return _vm.updateScroll($event)}}},[_c('SlErrorBoundary',[_vm._t("default",null,null,{
          scrolledToTop: _vm.scrolledToTop,
          scrolledToBottom: _vm.scrolledToBottom,
          scrolledToLeft: _vm.scrolledToLeft,
          scrolledToRight: _vm.scrolledToRight,
          hasVerticalScrollbar: _vm.hasVerticalScrollbar,
          hasHorizontalScrollbar: _vm.hasHorizontalScrollbar
        })],2)],1),(_vm.$scopedSlots.footer)?_c('div',{staticClass:"content-block__footer",class:{
      'content-block__footer--shadow': _vm.hasVerticalScrollbar && !_vm.scrolledToBottom
    }},[_vm._t("footer")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }