import { stepStatuses } from '@/config/integrations';

export const getParsedSteps = (steps, activeIndex = 0, getStatus = () => stepStatuses.INCOMPLETE) => {
  const activeSteps = Object.keys(steps).map((key, index) => ({
    ...steps[key],
    type: key,
    active: index === activeIndex,
    status: getStatus(index)
  }));

  return {
    steps: activeSteps,
    index: activeIndex
  };
};