export default function(data) {
  const { _id: id, _key: key } = data.settings.credentials;
  const {
    _nMonthsToDropToReceive: nMonthsToDropToReceive,
    _combineLocs: combineLocs
  } = data.settings.info;

  return {
    required: {
      id,
      key
    },
    optional: {
      nMonthsToDropToReceive,
      combineLocs
    }
  };
}
