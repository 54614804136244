<template>
  <div class="sl-color-picker">
    <SlDropdown
      ref="slColorPicker"
      :placement="placement"
      :content-class="`sl-color-picker-dropdown sl-color-picker-dropdown--width-${itemsPerRow}`"
      v-bind="$attrs"
      @toggle="handleToggle"
    >
      <template #target>
        <button
          class="sl-color-picker__target"
          :class="{ 'sl-color-picker__target--focus': isShown }"
          :disabled="disabled"
        >
          <SlColorPickerOption
            :option="selectedOption"
            compact
            no-actions
          />
          <icon
            data="@icons/chevron_down.svg"
            class="fill-off size-16"
            :class="{
              'rotate-180': isShown
            }"
          />
        </button>
      </template>
      <template #content>
        <div
          v-for="option in options"
          :key="option.name"
          v-close-popper
          class="sl-color-picker__option"
          @click="handleSelect(option)"
          @keydown.enter="handleSelect(option)"
        >
          <SlColorPickerOption
            :option="option"
            :selected="isOptionSelected(option)"
          />
        </div>
      </template>
    </SlDropdown>
  </div>
</template>

<script>
import SlColorPickerOption from './SlColorPickerOption.vue';

export default {
  name: 'SlColorPicker',
  components: {
    SlColorPickerOption
  },
  props: {
    value: {
      type: [String, Object],
      default: null
    },
    options: {
      type: Array,
      default: () => ([])
    },
    itemsPerRow: {
      type: Number,
      default: 6,
      validator: (v) => v >= 3 && v <= 10
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    disabled: Boolean,
    returnObject: Boolean
  },
  data() {
    return {
      isShown: false
    };
  },
  computed: {
    selectedOption() {
      return this.isValueObject
        ? this.value
        : this.options.find(option => option.name === this.value);
    },
    isValueObject() {
      return this.value && typeof this.value === 'object';
    }
  },
  methods: {
    handleToggle(isShown) {
      this.isShown = isShown;
    },
    handleSelect(option) {
      const newValue = this.returnObject
        ? option
        : option.name;

      this.$emit('input', newValue);
    },
    isOptionSelected(option) {
      return this.isValueObject
        ? this.value?.name === option.name
        : this.value === option.name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-color-picker/index";
</style>
