import { shopifyAuthTypes } from '@/config/connection';

export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const _groupBy = groupBy.toLowerCase();

  const {
    shopName: _shopName,
    authType: _authType,
    apiKey: _apiKey,
    apiAccessToken: _apiAccessToken
  } = data.required;

  const {
    combineLocs: _combineLocs,
    wantSubcats: _wantSubcats
  } = data.optional;

  const commonStructure = {
    credentials: {
      _authType,
      _shopName
    },
    oauthToken: {
      connection: {
        _type: 'none'
      }
    },
    info: {
      _startDate,
      _groupBy,
      _combineLocs: +_combineLocs,
      _wantSubcats: +_wantSubcats
    }
  };

  return shopifyAdapterByAuthType[_authType](commonStructure, _apiAccessToken, _apiKey);
}

const shopifyAdapterByAuthType = {
  [shopifyAuthTypes.OAUTH]: shopifyOauth,
  [shopifyAuthTypes.CUSTOM]: shopifyCustomApp
};

function shopifyCustomApp(data, _apiAccessToken, _apiKey) {
  data.credentials = {
    ...data.credentials,
    _apiKey,
    _apiAccessToken
  };

  return data;
}

function shopifyOauth(data, _apiAccessToken) {
  data.oauthToken = {
    ...data.oauthToken,
    apiAccessToken: {
      _val: _apiAccessToken
    }
  };

  return data;
}
