import { saveAs } from 'file-saver';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';
import { getFileNameFromResponse } from '@/helpers/utils/fileUpload';

export const fileSaver = {
  mixins: [modal],
  methods: {
    async saveFile(
      request,
      {
        errorTitle,
        errorText,
        notifyType = 'modal',
        notificationType = 'error'
      } = {}
    ) {
      try {
        toggleUserInteraction(true);

        const response = await request();

        const file = new File([response.data], getFileNameFromResponse(response));

        saveAs(file);
      } catch (error) {
        if (error.data instanceof Blob) {
          const reader = new FileReader();

          reader.onload = (event) => {
            const error = JSON.parse(event.target.result);
            const { message, description } = error;
            const errorMessage = message && description ? `${description}: ${message}` : '';

            if (notifyType === 'notify') {
              return this.$notify({
                type: notificationType,
                title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
                text: errorMessage || errorText || message || this.$t('ExportIssues.Ui.:Caption'),
                duration: -1
              });
            }

            this.showModal(modalsId.NOTIFY, {
              title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
              text: errorMessage || errorText || this.$t('ExportIssues.Ui.:Caption')
            });
          };

          reader.readAsText(error.data);

          return;
        }

        const message = error?.message;
        const description = error?.description;
        const errorMessage = message && description ? `${description}: ${message}` : '';

        if (notifyType === 'notify') {
          return this.$notify({
            type: notificationType,
            title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
            text: errorText || message || this.$t('ExportIssues.Ui.:Caption'),
            duration: -1
          });
        }

        this.showModal(modalsId.NOTIFY, {
          title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
          text: errorMessage || errorText || this.$t('ExportIssues.Ui.:Caption')
        });
      } finally {
        toggleUserInteraction(false);
      }
    }
  }
};
