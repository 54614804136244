import Vue from 'vue';
import changesApi from '@/api/changes';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { timezoneOffsetMinutes } from '@/helpers/date/date';
import { getTableConfigParams, getTableConfigStructure, sortingParamsAdapter } from '@/helpers/shared/tableConfig';
import { ampli } from '@/plugins/product/ampli';
import { exportTypes } from '@/config/report/inventoryReport';

const types = {
  SET_CHANGES: 'SET_CHANGES',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  table: null,
  tableConfig: getTableConfigStructure(['page'])
});

const state = initialState();

const mutations = {
  [types.SET_CHANGES](state, value) {
    state.table = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state.tableConfig, payload);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  async fetchChanges({ state, commit }) {
    try {
      const response = await changesApi.getChanges({
        ...getTableConfigParams(state.tableConfig),
        timezone: timezoneOffsetMinutes()
      });
      const table = response.data;

      if (!table) {
        return;
      }

      commit(types.SET_CHANGES, GenericTableAdapter(table));
      commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(table));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChanges' });
    }
  },
  exportChanges() {
    ampli.changelogExported({
      exportFormat: exportTypes.XLSX
    });

    return changesApi.exportChanges({
      timezone: timezoneOffsetMinutes()
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
