import i18n from '@/plugins/vue-i18n';

export const rowKeys = {
  ACTUAL_SALES: 'ActualSales',
  ALLOW_ORDER: 'AllowOrder',
  AVG_PRICE: 'AvgPrice',
  BACKORDER: 'Backorder',
  DAYS_OF_SUPPLY: 'DaysOfSupply',
  FORECAST: 'Forecast',
  LOST_DAYS: 'LostDays',
  LOWER_CONF_LIMIT: 'LowerConfLimit',
  LOWER_ECHELON_DEMAND: 'LowerEchelonDemand',
  MATERIAL_CONSUMPTION: 'MaterialConsumption',
  ON_HAND: 'OnHand',
  ON_ORDER: 'OnOrder',
  ORDER_PLAN: 'OrderPlan',
  OUTLIER: 'Outlier',
  OVERRIDES: 'Overrides',
  PROFIT: 'Profit',
  PROJECTED_INVENTORY: 'ProjectedInventory',
  REVENUE: 'Revenue',
  SERVICE_LEVEL: 'ServiceLevel',
  UPPER_CONF_LIMIT: 'UpperConfLimit'
};

export const checkboxRowKeys = [
  rowKeys.ALLOW_ORDER,
  rowKeys.OUTLIER
];

export const forecastVersionAccessTypes = {
  ALL: 'everyone',
  NONE: 'none',
  SOME: 'specificUsers'
};

export const forecastVersionsMagicIds = {
  STATISTICAL: 'statistical',
  FINAL: 'final',
  CUSTOM: 'custom'
};

export const forecastVersionTypes = {
  FORECAST_VERSION: 'forecastVersion',
  CUSTOM_FIELD: 'customField'
};

export const forecastVersionTypeOptions = [
  {
    label: i18n.tc('Web.CustomFields.ForecastVersion'),
    value: forecastVersionTypes.FORECAST_VERSION
  },
  {
    label: i18n.tc('Web.CustomFields.CustomField'),
    value: forecastVersionTypes.CUSTOM_FIELD
  }
];
