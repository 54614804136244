import { toArray } from '@/helpers/utils/toArray';

export const getIndexAndProjectById = (projects, id) => {
  const list = toArray(projects);
  const index = list.findIndex(project => project.id === id);
  const project = index !== -1 ? list[index] : null;

  return {
    index,
    project
  };
};