import { multipleSlots } from '@/config/integrations/transform.config';

export default function({ availableSlots, slotsIndexes, importPreview }) {
  if (!availableSlots?.length) {
    return null;
  }

  return availableSlots.reduce((acc, { colMeaning }) => {
    const headers = importPreview?.headers;
    const indexes = slotsIndexes[colMeaning];
    // if preview available then check if indexes are in headers count range
    const availableIndexes = headers
      ? indexes?.filter(index => index < headers.length)
      : indexes;

    if (availableIndexes?.length) {
      acc[colMeaning] = multipleSlots.includes(colMeaning)
        ? availableIndexes
        : availableIndexes[0];
    }

    return acc;
  }, {});
}