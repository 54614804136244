import regExp from '@/helpers/utils/regExp';

export const fileToFormData = (file, name = 'file') => {
  if (!file) {
    return null;
  }

  const formData = new FormData();

  formData.append(name, file, encodeURIComponent(file.name));

  return formData;
};

const isEncodedUri = (uri = '') => {
  try {
    return uri !== decodeURIComponent(uri);
  } catch (e) {
    return false;
  }
};

const decodeUri = (uri) => {
  while (isEncodedUri(uri)){
    uri = decodeURIComponent(uri);
  }

  if (regExp.encodeSpace.test(uri)) {
    return uri.replace(regExp.encodeSpace, ' ');
  }

  return uri;
};

export const getFileNameFromResponse = (response) => {
  const header = response?.headers?.['content-disposition'];

  if (!header) {
    return '';
  }

  const filename = header.split('filename*=UTF-8\'\'')[1] ?? '';

  return decodeUri(filename);
};

export const fileTypeFromName = (fileName) => {
  if (!fileName) {
    return null;
  }

  return fileName.split('.').at(-1).toLowerCase();
};