import TableSlotsAdapter from '@/adapters/request/integrations/transform/shared/TableSlots.adapter';
import { importTabKeys } from '@/config/integrations/transform.config';

export default function(state) {
  return importTabKeys.reduce((acc, tab) => {
    if (state[tab].query) {
      acc[tab] = {
        query: state[tab].query,
        slots: TableSlotsAdapter(state[tab])
      };
    }

    return acc;
  }, {});
}
