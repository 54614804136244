import { toArray } from '@/helpers/utils/toArray';

export default function(data) {
  if (!data) {
    return null;
  }

  const { nodes, highlighted } = data;
  const { item_cats, loc_cats, channel_cats, ...metadata } = data.metadata || {};
  const categories = [
    ...toArray(item_cats).map(cat => ({ type: 'item_cats', value: cat })),
    ...toArray(loc_cats).map(cat => ({ type: 'loc_cats', value: cat })),
    ...toArray(channel_cats).map(cat => ({ type: 'channel_cats', value: cat }))
  ];

  return {
    tree: nodes,
    metadata: metadata.order && ({
      ...metadata,
      wantAbc: metadata.wantAbc,
      wantHideDistortions: metadata.wantHideDistortions,
      wantHideInactive: metadata.wantHideInactive,
      categories
    }),
    // only in link request
    activeIndex: highlighted ?? null
  };
}
