import { AdminPortal, mapAuthActions, mapLoginActions } from '@frontegg/vue';
import Vue from 'vue';

const $sl_fe_usersReadAccess = 'fe.secure.read.users';
const $sl_fe_adminRoleKey = 'Admin';

export const isUserAdmin = () => {
  return Vue.fronteggAuth.user?.roles?.find(role => role.key === $sl_fe_adminRoleKey);
};

export const fronteggMixin = {
  data() {
    return {
      ...this.mapAuthState(),
      $sl_fe_adminRoleKey,
      awaitTimeout: 5000
    };
  },
  computed: {
    $sl_userId() {
      return this.authState?.user?.id;
    },
    $sl_tenantId() {
      return this.authState?.user?.tenantId;
    },
    $sl_userName() {
      return this.authState?.user?.name;
    },
    $sl_userEmail() {
      return this.authState?.user?.email;
    },
    $sl_teamMembers() {
      return this.authState?.teamState?.users || [];
    },
    $sl_teamMembersLength() {
      return this.$sl_teamMembers.length;
    },
    $sl_hasReadTeamAccess() {
      return this.authState?.user?.permissions?.find(
        (permission) => permission.key === $sl_fe_usersReadAccess
      );
    },
    $sl_isUserAdmin() {
      return !!this.authState.user?.roles?.find(role => role.key === $sl_fe_adminRoleKey);
    }
  },
  methods: {
    loadTeamMembers: mapAuthActions('loadUsers'),
    $sl_loginWithRedirect: mapLoginActions('loginWithRedirect'),
    $sl_loadTeamMembers() {
      this.loadTeamMembers({
        pageOffset: 0,
        pageSize: 100
      });
    },
    $sl_showAdminPortal() {
      AdminPortal.show();
    },
    async $sl_switchTenant(tenantId) {
      const start = Date.now();
      let expire = false;

      this.fronteggAuth.tenantsActions.switchTenant({ tenantId });

      while (tenantId !== this.authState.user.tenantId && !expire) {
        await new Promise((resolve, reject) => setTimeout(() => {
          expire = Date.now() - start >= this.awaitTimeout;

          if (expire) {
            reject('Unable to set tenantId');
          }

          resolve();
        }, 100));
      }
    }
  }
};
