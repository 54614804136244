import CommonSettingsAdapter from '@/adapters/response/connection/dbRelated/shared/CommonSettings.adapter';

export default function(sets, isDefault) {
  const { advanceSettings } = sets;

  const connectionData = {
    companyName: advanceSettings._companyName
  };

  return CommonSettingsAdapter(sets.innerSettings, isDefault, connectionData);
}

