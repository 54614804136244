export const notifyTypes = {
  warn: 'warn',
  error: 'error',
  success: 'success'
};

export const notifyLinkTypes = {
  DEFAULT: 'default',
  BUILTIN: 'built-in'
};
