import { tabKeys } from '@/config/connection/dbRelated.config';
import { connectionTypes, DEFAULT_GROUP_BY } from '@/config/connection';
import dynamicsNavAdapter from '@/adapters/response/connection/dbRelated/dynamicsNav/ConnectionSettings.adapter';
import sapAdapter from '@/adapters/response/connection/dbRelated/sap/ConnectionSettings.adapter';
import fishbowlAdapter from '@/adapters/response/connection/dbRelated/fishbowl/ConnectionSettings.adapter';
import databaseAdapter from '@/adapters/response/connection/dbRelated/database/ConnectionSettings.adapter';
import netsuiteAdapter from '@/adapters/response/connection/dbRelated/netsuite/ConnectionSettings.adapter';
import dynamicsGpAdapter from '@/adapters/response/connection/dbRelated/dynamicsGp/ConnectionSettings.adapter';
import odooAdapter from '@/adapters/response/connection/dbRelated/odoo/ConnectionSettings.adapter';
import TabsAdapter from '@/adapters/response/connection/dbRelated/shared/Tabs.adapter';

const commonAdapterByType = {
  [connectionTypes.DYNAMICS_NAV]: dynamicsNavAdapter,
  [connectionTypes.SAP]: sapAdapter,
  [connectionTypes.FISHBOWL]: fishbowlAdapter,
  [connectionTypes.DYNAMICS_GP]: dynamicsGpAdapter,
  [connectionTypes.ODOO]: odooAdapter
};

const uniqueAdapterByType = {
  [connectionTypes.DATABASE]: databaseAdapter,
  [connectionTypes.NETSUITE]: netsuiteAdapter
};

export default function(data, isDefault = false) {
  const { _type: type, settings } = data;

  if (uniqueAdapterByType[type]) {
    return uniqueAdapterByType[type](settings, isDefault);
  }

  const innerSettings = settings?.innerSettings;
  const dbSettings = innerSettings?.settings || settings;
  const { commonSets, tabInfo, exportSets } = dbSettings.queries;
  const groupByValue = commonSets.groupBy || DEFAULT_GROUP_BY;
  const { importTabs, exportTabs } = TabsAdapter({
    importData: tabInfo,
    exportData: exportSets.query
  });
  const adapter = commonAdapterByType[type];

  return {
    ...(!isDefault && { cache_id: dbSettings.info._cacheId }),
    commonData: {
      startDate: dbSettings._startDate,
      type
    },
    [tabKeys.REQUIRED]: {
      startFrom: +commonSets.start,
      groupBy: groupByValue
    },
    ...adapter(data.settings, isDefault),
    ...importTabs,
    ...exportTabs
  };
}
