<template>
  <ConnectorContentBlock>
    <template #title>
      {{ title }}
    </template>
    <div
      v-if="$sl_isEditingRoute"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
        :value="combineLocationsValueText"
      />
    </div>
    <SlCheckbox
      v-else
      v-model="combineLocationsModel"
      :label="$t('DbImportDialog.Ui.chkStickLocationsTogether')"
    />
    <SlCheckbox
      v-model="includeZeroOnHandModel"
      :label="$t('DbImportDialog.Ui.chkCleanup')"
    />
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { connection } from '@/mixins/connection';
import { tabKeys } from '@/config/connection/dbRelated.config';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { fileFormats } from '@/config/utils/fileUpload.config';

export default {
  name: 'Optional',
  components: {
    ConnectorContentBlock
  },
  mixins: [connection],
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileFormats
    };
  },
  inject: [
    'setSettings',
    'historicalTransactionsTemplate'
  ],
  computed: {
    ...mapState({
      tabData: state => state.dbRelated[tabKeys.OPTIONAL]
    }),
    combineLocationsValueText() {
      return getLabelFromBoolean(this.combineLocationsModel, this);
    },
    includeZeroOnHandModel: {
      get() {
        return this.tabData?.includeZeroOnHand;
      },
      set(value) {
        this.setSettings({ value, key: 'includeZeroOnHand' });
      }
    },
    combineLocationsModel: {
      get() {
        return this.tabData?.combineLocations;
      },
      set(value) {
        this.setSettings({ value, key: 'combineLocations' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/settings.scss";
</style>
