export default async function initialization({ to, store, next, nextMiddleware }) {
  // skip system redirects
  if (to.params.system) {
    return next();
  }

  while (store.state.initialization.initializing) {
    await new Promise(res => setTimeout(res, 100));
  }

  return nextMiddleware();
}