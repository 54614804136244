import { bomTypes } from '@/config/item/bom.config';

export const getOpenNodes = ({ rows, type }) => {
  if (type === bomTypes.ENTIRE) {
    return rows.reduce((acc, row, rowIndex) => {
      if (row.expandable && (row.depth < rows[rowIndex + 1]?.depth)) {
        acc.push(row.id);
      }

      return acc;
    }, []);
  }

  return rows.reduce((acc, row) => {
    if (row.expandable) {
      acc.push(row.id);
    }

    return acc;
  }, []);
};