export const formatNumber = (val) => {
  if (val === 'nan') {
    return ''; 
  }

  if (isNaN(val)) {
    return ''; 
  }

  if (!val && val !== 0) {
    return ''; 
  }

  let value = val;

  if (!value.split) {
    value = `${value}`; 
  }

  if (value.split('.')[0] === `${+value}`) {
    value = `${+value}`;
  } else {
    value = `${Math.round(+value)}`;
  }
  value = Intl.NumberFormat('en').format(value);

  return value;
};
