<template>
  <div class="sl-error-boundary">
    <slot
      v-if="err"
      name="error"
      v-bind="{
        err,
        vm,
        info
      }"
    >
      <div class="sl-error-boundary__content">
        <icon
          data="@icons/illustration/something-went-wrong.svg"
          class="illustration-md"
        />
        <div class="heading-4-md grey-100">
          {{ $t('Web.Error.ErrorBoundary.DefaultTitle') }}
        </div>
        <div class="body-1 grey-70">
          {{ $t('Web.Error.ErrorBoundary.DefaultText') }}
        </div>
      </div>
    </slot>
    <slot v-else />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import logger from '@/api/logger';

export default {
  name: 'SlErrorBoundary',
  props: {
    stopPropagation: {
      type: Boolean,
      default: true
    },
    passive: Boolean
  },
  data() {
    return {
      err: false,
      vm: null,
      info: null
    };
  },
  errorCaptured(err, vm, info) {
    // KOSTYL for unsupported libraries that throw errors
    if (this.passive) {
      return false;
    }

    this.err = err;
    this.vm = vm;
    this.info = info;

    if (this.stopPropagation) {
      console.error(err);

      Sentry.captureException(err);

      logger.formatAndWriteError({
        e: err,
        info
      });
    }

    return !this.stopPropagation;
  }
};
</script>

<style lang="scss" scoped>
.sl-error-boundary {
  width: 100%;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    width: 100%;
    padding: 10px;

    user-select: none;

    .sl-svg-icon {
      margin-bottom: 8px;
    }
  }
}
</style>
