<template>
  <div class="sl-no-data">
    <div class="sl-no-data__content">
      <div class="sl-no-data__image">
        <slot
          v-if="$slots.image"
          name="image"
        />
        <icon
          v-else
          data="@icons/illustration/no-data.svg"
          class="illustration-md fill-off"
        />
      </div>
      <div class="sl-no-data__title heading-5-md grey-100">
        <slot
          v-if="$slots.title"
          name="title"
        />
        <template v-else>
          {{ $t('Web.DbImport.TitleNoData') }}
        </template>
      </div>
      <div
        v-if="$slots.text"
        class="sl-no-data__text body-1 grey-70"
      >
        <slot name="text" />
      </div>
    </div>
    <div
      v-if="$slots.action"
      class="sl-no-data__action"
    >
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlNoData'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-no-data";
</style>