<template>
  <vue-resizable
    ref="resizable"
    v-bind="$attrs"
    v-on="$listeners"
    @resize:start="sizeMatcher('resize:start', $event)"
    @resize:end="sizeMatcher('resize:end', $event)"
  >
    <slot />
  </vue-resizable>
</template>

<script>
import VueResizable from 'vue-resizable';

export default {
  name: 'SlResizable',
  components: {
    VueResizable
  },
  methods: {
    // todo add rest events if needed
    sizeMatcher(emit, { width, height, ...options }) {
      const { clientWidth, clientHeight } = this.$refs.resizable.$el;

      if (width !== clientWidth) {
        this.$refs.resizable.w = clientWidth;
      }

      if (height !== clientHeight) {
        this.$refs.resizable.h = clientHeight;
      }

      this.$emit(emit, {
        ...options,
        width: clientWidth,
        height: clientHeight
      });
    }
  }
};
</script>
