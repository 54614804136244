export default function(data) {
  const { 
    _baseUrl: baseUrl,
    _clientId: clientId,
    _clientSecret: clientSecret
  } = data.settings.credentials;
  const {
    _startDate: startDate,
    _groupBy: groupBy,
    _combineLocs: combineLocs
  } = data.settings.info;

  return {
    required: {
      baseUrl,
      clientId,
      clientSecret,
      startDate,
      groupBy
    },
    optional: {
      combineLocs
    }
  };
}
