import TabsAdapter from '@/adapters/request/connection/dbRelated/shared/Tabs.adapter';
import CredentialsAdapter from '@/adapters/request/connection/dbRelated/database/Credentials.adapter';
import { dataFields, tabKeys } from '@/config/connection/dbRelated.config';
import { DEFAULT_START_DATE } from '@/config/connection';

export default function(state) {
  const { connection } = CredentialsAdapter(state[dataFields.CONNECTION_DATA]);
  const { tabInfo, exportSetsQuery } = TabsAdapter(state);

  return {
    connection,
    _startDate: state.commonData.startDate || DEFAULT_START_DATE,
    queries: {
      tabInfo,
      exportSets: {
        query: exportSetsQuery
      },
      commonSets: {
        groupBy: state[tabKeys.REQUIRED].groupBy,
        start: state[tabKeys.REQUIRED].startFrom,
        orderTypes: {
          purchase: state[tabKeys.ORDERS_RECEIVE].purchase,
          work: state[tabKeys.ORDERS_RECEIVE].manufacture,
          transfer: state[tabKeys.ORDERS_RECEIVE].transfer
        }
      },
      protection: {
        _val: 'none'
      }
    },
    info: {
      _combineLocs: +state[tabKeys.OPTIONAL].combineLocations,
      _cleanUnusedEntries: +state[tabKeys.OPTIONAL].includeZeroOnHand,
      _zeroPriceTransactions: +state[tabKeys.OPTIONAL].zeroPriceTransactions,
      _zeroQtyTransactions: +state[tabKeys.OPTIONAL].zeroQtyTransactions,
      _legacyExportMode: +state[tabKeys.OPTIONAL].legacyExportMode,
      _cacheId: state.cache_id
    }
  };
}
