<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="appID"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="accountId"
          label="API ID"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="APIKey"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="accountKey"
          label="API Key"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
export default {
  name: 'UnleashedRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.UNLEASHED
    };
  },
  computed: {
    accountId: {
      get() {
        return this.connectionData.required.id;
      },
      set(value) {
        this.setRequiredValue({
          key: 'id',
          value
        });
      }
    },
    accountKey: {
      get() {
        return this.connectionData.required.key;
      },
      set(value) {
        this.setRequiredValue({
          key: 'key',
          value
        });
      }
    }
  }
};
</script>
