<template>
  <SlModal
    :id="id"
    :width="600"
    :title="title"
    :loading="isLoading"
    @created="onCreated"
    @on-enter="handleSubmit"
    @hide="onModalHide"
  >
    <div class="modal-content sl-rename">
      <ValidationObserver ref="observer">
        <SlValidate
          v-slot="{ invalid }"
          vid="text"
          :rules="rules"
        >
          <SlInput
            v-model="text"
            :placeholder="placeholder"
            :is-invalid="invalid"
          />
        </SlValidate>
      </ValidationObserver>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ cancelText || $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleSubmit">
          {{ submitText || $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SlRenameModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_RENAME_MODAL,
      title: null,
      text: null,
      validationRules: null,
      cancelText: null,
      submitText: null,
      placeholder: null,
      saveCallback: null,
      isLoading: false
    };
  },
  computed: {
    rules() {
      return {
        required: true,
        sl_forbidden_chars: true,
        max: 200,
        ...(this.validationRules || {})
      };
    }
  },
  methods: {
    onModalHide() {
      this.title = null;
      this.text = null;
      this.validationRules = null;
      this.cancelText = null;
      this.submitText = null;
      this.placeholder = null;
      this.saveCallback = null;
    },
    async handleSubmit() {
      try {
        const valid = await this.$refs.observer.validate();

        if (!valid) {
          return;
        }

        this.isLoading = true;

        this.saveCallback && await this.saveCallback(this.text);

        this.hideModal(this.id);
      } catch (e) {
        const message = e?.message;

        if (message) {
          this.$notify({
            type: 'error',
            text: message
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-content.sl-rename {
  min-height: fit-content;
}
</style>
