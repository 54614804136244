<template>
  <div class="connector-content-block">
    <div
      v-if="$scopedSlots.title || $scopedSlots.subtitle"
      class="connector-content-block__header"
    >
      <div
        v-if="$scopedSlots.title"
        class="connector-content-block__title heading-3-sb grey-100"
      >
        <slot name="title" />
      </div>
      <div
        v-if="$scopedSlots.subtitle"
        class="connector-content-block__subtitle body-1 grey-70"
      >
        <slot name="subtitle" />
      </div>
    </div>
    <div class="connector-content-block__main">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConnectorContentBlock'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/connector-content-block.scss";
</style>