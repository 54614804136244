import axios from '@/plugins/axios';

export default {
  getConnectionInfo() {
    return axios.get('/connection');
  },
  importConnectionInfo(body) {
    return axios.post('/connection/create', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  reimportConnectionInfo(params, body) {
    return axios.post('/connection/edit', body, { params });
  },
  saveConnectionInfo(body) {
    return axios.put('/connection/save', body);
  },
  uploadFile(body) {
    return axios.post('/connection/additional_file', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-pid-independent': true
      }
    });
  },
  getDefaultSettings(params) {
    return axios.get('/connection/default_sets', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  checkConnect(params) {
    return axios.get('/connection/connect', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
