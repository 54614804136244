import TableSlotsAdapter from '@/adapters/response/integrations/transform/shared/TableSlots.adapter';
import { matchKeys } from '@/config/integrations/spreadsheet.config';

const parseImportSettings = (tables = {}, files = []) => {
  return Object.entries(tables).reduce((acc, [key, value]) => {
    const { slots, index, fileName } = value;
    const file = files.find(file => file.name === fileName);

    if (fileName) {
      acc[key] = TableSlotsAdapter(slots);

      acc.match_sheets[key] = {
        [matchKeys.FILE]: file?.id,
        [matchKeys.SHEET]: file?.id !== undefined ? index : null
      };
    }

    return acc;
  }, { match_sheets: {} });
};

export default function({ tables, ...settings }, _, connection) {
  return {
    settings,
    ...parseImportSettings(tables, connection.files)
  };
}
