<template>
  <div
    :class="{
      'sl-time-picker--inline': inline
    }"
  >
    <div
      v-if="label"
      class="sl-time-picker__label body-1-md grey-80"
    >
      {{ label }}
    </div>
    <DatePicker
      v-model="vModel"
      :data-test-id="dataTestId"
      type="time"
      value-type="format"
      :format="config.format"
      :clearable="clearable"
      :disabled="disabled"
      class="sl-time-picker body-1 grey-90"
    >
      <template #input>
        <input
          v-mask="config.mask"
          v-model="vModel"
          :data-test-id="dataTestId"
          :disabled="disabled"
          class="mx-input"
          :class="{
            'mx-input__invalid': isInvalid
          }"
          :placeholder="config.placeholder"
          type="text"
        >
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { timeConfigByFormat, timeFormats } from '@/config/shared/datePicker.config';
import { is24h } from '@/helpers/date/date';

export default {
  name: 'SlTimePicker',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: null,
      validator: (f) => ['24h', '12h'].includes(f)
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    isInvalid: Boolean,
    disabled: Boolean,
    inline: Boolean
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.value === value) {
          return;
        }

        this.$emit('input', value ?? '');
      }
    },
    timeFormat() {
      if (this.format) {
        return this.format;
      }

      return is24h() ? timeFormats.t_24h : timeFormats.t_12h;
    },
    config() {
      return timeConfigByFormat[this.timeFormat];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-date-time-picker";
</style>
