import Vue from 'vue';
import connectionApi from '@/api/connections';
import connectionResponseAdapter from '@/adapters/response/connection';
import connectionRequestAdapter from '@/adapters/request/connection';
import {
  connectionTypes,
  connectorStructure,
  dbRelatedConnections,
  sourceConnectDataFields,
  sourceConnectStructure,
  spreadsheetTypes
} from '@/config/connection';
import cloneObject from '@/helpers/utils/cloneObject';
import { toArray } from '@/helpers/utils/toArray';
import { fileToFormData } from '@/helpers/utils/fileUpload';
import { gzipFile } from '@/helpers/utils/gzip';

const types = {
  SET_CONNECTOR: 'SET_CONNECTOR',
  RESET_STATE: 'RESET_STATE',
  RESET_CONNECTION_DATA_STATE: 'RESET_CONNECTION_DATA_STATE',
  SET_EDITABLE: 'SET_EDITABLE',
  SET_CONNECTION_DATA: 'SET_CONNECTION_DATA',
  SET_SOURCE_CONNECT_DATA: 'SET_SOURCE_CONNECT_DATA',
  SET_SOURCE_CONNECT_VALUE_BY_KEY: 'SET_SOURCE_CONNECT_VALUE_BY_KEY',
  SET_CONNECTOR_VALUE_BY_KEY: 'SET_CONNECTOR_VALUE_BY_KEY'
};

const initialState = () => ({
  selectedConnector: null,
  connectionData: {},
  [sourceConnectDataFields.MAIN]: {},
  [sourceConnectDataFields.CACHE]: {},
  wasEdited: false
});

const state = initialState();

const getters = {
  connectionDataByType: (state) => state.connectionData,
  sourceConnectDataByType: (state) => state.sourceConnectData,
  sourceConnectDataCacheByType: (state) => state.sourceConnectDataCache,
  slProjectName: (state) => state.connectionData[state.selectedConnector]?.commonData?.slProjectName || ''
};

const mutations = {
  [types.SET_CONNECTOR](state, value) {
    if (value && state.selectedConnector !== value) {
      Vue.set(state.connectionData, value, connectorStructure(value));
      Vue.set(state[sourceConnectDataFields.MAIN], value, sourceConnectStructure(value));
      Vue.set(state[sourceConnectDataFields.CACHE], value, sourceConnectStructure(value));
    }

    state.selectedConnector = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.RESET_CONNECTION_DATA_STATE](state) {
    const currentName = state.connectionData?.[state.selectedConnector]?.commonData?.slProjectName;

    Vue.set(state.connectionData, state.selectedConnector, connectorStructure(state.selectedConnector));

    if (currentName) {
      Vue.set(state.connectionData[state.selectedConnector].commonData, 'slProjectName', currentName);
    }
  },
  [types.SET_CONNECTOR_VALUE_BY_KEY](state, { type, field, key, value, edit = true }) {
    const connectionDataType = type || state.selectedConnector;

    Vue.set(state.connectionData[connectionDataType][field], key, value);

    if (key !== 'slProjectName' && edit) {
      state.wasEdited = true;
    }
  },
  [types.SET_CONNECTION_DATA](state, { type, value }) {
    const {
      [sourceConnectDataFields.MAIN]: sourceConnectData,
      ...connectionData
    } = value;

    Vue.set(state.connectionData, type, connectionData);

    if (sourceConnectData) {
      Vue.set(state[sourceConnectDataFields.MAIN], type, sourceConnectData);
    }
  },
  [types.SET_SOURCE_CONNECT_VALUE_BY_KEY](state, {
    field = sourceConnectDataFields.MAIN,
    type,
    key,
    value
  }) {
    Vue.set(state[field][type], key, value);
  },
  [types.SET_SOURCE_CONNECT_DATA](state, {
    field = sourceConnectDataFields.MAIN,
    type,
    value
  }) {
    Vue.set(state[field], type, cloneObject(value));
  },
  [types.SET_EDITABLE](state, value) {
    state.wasEdited = value;
  }
};

const actions = {
  resetState({ state, commit }) {
    if (state.selectedConnector === connectionTypes.BUSINESS_CENTRAL) {
      this.dispatch('bc365/resetState');
    }

    if (state.selectedConnector === connectionTypes.NETSUITE) {
      this.dispatch('netsuite/resetState');
    }

    if (state.selectedConnector === connectionTypes.SKUBANA) {
      this.dispatch('skubana/resetState');
    }

    if (state.selectedConnector === connectionTypes.QB_ONLINE) {
      this.dispatch('qb/resetState');
    }

    commit(types.RESET_STATE);
  },
  resetConnectionDataState({ commit }) {
    commit(types.RESET_CONNECTION_DATA_STATE);
  },
  resetEditingState({ commit }) {
    commit(types.SET_EDITABLE, false);
  },
  selectConnector({ commit }, value) {
    commit(types.SET_CONNECTOR, value);
  },
  setConnectorValueByKey({ commit }, payload) {
    commit(types.SET_CONNECTOR_VALUE_BY_KEY, payload);
  },
  setSourceConnectData({ commit }, payload) {
    commit(types.SET_SOURCE_CONNECT_DATA, payload);
  },
  setSourceConnectValueByKey({ commit }, payload) {
    commit(types.SET_SOURCE_CONNECT_VALUE_BY_KEY, payload);
  },
  async getConnectionInfo({ commit, state }) {
    try {
      const response = await connectionApi.getConnectionInfo();
      const settingsList = toArray(response?.data?.settings?.connectorSettings?.settingsList);

      if (!settingsList.length) {
        return;
      }

      const importData = settingsList[0].importData;
      const errors = toArray(response?.data?.settings?.fetchErrors?.error);
      const paramsMap = {
        [connectionTypes.QB_DESKTOP]: state.connectionData[connectionTypes.QB_DESKTOP]?.optional,
        [connectionTypes.SPREADSHEET_TRANSACTIONAL]: errors,
        [connectionTypes.SPREADSHEET_AGGREGATED]: errors
      };

      const connectionData = connectionResponseAdapter(importData, paramsMap[importData._type]);

      commit(types.SET_CONNECTOR, connectionData.type);

      if (spreadsheetTypes.includes(connectionData.type)) {
        return this.dispatch('spreadsheet/setConnectionSettings', connectionData.value);
      }

      if (connectionData.type === connectionTypes.BUSINESS_CENTRAL) {
        const { environments, companies } = connectionData.value.additionalData;

        commit(types.SET_CONNECTION_DATA, connectionData);
        this.dispatch('bc365/setEnvironments', environments);
        this.dispatch('bc365/setCompanies', companies);

        return;
      }

      if (dbRelatedConnections.includes(connectionData.type)) {
        commit(types.SET_CONNECTION_DATA, {
          type: connectionData.type,
          value: {
            optional: {
              file: connectionData.value.transactionalFile
            }
          }
        });

        return this.dispatch('dbRelated/setConnectionSettings', connectionData.value);
      }

      if (connectionData.type === connectionTypes.SKUBANA) {
        const { locations, channels } = connectionData.value.optional;

        this.dispatch('skubana/setLocationsChannels', {
          locations,
          channels
        });
      }

      commit(types.SET_CONNECTION_DATA, connectionData);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getConnectionInfo' });
    }
  },
  reimportConnectionInfo({ state }, { data = null } = {}) {
    const connectorData = data || state.connectionData[state.selectedConnector];
    const shared = state.connectionData[state.selectedConnector];
    const connect = state[sourceConnectDataFields.MAIN][state.selectedConnector];

    const body = connectionRequestAdapter.edit({
      data: connectorData,
      shared,
      connect
    });

    const params = {
      type: 'Full',
      logLevel: 'off',
      storeResult: false
    };

    return connectionApi.reimportConnectionInfo(params, body);
  },
  importConnectionInfo({ state }, { data = null } = {}) {
    const connectorData = data || state.connectionData[state.selectedConnector];
    const shared = state.connectionData[state.selectedConnector];
    const connect = state[sourceConnectDataFields.MAIN][state.selectedConnector];

    const body = connectionRequestAdapter.create({
      data: connectorData,
      shared,
      connect
    });

    return connectionApi.importConnectionInfo(body);
  },
  async saveConnectionInfo({ state }, { data = null } = {}) {
    const connectorData = data || state.connectionData[state.selectedConnector];
    const shared = state.connectionData[state.selectedConnector];
    const connect = state[sourceConnectDataFields.MAIN][state.selectedConnector];

    const body = connectionRequestAdapter.edit({
      data: connectorData,
      shared,
      connect
    });

    return connectionApi.saveConnectionInfo(body);
  },
  async uploadFile({ commit, state }, file) {
    try {
      const response = await connectionApi.uploadFile(
        fileToFormData(await gzipFile(file))
      );
      const fileId = response?.data?.id;

      if (!fileId) {
        return;
      }

      commit(types.SET_CONNECTOR_VALUE_BY_KEY, {
        type: state.selectedConnector,
        field: 'optional',
        key: 'fileId',
        value: fileId
      });

      commit(types.SET_CONNECTOR_VALUE_BY_KEY, {
        type: state.selectedConnector,
        field: 'optional',
        key: 'file',
        value: file
      });

      return fileId;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'uploadFile' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
