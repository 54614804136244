import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { fileSaver } from '@/mixins/webAPI';
import { routeNames } from '@/config/router/router.config';
import { bomTypes } from '@/config/item/bom.config';
import { namespaceByRoute } from '@/config/report';
import modalsId from '@/config/shared/modalsId.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { projectRedirect } from '@/helpers/router';

export const cellActions = {
  mixins: [access, modal, fileSaver],
  data() {
    return {
    };
  },
  provide() {
    return {
      openTableLink: this.openTableLink,
      openTreeNodeLink: this.openTreeNodeLink,
      openBomLink: this.openBomLink,
      substituteItem: this.substituteItem,
      removeFromImport: this.removeFromImport,
      copyItemCode: this.copyItemCode,
      selectAll: this.selectAll,
      openExplainCalculation: this.openExplainCalculation,
      truncateProject: this.truncateProject,
      processOverrideError: this.processOverrideError
    };
  },
  computed: {
    ...mapGetters({
      hasBom: 'project/hasBom',
      activeNodeId: 'demand/tree/activeNodeId'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    tableMetadata() {
      return this.$store.state[this.reportNamespace].table?.metadata || {};
    }
  },
  methods: {
    ...mapActions({
      fetchTreeByLink: 'demand/tree/fetchTreeByLink',
      setBomEntry: 'bom/setSelectedEntry',
      getItemClones: 'substitutions/getItemClones',
      updateRowsSelection: 'inventoryReport/updateRowsSelection',
      downloadTruncatedProject: 'project/downloadTruncatedProject'
    }),
    openTableLink(payload) {
      return this.$store.dispatch(`${this.reportNamespace}/openTableLink`, payload);
    },
    async openTreeNodeLink({ metadata }) {
      await this.fetchTreeByLink(metadata.entity);

      return projectRedirect({ name: routeNames.DEMAND });
    },
    async openBomLink({ metadata }) {
      if (!this.hasBom || !this.$sl_hasAccess(this.$sl_features.bomAccess)) {
        return;
      }

      await this.setBomEntry({
        ...metadata.entity,
        route: this.$route.name
      });

      await projectRedirect({
        name: routeNames.BILL_OF_MATERIALS,
        params: {
          type: bomTypes.ITEM
        }
      });
    },
    async substituteItem({ metadata }) {
      if (!this.$sl_hasAccess(this.$sl_features.remSubsRules)) {
        return;
      }

      const { item } = metadata.entity;

      await this.getItemClones(item);

      this.showModal(modalsId.SUBSTITUTE_AN_ITEM, {
        activeItemCode: item
      });
    },
    async removeFromImport({ metadata }) {
      if (!this.$sl_hasAccess(this.$sl_features.remSubsRules)) {
        return;
      }

      const { item } = metadata.entity;

      await this.getItemClones(item);

      this.showModal(modalsId.REMOVE_FROM_IMPORT, {
        activeItemCode: item
      });
    },
    copyItemCode({ metadata }) {
      copyToClipboard(metadata.entity.item || '');
    },
    selectAll() {
      if (this.tableMetadata.selectedRowsCount === this.tableMetadata.totalRowsCount) {
        return;
      }

      this.updateRowsSelection({
        state: true,
        global: true
      });
    },
    openExplainCalculation({ metadata, cellIndex, col }) {
      const { item, loc: location } = metadata.entity;

      this.showModal(modalsId.EXPLAIN_CALCULATION, {
        payload: { item, location },
        tableRowsNumber: col.cells.length,
        tableRowIndex: cellIndex
      });
    },
    truncateProject() {
      const filterId = this.$store.getters[`${this.reportNamespace}/activeFilterId`];

      if (filterId === DEFAULT_FILTER_ID) {
        return;
      }

      this.saveFile(this.downloadTruncatedProject.bind(this, {
        type: this.$store.getters[`${this.reportNamespace}/reportType`](),
        filterId
      }));
    },
    processOverrideError({ e, isClear }) {
      const title = isClear
        ? this.$t('Web.Report.ClearOverridesError')
        : this.$t('Web.Report.ApplyOverridesError');

      if (e.metainfo) {
        return this.$notify({
          type: 'warn',
          title,
          text: e.message,
          duration: -1,
          data: {
            callback: () => this.showModal(modalsId.SL_TABLE_INFO_MODAL, {
              title,
              subtitle: e.message,
              table: e.metainfo
            })
          }
        });
      }

      this.$notify({
        type: 'error',
        title,
        text: e.message,
        duration: 10000
      });
    }
  }
};