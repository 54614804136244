export const avatarColors = [
  {
    background: '#5990FD',
    color: 'white'
  },
  {
    background: '#69D093',
    color: 'black'
  },
  {
    background: '#73C8C8',
    color: 'black'
  },
  {
    background: '#7AB4FF',
    color: 'white'
  },
  {
    background: '#9999FF',
    color: 'white'
  },
  {
    background: '#A3CAB7',
    color: 'black'
  },
  {
    background: '#ACDC79',
    color: 'black'
  },
  {
    background: '#BEEF9E',
    color: 'black'
  },
  {
    background: '#CD95EA',
    color: 'white'
  },
  {
    background: '#D18FAD',
    color: 'white'
  },
  {
    background: '#F2966A',
    color: 'white'
  },
  {
    background: '#F38888',
    color: 'white'
  },
  {
    background: '#F8E687',
    color: 'black'
  },
  {
    background: '#F9AAEF',
    color: 'white'
  },
  {
    background: '#FEACA7',
    color: 'black'
  },
  {
    background: '#FFC6A4',
    color: 'black'
  }
];
