export const formUpdateBody = (data, isLeaf) => {
  data._val = data._val.toString().replace(/ /g, '').replace(/,/g, '');

  const val = data._val.replace('>', '');
  const isPropagated = data._val.includes('>');
  const isPlus = val.includes('+');
  const isMinus = val.includes('-');
  let multiplier = '';
  let addend = '';

  if (val[0] === '*') {
    const splitDivider = isPlus
      ? '+'
      : isMinus
        ? '-'
        : '';

    if (splitDivider) {
      multiplier = val.replace('*', '').split(splitDivider)[0];
    } else {
      multiplier = val.replace('*', '');
    }
  }

  if (isPlus) {
    // *2+10 or +10
    addend = val.split('+')[1];
  } else if (isMinus) {
    // *2-10 or -10
    addend = '-' + val.split('-')[1];
  } else if (multiplier) {
    // *2 , no + sign
    addend = '0';
  } else {
    // only value
    addend = val;
  }

  if (isLeaf) {
    let mul = '0';

    if (isPlus || isMinus) {
      mul = 1;
    }

    if (multiplier) {
      mul = multiplier;
    } else if (!isPropagated && !val) {
      mul = 1;
    }

    return {
      _addend: addend || 'NaN',
      _multiplier: mul,
      _wantExtend: isPropagated
    };
  } else {
    let add = addend || 'NaN';
    let mul = '0';

    if (isPlus || isMinus) {
      add = addend || 'NaN';
      mul = 1;
    }

    if (multiplier) {
      add = addend || '0';
      mul = multiplier;
    } else if (!val) {
      mul = 'nan';
    }

    return {
      _addend: add,
      _multiplier: mul,
      _wantExtend: isPropagated
    };
  }
};

export const formatDemandOverride = (override) => {
  // example v1.30 <overrideParams propagated="0" isPropagationSet="1" multiplier="2000" addend="10000"/>
  let inputVal = '';
  const value = '';

  const {
    _multiplier,
    _propagated,
    _isPropagationSet,
    _addend
  } = override.overrideParams;

  if (
    _multiplier === '1'
    && _addend === '0'
    && _propagated === '0'
    && _isPropagationSet === '0'
  ) {
    return value;
  }

  if (+_multiplier && +_multiplier !== 1000) {
    inputVal = `*${+_multiplier / 1000}`;
  }

  if (+_addend && _addend !== '-2147483648') {
    if (!+_multiplier) {
      inputVal = _addend;
    } else {
      inputVal = `${inputVal}${+_addend >= 0 ? `+${_addend}` : _addend}`;
    }
  }

  if (+_isPropagationSet && !+_propagated) {
    if (!inputVal) {
      inputVal = '0 >';
    } else {
      inputVal = `${inputVal} >`;
    }
  }

  if (inputVal) {
    if (['*1', '*1 >'].includes(inputVal)) {
      return inputVal.slice(3);
    }

    if (+_propagated) {
      return override._ovValue;
    }

    return inputVal.replace('*1+', '+');
  }

  return inputVal;
};

export const getResizedIndexesFromDates = (table, columns) => {
  return Object.keys(columns).reduce((acc, key) => {
    const colIndex = table.findIndex(col => col.date === key);

    if (colIndex !== -1) {
      acc[colIndex] = columns[key];
    }

    return acc;
  }, []);
};
