import { routeNames } from '@/config/router/router.config';

export const namespaceByRoute = {
  [routeNames.INVENTORY]: 'sharedInventoryReport',
  [routeNames.INVENTORY_PLANNED_ORDERS]: 'sharedInventoryReport',
  [routeNames.INVENTORY_IN_TRANSITION]: 'sharedInventoryReport',
  [routeNames.COLLECTIONS]: 'sharedInventoryReport',
  [routeNames.CONSTRAINED_PLAN]: 'sharedInventoryReport',
  [routeNames.INTERSTORE]: 'sharedInterstoreReport',
  [routeNames.INTERSTORE_PLANNED_ORDERS]: 'sharedInterstoreReport',
  [routeNames.INTERSTORE_IN_TRANSITION]: 'sharedInterstoreReport',
  [routeNames.REPORTS]: 'sharedForecastReport'
};

export const DEFAULT_INVENTORY_CELL_INDEX = '18446744073709551615';
export const DISABLED_STYLE = 'disabled';

export const progressStates = {
  FINISHED: 'Finished',
  PENDING: 'Pending',
  IN_PROGRESS: 'InProgress'
};

export const filterByKeys = {
  COLUMN: 'Column',
  RELATED_BOMs: 'RelatedBoms'
};
