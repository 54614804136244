var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-input-wrapper",class:{
    'sl-input--inline': _vm.inline,
    'sl-input--custom-width': _vm.customWidth
  }},[(_vm.hasLabel)?_c('div',{staticClass:"sl-input__label"},[_c('div',{class:[{
        'sl-required': _vm.required
      }, 'sl-input__label-title body-1-md grey-80' ]},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.$slots.icon)?_vm._t("icon"):_vm._e()],2),_c('div',{staticClass:"sl-input__label-description body-3 grey-60"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._t("label"),_c('div',{staticClass:"sl-input-wrapper__inner"},[(_vm.hasPrependLabel)?_c('div',{staticClass:"sl-input__label-prepend"},[(!_vm.$slots['prepend-inner'])?[_vm._v(" "+_vm._s(_vm.prependLabel)+" ")]:_vm._t("prepend-inner")],2):_vm._e(),_c('div',{staticClass:"sl-input__content"},[(_vm.$slots.prepend)?_c('div',{staticClass:"prepend-element"},[_vm._t("prepend")],2):_vm._e(),((_vm.type)==='checkbox')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],ref:"input",staticClass:"sl-input body-1 grey-80",class:{
          'sl-input__prepend': _vm.$slots.prepend,
          'sl-input__append': _vm.$slots.append,
          'sl-input__prepend-inner': _vm.hasPrependLabel,
          'sl-input__append-inner': _vm.hasAppendLabel,
          'sl-input__wrapped-inner': _vm.hasPrependLabel && _vm.hasAppendLabel,
          'sl-input--invalid': _vm.isInvalid
        },attrs:{"data-test-id":_vm.dataTestId,"maxlength":_vm.maxLength,"step":_vm.localStep,"placeholder":_vm.localPlaceholder,"disabled":_vm.disabled,"name":_vm.name,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.vModel)?_vm._i(_vm.vModel,null)>-1:(_vm.vModel)},on:{"keypress":_vm.onKeyPress,"change":function($event){var $$a=_vm.vModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.vModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.vModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.vModel=$$c}}}},_vm.bindListeners)):((_vm.type)==='radio')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],ref:"input",staticClass:"sl-input body-1 grey-80",class:{
          'sl-input__prepend': _vm.$slots.prepend,
          'sl-input__append': _vm.$slots.append,
          'sl-input__prepend-inner': _vm.hasPrependLabel,
          'sl-input__append-inner': _vm.hasAppendLabel,
          'sl-input__wrapped-inner': _vm.hasPrependLabel && _vm.hasAppendLabel,
          'sl-input--invalid': _vm.isInvalid
        },attrs:{"data-test-id":_vm.dataTestId,"maxlength":_vm.maxLength,"step":_vm.localStep,"placeholder":_vm.localPlaceholder,"disabled":_vm.disabled,"name":_vm.name,"type":"radio"},domProps:{"checked":_vm._q(_vm.vModel,null)},on:{"keypress":_vm.onKeyPress,"change":function($event){_vm.vModel=null}}},_vm.bindListeners)):_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"}],ref:"input",staticClass:"sl-input body-1 grey-80",class:{
          'sl-input__prepend': _vm.$slots.prepend,
          'sl-input__append': _vm.$slots.append,
          'sl-input__prepend-inner': _vm.hasPrependLabel,
          'sl-input__append-inner': _vm.hasAppendLabel,
          'sl-input__wrapped-inner': _vm.hasPrependLabel && _vm.hasAppendLabel,
          'sl-input--invalid': _vm.isInvalid
        },attrs:{"data-test-id":_vm.dataTestId,"maxlength":_vm.maxLength,"step":_vm.localStep,"placeholder":_vm.localPlaceholder,"disabled":_vm.disabled,"name":_vm.name,"type":_vm.type},domProps:{"value":(_vm.vModel)},on:{"keypress":_vm.onKeyPress,"input":function($event){if($event.target.composing){ return; }_vm.vModel=$event.target.value}}},_vm.bindListeners)),(_vm.$slots.append)?_c('div',{staticClass:"append-element"},[_vm._t("append")],2):_vm._e()]),(_vm.hasAppendLabel)?_c('div',{staticClass:"sl-input__label-append"},[(!_vm.$slots['append-inner'])?[_vm._v(" "+_vm._s(_vm.appendLabel)+" ")]:_vm._t("append-inner")],2):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }