import TableSlotsAdapter from '@/adapters/response/connection/shared/TableSlots.adapter';
import FileEditAdapter from '@/adapters/response/connection/spreadsheet/file/FileEdit.adapter';
import { composeDateKeys, matchKeys, tabKeys } from '@/config/connection/spreadsheet.config';
import { DEFAULT_GROUP_BY } from '@/config/connection';
import { toArray } from '@/helpers/utils/toArray';

const parseImportSettings = ({ tables, files: rawFiles }) => {
  return Object.keys(tables).reduce((acc, key) => {
    const {
      matchedCols,
      _index: matchIndex,
      _fileName: matchFileName
    } = tables[key];

    const files = rawFiles.filter(file => file._fileId);

    const file = files.find(file => file._fileName === matchFileName);
    const fileId = file ? file._fileId : null;

    acc[key] = TableSlotsAdapter(matchedCols.col);

    acc[tabKeys.MATCH_SHEETS][key] = {
      [matchKeys.FILE]: fileId,
      [matchKeys.SHEET]: fileId ? matchIndex : null
    };

    return acc;
  }, {
    [tabKeys.MATCH_SHEETS]: {}
  });
};

const parseFiles = (data, errors) => {
  const files = toArray(data.settings?.files?.file);

  return files.map(file => {
    const error = errors.find(error => error._fileId === file._fileId);

    return {
      ...file,
      ...(error && { error: error._description })
    };
  });
};

export default function(data, errors) {
  const { options, tables } = data.settings;
  const files = parseFiles(data, errors);
  const importTabsSettings = parseImportSettings({
    tables,
    type: data._type,
    files
  });

  return {
    commonData: {
      type: data._type
    },
    [tabKeys.GENERAL]: {
      groupBy: options._groupBy ?? DEFAULT_GROUP_BY,
      startFrom: options._start ?? 0,
      csvDelimiter: options._csvDelimiter ?? '',
      composeDate: options._composeDate ?? composeDateKeys.NONE,
      headerRowsCount: options._HeaderRowsCount ?? 1,
      uniteLocations: options._stickLocations === '1',
      zeroPriceTransactions: options._zeroPriceTransactions === '1',
      zeroQtyTransactions: options._zeroQtyTransactions === '1',
      fileUrl: options._fileUrl || ''
    },
    files: files.reduce((acc, file) => {

      if (file._fileId) {
        acc.push(FileEditAdapter(file, file._fileId));
      }

      return acc;
    }, []),
    ...importTabsSettings
  };
}
