<template>
  <div class="sl-selection-info">
    <div class="sl-selection-info__description btn-1 grey-70">
      <slot name="description" />
    </div>
    <div class="sl-selection-info__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { keyCodes } from '@/config/utils/statusCodes.config';

export default {
  name: 'SlSelectionInfo',
  data() {
    return {
      eventByKeycode: {
        [keyCodes.del]: 'shortcut:delete'
      }
    };
  },
  mounted() {
    window.addEventListener('keydown', this.handleShortcut);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleShortcut);
  },
  methods: {
    handleShortcut({ keyCode }) {
      const eventByCode = this.eventByKeycode[keyCode];

      if (eventByCode) {
        this.$emit(eventByCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-selection-info";
</style>