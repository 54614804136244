import axios from '@/plugins/axios';

export default {
  getEntity(params) {
    return axios.get('/netsuite/entity', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  cacheEntity(params) {
    return axios.post('/netsuite/entity', null, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};