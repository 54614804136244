<template>
  <div class="connector-wrap connector-wrap--simple">
    <Periods
      :type="type"
      :is-new="isNew"
    >
      <template #edit>
        <SlInfoItem
          :label="$t('Web.QBOnline.Form.SelectPayAccount')"
          :value="payAccountLabel"
        />
      </template>
    </Periods>
    <SlSelect
      v-if="isNew"
      v-model="payAccountModel"
      :options="payAccountOptions"
      :select-label="$t('Web.QBOnline.Form.SelectPayAccount')"
      track-by="id"
      label="name"
      class="w-320"
      allow-empty
      return-object
      clearable
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Periods from '@/components/Connections/Common/Periods.vue';
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';

export default {
  name: 'QBOnlineRequiredTab',
  components: {
    Periods
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.QB_ONLINE
    };
  },
  computed: {
    ...mapState({
      payAccountOptions: state => state.qb.accounts || []
    }),
    payAccountModel: {
      get() {
        return this.connectionData.required?.payAccount;
      },
      set(value) {
        this.setRequiredValue({
          key: 'payAccount',
          value
        });
      }
    },
    payAccountLabel() {
      return this.payAccountModel?._name || this.$t('Main.Lv.ByNone');
    }
  }
};
</script>
