import { toArray } from '@/helpers/utils/toArray';

export default function({ availableSlots, slotsIndexes, importPreview }) {
  return toArray(availableSlots).reduce((acc, { colMeaning }) => {
    const headers = importPreview?.headers;
    const indexes = slotsIndexes[colMeaning];
    // if preview available then check if indexes are in headers count range
    const availableIndexes = headers
      ? indexes?.filter(index => index < headers.length)
      : indexes;

    if (indexes?.length) {
      acc.push({
        _colMeaning: colMeaning,
        index: availableIndexes
      });
    }

    return acc;
  }, []);
}