export const bomTypes = {
  ENTIRE: 'entire',
  ITEM: 'item'
};

export const bomRequestParamByType = {
  [bomTypes.ENTIRE]: 'tree',
  [bomTypes.ITEM]: 'item_tree'
};

export const emptyBomLocation = 'EMPTY_BOM_LOCATION';