<template>
  <ValidationProvider
    v-slot="{ errors, dirty }"
    ref="provider"
    v-bind="$attrs"
    :name="name"
    class="sl-validation-provider"
  >
    <slot
      v-bind="{
        invalid: !!(errors && errors.length),
        dirty,
        validate: $refs.provider && $refs.provider.validate,
        sync: $refs.provider && $refs.provider.syncValue,
        reset: $refs.provider && $refs.provider.reset
      }"
    />
    <transition
      v-if="message"
      name="fade"
    >
      <div
        v-if="errors && errors.length"
        class="error-wrapper body-1 accent-red-90"
      >
        {{ errors[0] }}
      </div>
    </transition>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'SlValidate',
  props: {
    name: {
      type: String,
      // space remove field name duplication in error message (by default)
      default: ' '
    },
    message: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/shared/sl-validate.scss';
</style>