<template>
  <div class="connector-wrap">
    <div class="connector-content-block__row">
      <SlCheckbox
        v-model="includeInvoicesModel"
        :label="$t('Web.QBOnline.Form.ImportPartialInvoice')"
      />
    </div>
    <div class="connector-content-block__row">
      <SlOptionalInput
        v-model="poCustomDeliverModel"
        vid="poCustomDeliver"
        :mount-open="!!poCustomDeliverModel"
        :label="$t('Web.QB.Form.poCustomDeliver')"
        :placeholder="$t('Web.QB.Form.customNamePlaceholder')"
      />
    </div>
  </div>
</template>

<script>
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';

export default {
  name: 'QBOnlineOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.QB_ONLINE
    };
  },
  computed: {
    poCustomDeliverModel: {
      get() {
        return this.connectionData.optional.poCustomDeliver;
      },
      set(value) {
        this.setOptionalValue({
          key: 'poCustomDeliver',
          value
        });
      }
    },
    includeInvoicesModel: {
      get() {
        return this.connectionData.optional.includeInvoices;
      },
      set(value) {
        this.setOptionalValue({
          key: 'includeInvoices',
          value
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.connector-content-block__row {
  width: fit-content;
}
</style>