import axios from '@/plugins/axios';

export default {
  getConstrainedPlan(params) {
    return axios.get('/constrained_plan', { params });
  },
  runCalculation() {
    return axios.post('/constrained_plan');
  },
  exportToXlsx(params) {
    return axios.get('/constrained_plan/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }
};
