export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const { id: _id, key: _key } = data.required;
  const {
    nMonthsToDropToReceive: _nMonthsToDropToReceive,
    combineLocs: _combineLocs
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    credentials: {
      _id,
      _key
    },
    info: {
      _startDate,
      _groupBy,
      _nMonthsToDropToReceive,
      _combineLocs: +_combineLocs
    }
  };
}
