import { fileTypeFromName } from '@/helpers/utils/fileUpload';

export default function(data, id = null) {
  if (!data) {
    return null;
  }

  const {
    _fileId: fileId,
    _sz: size,
    _fileName: name,
    error
  } = data;

  return {
    ...(id && { id }),
    fileId,
    name,
    size,
    error,
    type: fileTypeFromName(name),
    existing: true
  };
}
