import axios from '@/plugins/axios';

export default {
  getReport(params) {
    return axios.get('/report', { params });
  },
  duplicateFilter(params) {
    return axios.post('/report/filters', null, { params });
  },
  deleteFilter(params) {
    return axios.delete('/report/filters', { params });
  },
  filterBy(params, body) {
    return axios.post('/report/filters/filter_by', body, { params });
  },
  addToFilter(params, body) {
    return axios.post('/report/filters/add_to_filter', body, { params });
  },
  postFiltersOrder(body, params) {
    return axios.post('/report/filters/filter_order', body, { params });
  },
  postReportsSort(body) {
    return axios.post('/report/sort', body);
  },
  getColumnsVisibility(params) {
    return axios.get('report/columns/visibility', { params });
  },
  postColumnVisibility(params) {
    return axios.post('report/columns/visibility', null, { params });
  },
  toggleColumnPin(params) {
    return axios.put('report/columns/pin', null, { params });
  },
  exportTableToDatabase(params) {
    return axios.post('report/export', null, { params });
  }
};
