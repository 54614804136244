<template>
  <div
    class="sl-input-wrapper"
    :class="{
      'sl-input--inline': inline,
      'sl-input--custom-width': customWidth
    }"
  >
    <div
      v-if="hasLabel"
      class="sl-input__label"
    >
      <div
        :class="[{
          'sl-required': required
        }, 'sl-input__label-title body-1-md grey-80',
        ]"
      >
        {{ label }}
        <slot
          v-if="$slots.icon"
          name="icon"
        />
      </div>
      <div class="sl-input__label-description body-3 grey-60">
        {{ description }}
      </div>
    </div>
    <slot
      v-else
      name="label"
    />

    <div class="sl-input-wrapper__inner">
      <div
        v-if="hasPrependLabel"
        class="sl-input__label-prepend"
      >
        <template v-if="!$slots['prepend-inner']">
          {{ prependLabel }}
        </template>
        <slot
          v-else
          name="prepend-inner"
        />
      </div>

      <div class="sl-input__content">
        <div
          v-if="$slots.prepend"
          class="prepend-element"
        >
          <slot name="prepend" />
        </div>
        <input
          ref="input"
          v-model="vModel"
          :data-test-id="dataTestId"
          class="sl-input body-1 grey-80"
          :class="{
            'sl-input__prepend': $slots.prepend,
            'sl-input__append': $slots.append,
            'sl-input__prepend-inner': hasPrependLabel,
            'sl-input__append-inner': hasAppendLabel,
            'sl-input__wrapped-inner': hasPrependLabel && hasAppendLabel,
            'sl-input--invalid': isInvalid
          }"
          :maxlength="maxLength"
          :type="type"
          :step="localStep"
          :placeholder="localPlaceholder"
          :disabled="disabled"
          :name="name"
          v-on="bindListeners"
          @keypress="onKeyPress"
        >
        <div
          v-if="$slots.append"
          class="append-element"
        >
          <slot name="append" />
        </div>
      </div>

      <div
        v-if="hasAppendLabel"
        class="sl-input__label-append"
      >
        <template v-if="!$slots['append-inner']">
          {{ appendLabel }}
        </template>
        <slot
          v-else
          name="append-inner"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlInput',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    step: {
      type: String,
      default: '1'
    },
    label: {
      type: String,
      default: ''
    },
    prependLabel: {
      type: String,
      default: ''
    },
    appendLabel: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    maxLength: {
      type: [String, Number],
      default: 500
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    isInvalid: Boolean,
    disabled: Boolean,
    inline: Boolean,
    customWidth: Boolean,
    required: Boolean,
    float: Boolean
  },
  data() {
    return {
      allowedNumberSymbols: ['.', ',']
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        const newValue = this.isNumber ? +value : value;

        this.$emit('input', newValue);
      }
    },
    isNumber() {
      return this.type === 'number';
    },
    localStep() {
      return this.isNumber ? this.step : 'any';
    },
    localPlaceholder() {
      return this.disabled ? '' : this.placeholder;
    },
    bindListeners() {
      const { input, ...rest } = this.$listeners;

      return rest;
    },
    hasLabel() {
      return !this.$slots.label && (this.label || this.description);
    },
    hasPrependLabel() {
      return this.$slots['prepend-inner'] || this.prependLabel;
    },
    hasAppendLabel() {
      return this.$slots['append-inner'] || this.appendLabel;
    }
  },
  methods: {
    onKeyPress(e) {
      if (!this.isNumber) {
        return true;
      }

      const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
      const charStr = String.fromCharCode(charCode);

      if (this.float && this.allowedNumberSymbols.includes(charStr)) {
        return true;
      }

      if (!/^-?\d*$/.test(charStr)) {
        e.preventDefault();

        return false;
      }

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-input";
</style>
