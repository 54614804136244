import { contextMenuKeys } from '@/config/shared/contextMenu.config';
import { routeNames } from '@/config/router/router.config';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { substitutionRuleTypes } from '@/config/item/substitutions.config';

// ============= sl table clipboard context menu ===================== \\
export const clipboardTableOptions = (ctx) => ([
  {
    name: ctx.$t('Web.Edit.Copy'),
    key: contextMenuKeys.COPY,
    block: 'clipboard'
  },
  {
    name: ctx.$t('Web.Edit.Cut'),
    key: contextMenuKeys.CUT,
    block: 'clipboard'
  },
  {
    name: ctx.$t('Web.Edit.Paste'),
    key: contextMenuKeys.PASTE,
    block: 'clipboard'
  },
  {
    name: ctx.$t('Web.Edit.Delete'),
    key: contextMenuKeys.DELETE,
    block: 'clipboard'
  }
]);

export const clipboardTableActionsMap = {
  [contextMenuKeys.COPY]: 'copyCell',
  [contextMenuKeys.CUT]: 'cutCell',
  [contextMenuKeys.DELETE]: 'deleteCell',
  [contextMenuKeys.PASTE]: 'pasteCell'
};

const editableKeys = {
  COLLECTIONS: ['loadWeight', 'loadVolume', 'palletLoad', 'loadQty', 'orderingCost', 'maxContainers', 'minWeight', 'minVolume', 'minQty', 'maxQty', 'minCost', 'maxCost'],
  IN_TRANSITION: ['arrivalDate', 'orderType', 'sourceFrom', 'placeDate', 'cost', 'currency', 'orderNumber', 'shipmentDate', 'qty', 'orderNumber', 'partOfForecast'],
  PLANNED_ORDERS: ['QTY'],
  SUPPLIERS: ['LeadTime', 'PurchasePrice', 'MinLot', 'Rounding'],
  SUBSTITUTIONS: ['source', 'target', 'targetDescription', 'multiplier', 'date']
};

export const editableKeysByRouteName = {
  [routeNames.COLLECTIONS]: editableKeys.COLLECTIONS,
  [routeNames.INVENTORY_IN_TRANSITION]: editableKeys.IN_TRANSITION,
  [routeNames.INTERSTORE_IN_TRANSITION]: editableKeys.IN_TRANSITION,
  [routeNames.INVENTORY_PLANNED_ORDERS]: editableKeys.PLANNED_ORDERS,
  [routeNames.INTERSTORE_PLANNED_ORDERS]: editableKeys.PLANNED_ORDERS,
  [routeNames.SUPPLIERS]: editableKeys.SUPPLIERS,
  [routeNames.REMOVAL_SUBSTITUTION_RULES]: editableKeys.SUBSTITUTIONS
};

// ============= bom table clipboard context menu ===================== \\
const bomTableOptions = (ctx) => ([
  ...clipboardTableOptions(ctx),
  {
    name: ctx.$t('Web.Tree.ContextCopyItemCode'),
    key: contextMenuKeys.COPY_ITEM_TO_CLIPBOARD,
    icon: 'copy'
  }
]);

const bomTableActionsMap = {
  ...clipboardTableActionsMap,
  [contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]: 'copyItemCode'
};

export const bomTableClipboardMenuConfig = (ctx) => ({
  options: ({ cellKey, text }) => bomTableOptions(ctx).filter(option => {
    if (cellKey === 'Item') {
      return option.block !== 'clipboard';
    }

    return !!`${text}` && option.key === contextMenuKeys.COPY;
  }),
  actionsMap: bomTableActionsMap,
  actions: {
    copyItemCode({ row }) {
      copyToClipboard(row?.itemCode || '');
    }
  }
});

// ============= substitutions table clipboard context menu ===================== \\

export const substitutionsTableClipboardMenuConfig = (ctx) => ({
  options: ({ row, cellKey, cellValue, text }) => clipboardTableOptions(ctx).filter(({ key }) => {
    const isImported = row.type?.val === substitutionRuleTypes.IMPORTED;
    const isDisabled = ['source', 'target', 'targetDescription'].includes(cellKey);

    if (isImported && isDisabled) {
      return key === contextMenuKeys.COPY;
    }

    const isValueExist = typeof text !== 'object' && !!`${text}`;
    const editableKeys = editableKeysByRouteName[ctx.$sl_routeName] || [];

    if (!editableKeys.length || !editableKeys.includes(cellKey)) {
      return isValueExist && key === contextMenuKeys.COPY;
    }

    if (cellValue?.auto && !cellValue?.val) {
      return [contextMenuKeys.COPY, contextMenuKeys.PASTE].includes(key);
    }

    if (!isValueExist) {
      return key === contextMenuKeys.PASTE;
    }

    return true;
  })
});