import axios from '@/plugins/axios';

export default {
  getForecastSettings(params) {
    return axios.get('/demand/settings/forecast', { params });
  },
  updateForecastSettings(params, body) {
    return axios.post('/demand/settings/forecast', body, { params });
  },
  getModelSettings(params) {
    return axios.get('/demand/settings/model', { params });
  },
  updateModelSettings(params, body) {
    return axios.post('/demand/settings/model', body, { params });
  },
  getInventorySettings(params) {
    return axios.get('/demand/settings/inventory', { params });
  },
  getKPISettings(params) {
    return axios.get('/demand/settings/kpi', { params });
  },
  getHolidays() {
    return axios.get('/demand/settings/holidays');
  }
};
