import { connectionTypes } from '@/config/connection/index';

export const MAX_FILES = 9;

export const fileSourceTypes = {
  FILE: 'files',
  LINK: 'urls'
};

export const matchKeys = {
  FILE: 'file',
  SHEET: 'sheet'
};

export const fileSourceOptions = (ctx) => ([
  {
    label: ctx.$t('Web.File.UploadFile'),
    value: fileSourceTypes.FILE
  },
  {
    label: ctx.$t('Web.File.UploadFileURL'),
    value: fileSourceTypes.LINK
  }
]);

export const requestTypeByConnector = {
  [connectionTypes.SPREADSHEET_TRANSACTIONAL]: 'transactional',
  [connectionTypes.SPREADSHEET_AGGREGATED]: 'aggregated'
};

export const tabKeys = {
  GENERAL: 'general',
  MATCH_SHEETS: 'matchSheets',
  TRANSACTIONS: 'transactions',
  ITEM_INFO: 'inventory',
  ORDERS_RECEIVE: 'arrivals',
  ORDERS_SHIP: 'backorders',
  SUBSTITUTIONS: 'substitutions',
  BOM: 'bom',
  PROMOTIONS: 'promotions',
  BATCH_EXPIRATIONS: 'expirations',
  INVENTORY_PARAMETERS: 'inventoryParameters',
  SALES: 'salesAndProperties',
  STOCK_ON_HAND: 'onHand',
  REVENUE: 'revenue',
  SUPPLIERS: 'suppliers'
};

export const mainTabs = [
  tabKeys.TRANSACTIONS,
  tabKeys.SALES
];

export const importTabKeys = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.ORDERS_SHIP,
  tabKeys.SUBSTITUTIONS,
  tabKeys.BOM,
  tabKeys.PROMOTIONS,
  tabKeys.BATCH_EXPIRATIONS,
  tabKeys.INVENTORY_PARAMETERS,
  tabKeys.SUPPLIERS,
  tabKeys.SALES,
  tabKeys.STOCK_ON_HAND,
  tabKeys.REVENUE
];

const transactionalKeys = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.BOM,
  tabKeys.ORDERS_SHIP,
  tabKeys.SUBSTITUTIONS,
  tabKeys.PROMOTIONS,
  tabKeys.BATCH_EXPIRATIONS,
  tabKeys.INVENTORY_PARAMETERS,
  tabKeys.SUPPLIERS
];

const aggregatedKeys = [
  tabKeys.SALES,
  tabKeys.STOCK_ON_HAND,
  tabKeys.REVENUE,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.SUBSTITUTIONS
];

export const keysByConnectionType = {
  [connectionTypes.SPREADSHEET_TRANSACTIONAL]: transactionalKeys,
  [connectionTypes.SPREADSHEET_AGGREGATED]: aggregatedKeys
};

export const settingTabs = (ctx) => ([
  {
    label: ctx.$t('Web.Spreadsheet.TabGeneral'),
    value: tabKeys.GENERAL,
    component: 'General'
  },
  {
    label: ctx.$t('Web.Spreadsheet.TabMatchSheets'),
    value: tabKeys.MATCH_SHEETS,
    component: 'MatchSheets'
  }
]);

export const importTabs = (ctx, dataType) => {
  if (dataType === connectionTypes.SPREADSHEET_TRANSACTIONAL) {
    return [
      {
        label: ctx.$t('OrderListDialog.Ui.tabMain:at'),
        value: tabKeys.TRANSACTIONS,
        required: true,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabInventory:at'),
        value: tabKeys.ITEM_INFO,
        required: true,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabInTransition:at'),
        value: tabKeys.ORDERS_RECEIVE,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabBackorders:at'),
        value: tabKeys.ORDERS_SHIP,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabSubstitutions:at'),
        value: tabKeys.SUBSTITUTIONS,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabBom:at'),
        value: tabKeys.BOM,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabPromotions:at'),
        value: tabKeys.PROMOTIONS,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabExpirations:at'),
        value: tabKeys.BATCH_EXPIRATIONS,
        component: 'Import'
      },
      {
        label: ctx.$t('OrderListDialog.Ui.tabInvParams:at'),
        value: tabKeys.INVENTORY_PARAMETERS,
        component: 'Import'
      },
      {
        label: ctx.$t('Web.Suppliers.PageTitle'),
        value: tabKeys.SUPPLIERS,
        component: 'Import'
      }
    ];
  }

  if (dataType === connectionTypes.SPREADSHEET_AGGREGATED) {
    return [
      {
        label: ctx.$t('SheetImportDialog.Ui.tabSales:at'),
        value: tabKeys.SALES,
        required: true,
        component: 'Import'
      },
      {
        label: ctx.$t('SheetImportDialog.Ui.tabInventory:at'),
        value: tabKeys.STOCK_ON_HAND,
        component: 'Import'
      },
      {
        label: ctx.$t('SheetImportDialog.Ui.tabRevenue:at'),
        value: tabKeys.REVENUE,
        component: 'Import'
      },
      {
        label: ctx.$t('SheetImportDialog.Ui.tabArrivals:at'),
        value: tabKeys.ORDERS_RECEIVE,
        component: 'Import'
      },
      {
        label: ctx.$t('SheetImportDialog.Ui.tabSubstitutions:at'),
        value: tabKeys.SUBSTITUTIONS,
        component: 'Import'
      }
    ];
  }

  return [];
};

export const composeDateKeys = {
  NONE: 'None',
  YEAR_MONTH: 'YearMonth',
  YEAR_WEEK: 'YearWeek'
};

export const composeDateOptions = (ctx) => ([
  {
    label: ctx.$t('Import.ComposeDate.0'),
    value: composeDateKeys.NONE
  },
  {
    label: ctx.$t('Import.ComposeDate.YM'),
    value: composeDateKeys.YEAR_MONTH
  },
  {
    label: ctx.$t('Import.ComposeDate.YW'),
    value: composeDateKeys.YEAR_WEEK
  }
]);

export const csvDelimiterOptions = [
  {
    label: ',',
    value: ','
  },
  {
    label: ';',
    value: ';'
  },
  {
    label: 'space',
    value: ' '
  },
  {
    label: 'tab',
    value: '\t'
  }
];
