var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connector-wrap"},[(!_vm.isNew)?_c('div',{staticClass:"connector-content-block__row connector-content-block__row--divider"},[(_vm.isStartDateDisabled)?_c('SlInfoItem',{attrs:{"label":_vm.$t('DbInsideImport.Ui.lbStartDate'),"value":_vm.formatDayMonthYear(_vm.startDate)}}):_vm._e(),_c('SlInfoItem',{attrs:{"label":_vm.$t('DbInsideImport.Ui.lbGroupBy'),"value":_vm.groupByLabel}}),(_vm.isCustomApi && !_vm.isNew)?_c('SlInfoItem',{attrs:{"label":_vm.startFromKeyText,"value":_vm.startFromValueText}}):_vm._e(),_vm._t("edit")],2):_vm._e(),(!_vm.isStartDateDisabled)?_c('div',{staticClass:"connector-content-block__row w-240"},[_c('SlValidate',{attrs:{"vid":"startDate","rules":"required|sl_date_between|sl_valid_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlDatePicker',{attrs:{"label":_vm.$t('DbInsideImport.Ui.lbStartDate'),"is-invalid":invalid},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})]}}],null,false,3843977366)})],1):_vm._e(),(_vm.isNew)?_c('div',{staticClass:"connector-content-block__row w-240"},[_c('SlControl',{attrs:{"options":_vm.groupByOptions,"label":_vm.$t('DbInsideImport.Ui.lbGroupBy'),"description":_vm.$t('Web.DbImport.LabelDescrGroupBy')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(_vm.$t('Web.DbImport.TooltipGroupBy'))),expression:"getTooltip($t('Web.DbImport.TooltipGroupBy'))"}]})]},proxy:true}],null,false,2622804443),model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}}),(_vm.isCustomApi && _vm.isNew)?[(_vm.groupBy === _vm.groupByKeys.MONTH)?_c('SlValidate',{key:"startFromDay",attrs:{"rules":{
          required: true,
          between: {
            min: 1,
            max: 31,
            message: _vm.$t('Web.Validation.InvalidValue')
          }
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"min":1,"max":31,"label":_vm.$t('Web.DbImport.LabelStartFromDay'),"description":_vm.$t('Web.DbImport.LabelDescrStartFrom'),"is-invalid":invalid},model:{value:(_vm.startFrom),callback:function ($$v) {_vm.startFrom=$$v},expression:"startFrom"}})]}}],null,false,3544922565)}):_c('SlSelect',{staticClass:"start-from-select",attrs:{"select-label":_vm.$t('Web.DbImport.LabelStartFrom'),"options":_vm.startFromOptions,"disabled":_vm.groupBy === _vm.groupByKeys.DAY},model:{value:(_vm.startFrom),callback:function ($$v) {_vm.startFrom=$$v},expression:"startFrom"}})]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }