import { dbConnectionTypes } from '@/config/connection/database.config';

export default function(data) {
  if (!data) {
    return null;
  }

  const {
    type,
    host,
    database,
    sourceName,
    port,
    username: user = '',
    password = ''
  } = data;

  return {
    connection: {
      _type: type,
      host,
      dataset: type === dbConnectionTypes.MYSQL
        ? database
        : sourceName,
      credentials: {
        user,
        password
      },
      port
    }
  };
}