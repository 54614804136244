import axios from '@/plugins/axios';

export default {
  getEntities(params) {
    return axios.get('/collections', { params });
  },
  createEntity(params, body) {
    return axios.post('/collections/create', body, { params });
  },
  updateEntity(params, body) {
    return axios.put('/collections/update', body, { params });
  },
  deleteEntities(params) {
    return axios.delete('/collections/delete', { params });
  },
  saveOverrides() {
    return axios.put('/collections/save');
  },
  discardOverrides() {
    return axios.delete('/collections/discard');
  }
};
