import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(data) {
  const {
    _teamName: teamName,
    _user: user,
    _password: password
  } = data.settings.credentials;
  const { _startDate: startDate, _groupBy: groupBy } = data.settings.info;
  const { _combineLocs: combineLocs } = data.settings.advanced;

  return {
    required: {
      teamName,
      user,
      password,
      startDate,
      groupBy
    },
    optional: {
      combineLocs,
      file: FileEditAdapter(data.settings.transactionsFile)
    }
  };
}
