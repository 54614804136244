export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const {
    login: _login,
    password: _password
  } = data.required;
  const {
    wantSubcats: _wantSubcats,
    combineLocs: _combineLocs,
    customerGroupIsChannel: _customerGroupIsChannel,
    areItemsWoQtyDispatchShipped: _areItemsWoQtyDispatchShipped,
    nMonthsToDropToReceive: _nMonthsToDropToReceive,
    fileId: _fileId,
    fileRemoved: _fileRemoved
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    credentials: {
      _login,
      _password
    },
    info: {
      _startDate,
      _groupBy
    },
    advanced: {
      _wantSubcats,
      _combineLocs,
      _customerGroupIsChannel,
      _areItemsWoQtyDispatchShipped,
      _nMonthsToDropToReceive
    },
    transactionsFile: {
      _fileId,
      _fileRemoved
    }
  };
}
