import { connectionTypes } from '@/config/connection';
import dearAdapter from './connectors/dear';
import unleashedAdapter from './connectors/unleashed';
import prontoAdapter from './connectors/pronto';
import shopifyAdapter from './connectors/shopify';
import cin7Adapter from './connectors/cin7';
import customAdapter from './connectors/custom';
import spreadsheetAdapter from './spreadsheet/ImportData.adapter';
import exactAdapter from './connectors/exact';
import sellercloudAdapter from './connectors/sellercloud';
import bc365Adapter from './connectors/bc365';
import finaleAdapter from './connectors/finale';
import spireAdapter from './connectors/spire';
import QBAdapter from './connectors/qb';
import skubanaAdapter from './connectors/skubana';
import dbRelatedAdapter from './dbRelated/DbRelatedConnectionSettings.adapter';

export default {
  edit: editConnectionAdapter,
  create: ({ data, connect, shared }) => {
    const connectionData = editConnectionAdapter({ data, connect, shared });

    const projectName = shared?.commonData?.slProjectName
      || data.commonData.type.toUpperCase() + ' project ' + new Date().toISOString().replace(/[., :]/g, '_');

    return {
      data: {
        newProjectData: {
          projectName
        },
        ...connectionData
      }
    };
  }
};

function editConnectionAdapter({ data, connect, shared }) {
  const { type } = data.commonData;
  const adapter = adapterByType[type];

  return {
    connectorSettings: {
      _type: 'singleConnector',
      settingsList: {
        _n: '1',
        importData: [{
          _type: type,
          settings: adapter(data, connect, shared)
        }]
      }
    }
  };
}

const adapterByType = {
  [connectionTypes.DEAR]: dearAdapter,
  [connectionTypes.CIN7]: cin7Adapter,
  [connectionTypes.UNLEASHED]: unleashedAdapter,
  [connectionTypes.PRONTO_XI]: prontoAdapter,
  [connectionTypes.SHOPIFY]: shopifyAdapter,
  [connectionTypes.DATABASE]: dbRelatedAdapter,
  [connectionTypes.CUSTOM_API]: customAdapter,
  [connectionTypes.SPREADSHEET_TRANSACTIONAL]: spreadsheetAdapter,
  [connectionTypes.SPREADSHEET_AGGREGATED]: spreadsheetAdapter,
  [connectionTypes.EXACT]: exactAdapter,
  [connectionTypes.SELLERCLOUD]: sellercloudAdapter,
  [connectionTypes.BUSINESS_CENTRAL]: bc365Adapter,
  [connectionTypes.FINALE]: finaleAdapter,
  [connectionTypes.SPIRE]: spireAdapter.connection,
  [connectionTypes.QB_DESKTOP]: QBAdapter.desktop,
  [connectionTypes.QB_ONLINE]: QBAdapter.online,
  [connectionTypes.NETSUITE]: dbRelatedAdapter,
  [connectionTypes.SKUBANA]: skubanaAdapter,
  [connectionTypes.DYNAMICS_NAV]: dbRelatedAdapter,
  [connectionTypes.SAP]: dbRelatedAdapter,
  [connectionTypes.FISHBOWL]: dbRelatedAdapter,
  [connectionTypes.DYNAMICS_GP]: dbRelatedAdapter,
  [connectionTypes.ODOO]: dbRelatedAdapter
};
