<template>
  <div
    class="sl-progress-loader"
    :class="[`sl-progress-loader--${size}`]"
    :style="{
      width: `${currentSize}px`,
      height: `${currentSize}px`
    }"
  >
    <svg
      :width="currentSize"
      :height="currentSize"
      fill="none"
      class="sl-progress-loader__background"
    >
      <circle
        :cx="coordinates"
        :cy="coordinates"
        :r="radius"
        :transform="`rotate(-90 ${coordinates} ${coordinates})`"
        :stroke-width="width"
      />
    </svg>
    <svg
      :width="currentSize"
      :height="currentSize"
      fill="none"
      class="sl-progress-loader__progress"
      :class="color"
    >
      <circle
        :cx="coordinates"
        :cy="coordinates"
        :r="radius"
        :transform="`rotate(-90 ${coordinates} ${coordinates})`"
        :stroke-dasharray="circumference"
        :stroke-width="width"
        stroke-linecap="round"
        :stroke-dashoffset="loadingProgress"
      />
    </svg>
    <div class="sl-progress-loader__details">
      <span
        v-if="title"
        class="sl-progress-loader__details-label"
      >
        {{ title }}
      </span>
      <span class="sl-progress-loader__details-loading grey-80">
        {{ loadingDetails }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlProgressLoader',
  props: {
    progressPoint: {
      type: Number,
      required: true
    },
    donePint: {
      type: Number,
      default: 100
    },
    progressUnit: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'xl',
      validator: (s) => ['xl', 'md'].includes(s)
    },
    color: {
      type: String,
      default: '',
      validator: (s) => ['yellow', 'red', ''].includes(s)
    }
  },
  data() {
    return {
      circleSizes: {
        xl: 160,
        md: 64
      },
      circleWidths: {
        xl: 12,
        md: 6
      }
    };
  },
  computed: {
    currentSize() {
      return this.circleSizes[this.size];
    },
    coordinates() {
      return this.currentSize / 2;
    },
    width() {
      return this.circleWidths[this.size];
    },
    radius() {
      return (this.currentSize / 2) - (this.width / 2);
    },
    circumference() {
      return Math.PI * (this.radius * 2);
    },
    loadingProgress() {
      const progress = this.progressPoint / this.donePint;

      return (1 - progress) * this.circumference || 0;
    },
    loadingDetails() {
      return this.progressUnit ? this.progressUnit : `${this.progressPoint}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-progress-loader";
</style>