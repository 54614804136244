import { forecastVersionsMagicIds } from '@/config/demand/table.config';

export default function({ created, edited, deleted } = {}) {
  if (!created && !edited && !deleted) {
    return null;
  }

  const parsedCreated = changeForecastAdapter(created);
  const parsedEdited = changeForecastAdapter(edited);

  return {
    ...(created && { created: parsedCreated }),
    ...(edited && { edited: {[getKeyById(edited?.id)]: parsedEdited }}),
    ...(deleted && { deleted: deleted.id })
  };
}

function changeForecastAdapter(forecast) {
  if (!forecast) {
    return null;
  }

  const { roles: editorRoles, users: editorUsers } = splitUsersAndRoles(forecast.edit);
  const { roles: viewerRoles, users: viewerUsers } = splitUsersAndRoles(forecast.view);

  return {
    ...(forecast.id && { id: forecast.id }),
    name: forecast.name,
    type: forecast.type,
    viewers: {
      type: forecast.viewType,
      users: viewerUsers,
      roles: viewerRoles
    },
    editors: {
      type: forecast.editType,
      users: editorUsers,
      roles: editorRoles
    }
  };
}

function getKeyById(id) {
  return Object.values(forecastVersionsMagicIds).includes(id)
    ? id
    : forecastVersionsMagicIds.CUSTOM;
}

function splitUsersAndRoles(items) {
  return items.reduce((acc, item) => {
    if (item.profilePictureUrl) {
      acc.users.push(item.id);
    } else {
      acc.roles.push(item.id);
    }

    return acc;
  }, {
    roles: [],
    users: []
  });
}
