import i18n from '@/plugins/vue-i18n';

export const containerTypes = {
  CONTAINER: 'containers',
  GROUP: 'groups',
  MANUFACTURE_GROUP: 'manufacture_groups'
};

export const containerItemTypes = {
  ORDINARY: 'Imported',
  CUSTOM: 'Custom'
};

export const collectionCols = {
  CHECKBOX: 'enabled',
  IDENTIFIER: 'identifier',
  TYPE: 'type'
};

export const containersConfig = (ctx) => ([
  {
    label: ctx.$t('EditCollections.Ui.containersTab:at'),
    value: containerTypes.CONTAINER,
    uniqueKey: 'id'
  },
  {
    label: ctx.$t('EditCollections.Ui.groupsTab:at'),
    value: containerTypes.GROUP,
    uniqueKey: 'id'
  },
  {
    label: ctx.$t('EditCollections.Ui.mfgGroupsTab:at'),
    value: containerTypes.MANUFACTURE_GROUP,
    uniqueKey: 'id'
  }
]);

export const containersNames = {
  [containerTypes.CONTAINER]: i18n.t('Web.Collections.Containers.Container'),
  [containerTypes.GROUP]: i18n.t('Web.Collections.Groups.Group'),
  [containerTypes.MANUFACTURE_GROUP]: i18n.t('Web.Collections.ManufactureGroups.ManufactureGroup')
};