import Vue from 'vue';
import store from '@/store';
import { HttpError } from './HttpError';
import { errorRegistry } from './errorRegistry';
import statusCodes from '@/config/utils/statusCodes.config';
import { updateResponse, updateLastChangeStamps } from '@/api/helpers/updateResponse';
import { removeIdempotencyKey, setIdempotencyKey } from '@/api/helpers/idempotentRequests';

export const requestSuccess = async(config) => {
  setIdempotencyKey(config);

  config.headers['x-email'] = Vue.mapAuthState()?.authState?.user?.email;
  config.headers['X-pid'] = store.state.manageProjects.projectId || '';

  return config;
};

export const requestError = (error) => {
  return Promise.reject(error);
};

export const responseSuccess = async(response) => {
  if (response?.config?.raw) {
    return response;
  }

  if ([statusCodes.NOT_MODIFIED, statusCodes.OK].includes(response.status)) {
    removeIdempotencyKey(response.config);

    await updateLastChangeStamps(response);
    await updateResponse(response, response?.config?.method);

    // todo: refactor response structure with API change
    return response;
  }

  if (!response?.data) {
    throw new HttpError('API error. No data');
  }

  throw new HttpError('API error. Not OK');
};

export const responseError = (error) => errorRegistry.responseErrorHandler(error);
