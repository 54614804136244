<template>
  <div
    class="sl-table__cell sl-table__cell--header"
    :class="{
      'sl-table__cell--sortable': sortable,
      'sl-table__cell--resizable': resizable
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlTableCellHeader',
  props: {
    sortable: Boolean,
    resizable: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/sl-table-cell.scss";
@import "@/style/components/ui-kit/sl-table/sl-table-cell-header.scss";
</style>