import bc365Api from '@/api/connections/bc365';
import { connectionTypes } from '@/config/connection';

const types = {
  SET_ENVIRONMENTS: 'SET_ENVIRONMENTS',
  SET_COMPANIES: 'SET_COMPANIES',
  RESET_STATE: 'RESET_STATE'
};
const initialState = () => ({
  environments: null,
  companies: null
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_ENVIRONMENTS](state, environments) {
    state.environments = environments;
  },
  [types.SET_COMPANIES](state, value) {
    state.companies = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setEnvironments({ commit }, payload) {
    commit(types.SET_ENVIRONMENTS, payload);
  },
  setCompanies({ commit }, payload) {
    commit(types.SET_COMPANIES, payload);
  },
  fetchEnvironments({ rootGetters }, { cache } = {}) {
    const connectionData = rootGetters['connection/connectionDataByType'][connectionTypes.BUSINESS_CENTRAL];

    return bc365Api.getEnvironments({
      cacheId: cache ? connectionData.required.cacheId : undefined
    });
  },
  fetchCompaniesCloud({ rootGetters }, id) {
    const connectionData = rootGetters['connection/connectionDataByType'][connectionTypes.BUSINESS_CENTRAL];

    return bc365Api.getCompaniesCloud({
      cacheId: connectionData.required.cacheId,
      environmentId: id
    });
  },
  async fetchCompaniesOnPremises({ commit }, payload) {
    try {
      const response = await bc365Api.getCompaniesOnPremises(payload);
      const data = response?.data?.companies;

      if (!data) {
        return false;
      }

      const companies = data.map(company => ({
        name: company.name,
        id: company.id
      }));

      commit(types.SET_COMPANIES, companies);

      return true;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchCompaniesOnPremises' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
