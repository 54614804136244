import Vue from 'vue';
import settingsApi from '@/api/demand/settings';

const types = {
  SET_FORECAST_SETTINGS: 'SET_FORECAST_SETTINGS',
  SET_MODEL_SETTINGS: 'SET_MODEL_SETTINGS',
  SET_INVENTORY_SETTINGS: 'SET_INVENTORY_SETTINGS',
  SET_KPI_SETTINGS: 'SET_KPI_SETTINGS',
  SET_HOLIDAYS: 'SET_HOLIDAYS',
  RESET_CACHE_FLAGS: 'RESET_CACHE_FLAGS'
};

const state = () => ({
  forecast: null,
  model: null,
  inventory: null,
  kpi: null,
  holidays: null
});

const getters = {
  forecastSettings: state => {
    if (!state.forecast) {
      return {};
    }

    const { editable, useModelFrom, ...settings } = state.forecast;

    return settings;
  },
  isModelSeasonal: state => state.model?.modelCoeffs?.metadata?.isSeasonal,
  holidayOptions: (state) => {
    if (!state.holidays) {
      return [];
    }

    return state.holidays.map(holiday => {
      return {
        label: holiday,
        value: holiday,
        icon: 'calendar'
      };
    });
  }
};

const mutations = {
  [types.SET_FORECAST_SETTINGS](state, settings) {
    state.forecast = settings;
  },
  [types.SET_MODEL_SETTINGS](state, settings) {
    state.model = {
      ...settings,
      isCached: true
    };
  },
  [types.SET_INVENTORY_SETTINGS](state, settings) {
    state.inventory = {
      ...settings,
      isCached: true
    };
  },
  [types.SET_KPI_SETTINGS](state, settings) {
    state.kpi = {
      ...settings,
      isCached: true
    };
  },
  [types.SET_HOLIDAYS](state, value) {
    state.holidays = value;
  },
  [types.RESET_CACHE_FLAGS](state) {
    const { holidays, forecast, ...tabs } = state;

    Object.keys(tabs).forEach(key => {
      if (state[key]) {
        Vue.set(state[key], 'isCached', false);
      }
    });
  }
};

const actions = {
  async fetchForecastSettings({ commit, rootGetters }) {
    try {
      commit(types.RESET_CACHE_FLAGS);

      const response = await settingsApi.getForecastSettings({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });

      if (response?.data) {
        commit(types.SET_FORECAST_SETTINGS, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchForecastSettings' });
    }
  },
  async updateForecastSettings({ rootGetters }, body) {
    try {
      this.dispatch('demand/setIsNodeUpdating', true);

      await settingsApi.updateForecastSettings(
        {
          nodeId: rootGetters['demand/tree/activeNodeId'],
          allowForAvailableChildren: rootGetters['demand/tree/editableForSomeChildren']
        },
        body
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateForecastSettings' });
    } finally {
      this.dispatch('demand/setIsNodeUpdating', false);
    }
  },
  async fetchModelSettings({ state, commit, rootGetters }) {
    try {
      if (state.model?.isCached) {
        return;
      }

      const response = await settingsApi.getModelSettings({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });

      if (response?.data) {
        commit(types.SET_MODEL_SETTINGS, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchForecastSettings' });
    }
  },
  async updateModelSettings({ rootGetters }, { type, ...body }) {
    try {
      this.dispatch('demand/setIsNodeUpdating', true);

      await settingsApi.updateModelSettings(
        {
          nodeId: rootGetters['demand/tree/activeNodeId'],
          coeffType: type
        },
        body
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateModelSettings' });

      throw e;
    } finally {
      this.dispatch('demand/setIsNodeUpdating', false);
    }
  },
  async fetchInventorySettings({ state, commit, rootGetters }) {
    if (state.inventory?.isCached) {
      return;
    }

    const item = rootGetters['demand/tree/itemName'];
    const location = rootGetters['demand/tree/location'] || '';

    if (!item) {
      return;
    }

    try {
      const response = await settingsApi.getInventorySettings({
        item,
        location
      });

      if (response?.data) {
        commit(types.SET_INVENTORY_SETTINGS, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchInventorySettings' });
    }
  },
  async fetchKPISettings({ state, commit, rootGetters }) {
    try {
      if (state.kpi?.isCached) {
        return;
      }

      const response = await settingsApi.getKPISettings({
        nodeId: rootGetters['demand/tree/activeNodeId']
      });

      if (response?.data) {
        commit(types.SET_KPI_SETTINGS, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchKPISettings' });
    }
  },
  async fetchHolidays({ commit }) {
    try {
      const response = await settingsApi.getHolidays();
      const holidays = response?.data?.holidays;

      if (!holidays) {
        return;
      }

      commit(types.SET_HOLIDAYS, holidays);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchHolidays' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
