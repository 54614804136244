import { routeNames } from '@/config/router/router.config';

export const accessStateKeys = {
  EDITABLE: 'Editable',
  READ_ONLY: 'Read-only',
  NONE: 'None'
};

export const featureAccessKeys = {
  // server reserved
  manageUsers: 'manageUsers',
  projectSettings: 'configureProject',
  createPlannedOrders: 'exportOrders',
  editConnection: 'editConnection',
  exportTables: 'exportTables',
  remSubsRules: 'remSubsRules',
  updateConstrainedOrderingPlan: 'updateConstrainedOrderingPlan',
  accessSuppliers: 'accessSuppliers',
  updatePrj: 'updatePrj',
  bomAccess: 'bomAccess',
  approveForecast: 'approveForecast',
  manageForecastVersions: 'manageForecastVersions',
  salesData: 'salesData',
  purchasingData: 'purchasingData',
  // reduce from other modules
  demandSettings: 'demandSettings',
  demandNode: 'demandNode',
  planningItems: 'planningItems',
  intersiteOptimization: 'intersiteOptimization'
};

export const noPlanningItemsSensitiveRoutes = [
  routeNames.DEMAND,
  routeNames.INVENTORY,
  routeNames.INTERSTORE,
  routeNames.REPORTS,
  routeNames.BATCHES_ON_HAND,
  routeNames.PROMOTIONS,
  routeNames.SUPPLIERS,
  routeNames.BILL_OF_MATERIALS,
  routeNames.INVENTORY_IN_TRANSITION,
  routeNames.INTERSTORE_IN_TRANSITION,
  routeNames.INVENTORY_PLANNED_ORDERS,
  routeNames.INTERSTORE_PLANNED_ORDERS,
  routeNames.CONSTRAINED_PLAN
];
