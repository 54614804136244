import TabsImportAdapter from '@/adapters/request/integrations/transform/shared/TabsImport.adapter';
import { DEFAULT_START_DATE } from '@/config/connection';
import { tabKeys } from '@/config/integrations/transform.config';

export default function(state) {
  return {
    startDate: state[tabKeys.SETTINGS].startDate || DEFAULT_START_DATE,
    tables: TabsImportAdapter(state)
  };
}
