import { isUserAdmin } from '@/mixins/frontegg';
import { routeNames } from '@/config/router/router.config';
import { projectRedirect } from '@/helpers/router';

export default function isAdmin({ next }) {
  const isAdmin = isUserAdmin();

  if (!isAdmin) {
    return projectRedirect({
      name: routeNames.PROJECT_LIST
    });
  }

  return next();
}
