<template>
  <div class="sl-uploader">
    <slot
      v-bind="{
        open: openUploader
      }"
    />

    <input
      v-show="false"
      ref="uploader"
      type="file"
      :accept="accepted"
      :multiple="multiple"
      @change="handleFileChanged"
    >
  </div>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { xlsxAccepted } from '@/config/utils/fileUpload.config';
import { bytesToReadableSize, MB100 } from '@/helpers/file/fileSize';

export default {
  name: 'SlUploader',
  mixins:[modal],
  props: {
    accepted: {
      type: String,
      default: xlsxAccepted
    },
    maxFileSize: {
      type: Number,
      default: MB100
    },
    multiple: Boolean,
    disabled: Boolean
  },
  methods: {
    openUploader() {
      this.$refs.uploader.click();
    },
    resetUploader() {
      if (!this.$refs.uploader) {
        return;
      }

      this.$refs.uploader.value = '';
    },
    validateFileSize({ size }) {
      if (size > this.maxFileSize) {
        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Error.Error'),
          text: this.$t('Web.File.MaxFileSize', {
            1: bytesToReadableSize(this.maxFileSize)
          })
        });

        return this.resetUploader();
      }

      return true;
    },
    async handleFileChanged(e) {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      const isValid = this.validateFileSize(file);

      if (!isValid) {
        return;
      }

      this.$emit('file-changed', file);
      this.resetUploader();
    }
  }
};
</script>