import { dataFields, tabKeys } from '@/config/connection/dbRelated.config';
import { connectionTypes } from '@/config/connection';
import TabsAdapter from '@/adapters/response/connection/dbRelated/shared/Tabs.adapter';
import { dbConnectionTypes } from '@/config/connection/database.config';

export default function(data, isDefault) {
  const { connection, queries, info, _startDate: startDate  } = data;
  const { commonSets, tabInfo, exportSets } = queries;
  const { importTabs, exportTabs } = TabsAdapter({
    importData: tabInfo,
    exportData: exportSets.query
  });

  const connectionData = {
    type: connection?._type || dbConnectionTypes.ODBC,
    sourceName: connection?.dataset || '',
    host: connection?.host || '',
    port: connection?.port || '',
    database: connection?.dataset || '',
    username: connection?.credentials?.user || '',
    password: '' // useless server send ***** str
  };

  return {
    ...(!isDefault && { cache_id: info._cacheId }),
    ...(!isDefault && { [dataFields.CONNECTION_DATA]: connectionData }),
    commonData: {
      startDate,
      type: connectionTypes.DATABASE
    },
    [tabKeys.REQUIRED]: {
      startFrom: +commonSets.start,
      groupBy: commonSets.groupBy
    },
    [tabKeys.OPTIONAL]: {
      includeZeroOnHand: info._cleanUnusedEntries === '1',
      combineLocations: info._combineLocs === '1',
      zeroPriceTransactions: info._zeroPriceTransactions === '1',
      zeroQtyTransactions: info._zeroQtyTransactions === '1',
      legacyExportMode: info._legacyExportMode === '1'
    },
    ...importTabs,
    [tabKeys.ORDERS_RECEIVE]: {
      ...importTabs[tabKeys.ORDERS_RECEIVE],
      purchase: commonSets.orderTypes?.purchase || '',
      transfer: commonSets.orderTypes?.transfer || '',
      manufacture: commonSets.orderTypes?.work || ''
    },
    ...exportTabs
  };
}
