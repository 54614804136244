import { integrationModuleTypes } from '@/config/integrations';

const importAdapter = ({ orderColumnNames, aggregationByDay }) => {
  return {
    ...orderColumnNames,
    aggregationByDay
  };
};

const exportAdapter = ({ subsidiary, employee }) => {
  return {
    subsidiary: subsidiary.id
      ? { _id: subsidiary.id, _name: subsidiary.name }
      : null,
    employee: employee.id
      ? { _id: employee.id, _name: employee.name }
      : null
  };
};

export default function(settings, integration) {
  const adapterByModule = {
    [integrationModuleTypes.IMPORT]: importAdapter,
    [integrationModuleTypes.EXPORT]: exportAdapter
  };

  return adapterByModule[integration.module](settings, integration);
}
