import { pingRequests } from '@/config/api/helperRequests.config';
import { uuId } from '@/helpers/shared/uuId';

const idempotencyKeys = new Map();

const generateRequestKey = (config) => {
  if (!config) {
    return null;
  }

  const { url, method, params, data } = config;

  return [url, method, params, data]
    .map(i => typeof i !== 'string' ? JSON.stringify(i) : i)
    .join('-');
};

export const setIdempotencyKey = (config) => {
  if (pingRequests.includes(config.url)) {
    return;
  }

  const requestId = generateRequestKey(config);
  const newKey = uuId();
  const localKey = idempotencyKeys.get(requestId);

  config.headers['Idempotency-Key'] = localKey || newKey;

  if (localKey) {
    return;
  }

  idempotencyKeys.set(requestId, newKey);
};

export const removeIdempotencyKey = (config) => {
  if (!config) {
    return;
  }

  const requestId = generateRequestKey(config);
  const responseKey = config.headers['Idempotency-Key'];
  const existingKey = idempotencyKeys.get(requestId);

  if (responseKey === existingKey) {
    idempotencyKeys.delete(requestId);
  }
};