import { accessStateKeys } from '@/config/shared/access.config';
import { routeNames } from '@/config/router/router.config';

export const usersTabsConfig = (ctx) => ([
  {
    label: ctx.$t('WiMultiuserTab.Ui.usersBtn'),
    value: routeNames.USERS,
    link: {
      to: {
        name: routeNames.USERS
      }
    }
  },
  {
    label: ctx.$t('WiMultiuserTab.Ui.rolesBtn'),
    value: routeNames.ROLES,
    link: {
      to: {
        name: routeNames.ROLES
      }
    }
  },
  {
    label: ctx.$t('WiMultiuserTab.Ui.groupsBtn'),
    value: routeNames.CATALOGS,
    link: {
      to: {
        name: routeNames.CATALOGS
      }
    }
  }
]);

export const roleSettingTypes = {
  TAB_RESTRICTION: 'tabAccess',
  FUNCTIONAL_RESTRICTION: 'features',
  DATA_RESTRICTION: 'dataRestrictions',
  DEPENDENT_THINGS: 'dependentThings'
};

const tabAccessOptions = (ctx) => ([
  {
    label: ctx.$t('Web.Users.EditAccess'),
    value: accessStateKeys.EDITABLE
  },
  {
    label: ctx.$t('Web.Users.ViewAccess'),
    value: accessStateKeys.READ_ONLY
  },
  {
    label: ctx.$t('Web.Users.NoAccess'),
    value: accessStateKeys.NONE
  }
]);

const restrictedTabAccessOptions = (ctx) => ([
  {
    label: ctx.$t('Web.Users.Access'),
    value: accessStateKeys.EDITABLE
  },
  {
    label: ctx.$t('Web.Users.NoAccess'),
    value: accessStateKeys.NONE
  }
]);

export const defaultRoleSettings = (ctx) => {
  const defaultTabAccessOptions = tabAccessOptions(ctx);
  const readOnlyTabAccessOptions = restrictedTabAccessOptions(ctx);

  return {
    main: {
      label: ctx.$t('Main.tabNames.main'),
      value: accessStateKeys.EDITABLE,
      options: defaultTabAccessOptions,
      type: roleSettingTypes.TAB_RESTRICTION
    },
    inventory: {
      label: ctx.$t('Main.tabNames.inventory'),
      value: accessStateKeys.EDITABLE,
      options: defaultTabAccessOptions,
      type: roleSettingTypes.TAB_RESTRICTION
    },
    optimization: {
      label: ctx.$t('Main.tabNames.transfers'),
      value: accessStateKeys.EDITABLE,
      options: defaultTabAccessOptions,
      type: roleSettingTypes.TAB_RESTRICTION
    },
    forecastReport: {
      label: ctx.$t('Main.tabNames.report'),
      value: accessStateKeys.EDITABLE,
      options: defaultTabAccessOptions,
      type: roleSettingTypes.TAB_RESTRICTION
    },
    dashboard: {
      label: ctx.$t('Main.tabNames.dashboard'),
      value: accessStateKeys.EDITABLE,
      options: defaultTabAccessOptions,
      type: roleSettingTypes.TAB_RESTRICTION
    },
    changes: {
      label: ctx.$t('Main.tabNames.changes'),
      value: accessStateKeys.NONE,
      options: readOnlyTabAccessOptions,
      type: roleSettingTypes.TAB_RESTRICTION
    },
    manageUsers: {
      value: false,
      label: ctx.$t('WiMultiuserTab.Ui.manageUsers'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    updatePrj: {
      value: true,
      label: ctx.$t('WiMultiuserTab.Ui.chkUpdateProject'),
      info: ctx.$t('Web.UpdateData.TimeLabel'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    updateConstrainedOrderingPlan: {
      value: false,
      label: ctx.$t('Web.ConstrainedPlan.RoleProp'),
      info: ctx.$t('Web.UpdateData.TimeLabel'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    editConnection: {
      value: false,
      label: ctx.$t('WiMultiuserTab.Ui.chkEditConnection'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    configureProject: {
      value: true,
      label: ctx.$t('WiMultiuserTab.Ui.chkPrjCfg'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    remSubsRules: {
      value: true,
      label: ctx.$t('WiMultiuserTab.Ui.chkRemSubstRules'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    exportOrders: {
      value: false,
      label: ctx.$t('WiMultiuserTab.Ui.chkExport'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    exportTables: {
      value: false,
      label: ctx.$t('WiMultiuserTab.Ui.chkExportTables'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    bomAccess: {
      value: false,
      label: ctx.$t('Main.Ui.menuBom_2'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    accessSuppliers: {
      value: false,
      label: ctx.$t('Web.Permissions.Suppliers'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    approveForecast: {
      value: false,
      label: ctx.$t('Web.Permissions.ApproveUnapproveAccess'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    manageForecastVersions: {
      value: false,
      label: ctx.$t('Web.CustomFields.manageCustomFields'),
      type: roleSettingTypes.FUNCTIONAL_RESTRICTION
    },
    salesData: {
      value: false,
      label: ctx.$t('WiMultiuserTab.Ui.chkHideSalesData'),
      info: ctx.$t('Web.Users.TooltipRestrictions'),
      type: roleSettingTypes.DATA_RESTRICTION
    },
    purchasingData: {
      value: false,
      label: ctx.$t('WiMultiuserTab.Ui.chkHidePurchasingData'),
      info: ctx.$t('Web.Users.TooltipRestrictions'),
      type: roleSettingTypes.DATA_RESTRICTION
    }
  };
};

export const catalogAccessTypes = {
  ITEMS: 'Items',
  LOCATIONS: 'Locations',
  CHANNELS: 'Channels'
};

export const warningMessageMap = {
  [catalogAccessTypes.ITEMS]: 'NoItem',
  [catalogAccessTypes.LOCATIONS]: 'NoLocation',
  [catalogAccessTypes.CHANNELS]: 'NoChannel'
};

export const actionsConfig = (ctx) => [
  {
    flag: 1 << 3,
    label: ctx.$t('Web.Users.CatalogAccess.InhUnassigned'),
    icon: 'copy',
    action: (scope) => ctx.handleChangeAccess(scope, 'Inherit')
  },
  {
    flag: 1 << 4,
    label: ctx.$t('Web.Users.CatalogAccess.InhEdit'),
    icon: 'copy',
    action: (scope) => ctx.handleChangeAccess(scope, 'Inherit')
  },
  {
    flag: 1 << 5,
    label: ctx.$t('Web.Users.CatalogAccess.InhView'),
    icon: 'copy',
    action: (scope) => ctx.handleChangeAccess(scope, 'Inherit')
  },
  {
    flag: 1 << 0,
    label: ctx.$t('Web.Users.CatalogAccess.Unassigned'),
    icon: 'minus',
    action: (scope) => ctx.handleChangeAccess(scope, 'None')
  },
  {
    flag: 1 << 1,
    label: ctx.$t('Web.Users.CatalogAccess.Edit'),
    icon: 'pen',
    action: (scope) => ctx.handleChangeAccess(scope, 'Edit')
  },
  {
    flag: 1 << 2,
    label: ctx.$t('Web.Users.CatalogAccess.View'),
    icon: 'eye',
    action: (scope) => ctx.handleChangeAccess(scope, 'View')
  }
];

export const classByFlag = {
  2: 'primary',
  4: 'accent-green',
  16: 'accent-yellow',
  32: 'accent-yellow'
};
