export const slErrorCodes = {
  'LICENCE_USERS_LIMIT_HIT': 'licence_users_limit_hit', // The number of users for the license has reached the limit.
  'INVALID_XML_DOCUMENT': 'invalid_xml_document', // The specified XML is not syntactically valid.
  'INVALID_XML_NODE_VALUE': 'invalid_xml_node_value', // The value provided for one of the XML nodes in the request body was not in correct format.
  'INSUFFICIENT_ACCOUNT_PERMISSIONS': 'insufficient_account_permissions', // The account does not have sufficient permissions to execute an operation.
  'PROJECT_IMPORTING': 'project_importing', // The project is importing.
  'MODULE_IMPORTING': 'module_importing', // The module is importing.
  'RESOURCE_NOT_FOUND': 'resource_not_found', // The specified resource does not exist.
  'INVALID_INPUT': 'invalid_input', // One of the request inputs is not valid.
  'INVALID_NUMBER': 'invalid_localized_number_format', // Invalid number\locale (sended 1.1.1 or 1 000 with en locale)
  'INVALID_NUMBER_LIMIT': 'number_limit_exceeded', // Invalid number limit. (sended 2^100)
  'REPEAT_EMAIL': 'repeating_email', // There are users in project with the same email.
  'IMPLICIT_USE_INACCESSIBLE_RESOURCE': 'implicit_use_inaccessible_resource',
  'NO_PROJECT': 'no_running_projects', // default error for requests on server with /status false response
  'PROJECT_NOT_FOUND': 'project_not_found', // /ping request for closed example
  'NO_ACCESS': 'user_has_no_access_to_project', // User has no access to the requested project
  'ABSENT_TREE_NODE': 'absent_tree_node', // This tree node is available, but absent.
  'NO_PROJECT_PERMISSION': 'no_manage_projects_permission', // User's role in Frontegg does not have sufficient permissions to manage projects.
  'NO_TOKEN_PERMISSION': 'no_create_tenant_token_permission', // User's role in Frontegg does not have sufficient permissions to activate the server.
  'NO_OPERATIONS_LOGS_PERMISSION': 'no_operations_logs_access', // User's role in Frontegg does not have sufficient permissions to access operations logs.
  'NO_WORKING_TENANT': 'no_working_tenant', // Server has no working tenant set.
  'INACCESSIBLE_PROJECT_TENANT': 'inaccessible_project_tenant', // Project was created not in the current server tenant.
  'CONNECTOR_NEEDS_AUTH': 'connector_needs_authorization', // Need oAuth
  'RESOURSE_ALREADY_EXISTS': 'resourse_already_exists', // The specified resource already exists.
  'LIMIT_REACHED': 'limit_reached', // Encountered limit restrictions when executing an operation.
  'RESOURCE_IS_NOT_EDITABLE': 'resource_is_not_editable', // The specified resource is not editable.
  'EXPORT_ISSUE': 'export_issue', // Export finished with some issues
  'IMPORT_NO_DATA': 'import_no_data', // No sales history has been imported
  'MIGRATION_FAILED': 'migration_error', // Migration failed, no user was migrated.
  'MIGRATION_PART_FAILED': 'migration_partially_failed', // Migration failed, some users were not migrated.
  'AUTH_FAILED': 'authorization_failed', // JWT parsing failed, wrong fe env.
  'DIFFERENT_TENANT': 'user_belong_to_foreign_tenant', // wrong tenant, not active tenant, etc.
  'REVENUE_LIMIT_HINT': 'revenue_limit_hit', //	Revenue limit has exceeded in the project.	403
  'INVALID_LANGUAGE_HEADER': 'invalid_accept_language_header', //	Unsupported language.	406
  'AUTHENTICATION_FAILED': 'authentication_failed', //	Server failed to authenticate user.	401
  'NOT_IMPLEMENTED': 'not_implemented', //	The server does not support the functionality required to fulfill the request.	501
  'METHOD_NOT_IMPLEMENTED': 'method_not_allowed', // The target resource doesn't support this HTTP method.	405
  'NOT_FOUND': 'UrlNotFound', // 404
  'TOO_MANY': 'too_many_requests', // Too many failed login requests per IP 429
  'INTERNAL_ERROR': 'internal_error', //	The server encountered an internal error. 500
  'INVALID_METADATA': 'invalid_tenant_metadata', // invalid or incomplete metadata. This has to be managed internally in fe-admin portal
  'FE_SET_TOKEN': 'failed_to_set_working_tenant', // error while server tried to connect with fe
  'NOT_ALLOWED_BY_LICENSE': 'not_allowed_by_license', //	Request is not allowed because of the issues with Streamline license.
  'OAUTH_TIMEOUT_ERROR': 'oauth_timeout_error', //	OAuth authorization timeout.
  'INV_ACTION_PARTIALLY_SUCCEEDED': 'inv_action_partially_succeeded', //	Inventory action partially succeeded.
  'USERS_SERVICE_REQUEST_ERROR': 'users_service_request_error', //	Error while requesting users service.
  'NOT_ALLOWED_BY_CURRENT_PLAN': 'not_allowed_by_current_plan', //	Current plan does not allow to perform this operation.
  'RECALCULATING_PLANNED_ORDERS_IS_IN_PROGRESS': 'recalculating_planned_orders_is_in_progress' // Recalculation of planned orders is in progress
};

export const noAccessErrorCodes = [
  slErrorCodes.INVALID_METADATA,
  slErrorCodes.NO_PROJECT_PERMISSION,
  slErrorCodes.FE_SET_TOKEN,
  slErrorCodes.AUTH_FAILED,
  slErrorCodes.DIFFERENT_TENANT,
  slErrorCodes.NOT_ALLOWED_BY_LICENSE,
  slErrorCodes.NO_PROJECT
];

export const serverBusyErrorCodes = [
  slErrorCodes.PROJECT_IMPORTING,
  slErrorCodes.MODULE_IMPORTING
];

export const thirdPartyServiceErrorCodes = [
  slErrorCodes.USERS_SERVICE_REQUEST_ERROR
];
