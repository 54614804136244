import { routeNames } from '@/config/router/router.config';

export const projectStatuses = {
  RUNNING: 'Running',
  STOPPED: 'Stopped',
  LOADING: 'Loading',
  UNINITIALIZED: 'Uninitialized'
};

export const runningStatuses = [
  projectStatuses.RUNNING,
  projectStatuses.UNINITIALIZED
];

export const projectTypes = {
  PERMANENT: 'Permanent',
  EXAMPLE: 'Example',
  TIME_MACHINE: 'TimeMachine',
  UNINITIALIZED: 'Uninitialized',
  BACKUP: 'Backup'
};

export const projectTabsConfig = (ctx) => ([
  {
    label: ctx.$t('Main.Ui.btStartPageProjects'),
    value: routeNames.PROJECT_LIST,
    link: {
      to: {
        name: routeNames.PROJECT_LIST
      }
    }
  },
  {
    label: ctx.$t('Web.Project.Label.Archive'),
    value: routeNames.ARCHIVE,
    link: {
      to: {
        name: routeNames.ARCHIVE
      }
    }
  },
  {
    label: ctx.$t('Main.Ui.btStartPageExamples'),
    value: routeNames.EXAMPLES,
    link: {
      to: {
        name: routeNames.EXAMPLES
      }
    }
  }
]);

export const projectTableConfig = (ctx) => ({
  permanent: {
    uniqueKey: 'id',
    headers: [
      {
        text: ctx.$t('Web.Table.Header.Name'),
        key: 'name'
      },
      {
        text: ctx.$t('Web.Table.Header.Status'),
        key: 'status'
      },
      {
        key: 'actionsFlags'
      },
      {
        key: 'progress'
      }
    ]
  },
  temporary: {
    uniqueKey: 'id',
    headers: [
      {
        text: ctx.$t('Web.Table.Header.Name'),
        key: 'name'
      },
      {
        key: 'status'
      },
      {
        key: 'type'
      },
      {
        key: 'actionsFlags'
      }
    ]
  },
  archived: {
    uniqueKey: 'id',
    headers: [
      {
        text: ctx.$t('Web.Table.Header.Name'),
        key: 'name'
      },
      {
        key: 'actionsFlags'
      }
    ]
  }
});

export const projectListTabsConfig = (ctx) => ([
  {
    label: ctx.$t('Web.Project.Label.Main'),
    value: 'main'
  },
  {
    label: ctx.$t('Web.Project.Label.Temp'),
    value: 'temp'
  }
]);

export const projectStatsLabelsConfig = (ctx) => ([
  {
    text: ctx.$t('Web.Project.Statistics.Import'),
    value: 'importInfo'
  },
  {
    text: ctx.$t('Web.Project.Statistics.Items'),
    value: 'nItems'
  },
  {
    text: ctx.$t('Web.Project.Statistics.Location'),
    value: 'nLocations'
  },
  {
    text: ctx.$t('Web.Project.Statistics.Channels'),
    value: 'nChannels'
  },
  {
    text: ctx.$t('Web.Project.Statistics.PlanningItems'),
    value: 'nPlanningItems'
  },
  {
    text: ctx.$t('Web.Project.Statistics.Models'),
    value: 'nModels'
  },
  {
    text: ctx.$t('Web.Project.Statistics.BaseCurrency'),
    value: 'baseCurrency'
  }
]);

export const projectActionTypes = {
  START: 'start',
  STOP: 'stop',
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
  DELETE: 'delete',
  RENAME: 'rename',
  DOWNLOAD: 'download',
  DOWNLOAD_PORTABLE: 'downloadPortable',
  BACKUPS: 'backups'
};

export const archiveActonTypes = {
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive'
};

export const projectCreationTypes = {
  SINGLE: 'single',
  MULTIPLE: 'multiple'
};

export const periodTypes = {
  WEEK: 'Week',
  MONTH: 'Month',
  UNSUPPORTED: 'Unsupported'
};
