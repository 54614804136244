export default function(slots = {}) {
  const matchedSlots = {};
  const slotsIndexes = {};

  Object.entries(slots).forEach(([slot, index]) => {
    if (index !== null) {
      const indexes = [].concat(index);

      indexes.forEach(index => matchedSlots[index] = slot);
      slotsIndexes[slot] = indexes;
    }
  });

  return {
    matchedSlots,
    slotsIndexes
  };
}