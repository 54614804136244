import router from '@/router';
import { editRoutes } from '@/config/connection';

export const getRouteName = () => {
  return router.currentRoute.name;
};

export const getIsEditingRoute = () => {
  return editRoutes.includes(getRouteName());
};
