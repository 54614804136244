<template>
  <div class="sl-table__cell">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlTableCell'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/sl-table-cell.scss";
</style>