import { toArray } from '@/helpers/utils/toArray';
import { sourceConnectDataFields } from '@/config/connection';

export default {
  desktop(data, { manual_filepath } = {}) {
    const {
      _importInactiveItems: importInactiveItems,
      _ignoreLocations: ignoreLocations,
      _importChannels: importChannels,
      _vendorsAsCategories: vendorsAsCategories,
      _categoryAsItem: categoryAsItem,
      _readPoClass: readPoClass,
      _includeEstimates: includeEstimates,
      _calculateLeadTime: calculateLeadTime,
      _fillMissingVendors: fillMissingVendors,
      customFieldAsCategory,
      customFieldAsItemInfo: rawCustomFieldAsItemInfo,
      poCustomDeliver,
      locations: rawLocations,
      soCustomShipmentDate
    } = data.settings.advanced;
    const { filepath } = data.settings;

    const locations = toArray(rawLocations.location);
    const customFieldAsItemInfo = toArray(rawCustomFieldAsItemInfo.customField);

    return {
      required: {},
      optional: {
        filepath: manual_filepath || filepath,
        importInactiveItems,
        ignoreLocations,
        importChannels,
        vendorsAsCategories,
        categoryAsItem,
        readPoClass,
        includeEstimates,
        calculateLeadTime,
        fillMissingVendors,
        customFieldAsCategory,
        customFieldAsItemInfo,
        poCustomDeliver,
        locations,
        soCustomShipmentDate
      }
    };
  },
  desktopConnection({ customFields, locations } = {}) {
    const allCustomFields = toArray(customFields).map((field) => {
      return {
        name: field,
        checked: false
      };
    });
    const allLocations = toArray(locations).map((location) => ({
      name: location,
      checked: true
    }));

    return {
      locations: allLocations,
      customFieldAsItemInfo: allCustomFields
    };
  },
  online(data) {
    const {
      info,
      payAccount,
      poCustomDeliver
    } = data.settings;
    const {
      _cacheId: cacheId,
      _includeInvoices: includeInvoices
    } = info;

    return {
      required: {
        payAccount
      },
      optional: {
        includeInvoices: includeInvoices === '1',
        poCustomDeliver
      },
      [sourceConnectDataFields.MAIN]: {
        cacheId
      }
    };
  }
};
