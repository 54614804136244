import TableSlotsAdapter from '@/adapters/response/integrations/transform/shared/TableSlots.adapter';

export default function(tables) {
  return Object.entries(tables).reduce((acc, [key, value]) => {
    if (value) {
      const { query, slots } = value;

      acc[key] = {
        query,
        ...TableSlotsAdapter(slots)
      };
    }

    return acc;
  }, {});
}
