import { tabKeys } from '@/config/connection/dbRelated.config';
import CommonSettingsAdapter from '@/adapters/response/connection/dbRelated/shared/CommonSettings.adapter';

export default function(sets, isDefault) {
  const { advanceSettings } = sets;

  const commonSettings = CommonSettingsAdapter(sets.innerSettings, isDefault);

  return {
    ...commonSettings,
    [tabKeys.EXPORT]: {
      ...commonSettings[tabKeys.EXPORT],
      bitness: advanceSettings._bitness,
      sqlVersion: advanceSettings._sqlVersion,
      proxyServerName: advanceSettings._proxyServerName
    },
    [tabKeys.OPTIONAL]: {
      ...commonSettings[tabKeys.OPTIONAL],
      exportPOinsteadTO: advanceSettings._exportPOinsteadTO === '1'
    }
  };
}
