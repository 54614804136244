<template>
  <div
    class="sl-accordion"
    :class="{
      'sl-accordion--disabled': disabled,
      'sl-accordion--inline': inline,
      'sl-accordion--full-height': fullHeight,
      [`sl-accordion--${variant}`]: variant
    }"
  >
    <div
      v-if="$scopedSlots.trigger"
      class="sl-accordion__trigger"
      :class="{
        'sl-accordion__trigger--open': isOpen
      }"
    >
      <slot
        name="trigger"
        v-bind="{
          isOpen,
          toggle: toggleIsOpen
        }"
      />
    </div>
    <transition-expand>
      <div
        v-show="isOpen"
        class="sl-accordion__content"
      >
        <div
          class="sl-accordion__content-inner"
          :style="{
            'padding-top': `${gap}px`
          }"
        >
          <slot />
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
export default {
  name: 'SlAccordion',
  props: {
    gap: {
      type: [Number, null],
      default: null
    },
    variant: {
      type: String,
      default: 'dark',
      validator: (v) => ['dark', 'light', 'small', 'action-list'].includes(v)
    },
    disabled: Boolean,
    inline: Boolean,
    fullHeight: Boolean,
    mountOpen: Boolean
  },
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {
    this.isOpen = this.mountOpen;
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-accordion';
</style>
