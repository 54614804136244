import netsuiteApi from '@/api/integrations/netsuite';
import { toArray } from '@/helpers/utils/toArray';
import { entities } from '@/config/connection/netsuite.config';
import { needCacheUpdate } from '@/helpers/shared/cache';

const types = {
  RESET_STATE: 'RESET_STATE',
  SET_ENTITY_CACHE_ID: 'SET_ENTITY_CACHE_ID',
  SET_ENTITY_LIST: 'SET_ENTITY_LIST'
};

const initialState = () => ({
  [`${entities.SUBSIDIARY}_cache_id`]: null,
  [`${entities.EMPLOYEE}_cache_id`]: null,
  [`${entities.SUBSIDIARY}_list`]: null,
  [`${entities.EMPLOYEE}_list`]: null
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_ENTITY_CACHE_ID](state, { entity, value }) {
    state[`${entity}_cache_id`] = value;
  },
  [types.SET_ENTITY_LIST](state, { entity, value }) {
    state[`${entity}_list`] = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setEntityList({ commit }, { entity, value }) {
    commit(types.SET_ENTITY_LIST, { entity, value });
  },
  async fetchEntities({ commit }, { entity, query }) {
    try {
      const response = await netsuiteApi.getEntity({
        entityCacheId: state[`${entity}_cache_id`].cacheId,
        top: 20,
        skip: 0,
        query
      });
      const entities = response?.data?.entities?.entity;

      commit(types.SET_ENTITY_LIST, {
        entity,
        value: toArray(entities)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchEntities' });
    }
  },
  async cacheEntity({ rootState, commit }, entity) {
    try {
      const needUpdate = needCacheUpdate(state[`${entity}_cache_id`]?.timestamp || 0);

      if (!needUpdate) {
        return;
      }

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => netsuiteApi.cacheEntity({
          id: rootState.integrations.active_integration.id,
          entity
        })
      });

      commit(types.SET_ENTITY_CACHE_ID, {
        entity,
        value: {
          cacheId: operationData.id,
          timestamp: Date.now()
        }
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'cacheEntity' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
