import { dataFields } from '@/config/connection/dbRelated.config';

export default function(state, shared) {
  const {
    dbServer: host,
    dataset,
    port,
    username: user,
    password
  } = state[dataFields.CONNECTION_DATA];
  const {
    fileId: _fileId,
    fileRemoved: _fileRemoved
  } = shared.optional;

  return {
    connection: {
      _type: 'mysql',
      host,
      dataset,
      credentials: {
        user,
        password
      },
      port
    },
    advanceSettings: {
      transactionsFile: {
        _fileId,
        _fileRemoved
      }
    }
  };
}