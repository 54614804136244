<template>
  <div
    v-if="isValidType"
    class="sl-notification"
    :class="{
      [item.type]: item.type
    }"
  >
    <div class="sl-notification__content">
      <icon
        data="@icons/close.svg"
        class="size-20 fill-off sl-notification__close"
        @click="close"
      />
      <div
        v-if="item.title"
        class="sl-notification__title body-1-md"
      >
        {{ item.title }}
      </div>
      <div
        v-if="item.text"
        class="sl-notification__text body-1"
      >
        {{ item.text }}
        <SlLink
          v-if="isBuiltInLink"
          class="sl-notification__details"
          @click.stop="item.data.callback"
        >
          {{ item.data.text }}
        </SlLink>
      </div>
      <SlLink
        v-if="hasLink && !isBuiltInLink"
        class="sl-notification__details"
        @click.stop="item.data.callback"
      >
        {{ $t('Web.Notification.BtnDetails') }}
      </SlLink>
    </div>
  </div>
</template>

<script>
import { notifyTypes, notifyLinkTypes } from '@/config/plugins/notify.config';

export default {
  name: 'SlNotification',
  props: {
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  computed: {
    isValidType() {
      return notifyTypes[this.item.type];
    },
    hasLink() {
      return this.item.data;
    },
    isBuiltInLink() {
      return this.hasLink && this.item.data.type === notifyLinkTypes.BUILTIN;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-notification/index.scss";
</style>
