import { render, staticRenderFns } from "./Periods.vue?vue&type=template&id=8a536724&scoped=true&"
import script from "./Periods.vue?vue&type=script&lang=js&"
export * from "./Periods.vue?vue&type=script&lang=js&"
import style0 from "./Periods.vue?vue&type=style&index=0&id=8a536724&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a536724",
  null
  
)

export default component.exports