export default {
  PROJECT_SETTINGS: 'project-settings-modal',
  FORECAST_VERSIONS_FORM: 'forecast-versions-form',
  FORECAST_VERSIONS_INFO: 'forecast-versions-info',
  CONFIRM: 'confirm-modal',
  CONFIRM_FORECAST_VERSIONS: 'confirm-modal::forecast_versions',
  NOTIFY: 'notify-modal',
  IN_TRANSITION: 'in-transition-modal',
  PLANNED_ORDERS_CONFIGURATION: 'planned-orders-configuration-modal',
  SPLIT_BETWEEN_SUPPLIERS: 'split-between-suppliers',
  CREATE_SUPPLIER: 'create-supplier',
  FILTER: 'filter-modal',
  CREATE_COLLECTION: 'create-collection-modal',
  EXPORT_REPORT_PROGRESS: 'export-report-progress',
  ADD_USER: 'add-user-modal',
  EDIT_USER: 'edit-user-modal',
  ADD_ROLE_PERMISSION: 'add-role-permission-modal',
  REMOVAL_SUBSTITUTION_RULES_CREATE_RULE: 'removal-substitution-rules-create-rule-modal',
  UPLOAD_PROJECT_FILE: 'upload-project-file',
  SEASON_PATTERN: 'season-pattern-modal',
  SAVE_AS_PATTERN: 'save-as-pattern-modal',
  SUBSTITUTE_AN_ITEM: 'substitute-an-item-modal',
  REMOVE_FROM_IMPORT: 'remove-from-import-modal',
  META_INFO_ISSUES: 'meta-info-issues-modal',
  FREEZE_SIMULATION: 'freeze-simulation',
  ADD_NOTE: 'add-note-modal',
  EXPLAIN_CALCULATION: 'explain-calculation-modal',
  NEW_CONNECTION: 'new-connection-modal',
  DATABASE_CONNECTOR: 'database-connector-modal',
  UNSAVED_CONFIRM: 'unsaved-confirm-modal',
  SPREADSHEET_CONNECTOR: 'spreadsheet-connector-modal',
  SPREADSHEET_UPLOAD: 'spreadsheet-upload-modal',
  SPREADSHEET_UPDATE_DATA: 'spreadsheet-update-data-modal',
  SYSTEM_DETAILS: 'system-details-modal',
  KEYBOARD_SHORTCUTS: 'keyboard-shortcuts-modal',
  LANGUAGE_UI: 'language-ui-modal',
  UPLOAD_FILE: 'upload-file',
  BC365_CONNECTOR: 'bc365-connector-modal',
  BC365_CONNECTION_TIMEOUT: 'connection-timeout-modal::bc365',
  BC365_CONNECTION_TIMEOUT_2: 'connection-timeout-modal-2::bc365',
  BC365_OAUTH_LOGIN: 'oauth-login-modal::bc365',
  BC365_OAUTH_LOGIN_2: 'oauth-login-modal-2::bc365',
  CREATE_IN_TRANSITION_ORDER: 'create-in-transition-order-modal',
  CONFIRM_IN_TRANSITION: 'confirm-modal::in-transition',
  ERRORS_NOTIFY: 'errors-notify',
  IN_TRANSITION_CONFIRM_EXPORT: 'in-transition-confirm-export',
  SL_CONFIRM_MODAL: 'sl-confirm-modal',
  SL_RENAME_MODAL: 'sl-rename-modal',
  SL_LOADING_MODAL: 'sl-loading-modal',
  SL_ERROR_MODAL: 'sl-error-modal',
  SL_UPLOAD_FILE: 'sl-upload-file',
  SL_TABLE_INFO_MODAL: 'sl-table-info-modal',
  UPGRADE_PLAN: 'upgrade-plan',
  NETSUITE_CONNECTOR: 'netsuite-connector-modal',
  SKUBANA_CONNECTOR: 'skubana-connector-modal',
  SKUBANA_CONNECTION_TIMEOUT: 'connection-timeout-modal::skubana',
  SKUBANA_OAUTH_LOGIN: 'oauth-login-modal::skubana',
  DYNAMICS_NAV_CONNECTOR: 'dynamics-nav-connector-modal',
  SPIRE_CONNECTOR: 'spire-connector-modal',
  QB_DESKTOP_CONNECTOR: 'qb_desktop-connector-modal',
  QB_DESKTOP_LOADER: 'qb_desktop-connector-loader',
  QB_DESKTOP_ERROR: 'qb_desktop-connector-error',
  QB_ONLINE_CONNECTOR: 'qb-online-connector-modal',
  QB_ONLINE_CONNECTION_TIMEOUT: 'connection-timeout-modal::qb-online',
  QB_ONLINE_OAUTH_LOGIN: 'oauth-login-modal::qb-online',
  DATASOURCE_CONNECTION: 'datasource-connection-modal',
  SAP_CONNECTOR: 'sap-connector-modal',
  CONNECTOR_ERROR: 'connector-error-modal',
  REPORT_ISSUE: 'report-issue-modal',
  FISHBOWL_CONNECTOR: 'fishbowl-connector-modal',
  DYNAMICS_GP_CONNECTOR: 'dynamics-gp-connector-modal',
  ODOO_CONNECTOR: 'odoo-connector-modal',
  EXTERNAL_CONFIRM: 'external-confirm-modal',
  PROJECT_STATS: 'project-stats-modal',
  CREATE_INTEGRATION: 'create-integration-modal',
  CREATE_PROJECT: 'create-project-modal',
  UPDATE_WITH_LOGS: 'update-with-logs-modal',
  SEASONALITY_PATTERNS: 'seasonality-patterns-modal',
  CREATE_DASHBOARD_WIDGET: 'create-dashboard-widget-modal',
  WIDGET_CHART_MODAL: 'widget-chart-modal',
  INCREASE_MAX_USERS: 'increase-max-users-modal',
  REPORT_FILTER: 'report-filter-modal',
  ANALYTICS_FILTER: 'analytics-filter-modal',
  SAFETY_STOCK_EDIT: 'safety-stock-edit-modal',
  DEMAND_CHART_PREVIEW: 'demand-chart-preview-modal'
};
