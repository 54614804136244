import bc365Api from '@/api/integrations/bc365';

const types = {
  SET_ENVIRONMENTS: 'SET_ENVIRONMENTS',
  SET_COMPANIES: 'SET_COMPANIES',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  environments: [],
  companies: []
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_ENVIRONMENTS](state, value) {
    state.environments = value;
  },
  [types.SET_COMPANIES](state, value) {
    state.companies = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setEnvironments({ commit }, payload) {
    commit(types.SET_ENVIRONMENTS, payload);
  },
  setCompanies({ commit }, payload) {
    commit(types.SET_COMPANIES, payload);
  },
  fetchEnvironments(_, id) {
    return bc365Api.getEnvironments({ id });
  },
  fetchCompanies(_, id) {
    return bc365Api.getCompanies({ id });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
