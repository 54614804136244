import { routeNames } from '@/config/router/router.config';

export const uiTabByRoute = {
  [routeNames.DEMAND]: 'Demand',
  [routeNames.INVENTORY]: 'Inventory',
  [routeNames.REPORTS]: 'Reports',
  [routeNames.INTERSTORE]: 'Intersite'
};

export const uiSettingsKeys = {
  COLUMN_PIN: 'pinnedColumns',
  COLUMNS_WIDTH: 'columnsWidth',
  SPECIFIC: 'specific',
  DEMAND_TABLE_HEIGHT: 'demandTableHeight',
  DEMAND_TREE_WIDTH: 'demandTreeWidth',
  DEMAND_SETTINGS_WIDTH: 'demandSettingsWidth'
};
