<template>
  <div
    class="sl-modal__footer"
    :class="{
      'sl-modal__footer--between': between
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlModalFooter',
  props: {
    between: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/sl-modal/sl-modal-footer";
</style>