import { substitutionRuleTypes } from '@/config/item/substitutions.config';
import { FilterObject } from '@/helpers/api/adapters';

export default function(data) {
  if (!data) {
    return null;
  }

  return {
    substitutionRuleType: substitutionRuleTypes.CUSTOM,
    ...FilterObject(data)
  };
}