import { mapState, mapGetters } from 'vuex';
import { featureAccessKeys, noPlanningItemsSensitiveRoutes } from '@/config/shared/access.config';
import { routeNames } from '@/config/router/router.config';

export const access = {
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId
    }),
    ...mapGetters({
      featureAccess: 'access/featureAccess',
      isRouteAvailable: 'access/isRouteAvailable',
      isRouteEditable: 'access/isRouteEditable',
      isSupportedPeriod: 'project/isSupportedPeriod'
    }),
    // temp solution rm after redesign
    $sl_hasProjectAccess() {
      return !!(this.projectId && this.isSupportedPeriod);
    },
    $sl_tabEditable() {
      return this.isRouteEditable(this.$sl_routeName);
    },
    $sl_features() {
      return featureAccessKeys;
    },
    $sl_routes() {
      return routeNames;
    },
    $sl_hasPlanningItemsPageAccess() {
      return this.$sl_hasAccess(this.$sl_features.planningItems) || !noPlanningItemsSensitiveRoutes.includes(this.$sl_routeName);
    }
  },
  methods: {
    $sl_hasAccess(accessKey) {
      return this.featureAccess[accessKey];
    },
    $sl_hasRouteAccess(route) {
      return this.isRouteAvailable(route);
    }
  }
};
