<template>
  <div
    :class="{
      'sl-date-picker--inline': inline
    }"
  >
    <div
      v-if="label"
      class="sl-date-picker__label body-1-md grey-80"
    >
      {{ label }}
    </div>
    <DatePicker
      ref="datePicker"
      v-model="vModel"
      :data-test-id="dataTestId"
      type="date"
      value-type="format"
      :format="valueFormat.date"
      :clearable="clearable"
      :disabled="disabled"
      class="sl-date-picker body-1 grey-90"
      @close="onSubmit"
    >
      <template #icon-calendar>
        <icon
          data="@icons/calendar.svg"
          class="fill-off size-16"
        />
      </template>
      <template #input>
        <input
          v-model="vModel"
          :data-test-id="dataTestId"
          :disabled="disabled"
          class="mx-input"
          :class="{
            'mx-input__invalid': isInvalid
          }"
          type="date"
          @keydown.enter.stop="onSubmit"
        >
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { valueFormat } from '@/config/shared/datePicker.config';
import { isValidDate } from '@/helpers/date/date';

export default {
  name: 'SlDatePicker',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    isInvalid: Boolean,
    disabled: Boolean,
    inline: Boolean
  },
  data() {
    return {
      valueFormat
    };
  },
  computed: {
    vModel: {
      get() {
        return this.formatValue(this.value);
      },
      set(value) {
        this.$emit('input', this.formatValue(value));
      }
    }
  },
  methods: {
    formatValue(value) {
      return isValidDate(value) ? value : null;
    },
    onSubmit() {
      this.$nextTick(() => this.$emit('submit'));
    },
    openPopup() {
      this.$refs.datePicker.openPopup();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-date-time-picker";
</style>
