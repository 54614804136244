export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const { 
    baseUrl: _baseUrl,
    clientId: _clientId,
    clientSecret: _clientSecret
  } = data.required;
  const { combineLocs: _combineLocs } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    oauthToken: {
      connection: {
        _type: 'none'
      }
    },
    credentials: {
      _baseUrl,
      _clientId,
      _clientSecret
    },
    info: {
      _startDate,
      _groupBy,
      _combineLocs
    }
  };
}
