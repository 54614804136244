<template>
  <transition name="fade-tab">
    <div
      v-if="value === tabValue"
      class="sl-tab-content"
      :class="{
        'sl-tab-content--full-height': fullHeight
      }"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SlTabContent',
  props: {
    value: {
      type: String,
      required: false
    },
    tabValue: {
      type: String,
      required: false
    },
    fullHeight: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-tabs/sl-tab-content.scss";
</style>