export const csvAccepted = '.csv';
export const xlsxAccepted = '.xlsx';
export const spreadsheetAccepted = '.xlsx, .xls, .csv';
export const gslAccepted = '.gsl';
export const pemAccepted = '.pem';

export const fileFormats = {
  csv: 'csv',
  xlsx: 'xlsx',
  spreadsheet: 'spreadsheet',
  gsl: 'gsl',
  pem: 'pem'
};

export const uploadConfigByFormat = {
  [fileFormats.csv]: {
    accepted: csvAccepted,
    validate: 'required|sl_file_type:.csv|sl_file_size',
    label: 'Web.File.CSVFormat'
  },
  [fileFormats.xlsx]: {
    accepted: xlsxAccepted,
    validate: 'required|sl_file_type:.xlsx|sl_file_size',
    label: 'Web.File.XLSXFormat'

  },
  [fileFormats.spreadsheet]: {
    accepted: spreadsheetAccepted,
    validate: 'required|sl_file_type:.xlsx,.xls,.csv|sl_file_size',
    label: 'Web.Spreadsheet.UploadAccepted'
  },
  [fileFormats.gsl]: {
    accepted: gslAccepted,
    validate: 'required|sl_file_type:.gsl',
    label: 'Web.File.GSLFormat'
  },
  [fileFormats.pem]: {
    accepted: pemAccepted,
    validate: 'required|sl_file_type:.pem',
    label: 'Web.File.PEMFormat'
  }
};