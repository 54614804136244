import { toArray } from '@/helpers/utils/toArray';
import { DEFAULT_INVENTORY_CELL_INDEX } from '@/config/report';

export default function(matchedCols = []) {
  const matchedSlots = {};
  const slotsIndexes = {};

  toArray(matchedCols).forEach(slot => {
    if (slot.index !== DEFAULT_INVENTORY_CELL_INDEX) {
      const indexes = toArray(slot.index).map(idx => +idx);

      indexes.forEach(index => matchedSlots[index] = slot._colMeaning);
      slotsIndexes[slot._colMeaning] = indexes;
    }
  });

  return {
    matchedSlots,
    slotsIndexes
  };
}