import { toArray } from '@/helpers/utils/toArray';

export default function ReportTable(data) {
  const { header, body, metadata } = data;

  return {
    header: {
      pinned: toArray(header.pinned),
      default: toArray(header.default)
    },
    body: {
      pinned: toArray(body.pinned),
      default: toArray(body.default)
    },
    metadata: {
      ...metadata,
      rowsMetadata: toArray(metadata.rowsMetadata)
    }
  };
}