import { toArray } from '@/helpers/utils/toArray';

export const findCurrentRowIndex = ({ table, rowId, key = '_id' }) => {
  return toArray(table)?.findIndex(item => `${item[key]}` === `${rowId}` && !item.deleted);
};

export const findMultipleRowIndexes = ({ table, rowIds, key = '_id' }) => {
  return table?.reduce((indexes, row, rowIndex) => {
    if (rowIds.includes(row[key])) {
      indexes.push(rowIndex);
    }

    return indexes;
  }, []);
};
