import { keyCodes } from '@/config/utils/statusCodes.config';

export const customDebounce = (func, wait, immediate) => {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;

    clearTimeout(timeout);

    timeout = setTimeout(function() {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);

    if (immediate && !timeout) {
      func.apply(context, args);
    }
  };
};

export const customThrottle = (callback, limit) => {
  let waiting = false;

  return function() {
    if (!waiting) {
      callback.apply(this, arguments);
      waiting = true;

      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  };
};

export const scrollEndListener = (element, callback, timeout = 100) => {
  let handle = null;

  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }

    handle = setTimeout(callback, timeout);
  };

  element.addEventListener('scroll', onScroll);

  return () => {
    element.removeEventListener('scroll', onScroll);
  };
};

export const preventDefault = (e) => {
  return e.preventDefault();
};

const isMac = () => {
  return navigator.platform.match('Mac');
};

export const getCtrl = (e) => {
  return isMac() ? e.metaKey : e.ctrlKey;
};

export const getDel = (e) => {
  return isMac() ? e.keyCode === keyCodes.backspace : e.keyCode === keyCodes.del;
};