import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';

const headerNames = {
  string: 'string',
  date: 'date',
  dateSegment: 'dateSegment'
};

const getDate = (date) => {
  if (!date) {
    return '';
  }

  if (date._precision === 'Day') {
    return dateByLocaleKey(date.__text, localeDateKeys.YMD);
  }

  return dateByLocaleKey(date.__text, localeDateKeys.YM);
};

export const getLabel = (item) => {
  if (!item) {
    return '';
  }

  if (item._headerName === headerNames.string && item.__text) {
    return item.__text;
  }

  if (item._headerName === headerNames.date) {
    return getDate(item.date);
  }

  if (item._headerName === headerNames.dateSegment) {
    return `${getDate(item.start)} - ${getDate(item.finish)}`;
  }

  return '';
};

export const getLabelFromBoolean = (value, ctx) => {
  return value ? ctx.$t('Substitutions.TriBool.T') : ctx.$t('Substitutions.TriBool.F');
};
