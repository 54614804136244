<template>
  <div
    :class="{
      [`sl-alert--${type}`]: type
    }"
    class="sl-alert"
  >
    <span class="sl-alert__icon">
      <slot name="icon" />
      <icon
        v-if="!$scopedSlots.icon"
        :data="getIcon()"
        class="fill-off size-20"
      />
    </span>
    <div 
      class="sl-alert__content"
      :class="{
        'sl-alert__content--column': column
      }"
    >
      <slot />
      <template v-if="!$scopedSlots.default">
        <div
          v-if="$scopedSlots.accent"
          class="sl-alert__accent"
        >
          <slot name="accent" />
        </div>
        <div
          v-else-if="accent"
          class="sl-alert__accent"
        >
          {{ accent }}
        </div>
        <div
          v-if="$scopedSlots.text"
          class="sl-alert__text"
        >
          <slot name="text" />
        </div>
        <div
          v-else-if="text"
          class="sl-alert__text"
        >
          {{ text }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { lineAlertIcons } from '@/config/shared/alert.config';

export default {
  name: 'SlAlertLine',
  props: {
    type: {
      type: String,
      default: 'info',
      validator: (t) => ['success', 'error', 'warn', 'info', 'info-common', 'info-passive'].includes(t)
    },
    accent: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    column: Boolean
  },
  computed: {
    icon() {
      return lineAlertIcons[this.type];
    }
  },
  methods: {
    getIcon() {
      return require(`@icons/${this.icon}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-alert/sl-alert-line.scss";
</style>
