<template>
  <SlContextMenu
    :id="id"
    ref="contextMenu"
    :options="options"
    @option-click="handleOptionClick"
  />
</template>

<script>
import { contextMenuKeys } from '@/config/shared/contextMenu.config';
import {
  clipboardTableActionsMap,
  clipboardTableOptions,
  editableKeysByRouteName
} from '@/config/shared/clipboardContextMenu.config';
import { copyToClipboard, readClipboardText } from '@/helpers/utils/clipboard';

export default {
  name: 'SlTableClipboardMenu',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: [],
      config: {}
    };
  },
  methods: {
    show({ event, context, options, showMiddleware, ...config }) {
      if (showMiddleware && !showMiddleware(context)) {
        return event.preventDefault();
      }

      this.options = options?.(context) || this.getFilteredClipboardOptions(context);
      this.config = config || {};

      this.$refs.contextMenu.show({ event, context });
    },
    handleOptionClick({ key, context }) {
      const { actionsMap, actions } = this.config;

      if (actionsMap && actions) {
        const action = actions[actionsMap[key]];

        if (action) {
          return action(context);
        }
      }

      const action = clipboardTableActionsMap[key];

      this[action] && this[action](context);
    },
    getFilteredClipboardOptions(context = null) {
      const options = clipboardTableOptions(this);

      if (!context) {
        return options;
      }

      const { cellKey, cellValue, text } = context;

      const isValueExist = typeof text !== 'object' && !!`${text}`;
      const editableKeys = editableKeysByRouteName[this.$sl_routeName] || [];

      return options.filter(({ key }) => {
        if (!editableKeys.length || !editableKeys.includes(cellKey)) {
          return isValueExist && key === contextMenuKeys.COPY;
        }

        if (cellValue?.auto && !cellValue?.val) {
          return [contextMenuKeys.COPY, contextMenuKeys.PASTE].includes(key);
        }

        if (!isValueExist) {
          return key === contextMenuKeys.PASTE;
        }

        return true;
      });
    },
    updateCell(...args) {
      this.config.updateCallback && this.config.updateCallback(...args);
    },
    copyCell({ text }) {
      copyToClipboard(text);
    },
    cutCell(slotScope) {
      this.copyCell(slotScope);

      this.updateCell({
        slotScope,
        val: null
      });
    },
    async pasteCell(slotScope) {
      const val = await readClipboardText();

      if (!val) {
        return;
      }

      this.updateCell({
        slotScope,
        val
      });
    },
    deleteCell(slotScope) {
      this.updateCell({
        slotScope,
        val: null
      });
    }
  }
};
</script>