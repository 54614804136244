import { keysByConnectionType, matchKeys, tabKeys } from '@/config/connection/spreadsheet.config';
import TableSlotsAdapter from '@/adapters/request/connection/shared/TableSlots.adapter';

export default function(state) {
  const tabs = keysByConnectionType[state.commonData.type];

  const tables = tabs.reduce((acc, tab) => {
    const matchedTab = state[tabKeys.MATCH_SHEETS][tab];
    const matchedFileId = matchedTab[matchKeys.FILE];
    const matchedSheet = matchedTab[matchKeys.SHEET];
    const matchedFile = state.files.find(file => file.fileId === matchedFileId);
    const isMatched = matchedFile && matchedSheet !== null;
    const matchedSlots = TableSlotsAdapter(state[tab]);

    acc[tab] = {
      _index: isMatched ? matchedSheet : '',
      ...(isMatched && { _fileName: matchedFile.name }),
      matchedCols: {
        _wasCopied: '1',
        _n: matchedSlots.length,
        col: matchedSlots
      }
    };

    return acc;
  }, {});
  const files = state.files.reduce((acc, file) => {
    if (!file.existing) {
      acc.push({ _fileId: file.fileId });
    }

    return acc;
  }, []);

  return {
    options: {
      _HeaderRowsCount: state[tabKeys.GENERAL].headerRowsCount,
      _csvDelimiter: state[tabKeys.GENERAL].csvDelimiter,
      _composeDate: state[tabKeys.GENERAL].composeDate,
      _stickLocations: state[tabKeys.GENERAL].uniteLocations,
      _zeroPriceTransactions: state[tabKeys.GENERAL].zeroPriceTransactions,
      _zeroQtyTransactions: state[tabKeys.GENERAL].zeroQtyTransactions,
      _groupBy: state[tabKeys.GENERAL].groupBy,
      _start: state[tabKeys.GENERAL].startFrom
    },
    files: {
      _n: files.length,
      file: files
    },
    tables
  };
}
