import Vue from 'vue';
import dbRelatedApi from '@/api/connections/dbRelated';
import connectionApi from '@/api/connections';
import odooApi from '@/api/connections/odoo';
import logger from '@/api/logger';
import ImportQueryAdapter from '@/adapters/request/connection/dbRelated/shared/ImportQuery.adapter';
import ExportQueryAdapter from '@/adapters/request/connection/dbRelated/shared/ExportQuery.adapter';
import DbRelatedCredentialsAdapter from '@/adapters/request/connection/dbRelated/DbRelatedCredentials.adapter';
import ConnectionSettingsAdapter from '@/adapters/response/connection/dbRelated';
import TablePreviewAdapter from '@/adapters/response/connection/shared/TablePreview.adapter';
import odooAdditionalCredentialsAdapter from '@/adapters/request/connection/dbRelated/odoo/AdditionalCredentials.adapter';
import vueI18n from '@/plugins/vue-i18n';
import {
  dataFields,
  tabKeys,
  importTabKeys,
  exportTabKeys
} from '@/config/connection/dbRelated.config';
import { connectionTypes, DEFAULT_GROUP_BY, DEFAULT_START_DATE } from '@/config/connection';
import cloneObject from '@/helpers/utils/cloneObject';
import { setColSlot } from '@/helpers/connection';
import { toArray } from '@/helpers/utils/toArray';
import { slotsReducer } from '@/helpers/integration/slotsReducer';

const types = {
  SET_CACHE_ID: 'SET_CACHE_ID',
  UPDATE_CONNECTION_DATA: 'UPDATE_CONNECTION_DATA',
  SET_CONNECTION_DATA: 'SET_CONNECTION_DATA',
  UPDATE_TAB_SETTINGS: 'UPDATE_TAB_SETTINGS',
  SET_TABLE_PREVIEW: 'SET_TABLE_PREVIEW',
  SET_TABLES_LIST: 'SET_TABLES_LIST',
  SET_MATCHED_SLOT: 'SET_MATCHED_SLOT',
  SET_SLOTS_INDEXES: 'SET_SLOTS_INDEXES',
  RESET_STATE: 'RESET_STATE',
  SET_CONNECTION_SETTINGS: 'SET_CONNECTION_SETTINGS',
  SET_TAB_SLOTS: 'SET_TAB_SLOTS',
  SET_IMPORT_PREVIEW: 'SET_IMPORT_PREVIEW'
};

const importTabsState = () => {
  return importTabKeys.reduce((acc, tab) => {
    acc[tab] = {
      query: '',
      importPreview: {},
      availableSlots: [],
      matchedSlots: {},
      slotsIndexes: {},
      stubs: tab !== tabKeys.TRANSACTIONS
        ? []
        : [{
          label: vueI18n.tc('Web.Netsuite.StubStaringDate'),
          value: ':startdate'
        }]
    };

    return acc;
  }, {});
};
const exportTabsState = () => {
  return exportTabKeys.reduce((acc, tab) => {
    acc[tab] = {
      query: '',
      stubs: []
    };

    return acc;
  }, {});
};

const initialState = () => ({
  cache_id: '',
  tables_list: [],
  [dataFields.CONNECTION_DATA]: {},
  [dataFields.CONNECTION_DATA_CACHE]: {},
  commonData: {
    type: '',
    startDate: DEFAULT_START_DATE
  },
  [tabKeys.REQUIRED]: {
    startFrom: 1,
    groupBy: DEFAULT_GROUP_BY,
    zeroPriceTransactions: true,
    zeroQtyTransactions: false
  },
  [tabKeys.EXPORT]: {},
  [tabKeys.OPTIONAL]: {},
  ...importTabsState(),
  ...exportTabsState(),
  table_preview: {},
  // initialize client side default values
  [tabKeys.PLANNED_ORDERS]: {
    orderQty: '1',
    reorderPoint: '0'
  },
  [tabKeys.MIN_MAX]: {
    reorderPoint: '1',
    maxInventory: '0'
  },
  [tabKeys.INVENTORY_REPORT]: {
    onHand: '0',
    supplyDays: '0',
    receiveQty: '0'
  },
  [tabKeys.INVENTORY_FORECAST]: {
    demandForecast: '0',
    orderingPlan: '0',
    inventoryProjection: '0'
  },
  [tabKeys.REPORTS]: {
    demandForecast: '0',
    projectedRevenue: '0'
  }
});

const state = initialState();

const getters = {
  importDataByTab: (state) => (tab) => state[tab],
  uniqueConnectorParam: (_, __, rootState) => {
    const selected = rootState.connection.selectedConnector;

    return [connectionTypes.DATABASE, connectionTypes.NETSUITE].includes(selected)
      ? selected
      : connectionTypes.DATABASE;
  },
  serverName: (state) => {
    const { dbServer, sourceName, host, port } = state[dataFields.CONNECTION_DATA];

    // odbc db connection
    if (sourceName) {
      return sourceName
        ?.split(';')
        ?.find(param => param.toLowerCase().includes('server='))
        ?.split('=')?.[1];
    }

    // mysql db or db inside connection
    if (host && port) {
      return `${host}:${port}`;
    }

    // default db inside connection
    return dbServer || '';
  },
  isTabHasSlots: (state) => (tab) => Object.values(state[tab].matchedSlots).some(Boolean)
};

const mutations = {
  [types.SET_CACHE_ID](state, value) {
    state.cache_id = value;
  },
  [types.UPDATE_CONNECTION_DATA](state, { dataKey, key, value }) {
    Vue.set(state[dataKey], key, value);
  },
  [types.SET_CONNECTION_DATA](state, { field, value }) {
    state[field] = cloneObject(value);
  },
  [types.SET_TABLES_LIST](state, value) {
    state.tables_list = value;
  },
  [types.UPDATE_TAB_SETTINGS](state, { tab, key, value }) {
    Vue.set(state[tab], key, value);
  },
  [types.SET_TABLE_PREVIEW](state, value) {
    state.table_preview = value;
  },
  [types.SET_MATCHED_SLOT](state, { tab, index, value }) {
    Vue.set(state[tab].matchedSlots, index, value);
  },
  [types.SET_SLOTS_INDEXES](state, { tab, slotKey, indexesList }) {
    Vue.set(state[tab].slotsIndexes, slotKey, indexesList);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.SET_CONNECTION_SETTINGS](state, value) {
    Object.keys(value).forEach(key => {
      if (typeof state[key] !== 'object') {
        state[key] = value[key];

        return;
      }

      if (state[key] !== undefined) {
        Vue.set(state, key, {
          ...state[key],
          ...value[key]
        });
      }
    });
  },
  [types.SET_IMPORT_PREVIEW](state, { tab, value }) {
    Vue.set(state[tab], 'importPreview', {
      ...state[tab].importPreview,
      ...value
    });
  },
  [types.SET_TAB_SLOTS](state, { tab, slots }) {
    Vue.set(state[tab], 'availableSlots', slots);
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  updateLocalConnectionData({ commit }, payload) {
    commit(types.UPDATE_CONNECTION_DATA, payload);
  },
  setConnectionData({ commit }, payload) {
    commit(types.SET_CONNECTION_DATA, payload);
  },
  async setSQLQuery({ state, getters, commit }, payload) {
    try {
      const response = await dbRelatedApi.updateCache(
        {
          connector: getters.uniqueConnectorParam,
          cacheId: state.cache_id
        },
        ImportQueryAdapter(state, payload)
      );

      const data = response?.data;

      if (data) {
        commit(types.SET_CACHE_ID, data.id);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setSQLQuery' });
    }
  },
  async fetchSlotsList({ getters, commit }, tab) {
    try {
      const response = await dbRelatedApi.getSlotsList({
        connector: getters.uniqueConnectorParam,
        tab
      });

      const slots = response?.data;

      if (!slots) {
        return;
      }

      commit(types.SET_TAB_SLOTS, {
        tab,
        slots
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSlotsList' });
    }
  },
  updateTabSettings({ commit }, payload) {
    commit(types.UPDATE_TAB_SETTINGS, payload);
  },
  setColSlot(context, payload) {
    return setColSlot(types, context, payload);
  },
  fetchTablePreview({ dispatch }, payload) {
    if (!payload.table) {
      return dispatch('fetchMainTablePreview', payload);
    }

    return dispatch('fetchTempTablePreview', payload);
  },
  async fetchMainTablePreview({ state, getters, dispatch }, payload) {
    await dispatch('setSQLQuery', payload);

    return dbRelatedApi.getTablePreview({
      connector: getters.uniqueConnectorParam,
      autodetection: !getters.isTabHasSlots(payload.tab),
      id: state.cache_id
    });
  },
  fetchTempTablePreview({ state }, { table }) {
    return dbRelatedApi.getTableTempPreview({
      id: state.cache_id,
      table
    });
  },
  setTablePreviewState({ commit }, { table, value, tab }) {
    if (table) {
      return commit(types.SET_TABLE_PREVIEW, value);
    }

    commit(types.SET_IMPORT_PREVIEW, {
      tab,
      value
    });
  },
  resetUnusedSlots({ state, dispatch }, { tab, headers }) {
    if (!headers?.length) {
      return;
    }

    const { matchedSlots, slotsIndexes } = slotsReducer({
      tabData: state[tab],
      headers
    });

    dispatch('updateTabSettings', {
      tab,
      key: 'matchedSlots',
      value: matchedSlots
    });

    dispatch('updateTabSettings', {
      tab,
      key: 'slotsIndexes',
      value: slotsIndexes
    });
  },
  async setTablePreview({ commit, getters, dispatch }, { table, tab, isSql }) {
    const { matchedSlots, slotsIndexes, ...tableData } = TablePreviewAdapter(table);

    if (!isSql) {
      return commit(types.SET_TABLE_PREVIEW, {
        ...tableData,
        isLoading: false,
        isLoaded: true
      });
    }

    commit(types.SET_IMPORT_PREVIEW, {
      tab,
      value: {
        ...tableData,
        isLoading: false,
        isLoaded: true
      }
    });

    if (!getters.isTabHasSlots(tab)) {
      dispatch('updateTabSettings', {
        tab,
        key: 'matchedSlots',
        value: matchedSlots || {}
      });

      dispatch('updateTabSettings', {
        tab,
        key: 'slotsIndexes',
        value: slotsIndexes || {}
      });

      return;
    }

    dispatch('resetUnusedSlots', {
      tab,
      headers: tableData.headers
    });
  },
  async exportToCSV({ state, getters, dispatch }, tab) {
    await dispatch('setSQLQuery', tab);

    return dbRelatedApi.exportToCsv({
      connector: getters.uniqueConnectorParam,
      id: state.cache_id
    });
  },
  async downloadCsv({ state }, payload = {}) {
    return dbRelatedApi.downloadCsv({
      cacheId: state.cache_id,
      connector: connectionTypes.DATABASE,
      ...payload
    });
  },
  async fetchExportStubs({ dispatch }, tab) {
    try {
      const response = await dbRelatedApi.getExportStubs({
        tab
      });

      const data = response?.data?.stubs;

      if (data) {
        dispatch('updateTabSettings', {
          tab,
          key: 'stubs',
          value: data.map(stub => ({
            label: stub.name,
            value: stub.value
          }))
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchExportStubs' });
    }
  },
  async testExport({ state }, tab) {
    const response = await dbRelatedApi.testExport(
      {
        report: tab,
        id: state.cache_id
      },
      ExportQueryAdapter(state, tab)
    );

    return response?.data?.successful;
  },
  setConnectionSettings({ commit }, payload) {
    commit(types.SET_CONNECTION_SETTINGS, payload);
  },
  async fetchDatabaseTables({ state, commit }, id = state.cache_id) {
    try {
      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => dbRelatedApi.getDatabaseTables({
          id
        })
      });

      if (operationData) {
        commit(types.SET_TABLES_LIST, toArray(operationData?.tables));

        return true;
      }
    } catch (e) {
      logger.formatAndWriteError({
        e,
        from: 'fetchDatabaseTables'
      });

      throw e;
    }
  },
  async testConnection({ state, commit, dispatch }, {
    connectionDataKey,
    isEdit = false,
    testCallback
  } = {}) {
    let cacheId = state.cache_id;

    if (!isEdit) {
      cacheId = await dispatch('setCredentials', connectionDataKey);
    }

    let connection = false;

    if (testCallback) {
      connection = await testCallback(cacheId);
    } else {
      connection = await dispatch('fetchDatabaseTables', cacheId);
    }

    if (connection && cacheId) {
      commit(types.SET_CACHE_ID, cacheId);
    }

    return connection;
  },
  async testOdooConnection({ state, dispatch }, payload) {
    try {
      const field = payload.connectionDataKey || dataFields.CONNECTION_DATA;

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => dispatch('testConnection', {
          ...payload,
          testCallback: (cacheId) => odooApi.checkModules(
            { cacheId },
            odooAdditionalCredentialsAdapter(state[field])
          )
        })
      });

      if (operationData.successful) {
        const connection = await dispatch('fetchDatabaseTables', state.cache_id);

        return connection;
      }

      return false;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'netsuite/testConnection' });

      throw e;
    }
  },
  async setCredentials({ state, rootState }, field = dataFields.CONNECTION_DATA) {
    try {
      const connector = rootState.connection.selectedConnector;

      const response = await dbRelatedApi.createCache(
        { connector },
        DbRelatedCredentialsAdapter(connector, state[field])
      );

      return response?.data?.id;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setCredentials' });
    }
  },
  async getDefaultSettings({ rootState, commit }) {
    try {
      const response = await connectionApi.getDefaultSettings({
        connector: rootState.connection.selectedConnector
      });
      const importData = response?.data?.importData;

      if (!importData) {
        return;
      }

      const settings = ConnectionSettingsAdapter(importData, true);

      commit(types.SET_CONNECTION_SETTINGS, settings);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getDefaultSettings' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
