import { integrationModuleTypes } from '@/config/integrations';

const importAdapter = ({ companyName, orderColumnNames}) => {
  return {
    companyName,
    ...orderColumnNames
  };
};

const exportAdapter = (settings) => {
  const {
    password,
    ...connectionSettings
  } = settings ?? {};

  return {
    ...connectionSettings,
    password: {
      isSet: password?.isSet ?? false,
      value: ''
    }
  };
};

export default function(settings, integration) {
  const adapterByModule = {
    [integrationModuleTypes.IMPORT]: importAdapter,
    [integrationModuleTypes.EXPORT]: exportAdapter
  };

  return adapterByModule[integration.module](settings, integration);
}
