<template>
  <div
    class="sl-badge"
    :class="{
      [`sl-badge--${type}`]: type,
      [`sl-badge--${variant}`]: variant,
      'sl-badge--disabled': disabled
    }"
  >
    <slot
      v-if="$scopedSlots.prepend"
      name="prepend"
    />
    <div
      v-if="$scopedSlots.text"
      class="sl-badge__text"
    >
      <slot name="text" />
    </div>
    <slot />
    <SlButton
      v-if="closable"
      v-tooltip.bottom="closeTooltip"
      variant="tertiary"
      size="xs"
      icon
      @click="$emit('close')"
    >
      <icon
        data="@icons/close.svg"
        class="fill-off size-16"
      />
    </SlButton>
  </div>
</template>

<script>
export default {
  name: 'SlBadge',
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (v) => ['default', 'primary', 'accent-red', 'accent-yellow', 'accent-green', 'accent-purple'].includes(v)
    },
    type: {
      type: String,
      default: 'info',
      validator: (v) => ['info', 'editable', 'action'].includes(v)
    },
    closeTooltip: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    closable: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-badge";
</style>