import { DEFAULT_GROUP_BY, groupBy as groupByKeys } from '@/config/connection';

export default function(data) {
  if (!data) {
    return {};
  }

  const { start, combineLocs } = data;
  const groupBy = data.groupBy ?? DEFAULT_GROUP_BY;

  return {
    groupBy,
    // handle server inconsistency | month = 1-31, week = 0-6
    start: groupBy === groupByKeys.MONTH && !start
      ? 1
      : start,
    combineLocs
  };
}