import statusCodes from 'http-status-codes';

export default {
  ...statusCodes,
  CF_WEB_SERVER_DOWN: 521,
  CF_TIMEOUT: 524
};

export const keyCodes = {
  backspace: 8,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  esc: 27,
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
  del: 46,
  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  i: 73,
  l: 76,
  m: 77,
  n: 78,
  p: 80,
  q: 81,
  s: 83,
  u: 85,
  y: 89,
  z: 90,
  cmd: 91
};
