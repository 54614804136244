<template>
  <transition name="fade">
    <div
      v-if="show"
      class="sl-table__loader"
    >
      <SlOverlay :show="show" />
      <v-skeleton-loader
        :type="`table-row-divider@${rowPerTable}`"
        :types="{
          'table-row': `table-cell@${cellPerRow}`
        }"
      />
    </div>
  </transition>
</template>

<script>
import SlOverlay from '@/components/UIKit/SlOverlay';

export default {
  name: 'SlTableLoader',
  components: {
    SlOverlay
  },
  props: {
    cellPerRow: {
      type: String,
      default: '8'
    },
    rowPerTable: {
      type: String,
      default: '12'
    },
    show: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/sl-table-loader";
</style>