<template>
  <div
    class="sl-alert"
    :class="{
      [`sl-alert--${type}`]: type
    }"
  >
    <span class="sl-alert__icon">
      <slot name="icon" />
      <icon
        v-if="!$scopedSlots.icon"
        :data="getIcon()"
        class="fill-off size-20"
      />
    </span>
    <div class="sl-alert__content">
      <slot />
      <template v-if="!$scopedSlots.default">
        <div
          v-if="$scopedSlots.accent"
          class="sl-alert__accent"
        >
          <slot name="accent" />
        </div>
        <div
          v-else-if="accent"
          class="sl-alert__accent"
        >
          {{ accent }}
        </div>
        <div
          v-if="$scopedSlots.text"
          class="sl-alert__text"
        >
          <slot name="text" />
        </div>
        <div
          v-else-if="text"
          class="sl-alert__text"
        >
          {{ text }}
        </div>
        <div
          v-if="$scopedSlots.link"
          class="sl-alert__link"
        >
          <slot name="link" />
        </div>
        <SlLink
          v-else-if="link"
          :href="link"
          target="_blank"
          class="sl-alert__link"
        >
          {{ $t('Web.Notification.LinkDetails') }}
        </SlLink>
      </template>
    </div>
    <icon
      v-if="closable"
      data="@icon/close.svg"
      class="size-16"
      @click.stop="$emit('close')"
    />
  </div>
</template>

<script>
import { alertIcons } from '@/config/shared/alert.config';

export default {
  name: 'SlAlert',
  props: {
    type: {
      type: String,
      default: 'warn',
      validator: (t) => ['success', 'error', 'warn', 'info', 'info-extra'].includes(t)
    },
    accent: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    closable: Boolean
  },
  computed: {
    icon() {
      return alertIcons[this.type];
    }
  },
  methods: {
    getIcon() {
      return require(`@icons/${this.icon}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-alert/index.scss";
</style>
