import { integrationModuleTypes } from '@/config/integrations';
import { getPasswordPayload } from '@/helpers/integration';

const importAdapter = ({ companyName, ...orderColumnNames }) => {
  return {
    companyName,
    orderColumnNames
  };
};

const exportAdapter = (settings) => {
  const {
    password,
    ...connectionSettings
  } = settings;

  return {
    ...connectionSettings,
    password: getPasswordPayload(password)
  };
};

export default function(settings, integration) {
  const adapterByModule = {
    [integrationModuleTypes.IMPORT]: importAdapter,
    [integrationModuleTypes.EXPORT]: exportAdapter
  };

  return adapterByModule[integration.module](settings, integration);
}
