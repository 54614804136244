export default function(data) {
  if (!data) {
    return {};
  }

  return {
    dbServer: data.dbServer,
    database: data.database,
    user: data.username || '',
    password: data.password || ''
  };
}