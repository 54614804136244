<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="accountId"
        mode="eager"
        rules="required|sl_url"
      >
        <SlInput
          v-model="baseUrl"
          :label="$t('Web.BC365.Form.URL')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="accountKey"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="clientId"
          :label="$t('Web.Exact.clientId')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        key="clientSecret"
        vid="password"
        rules="required"
      >
        <SlPasswordInput
          v-model="clientSecret"
          :label="$t('Web.Exact.clientSecret')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import Periods from '@/components/Connections/Common/Periods.vue';
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import SlValidate from '@/components/UIKit/SlValidate';

export default {
  name: 'ExactRequiredTab',
  components: {
    Periods,
    SlValidate
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.EXACT
    };
  },
  computed: {
    baseUrl: {
      get() {
        return this.connectionData.required.baseUrl;
      },
      set(value) {
        this.setRequiredValue({
          key: 'baseUrl',
          value
        });
      }
    },
    clientId: {
      get() {
        return this.connectionData.required.clientId;
      },
      set(value) {
        this.setRequiredValue({
          key: 'clientId',
          value
        });
      }
    },
    clientSecret: {
      get() {
        return this.connectionData.required.clientSecret;
      },
      set(value) {
        this.setRequiredValue({
          key: 'clientSecret',
          value
        });
      }
    }
  }
};
</script>
