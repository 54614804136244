<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        vid="username"
        mode="eager"
        rules="required"
      >
        <SlInput
          v-model="user"
          :label="$t('DbInsideSourceWidget.Labels.lbUsername')"
          :is-invalid="invalid"
          required
        />
      </SlValidate>
    </div>
    <div class="connector-content-block__row">
      <SlValidate
        v-slot="{ invalid }"
        key="password"
        vid="password"
        rules="required"
      >
        <SlPasswordInput
          v-model="password"
          :label="$t('DbImportDialog.Ui.lbPassword')"
          :is-invalid="invalid"
          required
        />
      </SlValidate>
    </div>

    <ConnectorContentBlock>
      <template #title>
        {{ $t('Web.BaseConnectors.Form.Endpoints') }}
      </template>
      <template>
        <div class="connector-content-block__row">
          <SlValidate
            v-slot="{ invalid }"
            vid="transactional"
            mode="eager"
            rules="required"
          >
            <SlInput
              v-model="transactional"
              :label="$t('DbImportDialog.Ui.tabMainQuery:at')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
        <div class="connector-content-block__row">
          <SlValidate
            v-slot="{ invalid }"
            vid="itemInfo"
            mode="eager"
            rules="required"
          >
            <SlInput
              v-model="itemInfo"
              :label="$t('DbImportDialog.Ui.tabItemQry:at')"
              :is-invalid="invalid"
              required
            />
          </SlValidate>
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="toShip"
            :label="$t('File.Backorders')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="toReceive"
            :label="$t('File.OnOrder')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="bom"
            :label="$t('EntireBom.Ui.:Caption')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="suppliers"
            :label="$t('Web.CustomApi.Input.Suppliers')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="exportPO"
            :label="$t('Web.BaseConnectors.Form.ExportPO')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="exportTO"
            :label="$t('Web.BaseConnectors.Form.ExportTO')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="exportMO"
            :label="$t('Web.BaseConnectors.Form.ExportMO')"
          />
        </div>
        <div class="connector-content-block__row">
          <SlInput
            v-model="pageLimit"
            :label="$t('Web.BaseConnectors.Form.MaxPageSize')"
          />
        </div>
      </template>
    </ConnectorContentBlock>
    <Periods
      :type="type"
      :is-new="isNew"
    />
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import Periods from '@/components/Connections/Common/Periods.vue';
import SlValidate from '@/components/UIKit/SlValidate';

export default {
  name: 'CustomRequiredTab',
  components: {
    ConnectorContentBlock,
    Periods,
    SlValidate
  },
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.CUSTOM_API
    };
  },
  computed: {
    user: {
      get() {
        return this.connectionData.required.user;
      },
      set(value) {
        this.setRequiredValue({
          key: 'user',
          value
        });
      }
    },
    password: {
      get() {
        return this.connectionData.required.password;
      },
      set(value) {
        this.setRequiredValue({
          key: 'password',
          value
        });
      }
    },
    transactional: {
      get() {
        return this.connectionData.required.transactional;
      },
      set(value) {
        this.setRequiredValue({
          key: 'transactional',
          value
        });
      }
    },
    itemInfo: {
      get() {
        return this.connectionData.required.itemInfo;
      },
      set(value) {
        this.setRequiredValue({
          key: 'itemInfo',
          value
        });
      }
    },
    toShip: {
      get() {
        return this.connectionData.required.toShip;
      },
      set(value) {
        this.setRequiredValue({
          key: 'toShip',
          value
        });
      }
    },
    toReceive: {
      get() {
        return this.connectionData.required.toReceive;
      },
      set(value) {
        this.setRequiredValue({
          key: 'toReceive',
          value
        });
      }
    },
    bom: {
      get() {
        return this.connectionData.required.bom;
      },
      set(value) {
        this.setRequiredValue({
          key: 'bom',
          value
        });
      }
    },
    suppliers: {
      get() {
        return this.connectionData.required.suppliers;
      },
      set(value) {
        this.setRequiredValue({
          key: 'suppliers',
          value
        });
      }
    },
    exportPO: {
      get() {
        return this.connectionData.required.exportPO;
      },
      set(value) {
        this.setRequiredValue({
          key: 'exportPO',
          value
        });
      }
    },
    exportTO: {
      get() {
        return this.connectionData.required.exportTO;
      },
      set(value) {
        this.setRequiredValue({
          key: 'exportTO',
          value
        });
      }
    },
    exportMO: {
      get() {
        return this.connectionData.required.exportMO;
      },
      set(value) {
        this.setRequiredValue({
          key: 'exportMO',
          value
        });
      }
    },
    pageLimit: {
      get() {
        return this.connectionData.required.pageLimit;
      },
      set(value) {
        this.setRequiredValue({
          key: 'pageLimit',
          value
        });
      }
    }
  }
};
</script>
