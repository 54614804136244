<template>
  <SubPageWrapper
    class="system-page-wrapper"
    :show-alerts="false"
  >
    <template #loader>
      <SlOverlay :show="loading">
        <SlLoader :text="false" />
      </SlOverlay>
    </template>
    <div class="system-page">
      <div class="system-page__logo">
        <icon
          data="@icons/logo.svg"
          class="size-logo"
        />
      </div>
      <transition name="fade">
        <div
          v-if="!loading"
          class="system-page__main"
        >
          <slot />
        </div>
      </transition>
    </div>
  </SubPageWrapper>
</template>

<script>
export default {
  name: 'SystemPageWrapper',
  props: {
    loading: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/page/system-page/system-page-wrapper.scss";
</style>