<template>
  <div class="connector-wrap">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        v-if="isStartDateDisabled"
        :label="$t('DbInsideImport.Ui.lbStartDate')"
        :value="formatDayMonthYear(startDate)"
      />
      <SlInfoItem
        :label="$t('DbInsideImport.Ui.lbGroupBy')"
        :value="groupByLabel"
      />
      <SlInfoItem
        v-if="isCustomApi && !isNew"
        :label="startFromKeyText"
        :value="startFromValueText"
      />
      <slot name="edit" />
    </div>
    <div
      v-if="!isStartDateDisabled"
      class="connector-content-block__row w-240"
    >
      <SlValidate
        v-slot="{ invalid }"
        vid="startDate"
        rules="required|sl_date_between|sl_valid_date"
      >
        <SlDatePicker
          v-model="startDate"
          :label="$t('DbInsideImport.Ui.lbStartDate')"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
    <div
      v-if="isNew"
      class="connector-content-block__row w-240"
    >
      <SlControl
        v-model="groupBy"
        :options="groupByOptions"
        :label="$t('DbInsideImport.Ui.lbGroupBy')"
        :description="$t('Web.DbImport.LabelDescrGroupBy')"
      >
        <template #icon>
          <SlInfoButton v-tooltip="getTooltip($t('Web.DbImport.TooltipGroupBy'))" />
        </template>
      </SlControl>
      <template v-if="isCustomApi && isNew">
        <SlValidate
          v-if="groupBy === groupByKeys.MONTH"
          v-slot="{ invalid }"
          key="startFromDay"
          :rules="{
            required: true,
            between: {
              min: 1,
              max: 31,
              message: $t('Web.Validation.InvalidValue')
            }
          }"
        >
          <SlControlInput
            v-model="startFrom"
            :min="1"
            :max="31"
            :label="$t('Web.DbImport.LabelStartFromDay')"
            :description="$t('Web.DbImport.LabelDescrStartFrom')"
            :is-invalid="invalid"
          />
        </SlValidate>
        <SlSelect
          v-else
          v-model="startFrom"
          :select-label="$t('Web.DbImport.LabelStartFrom')"
          :options="startFromOptions"
          :disabled="groupBy === groupByKeys.DAY"
          class="start-from-select"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { connection } from '@/mixins/connection';
import { connectionTypes, groupByOptions, groupBy as groupByKeys } from '@/config/connection';
import { weekDays } from '@/config/utils/dateInfo.config';
import { formatDayMonthYear } from '@/helpers/date/date';

export default {
  name: 'CommonPeriods',
  mixins: [connection],
  props: {
    type: {
      type: String,
      required: true
    },
    isNew: Boolean
  },
  data() {
    return {
      formatDayMonthYear,
      groupByKeys,
      startFromOptions: weekDays(this, 1)
    };
  },
  inject: [
    'getTooltip'
  ],
  computed: {
    isStartDateDisabled() {
      return !this.isNew && this.type === connectionTypes.PRONTO_XI;
    },
    isCustomApi() {
      return this.type === connectionTypes.CUSTOM_API;
    },
    groupByOptions() {
      return groupByOptions(this);
    },
    startDate: {
      get() {
        return this.connectionData.commonData?.startDate;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: 'commonData',
          key: 'startDate',
          value
        });
      }
    },
    groupBy: {
      get() {
        return this.connectionData.commonData?.groupBy;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: 'commonData',
          key: 'groupBy',
          value
        });

        this.startFrom = 1;
      }
    },
    startFrom: {
      get() {
        return this.connectionData.commonData?.startFrom ?? 1;
      },
      set(value) {
        this.setConnectorValueByKey({
          type: this.type,
          field: 'commonData',
          key: 'startFrom',
          value
        });
      }
    },
    groupByLabel() {
      return this.groupByOptions.find(option => option.value === this.groupBy)?.label
        || this.groupByOptions[0].label;
    },
    startFromKeyText() {
      return this.groupBy !== groupByKeys.MONTH
        ? this.$t('Web.DbImport.LabelStartFrom')
        : this.$t('Web.DbImport.LabelStartFromDay');
    },
    startFromValueText() {
      return this.groupBy !== groupByKeys.MONTH
        ? this.startFromOptions.find(item => +item.value === +this.startFrom)?.label
        : this.startFrom;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/style/components/settings/content-block";

.connector-content-block__row:last-child {
  display: flex;
  gap: 16px;

  .start-from-select {
    margin-top: auto;
  }
}
</style>
