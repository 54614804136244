<template>
  <div class="info-section">
    <div
      v-if="!$scopedSlots.label"
      class="body-1 grey-70"
    >
      {{ label }}
    </div>
    <slot
      v-else
      name="label"
    />
    <div
      v-if="!$scopedSlots.value"
      class="heading-4-sb"
    >
      {{ value }}
    </div>
    <slot
      v-else
      name="value"
    />
    <div v-if="$scopedSlots.append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoSection',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.info-section {
  display: grid;
  gap: 8px;
  align-self: start;
}
</style>
