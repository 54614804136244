import TabsImportAdapter from '@/adapters/response/integrations/transform/shared/TabsImport.adapter';
import TabsExportAdapter from '@/adapters/response/integrations/transform/shared/TabsExport.adapter';
import { tabKeys } from '@/config/integrations/transform.config';
import { integrationModuleTypes } from '@/config/integrations';

const importAdapter = ({ startDate, tables }) => {
  return {
    [tabKeys.SETTINGS]: {
      startDate
    },
    ...TabsImportAdapter(tables)
  };
};

const exportAdapter = ({ exportScripts, legacyExportMode, orderColumnNames }) => {
  return {
    [tabKeys.SETTINGS]: {
      legacyExportMode,
      ...orderColumnNames
    },
    ...TabsExportAdapter(exportScripts)
  };
};

export default function(settings, integration) {
  const adapterByModule = {
    [integrationModuleTypes.IMPORT]: importAdapter,
    [integrationModuleTypes.EXPORT]: exportAdapter
  };

  return adapterByModule[integration.module](settings, integration);
}
