<template>
  <SlNoData>
    <template #image>
      <icon
        data="@icons/style_no_search_results_double.svg"
        class="illustration-md size-48 fill-off"
      />
    </template>
    <template #title>
      {{ $t('Web.NoData.SearchTitle') }}
    </template>
    <template #text>
      {{ $t('Web.NoData.SearchText') }}
    </template>
  </SlNoData>
</template>

<script>
export default {
  name: 'SlNoResultsOption'
};
</script>