import axios from '@/plugins/axios';

export default {
  formatError(data = {}) {
    const error = {
      type: 'Client error',
      message: data.e,
      stack: data.e?.stack?.slice(0, 300)
    };

    return {
      ...data,
      e: error
    };
  },
  writeError(body) {
    return axios.post('/log_error', body, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  formatAndWriteError(data) {
    const isServerError = data.e?.data;

    // ignore server errors
    if (isServerError) {
      return;
    }

    return this.writeError(this.formatError(data));
  }
};
