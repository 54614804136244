var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-tab-list",class:{
    'sl-tab-list--horizontal': _vm.horizontal,
    'sl-tab-list--collapsed': _vm.localCollapsed
  },on:{"mouseenter":function($event){return _vm.toggleHover(true)},"mouseleave":function($event){return _vm.toggleHover(false)}}},[(_vm.collapsable)?_c('div',{staticClass:"sl-tab-list__header--collapsable"},[_c('SlButton',{staticClass:"sl-tab-list__toggle",attrs:{"variant":"tertiary","color":"grey","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleList($event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.toggleButton.tooltip),expression:"toggleButton.tooltip",modifiers:{"bottom":true}}],staticClass:"size-16 color-grey-70",attrs:{"data":_vm.toggleButton.icon}})]},proxy:true}],null,false,4057112341)},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.localCollapsed)?_c('div',{staticClass:"sl-tab-list__toggle-text"},[(_vm.$scopedSlots.collapseText)?_vm._t("collapseText"):[_vm._v(" "+_vm._s(_vm.$t('Web.Sidebar.TabsSideMenuCollapse'))+" ")]],2):_vm._e()])],1)],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.localCollapsed)?_c('div',[(_vm.$scopedSlots.header)?_c('div',{staticClass:"sl-tab-list__header body-4-md grey-60"},[_vm._t("header")],2):_vm._e(),_c('div',{staticClass:"sl-tab-list__tabs-wrapper",class:{
          'sl-tab-list__tabs-wrapper--scroll': _vm.hasHorizontalScrollbar,
          'sl-tab-list__tabs-wrapper--scrolled-right': _vm.hasHorizontalScrollbar && _vm.scrolledToRight,
          'sl-tab-list__tabs-wrapper--scrolled-left': _vm.hasHorizontalScrollbar && _vm.scrolledToLeft
        }},[(_vm.$scopedSlots['before-list'] || _vm.hasHorizontalScrollbar)?_c('div',{staticClass:"sl-tab-list__tabs-before"},[_vm._t("before-list"),(_vm.checkIsScrollArrowsVisible(_vm.scrolledToLeft))?_c('SlButton',{staticClass:"sl-tab-list__tabs-arrow sl-tab-list__tabs-arrow--left",attrs:{"variant":"tertiary","color":"grey","icon":""},on:{"click":_vm.handleScrollLeft}},[_c('icon',{staticClass:"fill-off size-20 color-grey-80",attrs:{"data":require("@icons/chevron_left.svg")}})],1):_vm._e()],2):_vm._e(),_c('div',{ref:"scrollContainer",staticClass:"sl-tab-list__tabs",on:{"wheel":_vm.handleListScroll,"&scroll":function($event){return _vm.updateScroll($event)}}},[_c(_vm.localDraggable ? 'draggable' : 'div',_vm._b({tag:"component",staticClass:"sl-tab-list__tabs-inner",model:{value:(_vm.tabsModel),callback:function ($$v) {_vm.tabsModel=$$v},expression:"tabsModel"}},'component',_vm.dragOptions,false),[_vm._l((_vm.tabs),function(tab,tabIndex){return [(_vm.$scopedSlots.tab)?_vm._t("tab",null,null,{
                  tab: tab,
                  tabIndex: tabIndex,
                  active: _vm.checkIsActive(tab),
                  disabled: tab.disabled || _vm.disabled,
                  select: function () { return _vm.handleSelectTab(tab); }
                }):_c('SlTab',{key:tab[_vm.trackBy],attrs:{"is-link":!!tab.link,"to":tab.link && tab.link.to,"active":_vm.checkIsActive(tab),"disabled":tab.disabled || _vm.disabled,"drag-disabled":tab.dragDisabled,"required":tab.required,"horizontal":_vm.horizontal},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleSelectTab(tab)}},scopedSlots:_vm._u([(tab.restricted)?{key:"append",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/upgrade-plan.svg")}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(tab.label)+" ")])]})],2)],1),(_vm.$scopedSlots['after-list'] || _vm.hasHorizontalScrollbar)?_c('div',{staticClass:"sl-tab-list__tabs-after"},[(_vm.checkIsScrollArrowsVisible(_vm.scrolledToRight))?_c('SlButton',{staticClass:"sl-tab-list__tabs-arrow sl-tab-list__tabs-arrow--right",attrs:{"variant":"tertiary","color":"grey","icon":""},on:{"click":_vm.handleScrollRight}},[_c('icon',{staticClass:"fill-off size-20 color-grey-80",attrs:{"data":require("@icons/chevron_right.svg")}})],1):_vm._e(),_vm._t("after-list")],2):_vm._e()]),(_vm.$scopedSlots.nestedList)?_c('div',{staticClass:"sl-tab-list__nested-list"},[_vm._t("nestedList",null,null,{
            collapsed: _vm.localCollapsed
          })],2):_vm._e(),(_vm.$scopedSlots.append)?_c('div',{staticClass:"sl-tab-list__append"},[_vm._t("append")],2):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }