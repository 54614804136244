import healthApi from '@/api/health';

const HEALTH_INTERVAL = 60000;

const types = {
  SET_INTERVAL: 'SET_INTERVAL'
};

const state = () => ({
  interval: null
});

const getters = {};

const mutations = {
  [types.SET_INTERVAL](state, value) {
    state.interval = value;
  }
};

const actions = {
  initHealthCheckInterval({ commit, dispatch }) {
    dispatch('clearHealthCheckInterval');

    const interval = setInterval(() => {
      dispatch('checkHealth');
    }, HEALTH_INTERVAL);

    commit(types.SET_INTERVAL, interval);
  },
  clearHealthCheckInterval({ state, commit }) {
    clearInterval(state.interval);
    commit(types.SET_INTERVAL, null);
  },
  checkHealth() {
    return healthApi.checkHealth();
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
