var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.$t('Web.Integrations.Modals.TitleConnection'),"loading":_vm.isLoading,"persistent":""},on:{"created":_vm.onCreated,"on-enter":_vm.handleCreate,"show":_vm.onModalShow,"hide":_vm.onModalHide},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('SlModalFooter',[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleCreate}},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Modals.BtnCreate'))+" ")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content create-integration",attrs:{"tag":"div"}},[_c('SlValidate',{attrs:{"vid":"name","rules":_vm.nameRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.Integrations.Modals.LabelName'),"is-invalid":invalid,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),(_vm.isImport)?_c('SlValidate',{attrs:{"vid":"import","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlErrorBoundary',{attrs:{"passive":""}},[_c('SlSelect',{attrs:{"select-label":_vm.$t('Web.Integrations.Modals.LabelDatasource'),"options":_vm.options,"track-by":"type","label":"name","is-invalid":invalid,"required":""},scopedSlots:_vm._u([{key:"selectOption",fn:function(ref){
var option = ref.option;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
                content: _vm.$t('Web.FreeVersion.AvailableIn'),
                disabled: option.availableForCurrentPlan === true
              })),expression:"getTooltip({\n                content: $t('Web.FreeVersion.AvailableIn'),\n                disabled: option.availableForCurrentPlan === true\n              })"}],staticClass:"datasource-select__option",class:{ 'datasource-select__option--disabled': option.availableForCurrentPlan === false }},[_vm._v(" "+_vm._s(option.name)+" "),(option.availableForCurrentPlan === false)?_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/upgrade-plan.svg")}}):_vm._e()],1)]}}],null,true),model:{value:(_vm.datasource),callback:function ($$v) {_vm.datasource=$$v},expression:"datasource"}})],1)]}}],null,false,2456913368)}):_c('SlValidate',{attrs:{"vid":"export","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return [_c('SlSelect',{attrs:{"select-label":_vm.$t('Web.Integrations.Modals.LabelDatasource'),"options":_vm.options,"group-values":"integrations","group-label":"group","track-by":"id","label":"name","is-invalid":invalid,"return-object":"","required":""},scopedSlots:_vm._u([{key:"selectOption",fn:function(ref){
              var option = ref.option;
              var selected = ref.selected;
return [_c('div',{staticClass:"datasource-select__option"},[(option.$groupLabel)?[_vm._v(" "+_vm._s(option.$groupLabel)+" ")]:(option.name)?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
                  content: _vm.$t('Web.FreeVersion.AvailableIn'),
                  disabled: option.availableForCurrentPlan === true
                })),expression:"getTooltip({\n                  content: $t('Web.FreeVersion.AvailableIn'),\n                  disabled: option.availableForCurrentPlan === true\n                })"}],staticClass:"datasource-select__option",class:{ 'datasource-select__option--disabled': option.availableForCurrentPlan === false }},[_vm._v(" "+_vm._s(option.name)+" "),(option.availableForCurrentPlan === false)?_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/upgrade-plan.svg")}}):_vm._e()],1)]:_vm._e(),(selected)?_c('icon',{staticClass:"fill-off size-16 color-primary-80 check-icon",attrs:{"data":require("@icons/check.svg")}}):_vm._e()],2)]}}],null,true),model:{value:(_vm.datasource),callback:function ($$v) {_vm.datasource=$$v},expression:"datasource"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }