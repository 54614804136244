import { dataFields } from '@/config/connection/dbRelated.config';

export default function(state) {
  const {
    companyName: _companyName
  } = state[dataFields.CONNECTION_DATA];

  return {
    advanceSettings: {
      _companyName
    }
  };
}