import axios from '@/plugins/axios';

export default {
  getLocations(params) {
    return axios.get('/bom/locations', {
      params
    });
  },
  getTree(params) {
    return axios.get('/bom/tree', {
      params
    });
  },
  exportTree(params) {
    return axios.get('/bom/tree/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  getTreeByNode(params) {
    return axios.get('/bom/tree/specific_node', {
      params
    });
  },
  getTreeSearch(params) {
    return axios.get('/bom/tree/search', {
      params
    });
  }
};