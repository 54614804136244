<template>
  <div class="sl-linear-loader">
    <div class="sl-linear-loader__title">
      <div class="sl-linear-loader__name">
        {{ label }}
      </div>
      <div class="sl-linear-loader__value">
        {{ value }}
      </div>
    </div>
    <div class="sl-linear-loader__bar">
      <div
        class="sl-linear-loader__line"
        :style="{ width }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlProgressLoader',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-linear-loader";
</style>
