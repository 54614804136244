import vueI18n from '@/plugins/vue-i18n';
import { getBenefitsList } from '@/config/shared/freeVersion.config';
import { getInventoryColorInfo } from '@/config/report/inventoryReport';
import { formatFullTime, utcTimezoneOffsetTime } from '@/helpers/date/date';

export const getErrorTooltip = (content) => {
  if (!content) {
    return null;
  }

  return {
    content: `
      <div class="error-tooltip">
        <svg class="error-tooltip__icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.125 8.125L11.875 11.875M11.875 8.125L8.125 11.875M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z" stroke="#697586" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="error-tooltip__text">${content}</div>
      </div>`,
    theme: 'error',
    html: true
  };
};

export const getInventoryInfoTooltip = (ctx) => {
  const hintList = getInventoryColorInfo(ctx);
  const hintListMarkup = hintList.reduce((acc, { type, text }) => {
    return acc + ` <li class="color-info__item">
        <i class="${type}"></i>
        —
        <span>${text}</span>
      </li>`;
  }, '');

  return {
    content: `
      <div class="color-info-tooltip">
        <div class="color-info-tooltip__title">${vueI18n.tc('Web.Report.ColorHintsLabel')}</div>
        <i class="color-info-tooltip__divider"></i>
        <ul class="color-info-tooltip__content">${hintListMarkup}</ul>
      </div>`,
    theme: 'light',
    triggers: ['hover'],
    placement: 'top',
    html: true
  };
};

export const getFreePlanTooltip = (ctx) => {
  const benefitsList = getBenefitsList(ctx);
  const benefitsListMarkup = benefitsList.reduce((acc, b) => acc + `<li>${b}</li>`, '');

  return {
    placement: 'bottom',
    html: true,
    theme: 'dark',
    content: `<span>${ctx.$t('Web.FreeVersion.PlanInclude')}</span>
    <ul style="list-style:disc; white-space: normal; padding-left: 18px !important">
      ${benefitsListMarkup}
    </ul>`
  };
};

export const getSplitTooltip = (data) => {
  if (!data) {
    return null;
  }

  const { title, body } = data;

  return {
    content: `
      <div class="split-tooltip">
        <div class="split-tooltip__title">${title}</div>
        <div class="split-tooltip--divider"></div>
        <div class="split-tooltip--message">${body}</div>
      </div>`,
    theme: 'dark',
    html: true
  };
};

const getErrorTemplate = (data) => {
  if (!data) {
    return null;
  }

  const { content } = data;

  return {
    ...data,
    content: `
      <div class="error-tooltip">
        <svg class="error-tooltip__icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.125 8.125L11.875 11.875M11.875 8.125L8.125 11.875M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z" stroke="#697586" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="error-tooltip__text">${content}</div>
      </div>`,
    theme: 'error',
    html: true
  };
};

export const getTotalsTemplate = (data) => {
  if (!data) {
    return null;
  }

  const { title, list } = data;

  return {
    ...data,
    content: `
      <div class="totals-tooltip">
        <div class="totals-tooltip__title grey-90">${title}</div>
        <div class="totals-tooltip--divider"></div>
        <div class="totals-tooltip__list">
          ${list.map(({ text, value }) => `
            <div class="totals-tooltip__item">
              <div class="totals-tooltip__item-text">${text}:</div>
              <div class="totals-tooltip__item-value">${value}</div>
            </div>
          `).join('')}
        </div>
      </div>`,
    triggers: ['hover'],
    theme: 'light',
    html: true
  };
};

export const getOrdersTemplate = (data) => {
  if (!data) {
    return null;
  }

  return {
    ...data,
    content: `<div class="orders-tooltip">${data.content}</div>`,
    theme: 'dark',
    html: true
  };
};

export const getNoteTemplate = (data) => {
  if (!data) {
    return '';
  }

  const { body, author, editTime } = data.content;

  return {
    ...data,
    content: `
      <div class="split-tooltip">
        <div class="split-tooltip__title">${author}</div>
        <div class="split-tooltip__title">${formatFullTime(editTime)} UTC ${utcTimezoneOffsetTime}</div>
        <div class="split-tooltip--divider"></div>
        <div class="split-tooltip--content multiline-ellipsis">${body.replace(/\n/g, '<br/>')}</div>
      </div>`,
    theme: 'dark',
    html: true
  };
};

const templateByType = {
  error: getErrorTemplate,
  totals: getTotalsTemplate,
  orders: getOrdersTemplate,
  note: getNoteTemplate
};

export const getTooltip = (data) => {
  if (!data) {
    return null;
  }

  if (typeof data === 'string') {
    return {
      theme: 'dark',
      content: data
    };
  }

  const { theme, type } = data;

  if (type) {
    return templateByType[type](data);
  }

  return {
    ...data,
    theme: theme || 'dark'
  };
};
