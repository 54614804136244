// resizer usage

// require col with :data-resize="index" attr

// this.initResize({
//   tableId: 'table-id',
//   colSelector: '.col-selector', // must be relative for resizer el
//   minWidth: min col width <number> default 0,
//   resizerHeight: height of resizer <string>(exmpl: '45px') if falsy then === table height
//   initialColSizes: (work ONLY if width pass to style prop) columns size on mount by col index <array>
// });

export const colResize = {
  data() {
    return {
      options: {
        tableId: null,
        colSelector: null,
        minWidth: 0,
        resizerHeight: null,
        activeResizerHeight: null,
        lazyResize: true,
        updateSizeCallback: null
      },
      scrollbarSize: 12,
      colSizes: [],
      currentInitAttempt: 0,
      initAttempts: 10
    };
  },
  methods: {
    _setResizerSize(col, val = '') {
      const resizer = col.querySelector('.resizer');

      if (!resizer) {
        return;
      }

      resizer.style.width = val;

      if (col.nextElementSibling) {
        const nextResizer = col.nextElementSibling.querySelector('.resizer');

        if (!nextResizer) {
          return;
        }

        nextResizer.style.width = val;
      }
    },
    _toggleSiblingCols(col, isOff) {
      const pointerEventsValue = isOff ? 'none' : 'auto';

      if (col.nextElementSibling) {
        col.nextElementSibling.style.pointerEvents = pointerEventsValue;
      }

      if (col.previousElementSibling) {
        col.previousElementSibling.style.pointerEvents = pointerEventsValue;
      }
    },
    _getWidthNumber(width) {
      return parseInt(width, 10);
    },
    _fillCollSizes() {
      for (let i = 0; i <= this.colSizes.length - 1; i++) {
        this.updateColSize(i, this.colSizes[i] ?? 0);
      }
    },
    _saveSize(col, size) {
      const colIndex = col.attributes['data-resize']?.value;

      if (colIndex !== undefined) {
        this.updateColSize(+colIndex, this._getWidthNumber(size));
        this._fillCollSizes();
      }
    },
    _createLazyResizer(resizer, isLast) {
      const lazyResizer = document.createElement('div');
      const lazyResizerHeader = document.createElement('div');

      lazyResizer.classList.add('resizer--lazy');
      lazyResizerHeader.classList.add('resizer--header');

      lazyResizer.style = `
            position: absolute;
            top: 0;
            bottom: 0;
            right: ${isLast ? '0' : '-1px'};
            z-index: 99;
            width: 3px;
            background: #B2CCFF;
            opacity: 0;
        `;

      // 40px - header height
      lazyResizerHeader.style = `
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 100;
            width: 3px;
            background: #004EEB;
        `;

      lazyResizer.appendChild(lazyResizerHeader);
      resizer.appendChild(lazyResizer);
    },
    _createResizableColumn(col, resizer, table) {
      const lazyResizer = resizer.querySelector('.resizer--lazy');
      let x = 0;
      let w = 0;

      const mouseDownHandler = (e) => {
        document.body.classList.add('resize--active');

        if (this.options.lazyResize) {
          // taken from scroll mixin (uikit table only)
          const height = this.options.activeResizerHeight || (this.hasHorizontalScrollbar
            ? table.scrollHeight - this.scrollbarSize
            : table.scrollHeight);

          resizer.style.height = `${height}px`;
          lazyResizer.style.opacity = '1';
        }

        this._setResizerSize(col, '100%');
        this._toggleSiblingCols(col, true);

        const styles = window.getComputedStyle(col);

        x = e.clientX;
        w = this._getWidthNumber(styles.width);

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - x;
        const currentWidth = w + dx;

        if (currentWidth >= this.options.minWidth) {
          if (this.options.lazyResize) {
            lazyResizer.style.right = `${dx * -1}px`;

            return;
          }

          this._saveSize(col, currentWidth);
        }
      };

      const mouseUpHandler = (e) => {
        document.body.classList.remove('resize--active');

        if (this.options.lazyResize) {
          const dx = e.clientX - x;
          const currentWidth = w + dx;

          if (currentWidth >= this.options.minWidth) {
            this._saveSize(col, currentWidth);
          } else {
            this._saveSize(col, this.options.minWidth);
          }

          lazyResizer.style.right = '0';
          lazyResizer.style.opacity = '0';
          resizer.style.height = this._getResizerHeight(col);
        }

        this._toggleSiblingCols(col, false);
        this._setResizerSize(col, '8px');

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    },
    _applyOptions(options) {
      Object.keys(this.options).forEach(key => {
        if (options[key] !== undefined) {
          this.options[key] = options[key];
        }
      });

      if (options.initialColSizes !== undefined && options.initialColSizes.length) {
        this.colSizes = [...options.initialColSizes];
      }
    },
    _getResizerHeight(col, fallback = '100%') {
      return this.options.resizerHeight || fallback;
    },
    initResize(options) {
      const table = document.getElementById(options.tableId);

      if (!table) {
        if (this.currentInitAttempt <= this.initAttempts) {
          ++this.currentInitAttempt;

          return setTimeout(() => this.initResize(options), 100);
        }

        return;
      }

      this.currentInitAttempt = 0;
      this._applyOptions(options);

      const cols = table.querySelectorAll(this.options.colSelector);

      cols.forEach((col, idx) => {
        if (col.querySelector('.resizer')) {
          return;
        }

        const resizer = document.createElement('div');
        const isLastCol = cols.length - 1 === idx;

        resizer.classList.add('resizer');

        resizer.style = `
            position: absolute;
            top: 0;
            right: 0;
            width: 8px;
            height: ${this._getResizerHeight(col, `${table.offsetHeight}px`)};
            cursor: col-resize;
            user-select: none;
        `;

        col.appendChild(resizer);

        if (this.options.lazyResize) {
          this._createLazyResizer(resizer, isLastCol);
        }

        this._createResizableColumn(col, resizer, table);
      });
    },
    updateColSize(index, width = 0) {
      if (this.colSizes[index] === width || !width) {
        return;
      }

      this.$set(this.colSizes, index, width);

      this.options.updateSizeCallback?.({
        index,
        width
      });
    }
  }
};
