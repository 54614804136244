export default {
  desktop(data) {
    const { startDate: _startDate, groupBy } = data.commonData;
    const {
      importInactiveItems: _importInactiveItems,
      ignoreLocations: _ignoreLocations,
      importChannels: _importChannels,
      vendorsAsCategories: _vendorsAsCategories,
      categoryAsItem: _categoryAsItem,
      readPoClass: _readPoClass,
      includeEstimates: _includeEstimates,
      calculateLeadTime: _calculateLeadTime,
      fillMissingVendors: _fillMissingVendors,
      customFieldAsCategory,
      customFieldAsItemInfo: rawCustomFieldAsItemInfo,
      poCustomDeliver,
      locations: rawLocations,
      soCustomShipmentDate,
      manual_filepath,
      filepath
    } = data.optional;

    const _groupBy = groupBy.toLowerCase();

    const locations = rawLocations?.reduce((acc, location) => {
      if (location.checked) {
        acc.push(location.name);
      }

      return acc;
    }, []);
    const customFieldAsItemInfo = rawCustomFieldAsItemInfo?.reduce((acc, field) => {
      if (field.checked) {
        acc.push(field.name);
      }

      return acc;
    }, []);

    return {
      filepath: manual_filepath || filepath,
      info: {
        _startDate,
        _groupBy
      },
      advanced: {
        _importInactiveItems: +_importInactiveItems || 0,
        _ignoreLocations: +_ignoreLocations || 0,
        _importChannels: +_importChannels || 0,
        _vendorsAsCategories: +_vendorsAsCategories || 0,
        _categoryAsItem: +_categoryAsItem || 0,
        _readPoClass: +_readPoClass || 0,
        _includeEstimates: +_includeEstimates || 0,
        _calculateLeadTime: +_calculateLeadTime || 0,
        _fillMissingVendors: +_fillMissingVendors || 0,
        poCustomDeliver: poCustomDeliver || '',
        soCustomShipmentDate: soCustomShipmentDate || '',
        customFieldAsCategory,
        customFieldAsItemInfo: {
          _n: customFieldAsItemInfo?.length || 0,
          customField: customFieldAsItemInfo
        },
        locations: {
          _n: locations?.length || 0,
          location: locations
        }
      }
    };
  },
  online(data, connect) {
    const { startDate: _startDate, groupBy } = data.commonData;
    const { payAccount } = data.required;
    const {
      poCustomDeliver,
      includeInvoices: _includeInvoices
    } = data.optional;
    const { cacheId: _cacheId } = connect;

    const _groupBy = groupBy.toLowerCase();

    return {
      info: {
        _startDate,
        _groupBy,
        _cacheId,
        _includeInvoices
      },
      payAccount: {
        _id: payAccount?.id,
        _name: payAccount?.name
      },
      poCustomDeliver
    };
  }
};
