export const FilterObject = (object, fallback = {}) => {
  if (!object) {
    return fallback;
  }

  return Object.keys(object).reduce((acc, key) => {
    if (object[key] || [false, 0].includes(object[key])) {
      acc[key] = object[key];
    }

    return acc;
  }, {});
};
