import { mapActions, mapGetters, mapState } from 'vuex';

export const connection = {
  computed: {
    ...mapState({
      connectionDataByType: (state) => state.connection.connectionData,
      selectedConnector: (state) => state.connection.selectedConnector
    }),
    ...mapGetters({
      sourceConnectDataByType: 'connection/sourceConnectDataByType',
      sourceConnectDataCacheByType: 'connection/sourceConnectDataCacheByType'
    }),
    connectionData() {
      return this.connectionDataByType[this.selectedConnector] || {};
    },
    transactionsFile() {
      return this.connectionData.optional?.file;
    }
  },
  methods: {
    ...mapActions('connection', [
      'setConnectorValueByKey',
      'setSourceConnectData',
      'setSourceConnectValueByKey',
      'uploadFile'
    ]),
    setOptionalValue(payload) {
      this.setConnectorValueByKey({
        type: this.selectedConnector,
        field: 'optional',
        ...payload
      });
    },
    setRequiredValue(payload) {
      this.setConnectorValueByKey({
        type: this.type,
        field: 'required',
        ...payload
      });
    },
    removeFile() {
      this.setOptionalValue({
        key: 'file',
        value: null
      });

      this.setOptionalValue({
        key: 'fileRemoved',
        value: true
      });
    },
    async uploadTransactionsFile(file) {
      await this.uploadFile(file);

      this.setOptionalValue({
        key: 'fileRemoved',
        value: false
      });
    }
  }
};