var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-accordion",class:( _obj = {
    'sl-accordion--disabled': _vm.disabled,
    'sl-accordion--inline': _vm.inline,
    'sl-accordion--full-height': _vm.fullHeight
  }, _obj[("sl-accordion--" + _vm.variant)] = _vm.variant, _obj )},[(_vm.$scopedSlots.trigger)?_c('div',{staticClass:"sl-accordion__trigger",class:{
      'sl-accordion__trigger--open': _vm.isOpen
    }},[_vm._t("trigger",null,null,{
        isOpen: _vm.isOpen,
        toggle: _vm.toggleIsOpen
      })],2):_vm._e(),_c('transition-expand',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"sl-accordion__content"},[_c('div',{staticClass:"sl-accordion__content-inner",style:({
          'padding-top': (_vm.gap + "px")
        })},[_vm._t("default")],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }