import axios from '@/plugins/axios';

export default {
  getTenantInfo() {
    return axios.get('/initialization/tenant-info', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  setTenantInfo() {
    return axios.post('/initialization/tenant-info', null, {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getProjectStatus() {
    return axios.get('/initialization/status', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getSubscriptionDetails() {
    return axios.get('/initialization/subscription', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getTenantUsers() {
    return axios.get('/initialization/tenant-users', {
      headers: {
        'X-pid-independent': true
      }
    });
  }
};
