export const filtersToNum = (filters) => {
  if (!filters) {
    return;
  }

  const { wantZeroOrders, showFutureOrders, recalculateOrderQuantity, orderItemsWithStockout } = filters;

  const numArray = [
    +wantZeroOrders.val,
    +showFutureOrders.val,
    +recalculateOrderQuantity.val,
    +orderItemsWithStockout.val
  ];

  const binaryString = numArray.reduce((str, item) => {
    str = +item + str;

    return str;
  }, '');

  if (!binaryString) {
    return;
  }

  return parseInt(binaryString, 2);
};
