<template>
  <div
    :class="['sl-group-dropdown-option', {
      'sl-group-dropdown-option--title': title,
      'sl-group-dropdown-option--divider': divider
    }]"
  >
    <div
      v-if="title"
      class="sl-group-dropdown-option__title body-1-sb grey-80"
    >
      <SlButton
        v-if="returnable"
        variant="tertiary"
        color="grey"
        size="xs"
        icon
        @click="$emit('back')"
      >
        <icon
          data="@icons/arrow_left.svg"
          class="fill-off size-16 color-grey-60"
        />
      </SlButton>

      {{ title }}
    </div>
    <div class="sl-group-dropdown-option__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlGroupDropdownOption',
  props: {
    title: {
      type: String,
      default: ''
    },
    returnable: Boolean,
    divider: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-group-dropdown/sl-group-dropdown-option';
</style>
