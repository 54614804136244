<template>
  <div class="connector-wrap connector-wrap--simple">
    <div class="connector-content-block__row">
      <SlAccordion :disabled="!locationOptions.length">
        <template #trigger="{ isOpen, toggle }">
          <div class="sl-accordion__trigger checkbox-item__trigger">
            <SlCheckbox
              v-if="isNew"
              :value="isAllSelected(types.LOCATIONS)"
              :indeterminate="isIndeterminate(types.LOCATIONS)"
              @input="(checked) => selectAll({ key: types.LOCATIONS, checked })"
            />
            <div
              class="checkbox-item__content"
              @click="toggle"
            >
              <div class="body-2 grey-90">
                {{ $t('Web.BaseConnectors.Form.LabelWarehouses') }}
              </div>
              <icon
                data="@icons/chevron_down.svg"
                class="fill-off size-20"
                :class="{
                  'rotate-180': isOpen
                }"
              />
            </div>
          </div>
        </template>
        <div
          v-for="location in locationOptions"
          :key="location._id"
          class="checkbox-item"
        >
          <SlCheckbox
            v-if="isNew"
            :value="isSelected({ key: types.LOCATIONS, item: location })"
            :label="location._name"
            @input="(checked) => selectItem({ key: types.LOCATIONS, checked, item: location })"
          />
          <div
            v-else
            class="checkbox-item__label body-2 grey-90"
          >
            {{ location._name }}
          </div>
        </div>
      </SlAccordion>
    </div>
    <div class="connector-content-block__row connector-content-block__row--combine">
      <SlCheckbox
        v-if="isNew"
        v-model="combineLocs"
        :label="$t('Web.BaseConnectors.Form.LabelCombineWarehouses')"
        :disabled="!locations.length"
      />
      <SlInfoItem
        v-else
        :label="$t('Web.BaseConnectors.Form.LabelCombineWarehouses')"
        :value="combineLocsLabel"
      />
    </div>
    <div class="connector-content-block__row">
      <SlAccordion :disabled="!channelOptions.length">
        <template #trigger="{ isOpen, toggle }">
          <div class="sl-accordion__trigger checkbox-item__trigger">
            <SlCheckbox
              v-if="isNew"
              :value="isAllSelected(types.CHANNELS)"
              :indeterminate="isIndeterminate(types.CHANNELS)"
              @input="(checked) => selectAll({ key: types.CHANNELS, checked })"
            />
            <div
              class="checkbox-item__content"
              @click="toggle"
            >
              <div class="body-1 grey-90">
                {{ $t('Web.BaseConnectors.Form.LabelChannels') }}
              </div>
              <icon
                data="@icons/chevron_down.svg"
                class="fill-off size-20"
                :class="{
                  'rotate-180': isOpen
                }"
              />
            </div>
          </div>
        </template>
        <div
          v-for="channel in channelOptions"
          :key="channel._id"
          class="checkbox-item"
        >
          <SlCheckbox
            v-if="isNew"
            :value="isSelected({ key: types.CHANNELS, item: channel })"
            :label="channel._name"
            @input="(checked) => selectItem({ key: types.CHANNELS, checked, item: channel })"
          />
          <div
            v-else
            class="checkbox-item__label body-2 grey-90"
          >
            {{ channel._name }}
          </div>
        </div>
      </SlAccordion>
    </div>
    <div class="connector-content-block__row connector-content-block__row--combine">
      <SlCheckbox
        v-if="isNew"
        v-model="combineChannels"
        :label="$t('Web.BaseConnectors.Form.LabelCombineChannels')"
        :disabled="!channels.length"
      />
      <SlInfoItem
        v-else
        :label="$t('Web.BaseConnectors.Form.LabelCombineChannels')"
        :value="combineChannelsLabel"
      />
    </div>
    <div class="connector-content-block__row">
      <SlCheckbox
        v-if="isNew"
        v-model="replacePaymentWithShipmentDate"
        :label="$t('Web.BaseConnectors.Form.LabelUseShipmentInsteadOfPayDate')"
      />
      <SlInfoItem
        v-else
        :label="$t('Web.BaseConnectors.Form.LabelUseShipmentInsteadOfPayDate')"
        :value="replacePaymentWithShipmentDateLabel"
      />
    </div>
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
    <div class="connector-content-block__row start w-240">
      <SlValidate
        v-slot="{ invalid }"
        vid="nMonthsToDropToReceive"
        mode="eager"
        rules="required|between:0,120"
      >
        <SlInput
          v-model="nMonthsToDropToReceive"
          :label="$t('Web.BaseConnectors.Form.nMonthsToDropToReceive')"
          :description="$t('Web.BaseConnectors.Form.nMonthsToDropToReceiveSubtitle')"
          type="number"
          step="1"
          :is-invalid="invalid"
        />
      </SlValidate>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'SkubanaOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      fileFormats,
      type: connectionTypes.SKUBANA,
      types: {
        LOCATIONS: 'locations',
        CHANNELS: 'channels'
      }
    };
  },
  inject: [
    'historicalTransactionsTemplate'
  ],
  computed: {
    ...mapState({
      locationOptions: state => state.skubana.locations || [],
      channelOptions: state => state.skubana.channels || []
    }),
    connectionData() {
      return this.connectionDataByType[this.type];
    },
    optionsByKey() {
      return {
        [this.types.LOCATIONS]: this.locationOptions,
        [this.types.CHANNELS]: this.channelOptions
      };
    },
    locations() {
      return toArray(this.connectionData.optional.locations);
    },
    channels() {
      return toArray(this.connectionData.optional.channels);
    },
    combineLocs: {
      get() {
        return this.connectionData.optional.combineLocs;
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    },
    combineChannels: {
      get() {
        return this.connectionData.optional.combineChannels;
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineChannels',
          value
        });
      }
    },
    combineChannelsLabel() {
      return getLabelFromBoolean(this.combineChannels, this);
    },
    replacePaymentWithShipmentDate: {
      get() {
        return this.connectionData.optional.replacePaymentWithShipmentDate;
      },
      set(value) {
        this.setOptionalValue({
          key: 'replacePaymentWithShipmentDate',
          value
        });
      }
    },
    replacePaymentWithShipmentDateLabel() {
      return getLabelFromBoolean(this.replacePaymentWithShipmentDate, this);
    },
    nMonthsToDropToReceive: {
      get() {
        return +this.connectionData.optional.nMonthsToDropToReceive || 0;
      },
      set(value) {
        this.setOptionalValue({
          key: 'nMonthsToDropToReceive',
          value
        });
      }
    }
  },
  methods: {
    isAllSelected(key) {
      return !!(this[key].length && this[key].length <= this.optionsByKey[key].length);
    },
    isIndeterminate(key) {
      return !!(this[key].length && this[key].length !== this.optionsByKey[key].length);
    },
    isSelected({ key, item }) {
      return this[key].some(selectedItem => selectedItem._id === item._id);
    },
    selectItem({ key, checked, item }) {
      if (checked) {
        return this.setOptionalValue({
          key,
          value: [...this[key], item]
        });
      }

      return this.setOptionalValue({
        key,
        value: this[key].filter(selectedItem => selectedItem._id !== item._id)
      });
    },
    selectAll({ key, checked }) {
      if (checked) {
        return this.setOptionalValue({
          key,
          value: [...this.optionsByKey[key]]
        });
      }

      return this.setOptionalValue({
        key,
        value: []
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.connector-content-block__row--combine {
  padding-left: 12px;
}

::v-deep {
  .checkbox-item__trigger {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex: 1;
    height: 100%;

    .checkbox-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      width: 100%;
      height: 100%;
    }
  }

  .checkbox-item {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
    padding-left: 24px;

    &__label {
      display: list-item;
      list-style-type: disc;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .connector-content-additional {
    margin: 4px 0;
  }
}
</style>
