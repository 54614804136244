import vueI18n from '@/plugins/vue-i18n';

export const LICENSE_EXPIRE_START_DAYS = 14;
export const UNLIM_USERS_COUNT = 999999;
export const SUPPORT_EMAIL = 'support@streamlineplan.com';

export const licenseStatuses = {
  OK: 'ok',
  OPEN_INVOICE: 'openInvoice',
  OPEN_INVOICE_EXPIRED: 'openInvoiceExpired',
  EXPIRE_SOON: 'willExpireSoon',
  GRACE_PERIOD: 'gracePeriod',
  EXPIRED: 'expired'
};

export const licenseInvoiceDetails = [licenseStatuses.OPEN_INVOICE];

export const licenseDetails = {
  [licenseStatuses.OK]: {
    getLabel: () => vueI18n.t('Web.LicenseDetails.ActiveStatus'),
    variant: 'accent-green'
  },
  [licenseStatuses.EXPIRED]: {
    getLabel: () => vueI18n.t('Web.LicenseDetails.ExpiredStatus'),
    variant: 'accent-red'
  },
  [licenseStatuses.GRACE_PERIOD]: {
    getLabel: (val) => vueI18n.t('Web.LicenseDetails.RemainStatus.val', {
      p1: vueI18n.tc('Web.LicenseDetails.RemainStatus.p1', val, { n: val })
    }),
    variant: 'accent-yellow'
  },
  [licenseStatuses.EXPIRE_SOON]: {
    getLabel: (val) => vueI18n.t('Web.LicenseDetails.RemainStatus.val', {
      p1: vueI18n.tc('Web.LicenseDetails.RemainStatus.p1', val, { n: val })
    }),
    variant: 'accent-yellow'
  },
  [licenseStatuses.OPEN_INVOICE]: {
    getLabel: () => vueI18n.t('Web.LicenseDetails.OpenInvoice'),
    variant: 'accent-yellow'
  },
  [licenseStatuses.OPEN_INVOICE_EXPIRED]: {
    getLabel: () => vueI18n.t('Web.LicenseDetails.OpenInvoice'),
    variant: 'accent-red'
  }
};

export const accountPlans = {
  FREE: 'free',
  ENTERPRISE: 'enterprise'
};
