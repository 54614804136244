<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.QB.Form.ignoreLocations')"
        :value="ignoreLocationsLabel"
      />
      <SlInfoItem
        :label="$t('Web.QB.Form.categoryAsItem')"
        :value="categoryAsItemLabel"
      />
      <SlInfoItem
        :label="$t('Web.QB.Form.importChannels')"
        :value="importChannelsLabel"
      />
      <SlInfoItem
        :label="$t('Web.QB.Form.importInactiveItems')"
        :value="importInactiveItemsLabel"
      />
      <SlInfoItem
        :label="$t('Web.QB.Form.vendorsAsCategories')"
        :value="vendorsAsCategoriesLabel"
      />
    </div>
    <SlCheckbox
      v-model="fillMissingVendors"
      :label="$t('Web.QB.Form.fillMissingVendors')"
    />
    <SlCheckbox
      v-model="calculateLeadTime"
      :label="$t('Web.QB.Form.calculateLeadTime')"
    />
    <SlCheckbox
      v-if="isNew"
      v-model="ignoreLocations"
      :label="$t('Web.QB.Form.ignoreLocations')"
    />
    <SlCheckbox
      v-if="isNew"
      v-model="categoryAsItem"
      :label="$t('Web.QB.Form.categoryAsItem')"
    />
    <SlCheckbox
      v-if="isNew"
      v-model="importChannels"
      :label="$t('Web.QB.Form.importChannels')"
    />
    <SlCheckbox
      v-if="isNew"
      v-model="importInactiveItems"
      :label="$t('Web.QB.Form.importInactiveItems')"
    />
    <SlCheckbox
      v-model="includeEstimates"
      :label="$t('Web.QB.Form.includeEstimates')"
    />
    <SlOptionalInput
      v-model="poCustomDeliver"
      vid="poCustomDeliver"
      :mount-open="!!poCustomDeliver"
      :label="$t('Web.QB.Form.poCustomDeliver')"
      :placeholder="$t('Web.QB.Form.customNamePlaceholder')"
    />
    <SlOptionalInput
      v-model="soCustomShipmentDate"
      vid="soCustomShipmentDate"
      :mount-open="!!soCustomShipmentDate"
      :label="$t('Web.QB.Form.soCustomShipmentDate')"
      :placeholder="$t('Web.QB.Form.customNamePlaceholder')"
    />
    <SlCheckbox
      v-if="isNew"
      v-model="vendorsAsCategories"
      :label="$t('Web.QB.Form.vendorsAsCategories')"
    />
    <SlCheckbox
      v-model="readPoClass"
      :label="$t('Web.QB.Form.readPoClass')"
    />
    <p
      v-if="customFields.length || locations.length"
      class="heading-5"
    >
      {{ $t('Web.QB.AddToImport') }}
    </p>
    <div>
      <SlAccordion v-if="!isNew">
        <template #trigger="{ isOpen, toggle }">
          <div class="checkbox-item__trigger">
            <div
              class="checkbox-item__content"
              @click="toggle"
            >
              <div class="body-1 grey-90">
                {{ $t('Import.Combo.ItemCat') }}
              </div>
              <icon
                data="@icons/chevron_down.svg"
                class="fill-off size-20"
                :class="{
                  'rotate-180': isOpen
                }"
              />
            </div>
          </div>
        </template>
        <ul>
          <li class="body-1 grey-80">
            {{ customFieldAsCategory || $t('Settings.MassTd.none') }}
          </li>
        </ul>
      </SlAccordion>
      <template v-if="customFields.length">
        <SlAccordion v-if="isNew">
          <template #trigger="{ isOpen, toggle }">
            <div class="checkbox-item__trigger">
              <div
                class="checkbox-item__content"
                @click="toggle"
              >
                <div class="body-1 grey-90 ml-26">
                  {{ $t('Import.Combo.ItemCat') }}
                </div>
                <icon
                  data="@icons/chevron_down.svg"
                  class="fill-off size-20"
                  :class="{
                    'rotate-180': isOpen
                  }"
                />
              </div>
            </div>
          </template>
          <div class="checkbox-item">
            <SlRadioButton
              :value="customFieldAsCategory"
              :label="$t('Settings.MassTd.none')"
              @input="setCustomField"
            />
          </div>
          <div
            v-for="customField in customFields"
            :key="customField.name"
            class="checkbox-item"
          >
            <SlRadioButton
              :value="customFieldAsCategory"
              :label="customField.name"
              :selected="customField.name"
              @input="setCustomField"
            />
          </div>
        </SlAccordion>
        <SlAccordion>
          <template #trigger="{ isOpen, toggle }">
            <div class="checkbox-item__trigger">
              <SlCheckbox
                v-if="isNew"
                :value="isAllSelected(customFields)"
                :indeterminate="isIndeterminate(customFields)"
                :disabled="!isNew"
                @input="(checked) => selectAll({ list: customFields, checked })"
              />
              <div
                class="checkbox-item__content"
                @click="toggle"
              >
                <div class="body-1 grey-90">
                  {{ $t('Web.QB.Form.itemInfoField') }}
                </div>
                <icon
                  data="@icons/chevron_down.svg"
                  class="fill-off size-20"
                  :class="{
                    'rotate-180': isOpen
                  }"
                />
              </div>
            </div>
          </template>
          <template v-if="isNew">
            <div
              v-for="customField in customFields"
              :key="customField.name"
              class="checkbox-item"
            >
              <SlCheckbox
                :value="customField.checked"
                :label="customField.name"
                @input="(checked) => selectItem({
                  item: customField,
                  checked
                })"
              />
            </div>
          </template>
          <template v-else>
            <ul>
              <li
                v-for="customField in customFields"
                :key="customField"
                class="body-1 grey-80"
              >
                {{ customField }}
              </li>
            </ul>
          </template>
        </SlAccordion>
      </template>
      <SlAccordion v-if="locations.length">
        <template #trigger="{ isOpen, toggle }">
          <div class="checkbox-item__trigger">
            <SlCheckbox
              v-if="isNew"
              :value="isAllSelected(locations)"
              :indeterminate="isIndeterminate(locations)"
              :disabled="!isNew"
              @input="(checked) => selectAll({ list: locations, checked })"
            />
            <div
              class="checkbox-item__content"
              @click="toggle"
            >
              <div class="body-1 grey-90">
                {{ $t('Web.QB.Form.locs') }}
              </div>
              <icon
                data="@icons/chevron_down.svg"
                class="fill-off size-20"
                :class="{
                  'rotate-180': isOpen
                }"
              />
            </div>
          </div>
        </template>
        <template v-if="isNew">
          <div
            v-for="location in locations"
            :key="location.name"
            class="checkbox-item"
          >
            <SlCheckbox
              :value="location.checked"
              :label="location.name"
              :disabled="!isNew"
              @input="(checked) => selectItem({
                item: location,
                checked
              })"
            />
          </div>
        </template>
        <template v-else>
          <ul>
            <li
              v-for="location in locations"
              :key="location"
              class="body-1 grey-80"
            >
              {{ location }}
            </li>
          </ul>
        </template>
      </SlAccordion>
    </div>
  </div>
</template>

<script>
import { connectionTypes } from '@/config/connection';
import { connection } from '@/mixins/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';

export default {
  name: 'QBDesktopOptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.QB_DESKTOP
    };
  },
  computed: {
    customFields() {
      return this.connectionData.optional.customFieldAsItemInfo || [];
    },
    locations() {
      return this.connectionData.optional.locations || [];
    },
    customFieldAsCategory() {
      return this.connectionData.optional.customFieldAsCategory;
    },
    poCustomDeliver: {
      get() {
        return this.connectionData.optional.poCustomDeliver;
      },
      set(value) {
        this.setOptionalValue({
          key: 'poCustomDeliver',
          value
        });
      }
    },
    soCustomShipmentDate: {
      get() {
        return this.connectionData.optional.soCustomShipmentDate;
      },
      set(value) {
        this.setOptionalValue({
          key: 'soCustomShipmentDate',
          value
        });
      }
    },
    importInactiveItems: {
      get() {
        return Boolean(+this.connectionData.optional.importInactiveItems);
      },
      set(value) {
        this.setOptionalValue({
          key: 'importInactiveItems',
          value
        });
      }
    },
    ignoreLocations: {
      get() {
        return Boolean(+this.connectionData.optional.ignoreLocations);
      },
      set(value) {
        this.setOptionalValue({
          key: 'ignoreLocations',
          value
        });
      }
    },
    importChannels: {
      get() {
        return Boolean(+this.connectionData.optional.importChannels);
      },
      set(value) {
        this.setOptionalValue({
          key: 'importChannels',
          value
        });
      }
    },
    vendorsAsCategories: {
      get() {
        return Boolean(+this.connectionData.optional.vendorsAsCategories);
      },
      set(value) {
        this.setOptionalValue({
          key: 'vendorsAsCategories',
          value
        });
      }
    },
    categoryAsItem: {
      get() {
        return Boolean(+this.connectionData.optional.categoryAsItem);
      },
      set(value) {
        this.setOptionalValue({
          key: 'categoryAsItem',
          value
        });
      }
    },
    readPoClass: {
      get() {
        return Boolean(+this.connectionData.optional.readPoClass);
      },
      set(value) {
        this.setOptionalValue({
          key: 'readPoClass',
          value
        });
      }
    },
    includeEstimates: {
      get() {
        return Boolean(+this.connectionData.optional.includeEstimates);
      },
      set(value) {
        this.setOptionalValue({
          key: 'includeEstimates',
          value
        });
      }
    },
    calculateLeadTime: {
      get() {
        return Boolean(+this.connectionData.optional.calculateLeadTime);
      },
      set(value) {
        this.setOptionalValue({
          key: 'calculateLeadTime',
          value
        });
      }
    },
    fillMissingVendors: {
      get() {
        return Boolean(+this.connectionData.optional.fillMissingVendors);
      },
      set(value) {
        this.setOptionalValue({
          key: 'fillMissingVendors',
          value
        });
      }
    },
    ignoreLocationsLabel() {
      return getLabelFromBoolean(this.ignoreLocations, this);
    },
    categoryAsItemLabel() {
      return getLabelFromBoolean(this.categoryAsItem, this);
    },
    importChannelsLabel() {
      return getLabelFromBoolean(this.importChannels, this);
    },
    importInactiveItemsLabel() {
      return getLabelFromBoolean(this.importInactiveItems, this);
    },
    vendorsAsCategoriesLabel() {
      return getLabelFromBoolean(this.vendorsAsCategories, this);
    }
  },
  methods: {
    setCustomField(value) {
      let parsedValue = value;

      if (value === true) {
        parsedValue = '';
      }

      this.setOptionalValue({
        key: 'customFieldAsCategory',
        value: parsedValue
      });
    },
    isAllSelected(list) {
      return list.every(location => location.checked) || this.isIndeterminate(list);
    },
    isIndeterminate(list) {
      const checkedItems = list.filter(item => item.checked);

      return Boolean(checkedItems.length) && Boolean(checkedItems.length < list.length);
    },
    isSelected({ key, item }) {
      return this[key].some(selectedItem => selectedItem._id === item._id);
    },
    selectItem({ item, checked }) {
      item.checked = checked;
    },
    selectAll({ list, checked }) {
      list.forEach(item => {
        item.checked = checked;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

::v-deep {
  .sl-accordion__content {
    max-height: unset !important;
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    padding-left: 22px !important;
    list-style-type: disc;
  }

  .ml-26 {
    margin-left: 26px;
  }

  .checkbox-item__trigger {
    display: flex;
    align-items: center;
    column-gap: 8px;
    flex: 1;
    height: 100%;

    .checkbox-item__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      width: 100%;
      height: 100%;
    }
  }

  .checkbox-item {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
    padding-left: 24px;

    &__label {
      display: list-item;
      list-style-type: disc;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
