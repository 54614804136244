export const slotsReducer = ({ tabData, headers }) => {
  if (!headers?.length) {
    return {
      matchedSlots: {},
      slotsIndexes: {}
    };
  }

  const matchedSlots = Object.entries(tabData.matchedSlots).reduce((acc, [index, slotKey]) => {
    if (headers[index]) {
      acc[index] = slotKey;
    }

    return acc;
  }, {});
  const slotsIndexes = Object.entries(tabData.slotsIndexes).reduce((acc, [slotKey, indexes]) => {
    acc[slotKey] = indexes.filter(index => index < headers.length);

    return acc;
  }, {});

  return {
    matchedSlots,
    slotsIndexes
  };
};