import forecastReportApi from '@/api/reports/forecastReport';
import { namespaceByRoute } from '@/config/report';
import { getRouteName } from '@/helpers/shared/router';
import { routeNames } from '@/config/router/router.config';

const types = {
  SET_TOOLBAR: 'SET_TOOLBAR'
};

const state = {
  toolbar: null
};

const getters = {};

const mutations = {
  [types.SET_TOOLBAR](state, value) {
    state.toolbar = value;
  }
};

const actions = {
  async fetchToolbar({ commit }) {
    try {
      const response = await forecastReportApi.getToolbar();

      if (!response?.data) {
        return;
      }

      commit(types.SET_TOOLBAR, response.data.components);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchToolbar' });
    }
  },
  async setDataForLink({ dispatch }, { toolbar, sort }) {
    await dispatch('setReportToolbar', toolbar);
    await this.dispatch(`${namespaceByRoute[routeNames.REPORTS]}/updateSortingParams`, sort);
  },
  exportTableToXlsx({ rootGetters }, transposed) {
    const namespace = namespaceByRoute[getRouteName()];

    return forecastReportApi.exportTableToXlsx({
      filterId: rootGetters[`${namespace}/activeFilterId`],
      transposed
    });
  },
  importChanges({ rootGetters }, body) {
    const namespace = namespaceByRoute[getRouteName()];

    return forecastReportApi.importChanges({
      filterId: rootGetters[`${namespace}/activeFilterId`]
    }, body);
  },
  async setReportToolbar(_, payload) {
    const namespace = namespaceByRoute[routeNames.REPORTS];

    try {
      await this.dispatch(`${namespace}/updateTableConfig`, {
        key: 'page',
        value: 1
      });

      await forecastReportApi.postForecastReport(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setReportToolbar' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
