<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    persistent
    @created="onCreated"
  >
    <div class="modal-content sl-loading-modal">
      <SlLoader :text="false" />
      <div class="sl-loading-modal__title heading-4-sb grey-90">
        {{ title }}
      </div>
      <div class="sl-loading-modal__text body-1 grey-70">
        {{ text }}
      </div>
    </div>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SlLoadingModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_LOADING_MODAL,
      title: '',
      text: ''
    };
  },
  methods: {
    onHide() {
      this.title = '';
      this.text = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.sl-loading-modal {
  text-align: center;

  &.modal-content {
    min-height: fit-content;
  }

  &__title {
    margin: 12px 0 4px;
  }

  &__text {
    white-space: pre-line;
  }
}
</style>