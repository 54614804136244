export default function(tables) {
  return Object.entries(tables).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = {
        query: value
      };
    }

    return acc;
  }, {});
}
