<template>
  <div class="connector-query">
    <SlValidate
      v-slot="{ invalid }"
      vid="query"
      rules="required"
      mode="eager"
      :message="false"
    >
      <SlTextArea
        ref="queryInput"
        v-model="queryModel"
        :label="label"
        :placeholder="placeholder"
        :height="height"
        :is-invalid="invalid"
        highlight="sql"
      />
    </SlValidate>
    <div class="connector-query__slots">
      <div
        v-if="stubs && stubs.length"
        class="connector-query__stubs"
        :class="{
          'connector-query__stubs--only': !$slots.default && !$slots.actions
        }"
      >
        <div
          ref="stubsWrapper"
          v-resize-observe="onResizeStubs"
          class="connector-query__stubs-wrapper"
          :class="{
            'open': showStubs
          }"
        >
          <div class="connector-query__stubs-label body-4-md grey-60">
            {{ $t('Web.DbImport.LabelInsertParams') }}
          </div>
          <SlTag
            v-for="stub in stubs"
            :id="stub.value"
            :key="stub.value"
            @select="handleStubSelect"
          >
            {{ stub.label }}
          </SlTag>
          <SlButton
            v-if="showStubsArrow"
            size="xs"
            variant="secondary"
            color="grey"
            class="connector-query__stubs-arrow"
            icon
            @click="showStubs = !showStubs"
          >
            <icon
              data="@icons/chevron_down.svg"
              class="fill-off size-16"
              :class="{
                'rotate-180': showStubs
              }"
            />
          </SlButton>
        </div>
      </div>
      <div
        v-if="$slots.default"
        class="connector-query__main"
      >
        <slot />
      </div>
      <div
        v-if="$slots.actions"
        class="connector-query__actions"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import SlTextArea from '@/components/UIKit/SlTextArea';
import SlTag from '@/components/UIKit/SlTag';
import SlButton from '@/components/UIKit/SlButton';
import SlValidate from '@/components/UIKit/SlValidate';

export default {
  name: 'ConnectorQueryBlock',
  components: {
    SlValidate,
    SlButton,
    SlTextArea,
    SlTag
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '195'
    },
    stubs: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      showStubs: false,
      showStubsArrow: false,
      minStubsHeight: 26
    };
  },
  computed: {
    queryModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    stubs(val, old) {
      if (val?.length !== old?.length) {
        this.$nextTick(() => {
          this.showStubsArrow = this.$refs.stubsWrapper?.scrollHeight > this.minStubsHeight;
        });
      }
    }
  },
  methods: {
    onResizeStubs(el) {
      this.showStubsArrow = el.scrollHeight > this.minStubsHeight;
    },
    handleStubSelect(stub) {
      const input = this.$refs.queryInput.$refs.input;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      this.queryModel = this.queryModel.substring(0, start)
        + stub
        + this.queryModel.substring(end, this.queryModel.length);

      this.$nextTick(() => {
        input.focus();
        input.setSelectionRange(start, start + stub.length);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/common/connector-query-block.scss";
</style>