import axios from '@/plugins/axios';

export default {
  start(params) {
    return axios.post('/time_machine', null, { params });
  },
  stop() {
    return axios.delete('/time_machine');
  },
  setStep(params) {
    return axios.post('/time_machine/set_step', null,  { params });
  },
  exportOrdersToCsv() {
    return axios.get('/time_machine/orders/export/csv', {
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  getParams() {
    return axios.get('/time_machine/params');
  }
};