import axios from '@/plugins/axios';

export default {
  getUserInfo() {
    return axios.get('/users/me');
  },
  getUsers() {
    return axios.get('/all-users');
  },
  getRoles(params = null) {
    return axios.get('/roles', { params });
  },
  createRole(body) {
    return axios.post('/roles', body);
  },
  updateRole(body) {
    return axios.put('/roles', body);
  },
  deleteRole(id) {
    return axios.delete('/roles', {
      params: {
        id
      }
    });
  },
  getCatalogAccessTree(params) {
    return axios.get('/catalogs/tree', { params });
  },
  getCatalogTreeChildNodes(params) {
    return axios.get('/catalogs/tree/specific_node', { params });
  },
  changeCatalogAccessTree(params) {
    return axios.put('/catalogs/tree/overrides', null, { params });
  },
  createCatalogAccessTree() {
    return axios.post('/catalogs/tree/overrides');
  },
  deleteCatalogTreeCache(params) {
    return axios.delete('/catalogs/tree/overrides', { params });
  },
  getCatalogs() {
    return axios.get('/catalogs');
  },
  createCatalog(body) {
    return axios.post('/catalogs', body);
  },
  updateCatalog(params) {
    return axios.put('/catalogs', null, { params });
  },
  deleteCatalog(id) {
    return axios.delete('/catalogs', {
      params: {
        id
      }
    });
  },
  createUser(data) {
    return axios.post('/users', data);
  },
  deleteUser(id) {
    return axios.delete('/users', {
      params: {
        id
      }
    });
  },
  deleteOldUser(id) {
    return axios.delete('/old_users', {
      params: {
        id
      }
    });
  },
  updateUser(body) {
    return axios.put('/users', body);
  },
  updateOldUser(body) {
    return axios.put('/old_users', body);
  },
  migrateUsers() {
    return axios.post('/users_migration');
  },
  reportIssue({ formData, ...params }) {
    return axios.post('/issue', formData, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-pid-independent': true
      }
    });
  },
  getUserStats() {
    return axios.get('/users/my_stats');
  }
};
