import { getSplitTooltip } from '@/helpers/shared/tooltip';

export const logLevelTooltip = (ctx) => (
  getSplitTooltip({
    title: ctx.$t('Web.UpdateData.TooltipLogLevelTitle'),
    body: ctx.$t('Web.UpdateData.TooltipLogLevelBody')
  })
);

export const updateData = {
  QUICK: 'Quick',
  FULL: 'Full'
};

export const logLevel = {
  OFF: 'off',
  NORMAL: 'normal',
  QUERIES: 'queries'
};

export const updateDataOptions = (ctx) => ([
  {
    label: ctx.$t('Web.UpdateData.UpdateDataQuick'),
    value: updateData.QUICK,
    loading: false
  },
  {
    label: ctx.$t('Web.UpdateData.UpdateDataFull'),
    value: updateData.FULL,
    loading: false
  }
]);

export const logLevelOptions = (ctx) => ([
  {
    label: ctx.$t('Web.UpdateData.Normal'),
    value: logLevel.NORMAL,
    loading: false
  },
  {
    label: ctx.$t('Web.UpdateData.TransactionQueries'),
    value: logLevel.QUERIES,
    loading: false
  }
]);

export const logsStatuses = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  PENDING: 'Pending'
};
