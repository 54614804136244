export const formatTableName = (query) => {
  if (/\D/.test(query)) {
    return `[${query}]`;
  }

  if (/\s/g.test(query)) {
    return `'${query}'`;
  }

  return query;
};
