import axios from '@/plugins/axios';

export default {
  getSheetList(params) {
    return axios.get('/integrations/spreadsheet/sheetlist', {
      params
    });
  },
  getSheetPreview(params) {
    return axios.get('/integrations/spreadsheet/preview', {
      params
    });
  },
  getWarnings(params) {
    return axios.get('/integrations/spreadsheet/warning', {
      params
    });
  },
  getBasicSettings(params) {
    return axios.get('/integrations/spreadsheet/basic-settings', {
      params
    });
  }
};
