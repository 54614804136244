<template>
  <SlModal
    :id="id"
    :title="title"
    :loading="isUploading"
    :persistent="isUploading"
    @created="onCreated"
    @on-enter="handleUpload"
    @hidden="onModalHide"
  >
    <ValidationObserver
      ref="uploadObserver"
      class="modal-content"
    >
      <SlValidate
        v-slot="{ invalid, validate }"
        vid="file"
        :rules="uploadConfig.validate"
      >
        <SlDropzone
          ref="dropzone"
          :accepted="uploadConfig.accepted"
          :validator="validate"
          :is-invalid="invalid"
          @files-updated="handleFilesUpdated"
        >
          <template #accepted>
            {{ $t(uploadConfig.label) }}
          </template>
        </SlDropzone>
      </SlValidate>
    </ValidationObserver>

    <template #footer>
      <SlModalFooter between>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton @click="handleUpload">
            {{ $t('Common.Save') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { fileFormats, uploadConfigByFormat } from '@/config/utils/fileUpload.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SlUploadFileModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_UPLOAD_FILE,
      uploadConfigByFormat,
      fileFormat: fileFormats.xlsx,
      title: this.$t('Web.File.UploadFile'),
      newFile: null,
      isUploading: false,
      errorsHandler: null,
      uploadHandler: null
    };
  },
  computed: {
    uploadConfig() {
      return this.uploadConfigByFormat[this.fileFormat];
    }
  },
  methods: {
    onModalHide() {
      this.newFile = false;
      this.fileFormat = fileFormats.xlsx;
      this.title = this.$t('Web.File.UploadFile');
      this.isUploading = false;
      this.errorsHandler = null;
      this.uploadHandler = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    async handleUpload() {
      const valid = await this.$refs.uploadObserver.validate();
      const dropzoneValid = await this.$refs.dropzone.validate();

      if (!valid || !dropzoneValid) {
        return;
      }

      try {
        this.isUploading = true;

        await this.uploadHandler(this.newFile);

        this.hideModal(this.id);
      } catch (e) {
        if (this.errorsHandler) {
          return this.errorsHandler(e);
        }

        const text = e?.message;

        this.$notify({
          type: 'error',
          title: this.$t('Web.FileUpload.UploadError'),
          text
        });
      } finally {
        this.isUploading = false;
      }
    },
    async handleFilesUpdated(files) {
      this.newFile = files[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.sl-modal .modal-content {
  min-height: 100px;
}
</style>
