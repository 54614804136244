<template>
  <SlModal
    :id="id"
    :title="title"
    :subtitle="subtitle"
    :width="600"
    @on-enter="handleConfirm"
    @created="onCreated"
    @hide="onHide"
  >
    <div class="modal-content sl-table-info-modal">
      <SlTable
        v-if="table"
        id="sl-table-info"
        :headers="table.header"
        :rows="table.body"
        header-unique-key="key"
        header-name-key="name"
        unique-key="id"
        :value-parser="parseValue"
        :col-resize="false"
        :max-height="500"
        col-fill
      />
    </div>
    
    <template #footer>
      <SlModalFooter>
        <SlButton @click="handleConfirm">
          {{ confirmText || $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SlTableInfoModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_TABLE_INFO_MODAL,
      table: null,
      title: '',
      subtitle: '',
      confirmText: ''
    };
  },
  methods: {
    onHide() {
      this.table = null;
      this.title = '';
      this.subtitle = '';
      this.confirmText = '';
    },
    parseValue(value) {
      return value.val ?? value ?? '';
    },
    handleConfirm() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss">
.modal-content.sl-table-info-modal {
  display: flex;
  flex-direction: column;
}
</style>