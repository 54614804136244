import TabsAdapter from '@/adapters/request/connection/dbRelated/shared/Tabs.adapter';
import { tabKeys, netsuiteTabKeys } from '@/config/connection/dbRelated.config';
import { DEFAULT_START_DATE } from '@/config/connection';

export default function(state, shared) {
  const { tabInfo } = TabsAdapter(state);
  const {
    fileId: _fileId,
    fileRemoved: _fileRemoved
  } = shared.optional;

  const filteredTabInfo = tabInfo.filter(tab => {
    return netsuiteTabKeys.includes(tab.query._type);
  });

  return {
    info: {
      _groupBy: state[tabKeys.REQUIRED].groupBy,
      _startDate: state.commonData.startDate || DEFAULT_START_DATE,
      _combineLocs: +state[tabKeys.OPTIONAL].combineLocations,
      _cleanUnusedEntries: +state[tabKeys.OPTIONAL].cleanUnusedEntries,
      _aggregationByDay: +state[tabKeys.OPTIONAL].aggregationByDay,
      _cacheId: state.cache_id
    },
    queries: {
      tabInfo: filteredTabInfo,
      commonSets: {
        groupBy: state[tabKeys.REQUIRED].groupBy,
        start: state[tabKeys.REQUIRED].startFrom,
        orderTypes: {
          purchase: state[tabKeys.ORDERS_RECEIVE].purchase,
          work: state[tabKeys.ORDERS_RECEIVE].manufacture,
          transfer: state[tabKeys.ORDERS_RECEIVE].transfer
        }
      }
    },
    subsidiary: {
      _id: state[tabKeys.OPTIONAL].subsidiary?._id,
      _name: state[tabKeys.OPTIONAL].subsidiary?._name
    },
    employee: {
      _id: state[tabKeys.OPTIONAL].employee?._id,
      _name: state[tabKeys.OPTIONAL].employee?._name
    },
    transactionsFile: {
      _fileId,
      _fileRemoved
    }
  };
}
