import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';
import { sourceConnectDataFields } from '@/config/connection';

export default function(data) {
  const {
    _login: username,
    _password: password
  } = data.settings.credentials;
  const {
    _baseUrl,
    _cacheId,
    _company,
    _companyId,
    _environmentId,
    _environmentDisplayName,
    _solution,
    _startDate,
    _groupBy,
    _combineLocs,
    _description2AsLowestCat
  } = data.settings.info;

  return {
    required: {
      cacheId: _cacheId,
      groupBy: _groupBy,
      startDate: _startDate,
      environment: _environmentId,
      company: {
        id: _companyId,
        name: _company
      }
    },
    optional: {
      combineLocs: _combineLocs === 'true',
      description2AsLowestCat: _description2AsLowestCat,
      file: FileEditAdapter(data.settings.transactionsFile)
    },
    [sourceConnectDataFields.MAIN]: {
      type: _solution,
      baseUrl: _baseUrl,
      username,
      password
    },
    additionalData: {
      environments: [
        {
          id: _environmentId,
          value: _environmentDisplayName
        }
      ],
      companies: [
        {
          id: _companyId,
          name: _company
        }
      ]
    }
  };
}
