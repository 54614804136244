<template>
  <SlModal
    :id="id"
    :title="$t('Web.FreezeCalc.ModalTitle')"
    :loading="isApplying"
    @created="onCreated"
    @on-enter="handleApply"
    @hide="onModalHide"
    @show="onModalShow"
  >
    <ValidationObserver
      ref="observer"
      class="modal-content freeze-simulation"
    >
      <SlCheckbox
        v-model="frozen"
        :label="$t('Web.FreezeCalc.chkFreeze')"
      />
      <SlValidate
        :key="frozen"
        v-slot="{ invalid }"
        vid="date"
        :rules="dateRules"
        class="freeze-simulation__date"
      >
        <SlDatePicker
          v-model="date"
          class="w-30"
          :label="$t('Import.Combo.Date')"
          required
          :disabled="!frozen"
          :is-invalid="invalid"
        />
      </SlValidate>
    </ValidationObserver>
    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleApply">
          {{ $t('Web.Modals.BtnApply') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import modalIds from '@/config/shared/modalsId.config';

export default {
  name: 'CreateSupplierModal',
  mixins: [modal],
  data() {
    return {
      id: modalIds.FREEZE_SIMULATION,
      frozen: false,
      date: null,
      isApplying: true
    };
  },
  computed: {
    ...mapState({
      freezeSettings: state => state.project.freeze_settings
    }),
    dateRules() {
      return {
        required: this.frozen,
        sl_valid_date: true,
        sl_date_between: true
      };
    }
  },
  watch: {
    frozen(val) {
      if (!val) {
        this.date = null;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchFreezeSettings: 'project/fetchFreezeSettings',
      submitFreezeSettings: 'project/submitFreezeSettings'
    }),
    async onModalShow() {
      await this.fetchFreezeSettings();

      this.frozen = Boolean(this.freezeSettings.frozen);
      this.date = this.freezeSettings.date;
      this.isApplying = false;
    },
    onModalHide() {
      this.frozen = false;
      this.date = null;
    },
    getRequestPayload() {
      return {
        frozen: this.frozen,
        ...(this.frozen ? { date: this.date } : {})
      };
    },
    async handleApply() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      this.isApplying = true;

      const payload = this.getRequestPayload();

      await this.submitFreezeSettings(payload);

      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

.freeze-simulation {
  display: flex;
  flex-direction: column;
  gap: 20px;

  min-height: 108px !important;

  &__date {
    padding-left: 16px;
    border-left: 1px solid $grey-30;
  }

  .w-30 {
    max-width: 30%;
  }
}
</style>
