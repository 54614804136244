import regExp from '@/helpers/utils/regExp';

export const parseCsv = (str) => {
  if (!str) {
    return [];
  }

  const csvRows = str.split('\n').filter(c => c);

  if (csvRows.length > 1) {
    return csvRows;
  }

  const matchedValues = [...str.matchAll(regExp.parseCsv)];

  return matchedValues.map(match => match[1] || match[2]);
};
