import { tabKeys } from '@/config/connection/dbRelated.config';

export default function(state) {
  const {
    exportPOinsteadTO
  } = state[tabKeys.OPTIONAL];

  const {
    sqlVersion: _sqlVersion,
    bitness: _bitness,
    proxyServerName: _proxyServerName
  } = state[tabKeys.EXPORT];

  return {
    advanceSettings: {
      _exportPOinsteadTO: +exportPOinsteadTO,
      _sqlVersion,
      _bitness,
      _proxyServerName
    }
  };
}