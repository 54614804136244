import { roleSettingTypes } from '@/config/users/users.config';

export default function(role, settings) {
  if (!role) {
    return null;
  }

  Object.values(roleSettingTypes).forEach(type => {
    const settingsData = role[type];

    if (type === roleSettingTypes.DEPENDENT_THINGS) {
      settings[type] = settingsData;

      return;
    }

    Object.keys(settingsData).forEach(key => {
      if (settings[key]) {
        settings[key].value = settingsData[key];
      }
    });
  });

  return {
    id: role.id,
    name: role.name,
    descr: role.descr || '',
    settings
  };
}
