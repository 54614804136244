export default (data) => {
  if (!data) {
    return null;
  }

  const id = data.user.id;
  const userName = data.user.name || '';
  const userRole = data.user.roleName || '';

  return {
    id,
    userName,
    userRole
  };
};
