<template>
  <span
    :class="[
      'sl-shortcut',
      systemClass,
      {
        'sl-shortcut--outlined': isOutlined
      }
    ]"
  >
    {{ convertedValue }}
  </span>
</template>

<script>
import { shortcutDelimiters, winToMacConfig } from '@/config/shortcuts/shortcuts.config';

export default {
  name: 'SlShortcut',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    outline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delimiters: shortcutDelimiters(this)
    };
  },
  computed: {
    isOutlined() {
      return this.outline && !Object.keys(this.delimiters).includes(this.value);
    },
    currentSystem() {
      return navigator.appVersion.includes('Mac');
    },
    systemClass() {
      return `sl-shortcut--${this.currentSystem ? 'mac' : 'windows'}`;
    },
    convertedValue() {
      if (this.currentSystem && Object.keys(winToMacConfig).includes(this.value)) {
        return winToMacConfig[this.value];
      }

      if (Object.keys(this.delimiters).includes(this.value)) {
        return this.delimiters[this.value];
      }

      return this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-shortcut/index.scss";
</style>