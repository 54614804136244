<template>
  <div
    class="sl-table__row"
    :class="{
      'sl-table__row--even': isEven,
      'sl-table__row--highlight': highlight
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlTableRow',
  props: {
    isEven: Boolean,
    highlight: Boolean
  }
};
</script>

<style lang="scss">
@import "@/style/components/ui-kit/sl-table/sl-table-row.scss";
</style>