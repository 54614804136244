<template>
  <div class="connector-wrap connector-wrap--simple">
    <div
      v-if="!isNew"
      class="connector-content-block__row connector-content-block__row--divider"
    >
      <SlInfoItem
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
        :value="combineLocsLabel"
      />
    </div>
    <template v-else>
      <SlCheckbox
        v-model="combineLocs"
        :label="$t('Web.BaseConnectors.Form.combineLocs')"
      />
    </template>
    <SlCheckbox
      v-model="description2AsLowestCat"
      :label="$t('Web.BC365.description2AsLowestCat')"
    />
    <div class="connector-content-block__row">
      <span class="heading-5-sb grey-90">
        {{ $t('DbInsideSourceWidget.Ui.lbTransactions') }}
      </span>
      <div class="body-1 grey-70">
        {{ $t('Web.BaseConnectors.SampleCsvBeginning') }}
        <SlLink
          color="dark-grey"
          :href="historicalTransactionsTemplate"
          target="_blank"
        >
          {{ $t('Web.BaseConnectors.SampleCsvLink') }}
        </SlLink>
        {{ $t('Web.BaseConnectors.SampleCsvEnding') }}
      </div>
      <UploadFile
        :format="fileFormats.csv"
        :existing-file="transactionsFile"
        :upload-callback="uploadTransactionsFile"
        @remove="removeFile"
      />
    </div>
  </div>
</template>

<script>
import { connection } from '@/mixins/connection';
import { connectionTypes } from '@/config/connection';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';
import { fileFormats } from '@/config/utils/fileUpload.config';

export default {
  name: 'BC365OptionalTab',
  mixins: [connection],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      type: connectionTypes.BUSINESS_CENTRAL,
      fileFormats
    };
  },
  inject: [
    'historicalTransactionsTemplate'
  ],
  computed: {
    combineLocs: {
      get() {
        return Boolean(+this.connectionData.optional.combineLocs);
      },
      set(value) {
        this.setOptionalValue({
          key: 'combineLocs',
          value
        });
      }
    },
    description2AsLowestCat: {
      get() {
        return Boolean(+this.connectionData.optional.description2AsLowestCat);
      },
      set(value) {
        this.setOptionalValue({
          key: 'description2AsLowestCat',
          value
        });
      }
    },
    combineLocsLabel() {
      return getLabelFromBoolean(this.combineLocs, this);
    }
  }
};
</script>
