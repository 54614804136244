<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    @on-enter="handleConfirm"
    @created="onCreated"
    @hide="onHide"
  >
    <div class="modal-content sl-error-modal">
      <icon
        v-if="modalIcon"
        :data="modalIcon"
        class="fill-off size-48"
      />
      <div class="sl-error-modal__title heading-4-sb grey-90">
        {{ title }}
      </div>
      <div class="sl-error-modal__text body-1 grey-70">
        {{ text }}
        <div v-if="contactSupport">
          {{ $t('Web.Modals.Error.TextIfProblemExist') }}
          <SlLink @click="handleOpenChat">
            {{ $t('Web.Modals.Error.ContactSupport') }}
          </SlLink>
        </div>
      </div>
    </div>
    
    <template #footer>
      <SlModalFooter>
        <SlButton
          full-width
          @click="handleConfirm"
        >
          {{ confirmText || $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SlErrorModal',
  mixins: [modal],
  data() {
    return {
      id: modalsId.SL_ERROR_MODAL,
      title: '',
      text: '',
      icon: '',
      confirmText: '',
      confirmCallback: null,
      contactSupport: false
    };
  },
  computed: {
    modalIcon() {
      return require(`@icons/${this.icon || 'style_error_double'}.svg`);
    }
  },
  methods: {
    onHide() {
      this.title = '';
      this.text = '';
      this.icon = '';
      this.confirmText = '';
      this.confirmCallback = null;
      this.contactSupport = false;
    },
    handleConfirm() {
      this.confirmCallback && this.confirmCallback();
      this.hideModal(this.id);
    },
    handleOpenChat() {
      this.showModal(modalsId.REPORT_ISSUE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_variable.scss";

.sl-error-modal {
  text-align: center;

  &.modal-content {
    min-height: fit-content;
  }

  &__title {
    margin: 12px 0 4px;
  }

  &__text {
    color: $grey-80;
    white-space: pre-line;
  }
}
</style>