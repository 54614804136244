import axios from '@/plugins/axios';

export default {
  getFiltersList(params) {
    return axios.get('/filter/list', { params });
  },
  createFilterCache(params) {
    return axios.post('/filter', null, { params });
  },
  changeFilterCache(params, body) {
    return axios.patch('/filter', body, { params });
  },
  saveFilterCache(params) {
    return axios.put('/filter', null, { params });
  },
  discardFilterCache(params) {
    return axios.delete('/filter', { params });
  },
  getFilter(params) {
    return axios.get('/filter', { params });
  },
  getFilterableColumns(params) {
    return axios.get('/filter/columns', { params });
  },
  getFilterableColumnValues(params) {
    return axios.get('/filter/column/values', { params });
  },
  createFilterRule(params) {
    return axios.post('/filter/rule', null, { params });
  },
  duplicateFilterRule(params) {
    return axios.post('/filter/rule/duplicate', null, { params });
  },
  ungroupFilterRule(params) {
    return axios.post('/filter/rule/ungroup', null, { params });
  },
  changeFilterRule(params, body) {
    return axios.patch('/filter/rule', body, { params });
  },
  deleteFilterRule(params) {
    return axios.delete('/filter/rule', { params });
  },
  createFilterGroup(params) {
    return axios.post('/filter/group', null, { params });
  },
  changeFilterGroup(params, body) {
    return axios.patch('/filter/group', body, { params });
  },
  duplicateFilterGroup(params) {
    return axios.post('/filter/group/duplicate', null, { params });
  },
  ungroupFilterGroup(params) {
    return axios.post('/filter/group/ungroup', null, { params });
  },
  deleteFilterGroup(params) {
    return axios.delete('/filter/group', { params });
  }
};
