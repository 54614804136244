export default function(data, connectData) {
  const {
    type: _solution,
    baseUrl: _baseUrl,
    username: _login,
    password: _password
  } = connectData;
  const {
    startDate: _startDate,
    groupBy
  } = data.commonData;
  const {
    company,
    cacheId: _cacheId,
    environment: _environmentId
  } = data.required;
  const {
    combineLocs: _combineLocs,
    description2AsLowestCat: _description2AsLowestCat,
    fileId: _transactionsFile,
    fileRemoved: _fileRemoved
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    credentials: {
      _login,
      _password
    },
    info: {
      _baseUrl,
      _cacheId: _cacheId || '0',
      _company: company.name,
      _companyId: company.id,
      _environmentId,
      _solution,
      _startDate,
      _groupBy,
      _combineLocs: Boolean(_combineLocs),
      _transactionsFile,
      _description2AsLowestCat: +_description2AsLowestCat
    },
    transactionsFile: {
      _fileId: _transactionsFile,
      _fileRemoved
    }
  };
}
