<template>
  <SlModal
    :id="id"
    :title="title"
    :subtitle="subtitle"
    @created="onCreated"
    @hide="onModalHide"
  >
    <div class="modal-content meta-info-issues">
      <SlAccordion
        v-for="issue in issues"
        :key="issue.type"
        variant="light"
      >
        <template #trigger="{ isOpen, toggle }">
          <div
            class="meta-info-issues__trigger"
            @click="toggle"
          >
            <icon
              data="@icons/warning_triangle.svg"
              class="fill-off size-16 color-accent-yellow-90"
            />
            <div class="meta-info-issues__trigger-label">
              {{ issue.label }}
            </div>
            <icon
              data="@icons/chevron_down.svg"
              class="fill-off size-16 color-grey-60"
              :class="{
                'rotate-180': isOpen
              }"
            />
          </div>
        </template>
        <div class="meta-info-issues__content">
          <div
            v-if="issue.description"
            class="meta-info-issues__description"
          >
            {{ issue.description }}
          </div>
          <SlTable
            v-if="issue.table"
            id="meta-info-issues"
            :headers="issue.table.header"
            :rows="issue.table.body"
            header-unique-key="key"
            header-name-key="name"
            unique-key="id"
            :value-parser="parseValue"
            :col-resize="false"
            col-fill
          />
        </div>
      </SlAccordion>
    </div>

    <template #footer>
      <SlModalFooter :between="!!exportCallback">
        <SlButton
          v-if="exportCallback"
          variant="secondary"
          color="grey"
          @click="exportCallback"
        >
          {{ $t('Main.SaveXlsx') }}
        </SlButton>
        <SlButton
          @click="handleClose"
        >
          {{ $t('Common.Ok') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'MetaInfoIssuesModal',
  mixins : [modal],
  data() {
    return {
      id: modalsId.META_INFO_ISSUES,
      issues: null,
      title: null,
      subtitle: null,
      exportCallback: null
    };
  },
  methods: {
    onModalHide() {
      this.issues = null;
      this.title = null;
      this.subtitle = null;
      this.exportCallback = null;
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || '';
    },
    handleClose() {
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_mixins.scss";
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_color.scss";

.meta-info-issues {
  padding: 10px;

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 100%;

    .sl-svg-icon {
      flex-shrink: 0;
    }

    &-label {
      margin-right: auto;

      @include body-1-md;
      color: $grey-90;
    }
  }

  &__description {
    margin-bottom: 12px;

    @include body-1;
    color: $grey-70;
  }
}
</style>
