<template>
  <div
    class="sl-tab-list"
    :class="{
      'sl-tab-list--horizontal': horizontal,
      'sl-tab-list--collapsed': localCollapsed
    }"
    @mouseenter="toggleHover(true)"
    @mouseleave="toggleHover(false)"
  >
    <div
      v-if="collapsable"
      class="sl-tab-list__header--collapsable"
    >
      <SlButton
        variant="tertiary"
        color="grey"
        class="sl-tab-list__toggle"
        icon
        @click.stop="toggleList"
      >
        <template #prepend>
          <icon
            v-tooltip.bottom="toggleButton.tooltip"
            :data="toggleButton.icon"
            class="size-16 color-grey-70"
          />
        </template>
        <transition name="fade">
          <div
            v-if="!localCollapsed"
            class="sl-tab-list__toggle-text"
          >
            <slot
              v-if="$scopedSlots.collapseText"
              name="collapseText"
            />
            <template v-else>
              {{ $t('Web.Sidebar.TabsSideMenuCollapse') }}
            </template>
          </div>
        </transition>
      </SlButton>
    </div>
    <transition name="fade">
      <div v-if="!localCollapsed">
        <div
          v-if="$scopedSlots.header"
          class="sl-tab-list__header body-4-md grey-60"
        >
          <slot name="header" />
        </div>

        <div
          class="sl-tab-list__tabs-wrapper"
          :class="{
            'sl-tab-list__tabs-wrapper--scroll': hasHorizontalScrollbar,
            'sl-tab-list__tabs-wrapper--scrolled-right': hasHorizontalScrollbar && scrolledToRight,
            'sl-tab-list__tabs-wrapper--scrolled-left': hasHorizontalScrollbar && scrolledToLeft
          }"
        >
          <div
            v-if="$scopedSlots['before-list'] || hasHorizontalScrollbar"
            class="sl-tab-list__tabs-before"
          >
            <slot name="before-list" />
            <SlButton
              v-if="checkIsScrollArrowsVisible(scrolledToLeft)"
              variant="tertiary"
              color="grey"
              class="sl-tab-list__tabs-arrow sl-tab-list__tabs-arrow--left"
              icon
              @click="handleScrollLeft"
            >
              <icon
                data="@icons/chevron_left.svg"
                class="fill-off size-20 color-grey-80"
              />
            </SlButton>
          </div>
          <div
            ref="scrollContainer"
            class="sl-tab-list__tabs"
            @wheel="handleListScroll"
            @scroll.passive="updateScroll"
          >
            <component
              :is="localDraggable ? 'draggable' : 'div'"
              v-model="tabsModel"
              class="sl-tab-list__tabs-inner"
              v-bind="dragOptions"
            >
              <template v-for="(tab, tabIndex) in tabs">
                <slot
                  v-if="$scopedSlots.tab"
                  name="tab"
                  v-bind="{
                    tab,
                    tabIndex,
                    active: checkIsActive(tab),
                    disabled: tab.disabled || disabled,
                    select: () => handleSelectTab(tab)
                  }"
                />
                <SlTab
                  v-else
                  :key="tab[trackBy]"
                  :is-link="!!tab.link"
                  :to="tab.link && tab.link.to"
                  :active="checkIsActive(tab)"
                  :disabled="tab.disabled || disabled"
                  :drag-disabled="tab.dragDisabled"
                  :required="tab.required"
                  :horizontal="horizontal"
                  @click.native.stop="handleSelectTab(tab)"
                >
                  {{ tab.label }}

                  <template
                    v-if="tab.restricted"
                    #append
                  >
                    <icon
                      data="@icons/upgrade-plan.svg"
                      class="fill-off size-16"
                    />
                  </template>
                </SlTab>
              </template>
            </component>
          </div>
          <div
            v-if="$scopedSlots['after-list'] || hasHorizontalScrollbar"
            class="sl-tab-list__tabs-after"
          >
            <SlButton
              v-if="checkIsScrollArrowsVisible(scrolledToRight)"
              variant="tertiary"
              color="grey"
              class="sl-tab-list__tabs-arrow sl-tab-list__tabs-arrow--right"
              icon
              @click="handleScrollRight"
            >
              <icon
                data="@icons/chevron_right.svg"
                class="fill-off size-20 color-grey-80"
              />
            </SlButton>
            <slot name="after-list" />
          </div>
        </div>

        <div
          v-if="$scopedSlots.nestedList"
          class="sl-tab-list__nested-list"
        >
          <slot
            name="nestedList"
            v-bind="{
              collapsed: localCollapsed
            }"
          />
        </div>

        <div
          v-if="$scopedSlots.append"
          class="sl-tab-list__append"
        >
          <slot name="append" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { scroll } from '@/mixins/scroll';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { getTooltip } from '@/helpers/shared/tooltip';
import { customThrottle } from '@/helpers/shared/listeners';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'SlTabList',
  mixins: [modal, scroll],
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    tabs: {
      type: Array,
      default: () => ([])
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    disabled: Boolean,
    horizontal: Boolean,
    collapsable: Boolean,
    collapsed: Boolean,
    scrollToActiveTab: Boolean,
    draggable: Boolean
  },
  data() {
    return {
      isCollapsed: false,
      isHovered: false,
      defaultScrollGap: 400,
      scrollDeltaMultiplier: 4,
      dragOptions: {
        group: 'tabs',
        animation: 200,
        forceFallback: true,
        ghostClass: 'sl-tab--ghost',
        chosenClass: 'sl-tab--chosen',
        dragClass: 'sl-tab--drag',
        draggable: '.sl-tab',
        handle: '.sl-tab__text',
        filter: '.sl-tab--drag-disabled',
        move: (e) => !e.related.classList.contains('sl-tab--drag-disabled')
      }
    };
  },
  computed: {
    tabsModel: {
      get() {
        return this.tabs;
      },
      set(value) {
        this.$emit('dragged', value);
      }
    },
    toggleButton() {
      return {
        icon: this.isCollapsed
          ? require('@sidebar/expand.svg')
          : require('@sidebar/collapse.svg'),
        tooltip: this.isCollapsed
          ? getTooltip(this.$t('Web.Sidebar.TabsSideMenuExpand'))
          : ''
      };
    },
    localCollapsed() {
      return this.isCollapsed || this.collapsed;
    },
    localDraggable() {
      return this.draggable && this.horizontal;
    }
  },
  watch: {
    value() {
      if (this.scrollToActiveTab && this.horizontal) {
        this.$nextTick(this.handleScrollToActiveTab);
      }
    },
    tabs() {
      if (this.horizontal) {
        this.updateScroll();
      }
    }
  },
  mounted() {
    if (this.value === null && this.tabs) {
      const firstTab = this.tabs.find(tab => !tab.disabled) || this.tabs[0];

      this.handleSelectTab(firstTab);
    }
  },
  methods: {
    checkIsActive(tab) {
      return this.value === tab[this.trackBy];
    },
    checkIsScrollArrowsVisible(scrolled) {
      return this.hasHorizontalScrollbar && this.isHovered && !scrolled;
    },
    toggleList() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleHover(isVisible) {
      this.isHovered = isVisible;
    },
    handleSelectTab(tab) {
      if (tab.restricted) {
        ampli.upgradeStreamlineWindowViewed({ upgradeWindowSource: 'automationSettings' });

        return this.showModal(modalsId.UPGRADE_PLAN);
      }

      if (this.value === tab[this.trackBy]) {
        return;
      }

      this.$emit('input', tab[this.trackBy]);

      if (tab.hash && tab.hash !== this.$route.hash) {
        this.$router.replace({
          name: this.$sl_routeName,
          hash: tab.hash
        });
      }
    },
    handleListScroll(e) {
      if (!this.horizontal) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      requestAnimationFrame(() => this.throttledUpdateScroll(e));
    },
    throttledUpdateScroll: customThrottle(function(e) {
      const delta = e.deltaY || e.wheelDelta;

      this.handleScrollTo(this.$refs.scrollContainer.scrollLeft + delta * this.scrollDeltaMultiplier);
    }, 150),
    handleScrollTo(left) {
      this.$refs.scrollContainer.scrollTo({
        left,
        behavior: 'smooth'
      });
    },
    handleScrollToActiveTab() {
      const container = this.$refs.scrollContainer;
      const tabElement = container.querySelector('.sl-tab--active');

      if (!tabElement) {
        return;
      }

      const tabRect = tabElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const isTabOverflowingRight = tabRect.right > containerRect.right;
      const isTabOverflowingLeft = tabRect.left < containerRect.left;

      if (isTabOverflowingRight) {
        const scrollAmount = tabRect.right - containerRect.right;

        this.handleScrollTo(container.scrollLeft + scrollAmount);
      } else if (isTabOverflowingLeft) {
        const scrollAmount = containerRect.left - tabRect.left;

        this.handleScrollTo(container.scrollLeft - scrollAmount);
      }
    },
    handleScrollLeft() {
      this.handleScrollTo(this.scrollLeft - this.defaultScrollGap);
    },
    handleScrollRight() {
      this.handleScrollTo(this.scrollLeft + this.defaultScrollGap);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-tabs/sl-tab-list.scss";
</style>
