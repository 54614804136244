import { componentNames } from '@/config/app.config';
import { connectorRouteNames, routeNames } from '@/config/router/router.config';
import { namespaceByRoute } from '@/config/report';

const commonRequests = [
  'prj_info',
  'user_stats',
  'project_tabs',
  'user_access',
  'wsl-available_connectors',
  'background_operations',
  'subscription'
];

const treeRequests = [
  'tree',
  'tree_icons',
  'tree_distortions',
  'tree_abc',
  'tree_notes_changed'
];

const connectorsRequestsByRoute = Object.values(connectorRouteNames)
  .reduce((acc, route) => {
    acc[route] = [
      ...commonRequests,
      'wsl-available_connectors'
    ];

    return acc;
  }, {});

export const storeActionByRequest = {
  // custom keys web only
  'wsl-project_list': 'manageProjects/getProjectList',
  'wsl-ram_stats': 'project/fetchRamStats',
  'wsl-available_connectors': 'manageProjects/getAvailableConnectors',
  'wsl-backups': 'manageProjects/backups/fetchBackups',

  'background_operations': 'operations/initOperations',
  // 'tutorials': 'project/fetchTutorials',
  'examples': 'manageProjects/fetchExamples',
  'tree': 'demand/tree/fetchTree',
  'tree_icons': 'demand/tree/fetchTree',
  'tree_distortions': 'demand/tree/fetchTree',
  'tree_abc': 'demand/tree/fetchTree',
  'tree_notes_changed': 'demand/tree/fetchTree',
  'cat_headers': 'demand/tree/getTreeCategories',
  'demand_node': 'demand/fetchDemandNode',
  'demand_rows': 'demand/table/fetchNodeTable',
  'forecast_versions': 'demand/forecastVersions/fetchForecastVersions',
  'chart': 'demand/chart/fetchChart',
  'chart_curve': 'demand/chart/fetchCurvesVisibility',
  'model_tab': 'demand/settings/fetchModelSettings',
  'top_down_coeff_tab': 'demand/settings/fetchModelSettings',
  'inv_param_tab': 'demand/settings/fetchInventorySettings',
  'kpi_tab': 'demand/settings/fetchKPISettings',
  'ir': `${namespaceByRoute[routeNames.INVENTORY]}/fetchPage`,
  'inv_optimization': `${namespaceByRoute[routeNames.INTERSTORE]}/fetchPage`,
  'fr': `${namespaceByRoute[routeNames.REPORTS]}/fetchPage`,
  'prj_info': 'manageProjects/setupCurrentProject',
  'project_tabs': 'manageProjects/setupCurrentProject',
  'prj_sets': 'settings/fetchSettings',
  'constrained_ordering_plan': 'inventoryReport/constrainedPlan/fetchConstrainedPlan',
  'containers': 'inventoryReport/collections/fetchContainers',
  'groups': 'inventoryReport/collections/fetchAllGroups',
  'collections': 'inventoryReport/collections/fetchEntities',
  'dashboards': 'dashboard/monitoring/fetchDashboard',
  'changes': 'changes/fetchChanges',
  'user_access': 'manageProjects/setupCurrentProject',
  'ir_columns': `${namespaceByRoute[routeNames.INVENTORY]}/fetchColumnsVisibility`,
  'inv_opt_columns': `${namespaceByRoute[routeNames.INTERSTORE]}/fetchColumnsVisibility`,
  'fr_columns': `${namespaceByRoute[routeNames.REPORTS]}/fetchColumnsVisibility`,
  'seasonality_patterns': 'demand/seasonPattern/fetchSeasonPatterns',
  'seasonality_patterns_model': 'demand/seasonPattern/fetchSeasonPatternsModel',
  'catalog_access_tree': 'userAccess/fetchCatalogAccessTree',
  'ir_subtabs': `${namespaceByRoute[routeNames.INVENTORY]}/fetchFiltersList`,
  'inv_opt_subtabs': `${namespaceByRoute[routeNames.INTERSTORE]}/fetchFiltersList`,
  'fr_subtabs': `${namespaceByRoute[routeNames.REPORTS]}/fetchFiltersList`,
  'users': 'userAccess/fetchUsers',
  'old_users': 'userAccess/fetchUsers',
  'roles': 'userAccess/fetchRoles',
  'permissions': 'userAccess/fetchCatalogs',
  'items_list': 'project/fetchItemCodes',
  'forecast_toolbar': 'forecastReport/fetchToolbar',
  'in_transition': 'inventoryReport/inTransition/updateInTransitionTables',
  'planned_orders': 'inventoryReport/plannedOrders/fetchOrders',
  'planned_orders_suppliers': 'inventoryReport/plannedOrders/fetchOrderSuppliers',
  'suppliers': 'suppliers/fetchSuppliers',
  'substitutions': 'substitutions/fetchRules',
  'promotions': 'demand/promotion/fetchPromotions',
  'expirations': 'item/batchesOnHand/fetchBatchesOnHand',
  'holidays': 'demand/settings/fetchHolidays',
  'ui_settings': 'user/fetchUiSettings',
  'tenant_users': 'initialization/getTenantUsers',
  'integrations_list': 'integrations/fetchAllIntegrations',
  'project_creation_info': 'integrations/fetchProjectCreationInfo',
  'log_list': 'logs/fetchLogsList',
  'analytics_dashboards': 'dashboard/analytics/fetchDashboardsList',
  'analytics_widgets': 'dashboard/analytics/fetchWidgets',
  'analytics_ui': 'dashboard/analytics/fetchDashboardUiSettings',
  'subscription': 'account/getSubscriptionDetails',
  'user_stats': 'user/fetchUserStats'
};

export const requestsByRouteName = {
  [componentNames.ROOT]: [
    'background_operations',
    'wsl-available_connectors',
    'wsl-project_list',
    'user_stats'
  ],
  [componentNames.EXAMPLES]: [
    ...commonRequests,
    'examples',
    'wsl-ram_stats'
  ],
  // [componentNames.TUTORIALS]: [
  //   ...commonRequests,
  //   'tutorials'
  // ],
  [componentNames.PROJECT_LIST]: [
    ...commonRequests,
    'wsl-available_connectors',
    'wsl-project_list',
    'wsl-ram_stats'
  ],
  [componentNames.ARCHIVE]: [
    ...commonRequests,
    'wsl-project_list',
    'wsl-ram_stats'
  ],
  [componentNames.DEMAND]: [
    ...commonRequests,
    ...treeRequests,
    'ui_settings',
    'cat_headers',
    'demand_node',
    'demand_rows',
    'forecast_versions',
    'chart',
    'chart_curve',
    'model_tab',
    'top_down_coeff_tab',
    'inv_param_tab',
    'kpi_tab',
    'prj_sets',
    'items_list',
    'seasonality_patterns_model',
    'holidays'
  ],
  [componentNames.INVENTORY]: [
    ...commonRequests,
    'ui_settings',
    'ir',
    'prj_sets',
    'groups',
    'containers',
    'constrained_ordering_plan',
    'boms',
    'ir_columns',
    'ir_subtabs',
    'collections'
  ],
  [componentNames.INTERSTORE]: [
    ...commonRequests,
    'ui_settings',
    'inv_optimization',
    'groups',
    'containers',
    'prj_sets',
    'inv_opt_subtabs',
    'inv_opt_columns'
  ],
  [componentNames.DASHBOARD]: [
    ...commonRequests,
    'ui_settings',
    'dashboards',
    'prj_sets',
    'analytics_dashboards',
    'analytics_widgets',
    'analytics_ui'
  ],
  [componentNames.REPORTS]: [
    ...commonRequests,
    'ui_settings',
    'fr',
    'prj_sets',
    'fr_subtabs',
    'fr_columns',
    'forecast_toolbar'
  ],
  [componentNames.USERS]: [
    ...commonRequests,
    'users',
    'old_users',
    'roles',
    'permissions',
    'catalog_access_tree',
    'prj_sets',
    'tenant_users'
  ],
  [componentNames.ROLES]: [
    ...commonRequests,
    'roles',
    'prj_sets'
  ],
  [componentNames.EDIT_ROLE]: [
    ...commonRequests,
    'roles',
    'prj_sets'
  ],
  [componentNames.CREATE_ROLE]: [
    ...commonRequests,
    'roles',
    'prj_sets'
  ],
  [componentNames.CATALOGS]: [
    ...commonRequests,
    'permissions',
    'catalog_access_tree',
    'prj_sets'
  ],
  [componentNames.EDIT_CATALOG]: [
    ...commonRequests,
    'permissions',
    'catalog_access_tree',
    'prj_sets'
  ],
  [componentNames.CREATE_CATALOG]: [
    ...commonRequests,
    'permissions',
    'catalog_access_tree',
    'prj_sets'
  ],
  [componentNames.CHANGES]: [
    ...commonRequests,
    'changes'
  ],
  [componentNames.INVENTORY_PLANNED_ORDERS]: [
    ...commonRequests,
    'planned_orders_suppliers',
    'planned_orders'
  ],
  [componentNames.INTERSTORE_PLANNED_ORDERS]: [
    ...commonRequests,
    'planned_orders_suppliers',
    'planned_orders'
  ],
  [componentNames.INVENTORY_IN_TRANSITION]: [
    ...commonRequests,
    'in_transition'
  ],
  [componentNames.INTERSTORE_IN_TRANSITION]: [
    ...commonRequests,
    'in_transition'
  ],
  [componentNames.REMOVAL_SUBSTITUTION_RULES]: [
    ...commonRequests,
    'substitutions'
  ],
  [componentNames.CONSTRAINED_PLAN]: [
    ...commonRequests,
    'constrained_ordering_plan'
  ],
  [componentNames.COLLECTIONS]: [
    ...commonRequests,
    'containers',
    'groups',
    'collections'
  ],
  [componentNames.PROMOTIONS]: [
    ...commonRequests,
    ...treeRequests,
    'promotions'
  ],
  [componentNames.BATCHES_ON_HAND]: [
    ...commonRequests,
    ...treeRequests,
    'expirations'
  ],
  [componentNames.SUPPLIERS]: [
    ...commonRequests,
    'suppliers'
  ],
  [componentNames.CUSTOM_ROWS]: [
    ...commonRequests,
    'forecast_versions',
    'users',
    'old_users',
    'roles'
  ],
  [componentNames.SETTINGS]: [
    ...commonRequests,
    'prj_sets'
  ],
  ...connectorsRequestsByRoute,
  [componentNames.INTEGRATIONS]: [
    ...commonRequests,
    'integrations_list',
    'project_creation_info'
  ],
  [componentNames.BACKUPS]: [
    'wsl-project_list',
    'wsl-backups'
  ],
  [componentNames.LOGS_PAGE]: [
    'log_list'
  ],
  [componentNames.SEASONALITY_PATTERNS]: [
    'seasonality_patterns'
  ],
  [componentNames.SUBSCRIPTION] : [
    'subscription'
  ]
};

export const mountedRequestsByRouteName = {
  [componentNames.PROJECT_LIST]: [
    'wsl-project_list',
    'wsl-ram_stats'
  ],
  [componentNames.ARCHIVE]: [
    'wsl-project_list',
    'wsl-ram_stats'
  ],
  [componentNames.EXAMPLES]: [
    'examples',
    'wsl-ram_stats'
  ],
  // [componentNames.TUTORIALS]: [
  //   'tutorials'
  // ],
  [componentNames.DEMAND]: [
    'tree',
    'forecast_versions',
    'items_list',
    'demand_rows',
    'prj_sets',
    'ui_settings',
    'seasonality_patterns_model',
    'cat_headers',
    'holidays'
  ],
  [componentNames.INVENTORY]: [
    'ui_settings',
    'prj_sets',
    'ir',
    'ir_subtabs',
    'containers',
    'groups',
    'ir_columns'
  ],
  [componentNames.REPORTS]: [
    'ui_settings',
    'prj_sets',
    'fr',
    'fr_subtabs',
    'forecast_toolbar',
    'fr_columns'
  ],
  [componentNames.INTERSTORE]: [
    'ui_settings',
    'prj_sets',
    'inv_optimization',
    'inv_opt_subtabs',
    'containers',
    'groups',
    'inv_opt_columns'
  ],
  [componentNames.DASHBOARD]: [
    'ui_settings',
    'prj_sets',
    'analytics_dashboards'
  ],
  [componentNames.ROOT]: [
    'background_operations',
    'wsl-available_connectors',
    'wsl-project_list',
    'user_stats'
  ],
  [componentNames.CHANGES]: [
    'prj_sets',
    'changes'
  ],
  [componentNames.USERS]: [
    'prj_sets',
    'users',
    'old_users',
    'roles',
    'permissions',
    'tenant_users'
  ],
  [componentNames.INVENTORY_PLANNED_ORDERS]: [
    'planned_orders'
  ],
  [componentNames.INTERSTORE_PLANNED_ORDERS]: [
    'planned_orders'
  ],
  [componentNames.INVENTORY_IN_TRANSITION]: [
    'in_transition'
  ],
  [componentNames.INTERSTORE_IN_TRANSITION]: [
    'in_transition'
  ],
  [componentNames.REMOVAL_SUBSTITUTION_RULES]: [
    'substitutions'
  ],
  [componentNames.CONSTRAINED_PLAN]: [
    'constrained_ordering_plan'
  ],
  [componentNames.COLLECTIONS]: [
    'containers',
    'groups',
    'collections'
  ],
  [componentNames.PROMOTIONS]: [
    'promotions'
  ],
  [componentNames.BATCHES_ON_HAND]: [
    'expirations'
  ],
  [componentNames.CUSTOM_ROWS]: [
    'forecast_versions',
    'users',
    'old_users',
    'roles'
  ],
  [componentNames.SETTINGS]: [
    'prj_sets'
  ],
  [componentNames.BACKUPS]: [
    'wsl-project_list',
    'wsl-backups'
  ],
  [componentNames.LOGS_PAGE]: [
    'log_list'
  ],
  ...connectorsRequestsByRoute
};

export const requestDependencies = {
  // access reqs
  'prj_info': [],
  'user_access': [],
  'project_tabs': [],
  'subscription': [],
  // content reqs
  // 'tutorials': [],
  'wsl-available_connectors': [],
  'examples': [],
  'ui_settings': [...commonRequests, ...treeRequests, 'demand_rows'],
  'tree': [...commonRequests, 'cat_headers'],
  'tree_icons': [...commonRequests, 'cat_headers'],
  'tree_distortions': [...commonRequests, 'cat_headers'],
  'tree_abc': [...commonRequests, 'cat_headers'],
  'tree_notes_changed': [...commonRequests, 'cat_headers'],
  'cat_headers': commonRequests,
  'demand_node': [...commonRequests, ...treeRequests],
  'demand_rows': commonRequests,
  'forecast_versions': commonRequests,
  'chart_curve': commonRequests,
  'model_tab': [...commonRequests, 'demand_node'],
  'top_down_coeff_tab': [...commonRequests, 'demand_node'],
  'inv_param_tab': [...commonRequests, 'demand_node'],
  'kpi_tab': [...commonRequests, 'demand_node'],
  'ir': [...commonRequests, 'ui_settings'],
  'inv_optimization': commonRequests,
  'fr': [...commonRequests, 'forecast_toolbar', 'ui_settings'],
  'prj_sets': commonRequests,
  'containers': commonRequests,
  'groups': commonRequests,
  'dashboards': commonRequests,
  'changes': commonRequests,
  'ir_columns': commonRequests,
  'inv_opt_columns': [...commonRequests, 'ui_settings'],
  'fr_columns': commonRequests,
  'seasonality_patterns': commonRequests,
  'seasonality_patterns_model': commonRequests,
  'catalog_access_tree': commonRequests,
  'ir_subtabs': commonRequests,
  'inv_opt_subtabs': commonRequests,
  'fr_subtabs': commonRequests,
  'users': [...commonRequests, 'roles', 'permissions'],
  'old_users': [...commonRequests, 'roles', 'permissions'],
  'roles': commonRequests,
  'permissions': commonRequests,
  'items_list': commonRequests,
  'forecast_toolbar': commonRequests,
  'in_transition': commonRequests,
  'planned_orders': commonRequests,
  'planned_orders_suppliers': commonRequests,
  'promotions': [...commonRequests, ...treeRequests],
  'expirations': [...commonRequests, ...treeRequests],
  'holidays': commonRequests,
  'wsl-backups': ['wsl-project_list'],
  'log_list': commonRequests,
  'analytics_dashboards': commonRequests,
  'analytics_widgets': commonRequests,
  'analytics_ui': ['analytics_widgets', ...commonRequests],
  'user_stats': []
};
