import axios from '@/plugins/axios';

export default {
  fetchDashboard() {
    return axios.get('/monitoring/kpi');
  },
  setPeriods(params) {
    return axios.post('/monitoring/kpi/settings/periods_count', null, { params });
  },
  postDashboardTiles(body) {
    return axios.post('/monitoring/kpi/settings/tiles', body);
  },
  postDashboardPeriods(body) {
    return axios.post('/monitoring/kpi/settings/compare_base', body);
  }
};
