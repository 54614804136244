<template>
  <div
    class="sl-loader"
    :class="{
      ['sl-loader--inline']: inline
    }"
  >
    <icon
      data="@icons/loader.svg"
      class="sl-loader__svg"
      :class="`sl-loader--${size}`"
    />
    <div
      v-if="loaderText"
      class="sl-loader__label body-1-md grey-80"
    >
      {{ loaderText }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlLoader',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: (s) => ['lg', 'md', 'sm', 'xs', 'xxs', '3xs'].includes(s)
    },
    text: {
      type: [Boolean, String],
      default: true
    },
    inline: Boolean
  },
  computed: {
    loaderText() {
      if (typeof this.text === 'string') {
        return this.text;
      }

      return this.text
        ? this.$t('Wait.Head.Loading')
        : false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-loader";
</style>