import { noValueRelations } from '@/config/filter';

export const hasCompletedRule = (predicates = []) => {
  return predicates.some(rule => {
    if (rule.rules) {
      return hasCompletedRule(rule.rules);
    }

    const hasColumn = rule.columnKey;
    const isNoValue = noValueRelations.includes(rule.relation);
    const hasValue = Array.isArray(rule.val) ? rule.val.length : rule.val !== null;

    return hasColumn && (isNoValue || hasValue);
  });
};