import { TEMPLATE_PASSWORD } from '@/config/integrations';

export const getPasswordPayload = (password) => {
  if (!password) {
    return '';
  }

  const { value, isSet } = password;

  // password is not set
  if (!isSet) {
    return value;
  }

  // save without changes
  if (isSet && value === TEMPLATE_PASSWORD) {
    return '';
  }

  // edit existing password
  if (isSet && value !== TEMPLATE_PASSWORD) {
    return value;
  }

  // delete existing password
  if (isSet && !value) {
    return { deleted: true };
  }
};