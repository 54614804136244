import { dataFields, tabKeys } from '@/config/connection/dbRelated.config';
import { connectionTypes } from '@/config/connection';
import TabsAdapter from '@/adapters/response/connection/dbRelated/shared/Tabs.adapter';
import FileEditAdapter from '@/adapters/response/connection/shared/FileEdit.adapter';

export default function(data) {
  const { commonSets, tabInfo } = data.queries;
  const { subsidiary, employee } = data;
  const info = data.info;
  const { importTabs } = TabsAdapter({
    importData: tabInfo
  });

  return {
    ...(+info._cacheId && { cache_id: info._cacheId}),
    [dataFields.CONNECTION_DATA]: data.credentials,
    commonData: {
      type: connectionTypes.NETSUITE,
      startDate: info._startDate
    },
    [tabKeys.REQUIRED]: {
      startDate: info._startDate,
      groupBy: commonSets.groupBy,
      startFrom: +commonSets.start
    },
    [tabKeys.OPTIONAL]: {
      cleanUnusedEntries: info._cleanUnusedEntries === '1',
      combineLocations: info._combineLocs === '1',
      aggregationByDay: info._aggregationByDay === '1',
      subsidiary: subsidiary?._id ? subsidiary : null,
      employee: employee?._id ? employee : null
    },
    ...importTabs,
    [tabKeys.ORDERS_RECEIVE]: {
      ...importTabs[tabKeys.ORDERS_RECEIVE],
      purchase: commonSets.orderTypes?.purchase || '',
      transfer: commonSets.orderTypes?.transfer || '',
      manufacture: commonSets.orderTypes?.work || ''
    },
    transactionalFile: FileEditAdapter(data?.transactionsFile)
  };
}
