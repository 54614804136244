import axios from '@/plugins/axios';

export default {
  getConnectionSettings(params) {
    return axios.get('/integrations/connector/connection_settings', { params });
  },
  updateConnectionSettings(params, body) {
    return axios.put('/integrations/connector/connection_settings', body, { params });
  },
  connectToDatasource(params) {
    return axios.get('/integrations/connector/connect', { params });
  },
  getTransformSettings(params) {
    return axios.get('/integrations/connector/transform', { params });
  },
  updateTransformSettings(params, body) {
    return axios.put('/integrations/connector/transform', body, { params });
  },
  getSlots() {
    return axios.get('/integrations/connector/slots', {
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getTables(params) {
    return axios.get('/integrations/connector/tables', { params });
  },
  getTablePreview(params, body) {
    return axios.post('/integrations/connector/preview', body, { params });
  },
  getTableTempPreview(params) {
    return axios.get('/integrations/connector/preview_temp', { params });
  },
  updateIntegrationSettings(params, body) {
    return axios.put('/integrations/connector/settings', body, { params });
  },
  importIntegration(params) {
    return axios.post('/integrations/connector/import_integration', null, { params });
  }
};
