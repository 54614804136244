import { PER_PAGE_MIN } from '@/config/shared/slTable.config';

export const getTableConfigStructure = (defaultConfig = {}) => ({
  search: '',
  page: 1,
  sortOrder: '',
  colClass: '',
  perPage: PER_PAGE_MIN,
  queryKeys: [],
  ...defaultConfig
});

export const getTableConfigParams = (config) => {
  if (!config) {
    return {
      top: PER_PAGE_MIN,
      skip: 0
    };
  }

  const {
    page,
    search,
    perPage
  } = config;

  return {
    query: search || undefined,
    top: perPage,
    skip: perPage * (page - 1)
  };
};

export const getTableSortingParams = (config) => {
  if (!config) {
    return null;
  }

  const {
    sortOrder,
    colClass
  } = config;

  return {
    sortOrder,
    class: colClass
  };
};

export const sortingParamsAdapter = (table) => {
  if (!table) {
    return {};
  }

  return  {
    colClass: table?.metadata?.sortedMeaning,
    sortOrder: table?.metadata?.sortOrder
  };
};