import axios from '@/plugins/axios';

export default {
  getPlannedOrders(params) {
    return axios.get('/planned_orders', {
      params
    });
  },
  postOrders(params) {
    return axios.post('/planned_orders', null, { params });
  },
  saveOrderFilters(body) {
    return axios.put('/planned_orders/orders_filters', body);
  },
  updateOverrides(params, body) {
    return axios.put('/planned_orders/overrides', body, {
      params
    });
  },
  saveOverrides(params) {
    return axios.put('/planned_orders', null, {
      params
    });
  },
  updateVisibleColumns(params) {
    return axios.put('/planned_orders/columns', null, {
      params
    });
  },
  discardOverrides() {
    return axios.delete('/planned_orders/overrides');
  },
  exportPlannedOrdersToCsv(params) {
    return axios.get('/planned_orders/csv', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  exportPlannedOrdersToXlsx(params) {
    return axios.get('/planned_orders/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  getOrderSuppliers(params) {
    return axios.get('/planned_orders/suppliers', {
      params
    });
  },
  putOrderSupplierOverride(params, body) {
    return axios.put('/planned_orders/suppliers/override', body, {
      params
    });
  },
  saveOrderSuppliersOverrides(params) {
    return axios.put('/planned_orders/suppliers/overrides', null, {
      params
    });
  },
  deleteOrderSuppliersOverrides(params) {
    return axios.delete('/planned_orders/suppliers/overrides', {
      params
    });
  },
  toggleChildNodes(params) {
    return axios.put('/planned_orders/expanded_nodes', null, {
      params
    });
  }
};
