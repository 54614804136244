import { cellActions } from '@/mixins/report/cellActions.mixin';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { routeNames } from '@/config/router/router.config';
import { namespaceByRoute } from '@/config/report';
import { getCtrl, getDel } from '@/helpers/shared/listeners';

export const reportShortcuts = {
  mixins: [cellActions],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    table() {
      return this.$store.state[this.reportNamespace].table;
    },
    rowsMetadata() {
      return this.table.metadata.rowsMetadata;
    },
    totalRowsCount() {
      return this.table.metadata.totalRowsCount;
    },
    selectedRowsCount() {
      return this.table.metadata.selectedRowsCount;
    }
  },
  methods: {
    // actions from cellActions.mixin
    handleReportShortcuts(e) {
      if (this.isInput(e)) {
        return;
      }

      if (this.$sl_routeName !== routeNames.REPORTS) {
        // select all rows (ctrl + a)
        if (getCtrl(e) && e.keyCode === keyCodes.a) {
          e.preventDefault();

          return this.selectAll();
        }

        // download truncated project (ctrl + alt + shift + l)
        if (getCtrl(e) && e.altKey && e.shiftKey && e.keyCode === keyCodes.l) {
          e.preventDefault();

          return this.truncateProject();
        }
      }

      // prevent shortcuts if no row selected
      if (!this.cellContext) {
        return;
      }

      // copy item code (ctrl + c)
      if (getCtrl(e) && e.keyCode === keyCodes.c) {
        if (this.isTextSelected()) {
          return;
        }

        e.preventDefault();

        return this.copyItemCode(this.cellContext);
      }

      // open BOM (ctrl + m) if row selected
      if (getCtrl(e) && e.keyCode === keyCodes.m) {
        e.preventDefault();

        return this.openBomLink(this.cellContext);
      }

      // substitute (ctrl + u) if row selected
      if (getCtrl(e) && e.keyCode === keyCodes.u) {
        e.preventDefault();

        return this.substituteItem(this.cellContext);
      }

      // remove form import (alt + delete) if row selected
      if (e.altKey && getDel(e)) {
        e.preventDefault();

        return this.removeFromImport(this.cellContext);
      }

      // next shortcuts blocked for reports route
      if (this.$sl_routeName === routeNames.REPORTS) {
        return;
      }

      // open explain calculation (ctrl + i) if row selected
      if (getCtrl(e) && !e.shiftKey && e.keyCode === keyCodes.i) {
        e.preventDefault();

        return this.openExplainCalculation(this.cellContext);
      }
    }
  }
};
