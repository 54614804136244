import moment from 'moment';
import { stringToBoolean } from '@/helpers/format/workWithNums';
import { millisecondsToTime, dateFormats, is24h } from '@/helpers/date/date';
import { autoUpdateTypes } from '@/config/shared/settings.config';

const convertUTCTimeToLocal = (hour, minute) => {
  return moment.utc({
    hour,
    minute
  }).local().format(is24h() ? dateFormats.DEFAULT_TIME : dateFormats.SHORT_TIME);
};

const convertAutomation = (data) => {
  const [hour, minute] = data?.time?.split(':');

  return {
    enable: data?.enable,
    type: data?.type,
    dailyModel: data?.type === autoUpdateTypes.DAILY
      ? convertUTCTimeToLocal(hour, minute)
      : is24h() ? '06:00' : '06:00 AM',
    everyModel: data?.type === autoUpdateTypes.BY_HOURS
      ? millisecondsToTime(+data?.period * 1000)
      : '01:00'
  };
};

const mapSafetyStockMatrix = (matrix) => {
  return matrix.map(row => row.map(item => ({
    ...item,
    inventoryParams: {
      ...item.inventoryParams,
      serviceLevel: (item.inventoryParams.serviceLevel / 10).toFixed(1)
    },
    ...item.dcParams && {
      dcParams: {
        ...item.dcParams,
        serviceLevel: (item.dcParams?.serviceLevel / 10).toFixed(1)
      }
    }
  })
  ));
};

export default function(response) {
  const { settings, topDown } = response;

  return {
    ...settings,
    massTopDownItems: topDown,
    upperConfLevel: (settings.upperConfLevel * 100).toFixed(1), // %
    lowerConfLevel: (settings.lowerConfLevel * 100).toFixed(1), // %
    autoUpdate: convertAutomation(settings.autoUpdate),
    autoUpdateConstrainedOrderingPlan: convertAutomation(settings.autoUpdateConstrainedOrderingPlan),
    autoExport: {
      ...convertAutomation(settings.autoExport),
      fgs: settings.autoExport?.fgs
    },
    demandView: stringToBoolean(settings.demandView),
    safetyStock: {
      ...settings.safetyStock,
      matrix: mapSafetyStockMatrix(settings.safetyStock.matrix)
    }
  };
}
