import Vue from 'vue';
import userApi from '@/api/user';
import uiSettingsApi from '@/api/uiSettings';
import logger from '@/api/logger';
import { ContextHolder } from '@frontegg/vue';
import UserInfoResponseAdapter from '@/adapters/response/user/UserInfoResponse.adapter';
import { routeNames } from '@/config/router/router.config';
import { namespaceByRoute } from '@/config/report';
import { uiTabByRoute, uiSettingsKeys } from '@/config/users/uiSettings.config';
import { setLanguageHeader } from '@/helpers/api/axios';
import { getRouteName } from '@/helpers/shared/router';

const types = {
  SET_USER_INFO: 'SET_USER_INFO',
  RESET_STATE: 'RESET_STATE',
  SET_UI_SETTINGS: 'SET_UI_SETTINGS',
  SET_USER_STATS: 'SET_USER_STATS'
};

const defaultState = () => ({
  userInfo: null,
  ui_settings: {},
  user_stats: {}
});

const state = defaultState();

const getters = {
  userId: (state) => state.userInfo?.id,
  userName: (state) => state.userInfo?.userName,
  userRole: (state) => state.userInfo?.userRole,
  currentUiSettings: (state) => state.ui_settings[uiTabByRoute[getRouteName()]] || {}
};

const mutations = {
  [types.SET_USER_INFO](state, value) {
    state.userInfo = value;
  },
  [types.SET_UI_SETTINGS](state, { key, value }) {
    Vue.set(state.ui_settings, key, value);
  },
  [types.SET_USER_STATS](state, value) {
    state.user_stats = value;
  },
  [types.RESET_STATE](state) {
    const initialState = defaultState();

    Object.keys(state).forEach(key => {
      state[key] = initialState[key];
    });
  }
};

const actions = {
  async logout(_, payload) {
    if (payload?.withRedirect) {
      const baseUrl = ContextHolder.getContext().baseUrl;

      window.location.replace(`${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`);

      return localStorage.clear();
    }

    this.dispatch('timeMachine/reset');

    if (payload?.e && !payload.e.isAxios) {
      logger.formatAndWriteError(payload);
    }
  },
  async fetchUserInfo({ commit, dispatch, rootState }) {
    try {
      setLanguageHeader(rootState.settings.language);

      const response = await userApi.getUserInfo();

      if (!response?.data) {
        return;
      }

      const userInfo = UserInfoResponseAdapter(response.data);

      if (userInfo) {
        commit(types.SET_USER_INFO, userInfo);
      }

      return response.data;
    } catch (e) {
      dispatch('logout', { e, from: 'fetchUserInfo' });
      throw e;
    }
  },
  async reportIssue(_, { username, email, userIssue, files, needLogs }) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('file', file, encodeURIComponent(file.name));
    });

    await userApi.reportIssue({
      username,
      email,
      issue: userIssue,
      needLogs,
      formData
    });
  },
  async fetchUiSettings({ dispatch, commit }) {
    const routeName = getRouteName();
    const tab = uiTabByRoute[routeName];

    if (!tab) {
      return;
    }

    try {
      const response = await uiSettingsApi.getUiSettings({ tab });

      if (!response?.data) {
        return;
      }

      const data = response.data;

      commit(types.SET_UI_SETTINGS, {
        key: tab,
        value: data
      });

      const reportNamespace = namespaceByRoute[routeName];

      if (reportNamespace) {
        this.dispatch(`${reportNamespace}/setColumnSizes`, data[uiSettingsKeys.COLUMNS_WIDTH] || []);
      } else if (routeName === routeNames.DEMAND) {
        this.dispatch('demand/table/setResizedColumns', data[uiSettingsKeys.COLUMNS_WIDTH] || []);
      }
    } catch (e) {
      dispatch('logout', { e, from: 'fetchUiSettings' });
    }
  },
  async changeUiSettings({ dispatch }, { key, value }) {
    const routeName = getRouteName();
    const tab = uiTabByRoute[routeName];

    if (!tab) {
      return;
    }

    try {
      await uiSettingsApi.changeUiSettings(value, { tab, key });
    } catch (e) {
      dispatch('logout', { e, from: 'changeUiSettings' });
    }
  },
  async fetchUserStats({ dispatch, commit }) {
    try {
      const response = await userApi.getUserStats();
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_USER_STATS, data);
    } catch (e) {
      dispatch('logout', { e, from: 'fetchUserStats' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
