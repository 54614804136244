import {
  DEFAULT_SECTION_HEIGHT_PC,
  MAX_SECTION_WIDTH,
  emptySection
} from '@/config/dashboard/analytics.config';

const getRepositionedWidgetSizes = (widgets) => {
  if (!widgets.length) {
    return [];
  }

  const widgetsSize = Math.round(widgets.reduce((acc, widget) => acc + widget.width, 0));

  if (widgetsSize === MAX_SECTION_WIDTH) {
    return widgets;
  }

  return widgets.map(widget => ({
    ...widget,
    width: +(MAX_SECTION_WIDTH / widgets.length).toFixed(2)
  }));
};

export const applyPositionChanges = (sections) => {
  if (!sections?.length) {
    return [];
  }

  return sections.reduce((acc, { widgets, height }) => {
    const needCollapse = !widgets.length && !!height;
    const needExpand = !!widgets.length && !height;

    if (needCollapse) {
      return acc;
    }

    acc.push({
      height: needExpand ? DEFAULT_SECTION_HEIGHT_PC : height,
      widgets: getRepositionedWidgetSizes(widgets)
    });

    if (needExpand) {
      acc.push(emptySection());
    }

    return acc;
  }, []);
};

const getResizedSectionHeight = (section, sectionIndex, config) => {
  if (!config.sectionIndex === sectionIndex) {
    return section.height;
  }

  return config.height ?? section.height;
};

const getResizedWidgets = (widgets, config) => {
  if (!widgets?.length) {
    return [];
  }

  return widgets.map((widget, widgetIndex) => {
    const width = config.widgetIndex === widgetIndex
      ? config.width
      : config.widgetSiblingIndex === widgetIndex
        ? config.siblingWidth
        : widget.width;

    return {
      ...widget,
      width
    };
  });
};

export const applySizeChanges = (sections, config) => {
  if (!sections?.length) {
    return [];
  }

  return sections.map((section, sectionIndex) => {
    if (config.sectionIndex !== sectionIndex) {
      return section;
    }

    return {
      height: getResizedSectionHeight(section, sectionIndex, config),
      widgets: getResizedWidgets(section.widgets, config)
    };
  });
};

export const addWidgetToSection = (id, section = null) => ({
  height: section?.height ?? DEFAULT_SECTION_HEIGHT_PC,
  widgets: [
    ...(section?.widgets || []),
    { id, width: MAX_SECTION_WIDTH }
  ]
});

export const addWidget = (sections, widgetId) => {
  const filledSections = sections.filter(({ widgets }) => widgets.length);

  if (!filledSections.length) {
    return [addWidgetToSection(widgetId)];
  }

  return filledSections.reduce((acc, section, index) => {
    const isLastSection = !filledSections[index + 1];
    const isSingleWidgetSection = section.widgets.length <= 1;

    if (!isLastSection) {
      return [
        ...acc,
        section
      ];
    }

    if (isSingleWidgetSection) {
      return [
        ...acc,
        addWidgetToSection(widgetId, section)
      ];
    }

    return [
      ...acc,
      section,
      addWidgetToSection(widgetId),
      ...(isLastSection && [emptySection()])
    ];
  }, []);
};

export const deleteWidget = (sections, { sectionIndex, widgetId }) => {
  return sections.map((section, index) => {
    if (index !== sectionIndex) {
      return section;
    }

    return {
      ...section,
      widgets: section.widgets.filter(({ id }) => id !== widgetId)
    };
  });
};

export const toggleDataLabels = (sections, { sectionIndex, widgetId, ...value }) => {
  return sections.map((section, index) => {
    if (index !== sectionIndex) {
      return section;
    }

    return {
      ...section,
      widgets: section.widgets.map(widget => {
        if (widget.id !== widgetId) {
          return widget;
        }

        return {
          ...widget,
          ...value
        };
      })
    };
  });
};