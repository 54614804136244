export default function(data) {
  if (!data) {
    return null;
  }

  return Object.keys(data).reduce((acc, key) => {
    if (data[key]?.length) {
      const categories = data[key].map(item => ({
        type: key,
        value: item
      }));

      acc.push(...categories);
    }

    return acc;
  }, []);
}