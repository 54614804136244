import axios from '@/plugins/axios';

export default {
  getSeasonPatterns(params = null) {
    return axios.get('/seasonality_patterns', { params });
  },
  getSeasonPatternsModel() {
    return axios.get('/seasonality_patterns/model');
  },
  updateSeasonalPatterns(body, params) {
    return axios.put('/seasonality_patterns', body, { params });
  },
  createPattern(body, params) {
    return axios.post('/seasonality_patterns', body, { params });
  },
  deletePattern(params) {
    return axios.delete('/seasonality_patterns', { params });
  }
};