import Vue from 'vue';
import vueI18n from '@/plugins/vue-i18n';
import analyticsApi from '@/api/dashboard/analytics';
import RequestUiSettingsAdapter from '@/adapters/request/dashboard/UiSettings.adapter';
import ResponseUiSettingsAdapter from '@/adapters/response/dashboard/UiSettings.adapter';
import {
  applyPositionChanges,
  applySizeChanges,
  deleteWidget,
  addWidget,
  toggleDataLabels
} from '@/helpers/dashboard/analytics';
import { KPIsKey } from '@/config/dashboard/dashboard.config';

const types = {
  SET_DASHBOARDS_LIST: 'SET_DASHBOARDS_LIST',
  SET_UI_SETTINGS: 'SET_UI_SETTINGS',
  SET_WIDGETS: 'SET_WIDGETS'
};

const initialState = () => ({
  dashboards_list: [],
  widgets: [],
  ui_settings: []
});

const state = initialState();

const getters = {
  existingNames: (state) => state.dashboards_list?.map(({ name }) => name) || [],
  dashboardByIdMap: (state) => state.dashboards_list?.reduce((acc, item) => {
    acc[item.id] = item;

    return acc;
  }, {}) ?? {},
  widgetByIdMap: (state) => state.widgets?.reduce((acc, item) => {
    acc[item.id] = item;

    return acc;
  }, {}) ?? {},
  activeDashboard: (_, getters, rootState) => getters.dashboardByIdMap[rootState.dashboard.tab] ?? {}
};

const mutations = {
  [types.SET_DASHBOARDS_LIST](state, value) {
    state.dashboards_list = value;
  },
  [types.SET_UI_SETTINGS](state, value) {
    state.ui_settings = value;
  },
  [types.SET_WIDGETS](state, value) {
    state.widgets = value;
  }
};

const actions = {
  async fetchDashboardsList({ commit, rootState }) {
    try {
      // prevent fetch if user has no data access
      const { purchasingData, salesData } = rootState.access.feature_access;

      if (!purchasingData || !salesData) {
        return;
      }

      const response = await analyticsApi.fetchDashboards();
      const dashboards = response?.data?.dashboards;

      if (!dashboards) {
        return;
      }

      commit(types.SET_DASHBOARDS_LIST, dashboards);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/fetchDashboardsList' });
    }
  },
  async createDashboard(_, name) {
    try {
      const response = await analyticsApi.createDashboard({ name });

      Vue.notify({
        type: 'success',
        title: vueI18n.t('Web.Notification.CreatedSuccessfully', {
          1: vueI18n.t('Web.Dashboard.Title')
        })
      });

      return response?.data?.payload?.id ?? null;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/createDashboard' });

      throw e;
    }
  },
  async renameDashboard(_, { id: dashboardId, name }) {
    try {
      await analyticsApi.renameDashboard(
        { dashboardId },
        { name }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/renameDashboard' });
    }
  },
  async deleteDashboard(_, { id: dashboardId }) {
    try {
      await analyticsApi.deleteDashboard({ dashboardId });

      Vue.notify({
        type: 'success',
        title: vueI18n.t('Web.Notification.DeletedSuccessfully', {
          1: vueI18n.t('Web.Dashboard.Title')
        })
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/deleteDashboard' });
    }
  },
  async fetchDashboardUiSettings({ getters, rootState, commit }) {
    try {
      if (rootState.dashboard.tab === KPIsKey) {
        return;
      }

      const response = await analyticsApi.getDashboardUiSettings({
        dashboardId: rootState.dashboard.tab
      });
      const sections = response?.data?.sectionsInfo;

      if (!sections) {
        return;
      }

      commit(types.SET_UI_SETTINGS, ResponseUiSettingsAdapter(sections, getters.widgetByIdMap));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/fetchDashboardUiSettings' });
    }
  },
  repositionGrid({ commit, state, dispatch }) {
    commit(types.SET_UI_SETTINGS, applyPositionChanges(state.ui_settings));

    return dispatch('updateDashboardUiSettings');
  },
  resizeGrid({ commit, state, dispatch }, payload) {
    commit(types.SET_UI_SETTINGS, applySizeChanges(state.ui_settings, payload));

    return dispatch('updateDashboardUiSettings');
  },
  async updateDashboardUiSettings({ state, rootState }) {
    try {
      await analyticsApi.updateDashboardUiSettings(
        { dashboardId: rootState.dashboard.tab },
        RequestUiSettingsAdapter(state.ui_settings)
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/updateDashboardUiSettings' });
    }
  },
  async fetchWidgets({ rootState, commit }) {
    try {
      if (rootState.dashboard.tab === KPIsKey) {
        return;
      }

      const response = await analyticsApi.getWidgetsList({
        dashboardId: rootState.dashboard.tab
      });
      const widgets = response?.data?.widgets;

      if (!widgets) {
        return;
      }

      commit(types.SET_WIDGETS, widgets);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/fetchWidgets' });
    }
  },
  async createWidgetCache({ rootState }, widgetId) {
    try {
      const response = await analyticsApi.createWidgetCache({
        dashboardId: rootState.dashboard.tab,
        widgetId
      });

      return response?.data?.payload?.id ?? null;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/createWidgetCache' });
    }
  },
  async fetchWidgetConfiguration({ rootState }, widgetId) {
    try {
      const response = await analyticsApi.getWidgetConfiguration({
        dashboardId: rootState.dashboard.tab,
        widgetId
      });

      return response?.data;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/fetchWidgetConfiguration' });
    }
  },
  async updateWidgetConfiguration(_, { params, body }) {
    try {
      await analyticsApi.updateWidgetConfiguration(
        params,
        body
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/updateWidgetConfiguration' });
    }
  },
  async saveWidgetConfiguration(_, params) {
    try {
      const response = await analyticsApi.saveWidgetConfiguration(
        params
      );

      return response?.data?.payload?.id ?? null;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/saveWidgetConfiguration' });
    }
  },
  async discardWidgetConfiguration(_, params) {
    try {
      if (!params) {
        return;
      }

      await analyticsApi.discardWidgetConfiguration(
        params
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/discardWidgetConfiguration' });
    }
  },
  async addWidget({ commit, dispatch }, widgetId) {
    try {
      commit(types.SET_UI_SETTINGS, addWidget(state.ui_settings, widgetId));

      await dispatch('repositionGrid');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/addWidget' });
    }
  },
  async duplicateWidget({ commit, dispatch }, { dashboardId, widgetId }) {
    try {
      const response = await analyticsApi.duplicateWidget({
        dashboardId,
        widgetId
      });
      const newWidgetId = response?.data?.payload?.id;

      commit(types.SET_UI_SETTINGS, addWidget(state.ui_settings, newWidgetId));

      await dispatch('repositionGrid');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/deleteWidget' });
    }
  },
  async deleteWidget({ commit, dispatch }, { dashboardId, widgetId, sectionIndex }) {
    try {
      await analyticsApi.deleteWidget({
        dashboardId,
        widgetId
      });

      commit(types.SET_UI_SETTINGS, deleteWidget(state.ui_settings, {
        sectionIndex,
        widgetId
      }));
      await dispatch('repositionGrid');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'analytics/deleteWidget' });
    }
  },
  toggleDataLabels({ commit, state, dispatch }, payload) {
    commit(types.SET_UI_SETTINGS, toggleDataLabels(state.ui_settings, payload));

    return dispatch('updateDashboardUiSettings');
  },
  exportWidgetToXlsx(_, params) {
    return analyticsApi.exportWidgetToXlsx(params);
  },
  resetDashboard({ commit }) {
    commit(types.SET_UI_SETTINGS, []);
    commit(types.SET_WIDGETS, []);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
