import { formatNumber } from '@/helpers/format/formatNumberWithoutRemainder';
import { chartColors, periodKeys, tileKeys, titleByKey } from '@/config/dashboard/dashboard.config';

const MONTHS_IN_YEAR = 12;

function getDisplayData(valueToCompare, value, isSelfCompared, lessIsBetter, tileType) {
  if (valueToCompare === '—' || !valueToCompare) {
    return {
      value: '—'
    };
  } else if (isSelfCompared) {
    return {
      value: ' '
    };
  }

  let result;

  if (tileType === tileKeys.MARGIN) {
    result = Math.round(10000 * (value - valueToCompare)) / 100;
  } else if (tileType === tileKeys.TURNOVER) {
    result = Math.round(10000 * (1 - value / valueToCompare)) / 100;
  } else {
    result = Math.round(100 * (1 - value / valueToCompare));
  }

  if (isNaN(result)) {
    return {
      value: ' '
    };
  }

  let arrowColor;
  const direction = value > valueToCompare;

  if (lessIsBetter) {
    arrowColor = direction ? chartColors.red : chartColors.green;
  } else {
    arrowColor = direction ? chartColors.green : chartColors.red;
  }

  return {
    value: Math.abs(result) + '%',
    arrowColor,
    arrowDirection: direction ? 'up' : 'down'
  };
}

function getPercOfMax(value, maxValue) {
  return Math.abs(maxValue) > Math.abs(value) ? value / maxValue : maxValue / value;
}

export default function({ data, currency }) {
  let getComparedIndex = () => 1;

  const periodsNumber = data.settings.periods;
  const archive = data.archive.slice(-periodsNumber);

  const selectDates = archive.map(el => el.period);
  const barDates = [...selectDates];

  const selectTileNames = [];
  const visibleTileNames = [];

  const isVisible = {};
  const isAvailable = {};
  const periodValue = {};

  let tileValue;

  Object.entries(data.settings.fgs).forEach(([key, value]) => {
    const codeTitle = titleByKey[key].code;

    isVisible[key] = value.val;
    isAvailable[codeTitle] = value.available;
    selectTileNames.push(titleByKey[key].code);
  });

  data.settings.dashPeriods.forEach(el => {
    periodValue[el.name] = el.val;
  });

  const barValuesLength = data.archive.length > periodsNumber ? periodsNumber : data.archive.length;
  let emptyBarsLength = periodsNumber - barValuesLength;

  if (emptyBarsLength < 0) {
    emptyBarsLength = 0;
  }

  const lastBarIndex = barValuesLength - 1;

  const tiles = data.tileInfo
    .filter(tile => isVisible[tile.tileType])
    .map((tile) => {
      const tileName = tile.tileType;
      const tileTitle = titleByKey[tileName]?.code ?? tile.name?.code ;
      const hasArchiveData = archive.some(item => {
        return Object.prototype.hasOwnProperty.call(item, titleByKey[tileName].prop);
      });

      visibleTileNames.push(tileTitle);

      const fgs = {};

      Object.entries(tile.fgs).forEach(([key, val]) => {
        fgs[key] = val;
      });

      const archiveName = titleByKey[tileName].prop;
      const period = periodValue[tileName];

      const barValues = archive.map(column => +column[archiveName] || 0);

      const maxValue = Math.max(...barValues);

      if (tile.tileType === tileKeys.TURNOVER || tile.tileType === tileKeys.MARGIN) {
        tileValue = (Math.round(100 * barValues[lastBarIndex] * tile.multiplier) / 100).toString();
      } else {
        tileValue = formatNumber(barValues[lastBarIndex] * tile.multiplier);
      }

      const parsedBarValues = barValues.map((value, index) => {
        if (period === periodKeys.START) {
          getComparedIndex = () => 0;
        } else if (period === periodKeys.PREVIOUS) {
          getComparedIndex = (idx) => idx - 1;
        } else if (period === periodKeys.LAST_YEAR) {
          getComparedIndex = (idx) => {
            const currentPeriod = archive[idx].period;
            const comparedIndex = idx + periodsNumber > (MONTHS_IN_YEAR - 1) ? idx - MONTHS_IN_YEAR : -1;
            const comparedPeriod = archive[comparedIndex]?.period;

            if (comparedIndex < 0 || new Date(currentPeriod).getMonth() === new Date(comparedPeriod).getMonth()) {
              return comparedIndex;
            } else {
              return comparedIndex - 1;
            }
          };
        }

        const comparedIndex = getComparedIndex(index);
        let valueToCompare = barValues[comparedIndex];

        if (comparedIndex < 0) {
          valueToCompare = '—';
        }

        const isSelfCompared = comparedIndex === index;
        const displayData = getDisplayData(
          valueToCompare,
          value,
          isSelfCompared,
          fgs.lessIsBetter,
          tile.tileType
        );

        return {
          value,
          percOfMax: maxValue ? getPercOfMax(value, maxValue) : 0,
          hovered: false,
          empty: false,
          displayData
        };
      });

      const emptyBar = new Array(emptyBarsLength).fill({
        value: maxValue,
        percOfMax: 0,
        empty: true
      });

      const bar = parsedBarValues.concat(emptyBar);

      return {
        tileTitle,
        planItems: tile.nItems,
        period,
        unit: tile.unit,
        tileValue,
        currency: fgs.showCurrency === 'true' ? currency : '',
        bar,
        getComparedIndex,
        visible: hasArchiveData,
        type: tile.tileType,
        lessIsBetter: fgs.lessIsBetter
      };
    });

  return {
    periodsNumber,
    selectDates,
    barDates,
    selectTileNames,
    visibleTileNames,
    activeDate: lastBarIndex,
    tiles,
    isAvailable
  };
}
