import axios from '@/plugins/axios';

export default {
  createCache(params, body) {
    return axios.post('/db_related/data', body, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  updateCache(params, body) {
    return axios.put('/db_related/data', body, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getDatabaseTables(params) {
    return axios.post('/db_related/list', null, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getTablePreview(params) {
    return axios.get('/db_related/preview', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getTableTempPreview(params) {
    return axios.get('/db_related/preview_temp', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getExportStubs(params) {
    return axios.get('/db_related/export/stub', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  exportToCsv(params) {
    return axios.get('/db_related/export', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  downloadCsv(params) {
    return axios.get('/db_related/download', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream',
        'X-pid-independent': true
      }
    });
  },
  testExport(params, body) {
    return axios.post('/db_related/export/test', body, {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getSlotsList(params) {
    return axios.get('/db_related/slots', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  }
};