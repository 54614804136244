import { shopifyAuthTypes } from '@/config/connection';

export default function(data) {
  const {
    _shopName: shopName,
    _authType: authType
  } = data.settings.credentials;
  const {
    _wantSubcats: wantSubcats,
    _combineLocs: combineLocs
  } = data.settings.info || {};

  const commonStructure = {
    required: {
      authType,
      shopName
    },
    optional: {
      wantSubcats,
      combineLocs
    }
  };

  return shopifyAdapterByAuthType[authType](commonStructure, data);
}

const shopifyAdapterByAuthType = {
  [shopifyAuthTypes.OAUTH]: shopifyOauth,
  [shopifyAuthTypes.CUSTOM]: shopifyCustomApp
};

function shopifyOauth(data, source) {
  const apiAccessToken = source.settings.oauthToken?.apiAccessToken?._val;

  data.required = {
    ...data.required,
    apiAccessToken
  };

  return data;
}

function shopifyCustomApp(data, source) {
  const {
    _apiKey: apiKey,
    _apiAccessToken: apiAccessToken
  } = source.settings.credentials;

  data.required = {
    ...data.required,
    apiKey,
    apiAccessToken
  };

  return data;
}
