<template>
  <div
    class="sl-combobox-wrapper"
    :class="{
      'sl-combobox--custom': isComboboxCustom,
      'sl-combobox--multiple': multiple
    }"
  >
    <SlSelect
      ref="select"
      :value="vModel"
      :options="options"
      :track-by="trackBy"
      :clear-on-select="clearOnSelect"
      :multiple="multiple"
      searchable
      preserve-search
      v-bind="$attrs"
      v-on="bindListeners"
      @open="handleOpen"
      @close="handleClose"
      @search-change="handleSearchChange"
      @input="handleInput"
      @clear="handleClear"
    >
      <template
        v-for="(_, slot) in $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>
    </SlSelect>
    <SlButton
      v-if="multiple && isAddable"
      v-tooltip="getTooltip($t('Web.AddOwnValue'))"
      size="xs"
      variant="tertiary"
      color="grey"
      class="sl-combobox__add"
      :class="{
        'sl-combobox__add--shifted': !isClearable,
      }"
      icon
      @click="handleAddCustomTag"
    >
      <icon
        data="@icons/check.svg"
        class="fill-off size-16"
      />
    </SlButton>
  </div>
</template>

<script>
import { keyCodes } from '@/config/utils/statusCodes.config';
import { toArray } from '@/helpers/utils/toArray';
import { parseCsv } from '@/helpers/utils/parseCsv';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlCombobox',
  props: {
    value: {
      type: [String, Number, Object, Array, null],
      default: null
    },
    options: {
      type: Array,
      default: () => ([])
    },
    trackBy: {
      type: String,
      default: 'value'
    },
    hideInputOnSelection: {
      type: Boolean,
      default: true
    },
    multiple: Boolean,
    clearOnSelect: Boolean
  },
  data() {
    return {
      customValue: '',
      isAddable: false,
      getTooltip
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    bindListeners() {
      const { search, input, ...listeners } = this.$listeners;

      return listeners;
    },
    isOptionObject() {
      return this.options.length && typeof this.options[0] === 'object';
    },
    isComboboxCustom() {
      if (this.isOptionObject) {
        return this.customValue && !this.options.some(option => option[this.trackBy] === this.customValue);
      }

      return this.customValue && !this.options.includes(this.customValue);
    },
    isClearable() {
      return this.$refs.select.isClearable;
    }
  },
  mounted() {
    const searchInput = this.getSearchInputNode();

    searchInput.addEventListener('keydown', this.keydownHandler);
    searchInput.addEventListener('paste', this.pasteHandler);
    searchInput.addEventListener('blur', this.handleBlur);

    if (this.multiple) {
      return;
    }

    this.setSearch(this.vModel);
  },
  beforeDestroy() {
    const searchInput = this.getSearchInputNode();

    searchInput.removeEventListener('keydown', this.keydownHandler);
    searchInput.removeEventListener('paste', this.pasteHandler);
    searchInput.removeEventListener('blur', this.handleBlur);
  },
  methods: {
    getSearchInputNode() {
      return this.$refs.select.$el.querySelector('.multiselect__input');
    },
    setSearch(value) {
      const multiselect = this.$refs.select.$refs.multiselect;

      multiselect.search = typeof value === 'number' ? `${value}` : value;
      this.customValue = value;
    },
    selectInputValue() {
      const inputNode = this.getSearchInputNode();

      inputNode && inputNode.select();
    },
    handleOpen() {
      this.$emit('open');

      if (this.multiple) {
        return;
      }

      this.setSearch(this.vModel);
      this.selectInputValue();
    },
    handleClose() {
      this.$emit('close');

      if (this.multiple) {
        this.setSearch('');
      }
    },
    handleSearchChange(value) {
      if (value !== this.vModel && !this.multiple) {
        this.vModel = value;
      }

      if (this.multiple) {
        this.isAddable = Boolean(value);
      }

      this.customValue = value;

      this.$emit('search', value);
    },
    handleInput(value) {
      this.vModel = value;

      if (this.hideInputOnSelection) {
        this.customValue = this.isOptionObject ? value[this.trackBy] : value;
      }
    },
    handleAddCustomTag() {
      this.setValue(this.customValue);

      this.$emit('close');
    },
    handleBlur(e) {
      const isAddButton = e.relatedTarget?.closest('.sl-combobox__add');

      if (this.multiple && !isAddButton) {
        this.setSearch('');
      }
    },
    handleClear() {
      this.setSearch('');

      this.$emit('clear');
    },
    keydownHandler(event) {
      if (!this.multiple || event.keyCode !== keyCodes.enter) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (this.isComboboxCustom) {
        this.setValue(this.customValue);
      }
    },
    async pasteHandler(event) {
      event.preventDefault();
      event.stopPropagation();

      const clipboardText = event.clipboardData.getData('text');

      if (this.multiple) {
        return this.setValue(parseCsv(clipboardText));
      }

      this.setSearch(this.customValue + clipboardText);
    },
    setValue(value) {
      this.vModel = this.multiple
        ? [...new Set([...this.vModel, ...toArray(value)])]
        : value;

      if (this.multiple) {
        this.setSearch('');
      }

      this.$refs.select.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-combobox';
</style>