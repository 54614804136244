import { fileTypeFromName } from '@/helpers/utils/fileUpload';

export default function(data) {
  if (!data?._fileName) {
    return null;
  }

  const {
    _sz: size,
    _fileName: name
  } = data;

  return {
    name,
    size,
    type: fileTypeFromName(name)
  };
}