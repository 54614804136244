import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../../public/locale';
import clientStorage from '@/api/clientStorage';
import clientStorageKeysConfig from '@/config/api/clientStorageKeys.config';
import { availableLocales, slavicLocales } from '@/config/plugins/vue-i18n.config';

const currentLocale = clientStorage.getDataByKey(clientStorageKeysConfig.CURRENT_LOCALE_KEY) || availableLocales[0];

const defaultLocaleRule = (choice, choicesLength) => {
  // "1st | 2nd | 3rd | 4th | ''(empty line added for separation from slavic locale)" start from 0 for week num
  if (choicesLength === 5) {
    return choice - 1;
  }

  if (choice === 1) {
    return 0;
  }

  // "Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday" also starts from 0
  if (choicesLength > 5) {
    return choice;
  }

  return 1;
};
const slavicLocaleRule = (choice, choicesLength) => {
  // "1st | 2nd | 3rd | 4th | ''(empty line added for separation from slavic locale)" start from 0 for week num
  if (choicesLength === 5) {
    return choice - 1;
  }

  // "Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday" also starts from 0
  if (choicesLength > 5) {
    return choice;
  }

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2;
  }

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
};

const getPluralizationRules = (allLocales, slavicLocales) => {
  return allLocales.reduce((rules, locale) => {
    if (slavicLocales.includes(locale)) {
      rules[locale] = slavicLocaleRule;

      return rules;
    }

    rules[locale] = defaultLocaleRule;

    return rules;
  }, {});
};

Vue.use(VueI18n);

export default new VueI18n({
  sync: true,
  locale: currentLocale,
  availableLocales,
  messages,
  pluralizationRules: getPluralizationRules(availableLocales, slavicLocales),
  silentTranslationWarn: process.env.VUE_APP_MODE === 'production',
  missing: () => ('')
});
