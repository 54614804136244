import { tabKeys } from '@/config/connection/dbRelated.config';

export default function(state) {
  const {
    host: _host,
    port: _port,
    companyKey: _companyKey
  } = state[tabKeys.EXPORT];

  return {
    advanceSettings: {
      _host,
      _port,
      _companyKey
    }
  };
}