<template>
  <Component
    :is="componentName"
    :to="to"
    class="sl-link-card"
    v-on="$listeners"
  >
    <template v-if="$scopedSlots.icon">
      <div class="sl-link-card__icon">
        <slot name="icon" />
      </div>
    </template>
    <div class="sl-link-card__body">
      <h3 class="heading-3-sb grey-80">
        {{ title }}
      </h3>
      <p class="body-1 grey-70">
        {{ description }}
      </p>
    </div>
  </Component>
</template>

<script>
export default {
  name: 'SlLinkCard',
  props: {
    to: {
      type: [String, Object],
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    componentName() {
      return this.to ? 'router-link' : 'div';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-link-card";
</style>
